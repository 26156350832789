export default [
  {
    name: 'DOCUMENT GENERATOR',
    paths: ['/manage/document-generator-templates', '/documents']
  },
  {
    name: 'PLUGINS',
    paths: ['/manage/plugins']
  }
]
