import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Alert, Button } from 'antd'

import { StatusSummary } from '@/components/DocumentViewer/Content/Activities/Styled'
import { VERIFY_BOOKING_DOCUMENT } from '@/components/DocumentViewer/Content/schema'
import auth0 from '@/hocs/auth0'
import { handleResponse } from '@/utils/booking'
import { logger } from '@/utils/logger'

type DocumentVerifyViewProps = {
  bookingDocument: any
  toggleDocumentViewer: any
  refreshBooking: any
}

const DocumentVerifyView = (props: DocumentVerifyViewProps) => {
  const { bookingDocument, refreshBooking, toggleDocumentViewer } = props

  const [verifyBookingDocumentMutation, { error, loading }] = useMutation(VERIFY_BOOKING_DOCUMENT, {
    variables: { uuid: bookingDocument.uuid }
  })

  const handleVerified = async () => {
    try {
      await verifyBookingDocumentMutation()

      setTimeout(async () => {
        if (refreshBooking) {
          await refreshBooking()
        }
        toggleDocumentViewer()
      }, 2000)
    } catch (error) {
      if (error) {
        logger.error('Verify document err:', error)
        handleResponse(error)
      }
    }
  }

  if (bookingDocument.status === 'VERIFIED') {
    return (
      <StatusSummary>
        <CheckCircleOutlined twoToneColor="#52c41a" /> Document has been verified.
      </StatusSummary>
    )
  } else if (bookingDocument.status === 'DELETED') {
    return (
      <StatusSummary>
        <CloseCircleOutlined twoToneColor={'#f5222d'} /> Document has been deleted.
      </StatusSummary>
    )
  }

  return (
    <>
      {error && <Alert message={error.toString()} type="error" />}
      <Button type="primary" loading={loading} onClick={() => handleVerified()}>
        {loading ? 'Hang on...' : 'Verify this document'}
      </Button>
    </>
  )
}

export default auth0(DocumentVerifyView)
