import { useTranslation } from 'react-i18next'
import { Button, Result, Typography } from 'antd-v5'

function ErrorFallback() {
  const { t } = useTranslation()

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center'
      }}
    >
      <Result
        status="500"
        title={t('error.somethingWentWrong')}
        subTitle={t('error.pleaseContactItSupportForFurtherAssistance')}
        extra={
          <Button style={{ height: 30 }} href="/" type="primary">
            {t('error.backHome')}
          </Button>
        }
      />
    </div>
  )
}

export default ErrorFallback

export function ComponentErrorFallback() {
  const { t } = useTranslation()

  return (
    <Typography.Paragraph style={{ color: '#E53D46', padding: 10 }}>
      {t('error.errorRenderComponent')}
    </Typography.Paragraph>
  )
}
