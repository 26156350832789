import type { PropsWithChildren } from 'react'

import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import startsWith from 'lodash/startsWith'

import FeatureGuard from '@/components/Shared/FeatureGuard'
import flaggedPaths from '@/components/Shared/FeatureGuard/flaggedPaths'

type FeatureGuardUrlProps = PropsWithChildren<unknown>

const FeatureGuardUrl = (props: FeatureGuardUrlProps) => {
  const fPaths = useMemo(() => flaggedPaths, [])
  const location = useLocation()

  const featureFlagged = useMemo(() => {
    return fPaths.find(path => {
      const match = path.paths.find(p => startsWith(location.pathname, p))
      return match
    })
  }, [location.pathname, fPaths])

  return featureFlagged ? (
    <FeatureGuard name={featureFlagged.name} ghost={false}>
      {props.children}
    </FeatureGuard>
  ) : (
    <>{props.children}</>
  )
}

export default FeatureGuardUrl
