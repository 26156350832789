import type { SelectProps } from 'antd/lib/select'

import { useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Icon, Select } from 'antd'
import debounce from 'lodash/debounce'

import { GET_CURRENCIES } from '@/components/Manage/Currencies/schema'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const { Option } = Select

interface CurrencySelectProps extends SelectProps {
  showAll?: boolean
}

const CurrencySelect = ({ showAll, ...props }: CurrencySelectProps) => {
  const [allCurrencies, setAllCurrencies] = useState<any[]>([])
  const [searchInput, setSearchInput] = useState<string>('')

  const { error } = useQuery(GET_CURRENCIES, {
    variables: {
      q: searchInput || '',
      showAll
    },
    onCompleted: data => {
      setAllCurrencies(data?.currencies?.rows || [])
    },
    fetchPolicy: 'cache-and-network'
  })

  const handleSearch = useCallback(
    debounce(value => {
      try {
        setSearchInput(value)
      } catch (err) {
        logger.error('CurrencySelect handleSearch error', err)
      }
    }, 500),
    []
  )

  if (error) {
    logger.error('CurrencySelect error', error)
    responseHandler(error, 'error')
  }
  const sort = currencies => {
    return [...currencies].sort((a, b) => (a.code < b.code ? -1 : 1))
  }

  const sorted = sort(allCurrencies)
  return (
    <Select
      showSearch
      filterOption
      optionFilterProp="children"
      placeholder="Select a currency..."
      {...props}
      onSearch={handleSearch}
      notFoundContent={<Icon type="loading" />}
    >
      {sorted.map(currency => (
        <Option key={currency.uuid} value={currency.uuid} data-currency={JSON.stringify(currency)}>
          {currency.code} - {currency.name}
        </Option>
      ))}
    </Select>
  )
}

export default CurrencySelect
