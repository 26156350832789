import { graphql } from '@apollo/client/react/hoc'

import bookingSchema from 'App/containers/booking/schema'
import schema from '../schema'

export default graphql(schema.CHANGE_VOUCHER_STATUS, {
  props: ({ mutate }) => ({
    changeVoucherStatus: params =>
      mutate({
        variables: params
      })
  })
})

export const changeVoucherStatusWithBookingRefetch = graphql(schema.CHANGE_VOUCHER_STATUS, {
  props: ({ mutate }) => ({
    changeVoucherStatus: (params, bookingUuid) =>
      mutate({
        variables: params,
        refetchQueries: [
          {
            query: bookingSchema.BOOKING_VOUCHER_COST_SHEET,
            variables: {
              uuid: bookingUuid
            }
          }
        ]
      })
  })
})
