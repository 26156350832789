import { Component } from 'react'
import { Button, Col, Icon, Popconfirm, Row, Tag } from 'antd'
import find from 'lodash/find'
import PropTypes from 'prop-types'

import ApprovalSelector from '@/components/ApprovalSelector'
import useGlobalCompanyStore from '@/store/globalCompany'
import { approvalTypes, documentType } from 'App/utils/labelMap'
import handleResponse from 'App/utils/responseHandler'
import DocumentFieldUpdate from './DocumentFieldUpdate'
import { Approval, DocumentTitle, ImagePreview, StyledModal } from './Styled'
import TagsValueUpdate from './TagsValueUpdate'

class Preview extends Component {
  handleClose = () => {
    this.props.togglePreview(false)
  }

  handleVerify = async () => {
    handleResponse('Verifying document, hang on...', 'load')

    const { selectedDocument, uploadActions, toggleSelectedDocument } = this.props
    const resp = await uploadActions.handleVerifyDocument(selectedDocument.uuid)

    if (resp.success) {
      const updatedDocument = { ...selectedDocument }
      updatedDocument.status = 'VERIFIED'

      toggleSelectedDocument(updatedDocument)
      this.handleClose()

      handleResponse('Verified successfully.', 'success')
    } else {
      handleResponse(resp.message, 'error')
    }
  }

  handleSent = async () => {
    handleResponse('Mark document as sent to custom...', 'load')

    const { selectedDocument, uploadActions } = this.props
    const resp = await uploadActions.handleMarkSentDocument({
      uuid: selectedDocument.uuid,
      type: 'impCustK1',
      hardCopyStatus: 'SUBMITTED',
      location: 'CustomsOffice'
    })

    if (resp.success) {
      handleResponse('Mark as sent successfully.', 'success')
      this.handleClose()
    } else {
      handleResponse(resp.message, 'error')
    }
  }

  handleUpdate = async (field, value) => {
    handleResponse('Updating document...', 'load')

    const { selectedDocument, uploadActions } = this.props
    const params = {
      uuid: selectedDocument.uuid,
      type: selectedDocument.type
    }
    params[field] = value

    const resp = await uploadActions.handleUpdateDocument(params)

    if (resp.success) {
      handleResponse('Updated successfully.', 'success')
    } else {
      handleResponse(resp.message, 'error')
    }
  }

  handleTagsUpdate = async tags => {
    await this.handleUpdate('tags', tags)
  }

  handleDelete = async () => {
    handleResponse('Deleting document, hang on...', 'load')

    const { selectedDocument, uploadActions } = this.props
    const resp = await uploadActions.handleDeleteDocument(selectedDocument.uuid)

    if (resp.success) {
      handleResponse('Deleted successfully.', 'success')
      this.handleClose()
    } else {
      handleResponse(resp.message, 'error')
    }
  }

  handleApprove = async type => {
    handleResponse('Approving document, hang on...', 'load')

    const { selectedDocument, uploadActions, toggleSelectedDocument, section } = this.props
    const resp = await uploadActions.handleApproveDocument(selectedDocument.uuid, type)

    if (resp.success) {
      if (resp.data) {
        const documentApproval = find(
          section.bookingDocuments,
          bookingDoc => bookingDoc.type === selectedDocument.type
        )

        toggleSelectedDocument({
          ...resp.data,
          allowedApprovals: documentApproval
        })
      }

      handleResponse('Approved successfully.', 'success')
    } else {
      handleResponse(resp.message, 'error')
    }
  }

  renderApprovals = approvals => {
    const { selectedDocument } = this.props

    const status = selectedDocument.status

    if (status !== 'UPLOADED') {
      return (
        <>
          {approvals.map(approval => {
            const mappedType = approvalTypes[approval] || { text: approval }
            const approvedApproval = find(
              selectedDocument.approvals,
              docApproval => docApproval.type === approval
            )
            return (
              <ApprovalSelector
                key={approval.uuid}
                title={mappedType.text}
                approval={approval}
                onApprove={this.handleApprove}
                mappedType={mappedType}
                approvedApproval={approvedApproval}
              />
            )
          })}
        </>
      )
    }
  }

  renderTags = tags => {
    if (!tags) {
      return '-'
    }

    return tags.map(tag => {
      return <Tag key={tag}>{tag}</Tag>
    })
  }

  render() {
    const { selectedDocument, state, showPreview } = this.props

    const selectedGlobalCompany = useGlobalCompanyStore.getState().selectedGlobalCompany

    let disableMarkK1Sent = true

    if (selectedDocument.type && selectedDocument.type === 'K1') {
      disableMarkK1Sent = !find(
        state,
        state =>
          state.key === 'impCust' &&
          state.value === 'impCustPendHardCopySubmit' &&
          selectedDocument.status === 'VERIFIED'
      )
    }

    const typeText = documentType[selectedDocument.type] || selectedDocument.type
    const verifiedEmail = selectedDocument.verifiedBy?.email || '-'
    const createdEmail = selectedDocument.createdBy?.email || '-'
    const fileUrl = selectedDocument.url
    const fileType = selectedDocument.document && selectedDocument.document.type
    const isImageType = fileType && fileType.includes('image')
    const approvals = selectedDocument.allowedApprovals?.approvals || []

    return (
      <StyledModal
        title={
          <DocumentTitle>
            <span className="type">{typeText} - </span>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              {selectedDocument.document && selectedDocument.document.name}
              <Icon type="export" />
            </a>
          </DocumentTitle>
        }
        visible={showPreview}
        onCancel={this.handleClose}
        footer={
          <Approval>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={5}>
                <div>
                  <div className="label">Created by : </div>
                  <div className="value"> {createdEmail}</div>
                </div>
                <div>
                  <div className="label">Verified by : </div>
                  <div className="value"> {verifiedEmail}</div>
                </div>
              </Col>
              <Col span={5}>
                <div>
                  <div className="label">Reference : </div>
                  <div className="value">
                    <DocumentFieldUpdate
                      document={selectedDocument}
                      field="reference"
                      onUpdate={this.handleUpdate}
                    />
                  </div>
                </div>
                <div>
                  <div className="label">Remarks : </div>
                  <div className="value">
                    <DocumentFieldUpdate
                      document={selectedDocument}
                      field="remarks"
                      onUpdate={this.handleUpdate}
                    />
                  </div>
                </div>
              </Col>
              <Col span={4}>
                <div>
                  <div className="label">Tags : </div>
                  <div className="value">
                    <TagsValueUpdate
                      tags={selectedDocument.tags}
                      onTagsUpdated={this.handleTagsUpdate}
                    />
                  </div>
                </div>
              </Col>
              <Col span={5}>{this.renderApprovals(approvals)}</Col>
              <Col span={5}>
                <div className="actions">
                  <Button onClick={this.handleClose}>Close</Button>
                  {selectedDocument.status !== 'DELETED' ? (
                    <Popconfirm
                      okText="Delete"
                      okType="danger"
                      title="Confirm delete this document?"
                      onConfirm={this.handleDelete}
                    >
                      <Button type="danger">Delete</Button>
                    </Popconfirm>
                  ) : null}
                  {selectedDocument && selectedDocument.status === 'UPLOADED' ? (
                    <Button type="primary" onClick={this.handleVerify}>
                      Verify
                    </Button>
                  ) : null}
                  {selectedDocument && selectedDocument.type === 'K1' && (
                    <Button type="primary" disabled={disableMarkK1Sent} onClick={this.handleSent}>
                      Mark as Sent
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Approval>
        }
      >
        <ImagePreview type={fileType}>
          <embed
            src={`${fileUrl}?base-company-uuid=${selectedGlobalCompany.uuid}`}
            type={fileType}
            id="plugin"
            name="plugin"
            width={isImageType ? 'auto' : '100%'}
            height={isImageType ? 'auto' : '100%'}
          />
        </ImagePreview>
      </StyledModal>
    )
  }
}

Preview.propTypes = {
  state: PropTypes.array,
  showPreview: PropTypes.bool,
  togglePreview: PropTypes.func,
  uploadActions: PropTypes.object,
  selectedDocument: PropTypes.object,
  toggleSelectedDocument: PropTypes.func
}

export default Preview
