import { Icon, List, Tooltip } from 'antd'

const statusIcon = {
  pending: {
    type: 'clock-circle-o',
    color: 'grey'
  },
  fail: {
    type: 'exclamation-circle',
    color: 'red'
  },
  success: {
    type: 'check',
    color: 'green'
  }
}

const getVoucherIcon = voucherResult => {
  const icon = statusIcon[voucherResult.result.status]
  const errorMessage = voucherResult.result?.error?.message?.replace('GraphQL error: ', '')

  return (
    <Tooltip title={errorMessage}>
      <Icon type={icon.type} style={{ color: icon.color }} />
    </Tooltip>
  )
}

const BulkSubmitList = props => {
  const { voucherResults } = props

  return (
    <List
      size="small"
      bordered
      dataSource={voucherResults}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta title={item.voucherNumber || item.uuid.substring(0, 8)} />
          {getVoucherIcon(item)}
        </List.Item>
      )}
    />
  )
}

export default BulkSubmitList
