import { NODE_BODY } from 'App/components/Manage/Rates/fragments'

export const ROOTS_QUERY = `
  query roots(
    $input:
    RootsQueryInput
  ) {
    roots(
    input: $input
    ) {
      rows {
        _id
        name
        code
        description
        status
      }

      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

export const NODE_QUERY = `
  query node($nodeId: ObjectID) {
    node(nodeId: $nodeId) {
      ${NODE_BODY}
    }
  }
`

export const TREE_QUERY = `
  query tree($input: RootQueryInput) {
      tree(input: $input){
      root {
        _id
        name
        code
        description
        status
      }
      branches
    }
  }
`
