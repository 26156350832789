import type { DocumentCreatorTemplateStatus } from 'App/types/graphql'

import { useQuery } from '@apollo/client'

import { GET_DOCUMENT_CREATOR_TEMPLATE } from '@/components/Transport/Schemas/schema'

export type TemplateVariableProps = {
  qs: string[]
  statuses: DocumentCreatorTemplateStatus[]
}

export const useDocumentTemplates = (variables: TemplateVariableProps) => {
  const { data: templates } = useQuery(GET_DOCUMENT_CREATOR_TEMPLATE, {
    variables
  })

  return { templates: templates?.documentCreatorTemplates?.rows || [] }
}
