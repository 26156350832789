import { Timeline, Typography } from 'antd-v5'

import { getActTime } from '@/components/RelativeDateTime'

import './StatTimeline.css' // Import the CSS file

import isEmpty from 'lodash/isEmpty'

type StatTimelineProps = {
  stat: Array<{
    i: number
    t: string
    ts: string
    meta?: {
      drId?: string
      vCode?: string
      trId?: string
    }
  }>
}

const StatTimeline = ({ stat = [] }: StatTimelineProps) => {
  if (isEmpty(stat)) return null

  const timelineItems = stat?.map(item => ({
    children: (
      <>
        <Typography.Text strong className="small-text">
          {item.t}
        </Typography.Text>
        {' - '}
        <Typography.Text type="secondary" className="small-text">
          [{getActTime(item.ts)}]
        </Typography.Text>

        {item.meta && (
          <Typography.Text type="secondary" className="small-text">
            {' '}
            ({[item.meta.drId, item.meta.vCode, item.meta.trId].filter(x => x).join(' - ')})
          </Typography.Text>
        )}
      </>
    )
  }))

  return <Timeline mode="left" className="dense-timeline" items={timelineItems} />
}

export default StatTimeline
