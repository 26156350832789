import { JobTypeStatus } from 'App/types/graphql'

import { isArray } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

const sampleData = [
  {
    name: 'gp',
    code: 'GP',
    sorting: 1,
    description: 'GP',
    status: 'ACTIVE',
    tripDetails: [],
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: The required fields are: name (e.g. GP or DG1), code (e.g. GP), sorting (e.g. 1)'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Sorting*',
    dataIndex: 'sorting',
    key: 'sorting'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Trip Details',
    dataIndex: 'tripDetails',
    key: 'tripDetails',
    ellipsis: true,
    render: (text: any, record: any) => JSON.stringify(record.tripDetails)
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (jobType: any) => {
  if (!jobType.code) return { nullError: 'Code is required, e.g. GP.' }
  if (!jobType.name) return { nullError: 'Name is required, e.g. GP.' }
  if (!jobType.sorting) return { nullError: 'Sorting is required, e.g. 1.' }

  if (!isArray(jobType.tripDetails)) {
    return { nullError: 'Trip Details is required and must be an array.' }
  }
}

const populateJobTypeObj = (obj: any, jobTypeId: string) => {
  const jobTypeObj: any = {
    uuid: jobTypeId?.trim(),
    code: obj.code?.toString()?.trim(),
    name: obj.name,
    sorting: parseInt(obj.sorting),
    description: obj.description,
    status: obj.status || JobTypeStatus.Active,
    tripDetails: obj.tripDetails
  }

  return jobTypeObj
}

const jobTypesHelper = {
  queryName: 'jobTypes',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const jobTypes = results?.data?.jobTypes?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row,
        zzz: ''
      }))
      return jobTypes
    } catch (error) {
      logger.error('jobTypesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (jobTypeData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...jobType } = jobTypeData

    const nullInputError = findNullInputs(jobType)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const jobTypesQuery = {
          limit: 10000
        }
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          input: jobTypesQuery
        })
        const jobTypes = results?.data?.jobTypes?.rows

        for (let i = 0; i < jobTypes?.length; i++) {
          cache.uuid[jobTypes[i]?.uuid] = jobTypes[i]?.code
          cache.code[jobTypes[i]?.code] = jobTypes[i]?.uuid
        }
      } catch (error) {
        logger.error('jobTypesHelper cache getDataGql error', error)
        return error
      }
    }

    // Check jobTypeId
    let jobTypeId: string = ''
    if (cache.uuid[jobType?.uuid]) {
      jobTypeId = jobType?.uuid
    } else if (cache.code[jobType?.code]) {
      jobTypeId = cache.code[jobType?.code]
    }

    let mutationResults
    // Update
    if (jobTypeId?.length > 0) {
      try {
        const jobTypeObj = populateJobTypeObj(jobType, jobTypeId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: jobTypeObj
        })
      } catch (error) {
        logger.error('jobTypesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        jobTypeId = uuidv4()
        const jobTypeObj = populateJobTypeObj(jobType, jobTypeId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: jobTypeObj
        })
      } catch (error) {
        logger.error('jobTypesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default jobTypesHelper
