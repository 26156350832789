import type { PropsWithChildren } from 'react'

import { LoadingOutlined } from '@ant-design/icons'

import { ItemNotfound, SkeletonSpinner } from './Styled'

type SpinSkeletonProps = PropsWithChildren<{
  loading: boolean
  data: unknown
}>

const SpinSkeleton = ({ loading, children, data }: SpinSkeletonProps) => {
  if (loading)
    return (
      <SkeletonSpinner>
        <LoadingOutlined spin />
      </SkeletonSpinner>
    )

  if (!data) return <ItemNotfound>Whoops, seems like this item could not be found.</ItemNotfound>

  return children
}

export default SpinSkeleton
