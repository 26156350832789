export const getDataGql = `
  query rolesExport1 ($input: RolesQuery) {
    roles (input: $input) {
      rows {
        code
        name
        tags
        status
        uuid
        createdAt
        updatedAt
      }
    }
  }
`

export const createEntityGql = `
  mutation createRoleImport1 ($input: CreateRoleInput!) {
    createRole (input: $input) {
      uuid
    }
  }
`

export const updateEntityGql = `
  mutation updateRoleImport1 ($input: UpdateRoleInput!) {
    updateRole (input: $input) {
      uuid
    }
  }
`
