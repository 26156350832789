import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { Popover, Spin } from 'antd'
import { find } from 'lodash'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import CostItems from 'App/components/Voucher/CostItems'
import UserQuery from 'App/containers/user/query/user'
import { COST_ITEMS_QUERY } from 'App/graphql/costItem'
import * as voucherActions from 'App/states/reducers/voucher'

const Td = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

type BookingRowType = {
  elasticBk: any

  voucherBookings: any
  updateVoucherBookings: any
  onSelectSingleCostItem: any
  onCostItemAdded: any
}

const enhance = compose(
  connect(
    (state: any) => ({
      voucherBookings: state.voucher.voucherBookings
    }),
    (dispatch: any) => ({
      dispatch,
      // @ts-ignore
      ...bindActionCreators({ ...voucherActions }, dispatch)
    })
  ),
  UserQuery
)

const BookingRow = (props: BookingRowType) => {
  const {
    elasticBk,
    onCostItemAdded,
    onSelectSingleCostItem,
    voucherBookings,
    updateVoucherBookings
  } = props

  const loadingObj = useState(true)
  const loading: boolean = loadingObj[0]
  const setLoading: any = loadingObj[1]

  const errorObj = useState('')
  const error: string = errorObj[0]
  const setError: any = errorObj[1]

  const [getCostItems, { data: costItemsData }] = useLazyQuery(COST_ITEMS_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (!elasticBk) return
    ;(async () => {
      try {
        const costItems = await getCostItems({ variables: { uuid: elasticBk.uuid } })

        // Update redux store
        const isFound = find(voucherBookings, (v: any) => v.uuid === elasticBk.uuid)

        if (!isFound) {
          updateVoucherBookings([...voucherBookings, { ...elasticBk, costItems }])
        }
      } catch (e) {
        setError(e)
      }
      setLoading(false)
    })()
  }, [getCostItems, elasticBk, setLoading, updateVoucherBookings, voucherBookings, setError])

  const refetch = (uuid: string) => getCostItems({ variables: { uuid } })

  return (
    <>
      <tr>
        <Td>
          {loading && (
            <>
              {elasticBk.no}
              <Spin />
            </>
          )}
          {!loading && elasticBk && (
            <Popover
              content={`${elasticBk.no} - ${elasticBk.details.shipperRef} - ${elasticBk.details.references}`}
            >
              {elasticBk.no}
              {error && <span style={{ color: 'red' }}> - {error}</span>}-{' '}
              {elasticBk.no && (
                <a href={`/bookings/${elasticBk?.uuid}`} target="booking">
                  {elasticBk.no}
                </a>
              )}
              <br />
              {elasticBk.billTo.name}
            </Popover>
          )}
        </Td>
        <Td>
          {elasticBk && (
            <Popover content={`${elasticBk.shipper.name} -> ${elasticBk.consignee.name}`}>
              {elasticBk.shipper.name}
              <br />
              {elasticBk.consignee.name}
            </Popover>
          )}
        </Td>
        <td>
          {costItemsData?.costItems && elasticBk && (
            <CostItems
              compact
              voucherBooking={{ ...elasticBk, costItems: costItemsData.costItems }}
              onSelectSingleCostItem={onSelectSingleCostItem}
              onCostItemAdded={onCostItemAdded}
              onCostItemEdited={refetch}
            />
          )}
        </td>
      </tr>
    </>
  )
}

// @ts-ignore
export default enhance(BookingRow)
