import { gql } from '@apollo/client'

export const EMPLOYEES_QUERY = gql`
  query employeesTable1(
    $query: String
    $limit: Int
    $offset: Int
    $companyUuids: [UUID]
    $roles: [String]
  ) {
    employees(
      query: $query
      limit: $limit
      offset: $offset
      companyUuids: $companyUuids
      roles: $roles
    ) {
      rows {
        uuid
        roles
        status
        user {
          name
          email
        }
      }

      pageInfo {
        count
        limit
        offset
      }
    }
  }
`
