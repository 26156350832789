import { memo, useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Popconfirm } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import { DELETE_LEG_MUTATION, UNDELETE_LEG_MUTATION } from 'App/components/Transport/Schemas/schema'
import { isActiveLeg } from 'App/components/Transport/Utils/jobHelper'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'

const DeleteLegButton = memo((props: any) => {
  const { leg, refetchLegs } = props

  const [deleteLeg, { loading: deleteLegLoading }] = useMutation(DELETE_LEG_MUTATION)
  const [undeleteLeg, { loading: undeleteLegLoading }] = useMutation(UNDELETE_LEG_MUTATION)

  const onDelete = useCallback(async () => {
    try {
      const inputObj = {
        _id: uuidv4(),
        tripUuid: leg?.tripUuid,
        legUuid: leg?.legUuid
      }

      if (inputObj.legUuid && deleteLeg) {
        const res = await deleteLeg({ variables: { input: inputObj } })
        if (res?.data?.deleteLeg?.success) {
          respHandler('Successfully deleted leg.', 'success')
          await refetchLegs()
        }
      }
    } catch (error) {
      logger.error('DeleteLegButton onDelete error', error)
      respHandler(error, 'error')
    }
  }, [deleteLeg, leg, refetchLegs])

  const onUndelete = useCallback(async () => {
    try {
      const inputObj = {
        _id: uuidv4(),
        tripUuid: leg?.tripUuid,
        legUuid: leg?.legUuid
      }

      if (inputObj.legUuid && undeleteLeg) {
        const res = await undeleteLeg({ variables: { input: inputObj } })
        if (res?.data?.undeleteLeg?.success) {
          respHandler('Successfully undeleted leg.', 'success')
          await refetchLegs()
        }
      }
    } catch (error) {
      logger.error('DeleteLegButton onUndelete error', error)
      respHandler(error, 'error')
    }
  }, [leg, refetchLegs, undeleteLeg])

  const isDeleted: boolean = useMemo(() => !isActiveLeg(leg), [leg])

  return (
    <Popconfirm
      title={`${!isDeleted ? 'Delete' : 'Undelete'} this leg?`}
      onConfirm={!isDeleted ? onDelete : onUndelete}
      okText="Yes"
      okType="danger"
      cancelText="No"
    >
      <Button
        loading={deleteLegLoading || undeleteLegLoading}
        size="small"
        shape="circle"
        icon={`${!isDeleted ? 'delete' : 'undo'}`}
        type="link"
        style={{ color: 'rgba(0, 0, 0, 0.65)', padding: 0, margin: 0 }}
      />
    </Popconfirm>
  )
})

export default DeleteLegButton
