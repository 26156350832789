import type { ComponentType } from 'react'
import { BookingStatus } from '@/types/graphql'

import {
  CheckCircleOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MinusOutlined
} from '@ant-design/icons'

type StatusConfig = {
  tips: string
  icon: ComponentType
  color?: string
  backgroundColor?: string
}

type BookingStatusMap = {
  [key in BookingStatus]: StatusConfig
} & {
  default: StatusConfig
}

const bookingStatus: BookingStatusMap = {
  DRAFT: {
    tips: 'Draft',
    icon: EditOutlined
  },
  SUBMITTED: {
    tips: 'Submitted',
    color: '#FFC000',
    icon: InfoCircleOutlined
  },
  ACCEPTED: {
    tips: 'Accepted',
    color: 'green',
    icon: CheckCircleOutlined
  },
  CLOSE_OPERATION: {
    tips: 'Operation Closed',
    icon: CheckSquareOutlined,
    color: '#00FF00',
    backgroundColor: '#cfffcf'
  },
  CLOSE_FINANCE: {
    tips: 'Finance Closed',
    icon: ClockCircleOutlined,
    color: 'blue',
    backgroundColor: '#e0ffff'
  },
  REJECTED: {
    tips: 'Rejected',
    icon: CloseCircleOutlined,
    color: 'red'
  },
  CANCELLED: {
    tips: 'Cancelled',
    icon: CloseCircleOutlined,
    color: 'red'
  },
  default: {
    tips: 'N/A',
    icon: MinusOutlined
  }
}

export default bookingStatus
