import React, { Component } from 'react'
import { Col, Row, Select } from 'antd'

import EmployeeRoleSelect from 'App/components/Select/EmployeeRoleSelect'
import ImportExport from 'App/components/Shared/ImportExport'
import { ActionWrapper, FilterName } from './Styled'

class TableViewActions extends Component {
  render() {
    const { selectedRole, selectedSystem, updateSelectedRole, updateSelectedSystem } = this.props
    const query = {
      limit: 20,
      statuses: ['ACTIVE']
    }

    return (
      <ActionWrapper>
        <Row type="flex" align="middle" style={{ marginBottom: '8px' }}>
          <Col span={3}>
            <FilterName>System : </FilterName>
          </Col>
          <Col span={6}>
            <Select
              value={selectedSystem}
              onChange={value => {
                updateSelectedSystem(value)
              }}
            >
              <Select.Option value="">ShipX</Select.Option>
              <Select.Option value="entity">Entity</Select.Option>
              <Select.Option value="transport">Transport</Select.Option>
            </Select>
          </Col>
          <Col span={1} />
          <Col span={6}>
            <ImportExport selectedEntity="rolesPermissions" />
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={3}>
            <FilterName>Roles : </FilterName>
          </Col>
          <Col span={6}>
            <EmployeeRoleSelect query={query} value={selectedRole} onChange={updateSelectedRole} />
          </Col>
          <Col span={1} />
        </Row>
      </ActionWrapper>
    )
  }
}

export default TableViewActions
