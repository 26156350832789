import { Col, Icon } from 'antd'

import { StyledRow } from '../Styled'

const SignOff = ({ message }: { message: string }) => {
  return (
    <StyledRow type="flex" justify="space-around" align="middle">
      <Col span={24} style={{ textAlign: 'center' }}>
        <div className="load-text">{message || 'Signing off...'}</div>
        <Icon type="loading" />
      </Col>
    </StyledRow>
  )
}

export default SignOff
