export const getDataGql = `
  query transportZones($limit: Int, $offset: Int, $q: String, $statuses: [TransportZoneStatus]) {
    transportZones(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        status
        sorting
        description
      }
    }
  }
`

export const createEntityGql = `
  mutation createTransportZone($input: CreateTransportZoneInput!) {
    createTransportZone(input: $input) {
      code
      uuid
      name
      status
      sorting
      description
    }
  }
`

export const updateEntityGql = `
  mutation updateTransportZone($input: CreateTransportZoneInput!) {
    updateTransportZone(input: $input) {
      code
      uuid
      name
      status
      sorting
      description
    }
  }
`
