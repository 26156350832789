import { gql } from '@/types'

export const ADDRESSES_QUERY = gql(`
  query addresses1(
    $companyUuid: UUID
    $type: [AddressType]
    $statuses: [AddressStatus]
    $limit: Int
    $offset: Int
    $q: String
  ) {
    addresses(
      companyUuid: $companyUuid
      type: $type
      statuses: $statuses
      limit: $limit
      offset: $offset
      q: $q
    ) {
      rows {
        uuid
        name
        status
        type
        address1
        address2
        address3
        address4
        postCode
        areaCode
        zone
      }
    }
  }
`)
