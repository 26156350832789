/* eslint-disable react-hooks/exhaustive-deps */
import type { Driver } from 'App/types/graphql'
import { DriverStatus } from 'App/types/graphql'

import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd'
import debounce from 'lodash/debounce'

import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'
import { DRIVERS_QUERY } from '../Schemas/schema'

const Option = Select.Option

type DriverSelectorProps = {
  mode?: string | undefined | null
  onChange?: any
  statuses?: Array<DriverStatus>
  isUpdate?: boolean | undefined | null
  setVehiclePreference?: any
  style?: any
  value?: any
  queryOnMount?: boolean
}

const DriverSelector = forwardRef((props: DriverSelectorProps, ref: any) => {
  const { t } = useTranslation()
  const {
    mode,
    style,
    value,

    onChange,
    isUpdate,
    queryOnMount = false,
    setVehiclePreference,
    statuses = [DriverStatus.Active]
  } = props

  const [drivers, setDrivers] = useState<Driver[]>([])
  const [searchInput, setSearchInput] = useState('')

  const input = useMemo(
    () => ({
      q: (!mode && value) || searchInput || '',
      statuses,
      uuids: (mode && !searchInput && value) || [],
      limit: 20
    }),
    [mode, value, searchInput]
  )

  const [getDrivers, { data, error, loading }] = useLazyQuery(DRIVERS_QUERY, {
    variables: { ...input },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (queryOnMount || value) {
      getDrivers()
    }
  }, [queryOnMount, value])

  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        getDrivers()
      } catch (error) {
        // ok to fail when the component has already unmounted
      }
    }, 500),
    []
  )

  const onSelect = useCallback(
    (onSelectVal: string) => {
      const selectedDriver = drivers?.find((d: any) => d?.uuid === onSelectVal)

      if (selectedDriver?.vehiclePreference?.length && setVehiclePreference) {
        setVehiclePreference(selectedDriver?.vehiclePreference)
      }
    },
    [drivers, setVehiclePreference]
  )

  useEffect(() => {
    if (data?.drivers?.rows?.length && setDrivers) {
      setDrivers(data?.drivers?.rows)
    }
  }, [data])

  if (error) {
    logger.error('DriverSelector useQuery error', error)
    responseHandler(error, 'error')
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return

    getDrivers()
  }

  return (
    <Select
      id="driver-leg-form-selector"
      ref={ref}
      allowClear
      showSearch
      // @ts-ignore
      mode={mode}
      style={style}
      loading={loading}
      onChange={onChange}
      onSelect={onSelect}
      filterOption={false}
      disabled={!!isUpdate}
      onFocus={fetchOnFocus}
      onSearch={handleSearch}
      placeholder={t('common.searchADriver')}
      value={value || undefined} // undefined to show placeholder
      notFoundContent={loading ? t('common.searching') : t('common.notFound')}
    >
      {drivers?.map((driver: Driver) => (
        <Option key={driver.uuid} value={driver.uuid}>
          {driver.code} - {driver.name}
        </Option>
      ))}
    </Select>
  )
})

export default DriverSelector
