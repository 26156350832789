import type { TripSummary } from 'App/types/graphql'
import type { formatTripSummaryTableOptions, QueryType } from './types'
import { TransportSource } from 'App/types/graphql'

import { cloneDeep, find, groupBy, mapValues } from 'lodash'
import moment from 'moment'
import { v4 as uuidV4 } from 'uuid'

import { getDateScale } from 'App/components/Transport/Components/Monitoring/EmptyTrips/helper'
import { json2xlsx } from 'App/components/Transport/Shift/Actions/Export/exportHelper'
import respHandler from 'App/utils/responseHandler'
import { optionsArray, ViewOptions } from './TripSummaryTableWrapper'

export const transportSources = Object.values(TransportSource)

const dailyTimeFormat = 'YYYY-MM-DD'

const getAllMovementCombos = (zones: Array<string>) => {
  const movements: any = []
  zones.forEach((from: string) => {
    zones.forEach((to: string) => {
      movements.push({
        from,
        to,
        movement: `${from} - ${to}`
      })
    })
  })
  return movements
}

const groupByDateZoneType = (data: [TripSummary], loopInterval: string) => {
  const groupedIndex = groupBy(
    data,
    (t: TripSummary) =>
      // @ts-ignore
      `${moment(t.date).startOf(loopInterval).format(dailyTimeFormat)}:${t.from || '-'}:${
        t.to || '-'
      }`
  )

  // @ts-ignore
  return mapValues(groupedIndex, grp =>
    grp.reduce((tripSum: TripSummary, currentObj: TripSummary) => {
      if (!Object.keys(tripSum).length) {
        return currentObj
      }

      return {
        ...tripSum,
        emptyCount: Number(tripSum.emptyCount) + Number(currentObj.emptyCount),
        emptyCost: Number(tripSum.emptyCost) + Number(currentObj.emptyCost),
        emptyUuids: tripSum.emptyUuids?.concat(currentObj.emptyUuids),
        ladenCount: Number(tripSum.ladenCount) + Number(currentObj.ladenCount),
        ladenUuids: tripSum.ladenUuids?.concat(currentObj.ladenUuids)
      }
    }, {})
  )
}

export const getCurrentDateString = (currentDate: any, ViewOption: string): string => {
  switch (ViewOption) {
    case ViewOptions.DAILY:
      return currentDate.format(dailyTimeFormat)
    case ViewOptions.WEEKLY:
      return currentDate.week().toString()
    case ViewOptions.MONTHLY:
      return currentDate.format('MMM')
    default:
      return currentDate.format(dailyTimeFormat)
  }
}

const getPreviousDateString = (currentDate: any, ViewOption: string): string => {
  switch (ViewOption) {
    case ViewOptions.DAILY:
      return moment(currentDate).subtract(1, 'days').format(dailyTimeFormat)
    case ViewOptions.WEEKLY:
      return moment(currentDate).subtract(1, 'weeks').week().toString()
    case ViewOptions.MONTHLY:
      return moment(currentDate).subtract(1, 'months').format('MMM')
    default:
      return moment(currentDate).subtract(1, 'days').format(dailyTimeFormat)
  }
}

export const getLoopInterval = (ViewOption: string): string => {
  switch (ViewOption) {
    case ViewOptions.DAILY:
      return 'day'
    case ViewOptions.WEEKLY:
      return 'week'
    case ViewOptions.MONTHLY:
      return 'month'
    default:
      return 'day'
  }
}

const filterData = (data: [TripSummary], filters: any) => {
  let filteredData = data
  const { selectedDepts } = filters

  if (selectedDepts?.length) {
    // @ts-ignore
    filteredData = filteredData.filter((d: TripSummary) => {
      const queriedDepts = Object.keys(d.dept)
      const foundDept = selectedDepts?.reduce((res: boolean, selectedDept: string) => {
        if (queriedDepts.includes(selectedDept)) {
          res = true
        }
        return res
      }, false)

      if (foundDept) {
        return true
      }
      return false
    })
  }

  return filteredData
}

export const formatTripSummaryForTable = (
  data: [TripSummary],
  query: QueryType,
  zones: string[],
  options: formatTripSummaryTableOptions
) => {
  const { selectedDepts } = options
  const filteredData = filterData(cloneDeep(data), { selectedDepts })

  const ViewOption = options.viewBy || optionsArray[0]
  const movements: any = []
  const loopInterval = getLoopInterval(ViewOption)
  const byDateZoneType: any = groupByDateZoneType(filteredData, loopInterval)
  // const ownerTypes = query?.transportSources?.length ? query?.transportSources : transportSources

  const dates = getDateScale(
    new Date(query.startDate),
    new Date(query.endDate),
    //@ts-ignore
    loopInterval,
    dailyTimeFormat
  )

  // Populate table data to movements array
  zones.forEach((zone: string) => {
    zones.forEach((subZone: string) => {
      const rowData: any = {
        key: uuidV4(),
        from: zone,
        to: subZone,
        movement: `${zone} - ${subZone}`
      }

      rowData['Total(L)'] = 0
      rowData['Total(R)'] = 0
      rowData['Total(MT)'] = 0
      rowData['Total(E)'] = 0
      rowData['Total Cost'] = 0
      rowData['Total Trips'] = 0
      rowData['Total(% Empty / Total Trip)'] = 0

      // Loop though all the dates...
      dates.forEach((date: string) => {
        rowData[`${date}(L)`] = byDateZoneType[`${date}:${zone}:${subZone}`]?.ladenCount || 0

        rowData[`${date}(E)`] = byDateZoneType[`${date}:${zone}:${subZone}`]?.emptyCount || 0
        rowData[`${date}(C)`] = byDateZoneType[`${date}:${zone}:${subZone}`]?.emptyCost || 0

        rowData[`${date}(T)`] =
          (byDateZoneType[`${date}:${zone}:${subZone}`]?.ladenCount || 0) +
          (byDateZoneType[`${date}:${zone}:${subZone}`]?.emptyCount || 0)

        rowData[`${date}(% MT Return)`] = 0
      })

      // Calculate totals for the first 3 columns
      // @ts-ignore
      dates.forEach((date: string, dateIndex: number) => {
        if (byDateZoneType[`${date}:${zone}:${subZone}`]?.ladenCount) {
          rowData['Total(L)'] += byDateZoneType[`${date}:${zone}:${subZone}`]?.ladenCount
          // rowData['Total Trips'] = rowData['Total Trips'] + byDateZoneType[`${date}:${zone}:${subZone}`]?.ladenCount
        }
        if (byDateZoneType[`${date}:${zone}:${subZone}`]?.emptyCount) {
          rowData['Total(E)'] += byDateZoneType[`${date}:${zone}:${subZone}`]?.emptyCount
          // rowData['Total Trips'] += byDateZoneType[`${date}:${zone}:${subZone}`]?.emptyCount
        }
        if (byDateZoneType[`${date}:${zone}:${subZone}`]?.emptyCost) {
          rowData['Total Cost'] += byDateZoneType[`${date}:${zone}:${subZone}`]?.emptyCost
        }
      })

      // only compute total for OWN type
      // if (rowData['Total(E)'] + rowData['Total(L)'] > 0) {
      //   rowData['Total(% Empty / Total Trip)'] = Math.floor(rowData['Total(E)'] / (rowData['Total(E)'] + rowData['Total(L)']) * 100)
      // }

      movements.push(rowData)
    })
  })

  // Calculate sub totals for each column
  const subTotal: any = { key: 'subTotal', movement: 'Total' }
  // for [OWN, INTERCOMPANY, INTERBRANCH, OUTSOURCE]
  subTotal['Total(L)'] = 0
  subTotal['Total(R)'] = 0
  subTotal['Total(MT)'] = 0
  subTotal['Total(E)'] = 0
  subTotal['Total Cost'] = 0
  subTotal['Total Trips'] = 0
  subTotal['Total(% Empty / Total Trip)'] = 0

  // @ts-ignore
  dates.forEach((date: string, dateIndex: number) => {
    if (!subTotal[`${date}(L)`]?.length) {
      subTotal[`${date}(L)`] = 0
    }
    if (!subTotal[`${date}(R)`]?.length) {
      subTotal[`${date}(R)`] = 0
    }
    if (!subTotal[`${date}(E)`]?.length) {
      subTotal[`${date}(E)`] = 0
    }
    if (!subTotal[`${date}(C)`]?.length) {
      subTotal[`${date}(C)`] = 0
    }
    if (!subTotal[`${date}(T)`]?.length) {
      subTotal[`${date}(T)`] = 0
    }
    if (!subTotal[`${date}(MT)`]?.length) {
      subTotal[`${date}(MT)`] = 0
    }
    if (!subTotal[`${date}(% MT Return)`]) {
      subTotal[`${date}(% MT Return)`] = 0
    }

    movements.length &&
      movements.forEach((movement: any) => {
        if (movement[`${date}(L)`] > 0) {
          // is not NaN
          subTotal[`${date}(L)`] += movement[`${date}(L)`]
        }
        if (movement[`${date}(E)`] > 0) {
          subTotal[`${date}(E)`] += movement[`${date}(E)`]
        }
        if (movement[`${date}(C)`] > 0) {
          subTotal[`${date}(C)`] += movement[`${date}(C)`]
        }
        if (movement[`${date}(T)`] > 0) {
          subTotal[`${date}(T)`] += movement[`${date}(T)`]
        }
      })
  })

  // Calculate totals of totals
  movements.length &&
    movements.forEach((movement: any) => {
      const foundReturn = find(
        movements,
        (m: any) => m.from === movement.to && m.to === movement.from
      )

      // @ts-ignore
      dates.forEach((date: string, dateIndex: number) => {
        if (movement[`${date}(L)`]?.length) {
          movement[`${date}(R)`] = foundReturn[`${date}(L)`]
          subTotal[`${date}(R)`] += foundReturn[`${date}(L)`]
        }

        if (movement[`${date}(E)`]?.length) {
          movement[`${date}(MT)`] = foundReturn[`${date}(E)`]
          subTotal[`${date}(MT)`] += foundReturn[`${date}(E)`]
        }

        // only compute total for OWN type
        if (movement[`${date}(E)`] > 0) {
          movement[`${date}(% MT Return)`] = Math.floor(
            (movement[`${date}(E)`] / (movement[`${date}(E)`] + (movement[`${date}(L)`] || 0))) *
              100
          )
          subTotal[`${date}(% MT Return)`] = Math.floor(
            (subTotal[`${date}(E)`] / (subTotal[`${date}(E)`] + (subTotal[`${date}(L)`] || 0))) *
              100
          )
        }
      })

      if (movement['Total(L)'] > 0) {
        subTotal['Total(L)'] += movement['Total(L)']
        // subTotal['Total Trips'] = subTotal['Total Trips'] + movement['Total(L)']
      }
      if (foundReturn['Total(L)'] > 0) {
        subTotal['Total(R)'] += foundReturn['Total(L)']
        movement['Total(R)'] += foundReturn['Total(L)']
      }
      if (movement['Total(E)'] > 0) {
        // movement['Total(MT)'] += foundReturn['Total(E)']
        // subTotal['Total(MT)'] += foundReturn['Total(E)']
        // movement['Total(MT)'] += movement['Total(E)']
        // subTotal['Total(MT)'] += movement['Total(E)']

        subTotal['Total(E)'] += movement['Total(E)']
        // subTotal['Total Trips'] += movement['Total(E)']
      }
      if (movement['Total Cost'] > 0) {
        subTotal['Total Cost'] += movement['Total Cost']
      }

      // only compute total for OWN type
      if (movement['Total(E)'] + movement['Total(L)'] > 0) {
        movement['Total Trips'] = movement['Total(E)'] + movement['Total(L)']
        movement['Total(% Empty / Total Trip)'] = Math.floor(
          (movement['Total(E)'] / (movement['Total(E)'] + (movement['Total(L)'] || 0))) * 100
        )
      }

      if (subTotal['Total(E)'] + subTotal['Total(L)'] > 0) {
        subTotal['Total Trips'] = subTotal['Total(E)'] + subTotal['Total(L)']
        subTotal['Total(% Empty / Total Trip)'] = Math.floor(
          (subTotal['Total(E)'] / (subTotal['Total(E)'] + subTotal['Total(L)'])) * 100
        )
      }
    })

  movements.unshift(subTotal)

  // Replace 0 with '-'
  movements.length &&
    movements.forEach((movement: any) => {
      movement &&
        Object.keys(movement).forEach((key: string) => {
          if (movement[key] && movement[key]?.length && Array.isArray(movement[key])) {
            movement[key].forEach((elem: number, index: number) => {
              if (elem === 0) {
                movement[key][index] = '-'
              }
            })
          }
        })
    })

  return movements
}

export const exportTripSummary = (
  data: [TripSummary],
  baseCompanies: any,
  query: QueryType,
  zones: string[],
  options: formatTripSummaryTableOptions
) => {
  if (!data?.length) {
    return respHandler('No data to export.', 'warning')
  }

  const { selectedDepts } = options
  const filteredData = filterData(cloneDeep(data), { selectedDepts })

  const movements = getAllMovementCombos(zones)
  const scaleInterval = getLoopInterval(options.viewBy)
  const groupedData = groupByBcDateFromToSource(filteredData, scaleInterval)
  // @ts-ignore
  const dates = getDateScale(
    new Date(query.startDate),
    new Date(query.endDate), //@ts-ignore
    scaleInterval,
    dailyTimeFormat
  )
  const selectedBaseCompanies = getSelectedBcs(filteredData, baseCompanies)

  const columnsData = populateColumns(selectedBaseCompanies, movements, dates, groupedData)
  const totalsPerMovement = getFinalExportData(columnsData)

  const fileDates = {
    // @ts-ignore
    start: moment(query.startDate).format(dailyTimeFormat),
    end: moment(query.endDate).format(dailyTimeFormat)
  }
  return json2xlsx(
    `${query.summaryBy || 'TRIP'}_SUMMARY_${query.transportSources?.[0] || 'OWN'}`,
    totalsPerMovement,
    options.viewBy,
    fileDates
  )
}

const groupByBcDateFromToSource = (data: [TripSummary], scaleInterval: string) => {
  return groupBy(
    data,
    (t: TripSummary) =>
      // @ts-ignore
      `${t.baseCompanyUuid}:${moment(t.date).startOf(scaleInterval).format(dailyTimeFormat)}:${
        t.from || '-'
      }:${t.to || '-'}:${t.transportSource || 'OWN'}`
  )
}

const getSelectedBcs = (data: [TripSummary], baseCompanies: any) => {
  return data.reduce((baseCos: any, t: TripSummary) => {
    const foundBc = baseCos.find((bc: any) => bc.uuid === t.baseCompanyUuid)
    if (!foundBc) {
      baseCos.push({
        uuid: t.baseCompanyUuid,
        name: baseCompanies.find((bc: any) => bc.uuid === t.baseCompanyUuid)?.name
      })
    }
    return baseCos
  }, [])
}

const populateColumns = (
  selectedBaseCompanies: Array<string>,
  movements: any,
  dates: Array<string>,
  groupedData: any
) => {
  let columnsData: any = []
  selectedBaseCompanies.forEach((bc: any) => {
    const byBaseCo: any = []
    movements.forEach((movement: any) => {
      const row: any = {
        baseCompany: bc.name,
        movement: movement.movement,
        from: movement.from,
        to: movement.to
      }

      dates.forEach((date: string, dateIndex: number) => {
        row[`${date}_(LADEN)`] = getColumnValue(
          groupedData,
          `${bc.uuid}:${date}:${movement.from}:${movement.to}:OWN`,
          ['ladenCount']
        )
        row[`${date}_(MT)`] = getColumnValue(
          groupedData,
          `${bc.uuid}:${date}:${movement.from}:${movement.to}:OWN`,
          ['emptyCount']
        )
        row[`${date}_(COST)`] = getColumnValue(
          groupedData,
          `${bc.uuid}:${date}:${movement.from}:${movement.to}:OWN`,
          ['emptyCost']
        )
        row[`${date}_(TOTAL)`] = getColumnValue(
          groupedData,
          `${bc.uuid}:${date}:${movement.from}:${movement.to}:OWN`,
          ['ladenCount', 'emptyCount']
        )
        row[`${date}_(%MT)`] =
          row[`${date}_(LADEN)`] + row[`${date}_(MT)`] > 0
            ? Math.floor(
                (row[`${date}_(MT)`] / (row[`${date}_(MT)`] + (row[`${date}_(LADEN)`] || 0))) * 100
              )
            : 0
      })

      byBaseCo.push(row)
    })

    const totalsPerDate = getTotalPerDate(byBaseCo, bc)

    // compute total %MT RETURN per day/week/month (per table column)
    Object.keys(totalsPerDate).forEach((key: string) => {
      if (key.includes('%MT')) {
        const dateString = key.split('_')[0]
        totalsPerDate[key] =
          totalsPerDate[`${dateString}_(LADEN)`] + totalsPerDate[`${dateString}_(MT)`] > 0
            ? Math.floor(
                (totalsPerDate[`${dateString}_(MT)`] /
                  (totalsPerDate[`${dateString}_(MT)`] +
                    (totalsPerDate[`${dateString}_(LADEN)`] || 0))) *
                  100
              )
            : 0
      }
    })

    byBaseCo.unshift(totalsPerDate)
    columnsData = columnsData.concat(byBaseCo)
  })

  return columnsData
}

const getColumnValue = (obj: any, key: string, variables: Array<string>) => {
  return obj[key]?.reduce((total: number, tripSum: any) => {
    variables.forEach((variable: string) => {
      if (tripSum[variable]) {
        total += tripSum[variable]
      }
    })
    return total
  }, 0)
}

const getTotalPerDate = (byBaseCo: any, baseCompany: any) => {
  // compute total laden, return & empty per day/week/month (per table column)
  return byBaseCo.reduce(
    (totals: any, row: any) => {
      Object.keys(row).forEach((key: string) => {
        if (!totals[key]) {
          totals[key] = 0
        }

        if (!key.includes('%MT') && row[key] > 0) {
          totals[key] = totals[key] + row[key]
        }
      })

      return totals
    },
    { baseCompany: baseCompany.name, movement: 'TOTAL' }
  )
}

const getFinalExportData = (columnsData: any) => {
  // compute total laden, return & empty per movement for entire date range (first few left columns)
  return columnsData.map((data: any) => {
    const [totalLaden, totalReturn, totalEmpty, totalCost] = computeTotals(data, [
      '_(LADEN)',
      '_(RETURN)',
      '_(MT)',
      '_(COST)'
    ])

    const restOfData = cloneDeep(data)
    delete restOfData.baseCompany
    delete restOfData.movement
    delete restOfData.from
    delete restOfData.to

    return {
      'BASE COMPANY': data.baseCompany,
      MOVEMENT: data.movement,
      FROM: data.from || '',
      TO: data.to || '',
      'TOTAL LADEN': totalLaden,
      // 'TOTAL RETURN': totalReturn,
      'TOTAL MT': totalEmpty,
      'TOTAL COST': totalCost,
      'TOTAL TRIPS': totalLaden + totalEmpty,
      'TOTAL (%MT)':
        totalLaden + totalEmpty > 0
          ? Math.floor((totalEmpty / (totalEmpty + (totalLaden || 0))) * 100)
          : 0,
      ...restOfData
    }
  })
}

const computeTotals = (data: any, columns: Array<string>) => {
  return columns.map((col: string) => {
    return Object.keys(data).reduce((total: number, key: string) => {
      if (key.includes(col) && data[key]) {
        total = total + data[key]
      }
      return total
    }, 0)
  })
}
