import type { Node, Root, RootsQueryInput } from '@/types/graphql'
import type { Json2CsvOptions } from 'json-2-csv'

import { mapKeys } from 'lodash'

import { nodeReducer } from 'App/components/Manage/Rates/helper'
import { logger } from 'App/utils/logger'
import { batchQuery, getGqlResponse } from '../helpers'
import { NODE_QUERY, ROOTS_QUERY, TREE_QUERY } from './schema'

const sampleData = [
  {
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: Please first export to update. The required fields are: from (i.e. input an area code), name, to (i.e. input an area code), transportType (e.g. TRANSPORT). ' +
  'The required rates fields are: rates_1.rateType (e.g. FIXED), rates_1.rate (e.g. 1200), rates_1.transactionType (e.g. SELL), and rates_1.type (e.g. TRIP).'

const tableColumns = []

const ratesHelper = {
  queryName: 'rates',

  getExportData: async (
    selectedGlobalCompany: any,
    filter: { input?: RootsQueryInput; mode?: string } = { input: {} }
  ) => {
    try {
      const results = await batchQuery(
        {
          selectedGlobalCompany,
          queryGql: ROOTS_QUERY,
          queryObj: { input: filter.input },
          dataKeyExtractor: 'roots'
        },
        1000
      )

      const roots = results

      const treeMap = new Map<Root['_id'], any[]>()
      const childMap = new Map<Node['_id'], Node>()

      for await (const root of roots) {
        const res = await getGqlResponse(selectedGlobalCompany, TREE_QUERY, { nodeId: root._id })
        const resMapped = res.data.nodeTree.map(t => t.branch) || []
        treeMap.set(root._id, resMapped)
      }

      const nodeIds = Array.from(treeMap.values()).flatMap(t =>
        t.flatMap(n => nodeReducer(n, 'childId'))
      )

      for await (const nodeId of nodeIds) {
        const res = await getGqlResponse(selectedGlobalCompany, NODE_QUERY, { nodeId: nodeId })
        childMap.set(nodeId, res.data.node)
      }

      const transformNode = (nodes: any[]): any[] => {
        return nodes.map(node => {
          const nodeData = childMap.get(node.childId)
          const children = node.children || []

          return {
            ...node,
            ...nodeData,
            children: transformNode(children)
          }
        })
      }

      const addNodeRow = (node: any, csvData: any[], depth: number = 0) => {
        depth += 1

        const children = node.children || []
        node.depth = depth
        delete node.children

        node = mapKeys(node, (value, key) => `child_${key}`)

        csvData.push(node)

        children.forEach(child => {
          addNodeRow(child, csvData, depth)
        })
      }

      const csvData: any[] = []

      for (const root of roots) {
        const nodeTree = treeMap.get(root._id) || []
        const nodes = transformNode(nodeTree)

        csvData.push(root)

        nodes.forEach(node => {
          addNodeRow(node, csvData)
        })
      }

      return csvData
    } catch (error) {
      logger.error('transAreaCodesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (selectedGlobalCompany: any, data: any) => {},

  mapFromCsv: (csvData: any) => {},

  sampleData,

  remarks,

  tableColumns,

  options: {
    emptyFieldValue: '',
    expandArrayObjects: false
  } as Json2CsvOptions
}

export default ratesHelper
