import type { DocumentNode } from '@apollo/client'
import type { JobType } from 'App/types/graphql'
import { JobTypeStatus } from 'App/types/graphql'
import { ActiveStatus } from '@/types/graphql'

import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Pagination, Result, Row, Skeleton, Table } from 'antd'
import camelCase from 'lodash/camelCase'
import kebabCase from 'lodash/kebabCase'
import startCase from 'lodash/startCase'
import styled from 'styled-components'

import PageHeader from 'App/components/Manage/PageHeader'
import TableActions from 'App/components/Manage/Shared/CrudType/TableView/Actions'
import StatusFilter from 'App/components/Manage/Shared/CrudType/TableView/StatusFilter'
import { PageView, ViewContent } from 'App/components/Manage/Styled'
import HelpIcon from 'App/components/Shared/HelpIcon'
import NoAccess from 'App/components/Shared/NoAccess'
import { StyledAnchor } from 'App/components/TableView/Styled'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'

const StyledTableDiv = styled.div`
  .ant-table-thead > tr > th {
    padding: 16px 4px;
  }

  .ant-table-tbody > tr > td {
    padding: 16px 4px;
  }
`

type CrudTypesTableViewProps = {
  gqlQuery: DocumentNode
  crudType: string
  articleUrl?: string
}

const CrudTypesTableView = (props: CrudTypesTableViewProps) => {
  const { gqlQuery, crudType, articleUrl } = props

  const dataKey = `${crudType}s`
  const crudName = startCase(camelCase(dataKey))
  const createUrl = `/manage/${kebabCase(crudType)}s/create`

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: `${crudType}`,
    permission: 'index'
  })

  const tableColumns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (code: string, record: JobType) => {
        return (
          <Link to={`/manage/${kebabCase(dataKey)}/${record.uuid}`}>
            <StyledAnchor>{code}</StyledAnchor>
          </Link>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: JobType) => (
        <Link to={`/manage/${kebabCase(dataKey)}/${record.uuid}`}>
          <StyledAnchor>{name}</StyledAnchor>
        </Link>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description: string, record: JobType) => (
        <Link to={`/manage/${kebabCase(dataKey)}/${record.uuid}`}>
          <StyledAnchor>{description}</StyledAnchor>
        </Link>
      )
    },
    {
      title: 'Sorting',
      dataIndex: 'sorting',
      key: 'sorting',
      render: (sorting: any, record: JobType) => (
        <Link to={`/manage/${kebabCase(dataKey)}/${record.uuid}`}>
          <StyledAnchor>{sorting}</StyledAnchor>
        </Link>
      )
    },
    {
      ...StatusFilter({
        status: ActiveStatus
      })
    }
  ]

  const [tableData, setTableData] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [pagination, setPagination] = useState({ limit: 20, offset: 0 })

  const queryObj = {
    q: searchKeyword,
    statuses: [JobTypeStatus.Active, JobTypeStatus.Deleted],
    ...pagination
  }

  const { data, error, loading, refetch } = useQuery(gqlQuery, {
    variables: queryObj,
    fetchPolicy: 'cache-and-network'
  })

  const pageInfo = data?.[dataKey]?.pageInfo

  const onPaginationChange = useCallback((current: number, pageSize: number) => {
    setPagination({ limit: pageSize, offset: (current || 1) - 1 })
  }, [])

  useEffect(() => {
    setTableData(data?.[dataKey]?.rows)
  }, [data])

  if (error) {
    logger.error(`CrudTypeTableView ${crudName} error`, error)
    respHandler(error, 'error')
    return <Result status="warning" title={`Failed to load ${crudName}`} />
  }

  if (permLoading) {
    return (
      <Row style={{ marginTop: '30px' }}>
        <Skeleton active />
      </Row>
    )
  }

  if (!hasPermission) {
    return <NoAccess />
  }

  return (
    <PageView>
      <Row type="flex" align="middle">
        <PageHeader title={crudName} />
        {articleUrl && (
          <HelpIcon style={{ marginTop: 20, marginLeft: 10 }} articleUrl={articleUrl} />
        )}
      </Row>

      <ViewContent>
        <TableActions
          refetch={refetch}
          createUrl={createUrl}
          exportSelectedEntity={dataKey}
          setSearchKeyword={setSearchKeyword}
        />
        <StyledTableDiv>
          <Table
            loading={loading}
            pagination={false}
            columns={tableColumns}
            dataSource={tableData}
            rowKey={(record: JobType) => record.uuid}
          />
          <Row style={{ display: 'flex', justifyContent: 'flex-end', margin: '16px 0px' }}>
            <Pagination
              showSizeChanger
              total={pageInfo?.count || 0}
              // @ts-ignore
              onChange={onPaginationChange}
              pageSize={pageInfo?.limit || 20}
              current={(pageInfo?.offset || 0) + 1}
              onShowSizeChange={onPaginationChange}
              pageSizeOptions={['10', '20', '50', '100']}
              showTotal={(total: number, range: any) => `${range[0]}-${range[1]} of ${total} items`}
            />
          </Row>
        </StyledTableDiv>
      </ViewContent>
    </PageView>
  )
}

export default CrudTypesTableView
