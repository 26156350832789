import ActivitiesView from '@/components/DocumentViewer/Content/Activities'
import {
  ActivityPanel,
  DocumentView,
  PreviewWrapper,
  StyledContent,
  Wrapper
} from '@/components/DocumentViewer/Content/Styled'
import ResourceViewer from '@/components/ResourceViewer'
import useGlobalCompanyStore from '@/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import webStorage from '@/utils/webStorage'

function ViewerContent(props) {
  const { bookingDocument = {}, bookingQuery, refreshBooking, toggleDocumentViewer } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const fileType = bookingDocument?.document?.type
  const url = bookingDocument?.url

  return (
    <Wrapper>
      <StyledContent>
        <DocumentView>
          <PreviewWrapper type={fileType}>
            <ResourceViewer
              url={url}
              mimeType={fileType}
              fetchOptions={{
                method: 'GET',
                headers: {
                  authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
                  'base-company-uuid': selectedGlobalCompany.uuid
                }
              }}
            />
          </PreviewWrapper>
        </DocumentView>
        <ActivityPanel>
          <ActivitiesView
            bookingQuery={bookingQuery}
            bookingDocument={bookingDocument}
            toggleDocumentViewer={toggleDocumentViewer}
            refreshBooking={refreshBooking}
          />
        </ActivityPanel>
      </StyledContent>
    </Wrapper>
  )
}

export default ViewerContent
