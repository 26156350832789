import styled from 'styled-components'

export const PermissionName = styled.div`
  font-size: 11.5px;
  font-weight: 600;
  text-transform: uppercase;
  color: #151b26;
`

export const PermissionItem = styled.div`
  width: 100px;

  .fa,
  .fas {
    font-size: 16px;
    color: rgb(145, 147, 149);
    cursor: pointer;

    &:hover {
      color: #151b26;
    }
  }

  .anticon-spin {
    color: rgb(160, 160, 160);
    animation: loadingCircle 0.7s infinite linear;
  }
`

export const StyledMiscPermissions = styled.div`
  min-width: 250px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border: 1px solid #d5dce0;
  line-height: normal;
  padding: 5px 15px;

  font-size: 13.222px;
  color: #151b26;

  .ant-btn {
    width: 100%;
    margin: 10px 0;
    font-size: 13.222px;
    font-weight: 500;
  }
`

export const MiscTitle = styled.div`
  padding: 10px 0;
`

export const MiscItem = styled.div`
  font-size: 13.222px;
  color: #151b26;
  padding: 10px 0;
  min-height: 40px;

  .ant-checkbox-wrapper {
    font-size: 13.222px;
    font-weight: 400;
    color: #151b26;
  }
`

export const NotAvailable = styled.span`
  font-size: 11.222px;
  color: rgb(160, 160, 160);
`
