import { Button } from 'antd-v5'
import { compose, withHandlers, withState } from 'recompose'

import approveVoucherMutation from 'App/containers/voucher/mutation/approveVoucher'
import BulkApproveModal from './BulkApproveModal'

const visibleState = withState('visible', 'setVisible', false)

const handlers = withHandlers({
  handleClick: props => () => {
    const { setVisible } = props
    setVisible(true)
  },
  handleClose: props => status => {
    const { setVisible, onVouchersApproved = () => {} } = props
    setVisible(false)

    if (status === 'done') {
      onVouchersApproved()
    }
  }
})

const enhance = compose(approveVoucherMutation, visibleState, handlers)

const BulkApproveButton = props => {
  const { visible, handleClick, handleClose, vouchers = [] } = props

  return (
    <>
      <Button disabled={vouchers.length === 0} onClick={handleClick}>
        Approve
      </Button>
      {visible && <BulkApproveModal visible={visible} vouchers={vouchers} onClose={handleClose} />}
    </>
  )
}

export default enhance(BulkApproveButton)
