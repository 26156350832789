import { graphql } from '@apollo/client/react/hoc'

import CHANGE_CONSOL_VOUCHER_STATUS from '../schema/changeConsolVoucherStatus'

export default graphql(CHANGE_CONSOL_VOUCHER_STATUS, {
  props: ({ mutate }) => ({
    changeConsolVoucherStatus: (uuid, status) =>
      mutate({
        variables: {
          uuid,
          status
        }
      })
  })
})
