import { withApollo } from '@apollo/client/react/hoc'
import { Button, Modal } from 'antd'
import { compose, withHandlers, withState } from 'recompose'

import voucherRequiredDataSchema from 'App/containers/bulk/voucherRequiredData/schema'
import approveVoucherMutation from 'App/containers/voucher/mutation/approveVoucher'
import BulkApproveList from './BulkApproveList'

const STATUS = {
  pending: 'pending',
  inProgress: 'inProgress',
  done: 'done'
}

const statusState = withState('status', 'setStatus', STATUS.pending)
const voucherResultsState = withState('voucherResults', 'setVoucherResults', props => {
  const { vouchers = [] } = props
  return vouchers.map(v => {
    return {
      ...v,
      result: {
        status: 'pending'
      }
    }
  })
})

const handleApproveVoucher = async (client, voucher, approveVoucher) => {
  const { data: voucherRequiredData } = await client.query({
    query: voucherRequiredDataSchema,
    variables: {
      resource: 'voucher',
      voucherType: voucher.type,
      bookingType: voucher.bookings[0].type
    }
  })

  const approvals =
    voucherRequiredData &&
    voucherRequiredData.actionTemplate &&
    voucherRequiredData.actionTemplate.approvals

  const firstUnapprovedApproval = approvals.find(a => {
    const voucherApproval = voucher.approvals.find(va => va.type === a && va.status === 'APPROVED')
    return !voucherApproval
  })

  if (!firstUnapprovedApproval) {
    return {
      status: 'fail',
      reason: 'No approval required.',
      error: {
        message: 'No approval required.'
      }
    }
  }

  try {
    const { data: approveData } = await approveVoucher({
      voucherUuid: voucher.uuid,
      type: firstUnapprovedApproval
    })

    if (approveData && approveData.approve && approveData.approve.status === 'APPROVED') {
      return {
        status: 'success'
      }
    }
  } catch (error) {
    return {
      status: 'fail',
      reason: 'Error in approving voucher.',
      error
    }
  }
}

const handlers = withHandlers({
  handleCancel: props => () => {
    const { onClose, status } = props
    if (onClose) {
      onClose(status)
    }
  },
  handleOk: props => async () => {
    const { vouchers, approveVoucher, status, setStatus, onClose, setVoucherResults } = props
    let { voucherResults } = props

    if (status === STATUS.done) {
      setStatus(STATUS.pending)
      if (onClose) {
        onClose(status)
      }
      return
    }

    setStatus(STATUS.inProgress)

    for (let i = 0; i < vouchers.length; i++) {
      const voucher = vouchers[i]

      const result = await handleApproveVoucher(props.client, voucher, approveVoucher)

      const newVoucherResults = voucherResults.map(r => {
        if (voucher.uuid === r.uuid) {
          return {
            ...r,
            result
          }
        }

        return r
      })

      voucherResults = newVoucherResults
      setVoucherResults(newVoucherResults)
    }

    setStatus(STATUS.done)
  }
})

const enhance = compose(
  approveVoucherMutation,
  statusState,
  voucherResultsState,
  handlers,
  withApollo
)

const getOkText = status => {
  if (status === STATUS.pending) {
    return 'Approve'
  } else if (status === STATUS.inProgress) {
    return 'Approving'
  } else if (status === STATUS.done) {
    return 'Done'
  }
}

const getFooter = props => {
  const { status, handleOk } = props

  return (
    <Button type="primary" loading={status === STATUS.inProgress} onClick={handleOk}>
      {getOkText(status)}
    </Button>
  )
}

const BulkApproveModal = props => {
  const { visible, handleCancel, handleOk, voucherResults } = props

  if (!visible) {
    return null
  }

  return (
    <Modal
      destroyOnClose
      title="Bulk approve vouchers"
      visible={visible}
      footer={getFooter(props)}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <p>Click OK to approve the following vouchers.</p>
      <BulkApproveList voucherResults={voucherResults} />
    </Modal>
  )
}

export default enhance(BulkApproveModal)
