import type { DynamicField, Maybe } from 'App/types/graphql'
import type { Moment } from 'moment'
import type { ForwardedRef } from 'react'
import { DynamicFieldQuery, DynamicFieldType } from 'App/types/graphql'

import { forwardRef, useState } from 'react'
import { DatePicker, Input } from 'antd'
import moment from 'moment'

import DynamicInputText from '@/components/Booking/DynamicField/DynamicInputText'
import FieldSelector from '@/components/Booking/DynamicField/FieldSelector'
import UploadDocument from '@/components/Booking/DynamicField/UploadDocument'
import AddressSelect from '@/components/Select/AddressSelect'
import EnumSelector from '@/components/Shared/EnumSelector'
import TagsInput from '@/components/Shared/TagsInput/TagsInput'

interface DynamicFieldProps {
  field: Maybe<DynamicField>
  id?: string
  value?: any
  onChange?: (value: any) => void
  disabled?: boolean
  isCapitalize?: boolean
  textColor?: string
}

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm'

const DynamicFieldComponent = forwardRef((props: DynamicFieldProps, ref: ForwardedRef<any>) => {
  const { id, field, value, onChange, disabled = false, textColor = 'black' } = props

  const [companyUuid, setCompanyUuid] = useState<any>(undefined)

  const renderField = (dynamicField: any) => {
    const { key, query, type, customValues, isMultiline, isCapitalize } = dynamicField

    const renameId = id?.split('.').join('-') || id

    let sharedProps: any = {
      value,
      id: renameId,
      disabled,
      ref
    }

    if (!field) {
      return <span>Dynamic Field is undefined</span>
    }

    switch (type) {
      case DynamicFieldType.Selector:
        sharedProps = {
          ...sharedProps,
          onChange
        }
        switch (query) {
          case DynamicFieldQuery.Enums:
            return (
              <EnumSelector
                placeholder="Select a value..."
                enumName={field.enumName}
                multiple={field.multiple}
                {...sharedProps}
              />
            )
          case DynamicFieldQuery.Address:
            return (
              <>
                <FieldSelector
                  key={key}
                  dynamicField={field}
                  style={{ width: '100%' }}
                  onChange={value => setCompanyUuid(value)}
                  disabled={sharedProps.disabled}
                />

                <AddressSelect companyUuid={companyUuid} {...sharedProps} />
              </>
            )
          default: {
            if (query === DynamicFieldQuery.CustomValues && !customValues) {
              return <TagsInput placeholder={`Enter ${key}`} {...sharedProps} />
            }

            return (
              <FieldSelector
                key={key}
                dynamicField={field}
                style={{ width: '100%' }}
                {...sharedProps}
              />
            )
          }
        }
      case DynamicFieldType.Date:
        sharedProps = {
          ...sharedProps,
          onChange: onChange
            ? (_: Moment, dateString: string) => {
                onChange(dateString)
              }
            : onChange,
          value: value ? moment(value, DATE_FORMAT) : undefined
        }

        return (
          <DatePicker
            showTime
            format={DATE_FORMAT}
            placeholder="Select a date and time"
            getCalendarContainer={trigger => trigger.parentNode}
            {...sharedProps}
          />
        )

      case DynamicFieldType.Document:
        sharedProps = {
          ...sharedProps
        }
        return (
          <UploadDocument
            data={value}
            fieldKey={field.key}
            handleDocumentUpload={(acceptedFiles: File) => onChange?.(acceptedFiles)}
            {...sharedProps}
          />
        )
      default:
        sharedProps = {
          ...sharedProps,
          onChange
        }

        return isMultiline ? (
          <Input.TextArea
            rows={4}
            autoComplete="off"
            placeholder={`Enter ${key}`}
            {...sharedProps}
          />
        ) : (
          <DynamicInputText
            isCapitalize={isCapitalize}
            text={key || ''}
            style={{ color: textColor }}
            {...sharedProps}
          />
        )
    }
  }

  return renderField(field)
})

export default DynamicFieldComponent
