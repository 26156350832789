import './index.css'
import './shipx.css'
import 'regenerator-runtime'

import { createRoot } from 'react-dom/client'

import App from './App/App'
import config from './App/config'
import { init } from './App/utils/logger'

init(config.sentry)

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(<App />)
