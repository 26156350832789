import type { SelectProps } from 'antd-v5'

import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'
import find from 'lodash/find'

import responseHandler from '@/utils/responseHandler'
import { isUuid } from '@/utils/u'
import CHARGE_ITEMS_QUERY from './schema'

const { Option } = Select

type ChargeItemSelectProps = SelectProps & {
  name?: string
  isUseCode?: boolean
  statuses?: string[]
}

const ChargeItemSelect = (props: ChargeItemSelectProps) => {
  const { name, isUseCode, statuses, ...rest } = props
  const { value, onChange } = rest

  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    if (name) {
      setKeyword(name)
    }
  }, [name])

  const { loading, data, error } = useQuery(CHARGE_ITEMS_QUERY, {
    variables: {
      query: !isUuid(value) ? value : keyword,
      statuses
    }
  })

  if (error) {
    responseHandler(error.message, 'error')
  }

  const chargeItems = data?.chargeItems?.rows || []
  let bindingValue = value

  if (!find(chargeItems, c => c?.uuid === value || c?.code === value)) {
    bindingValue = name
  }

  const handleChange = (value, option) => {
    onChange?.(value, option?.item || {})
  }

  const handleSearch = debounce(setKeyword, 500)

  return (
    <Select
      {...rest}
      id="cost-charge-item-selector"
      allowClear
      showSearch
      filterOption={false}
      value={bindingValue || value}
      onChange={handleChange}
      placeholder="Select a charge item..."
      notFoundContent={loading ? 'Searching...' : null}
      onSearch={handleSearch}
      getPopupContainer={triggerNode => triggerNode.parentNode}
    >
      {chargeItems.map(chargeItem => (
        <Option
          key={isUseCode ? chargeItem?.code : chargeItem?.uuid}
          value={isUseCode ? chargeItem?.code : chargeItem?.uuid}
          item={chargeItem}
        >
          {chargeItem?.code ? `${chargeItem?.code} - ` : ''}
          {chargeItem?.name}
        </Option>
      ))}
    </Select>
  )
}

export default ChargeItemSelect
