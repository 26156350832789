import { toUpper } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { isUuid } from 'App/utils/u'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

export type AreaCodeQueryType = {
  q?: string | null | undefined
  offset?: number | null | undefined
  limit?: number | null | undefined
}

const sampleData = [
  {
    no: 1,
    category: 'Test',
    code: 'KUL-TEST',
    description: 'Testing',
    name: 'KUL - Test Area Code',
    zone: 'Kuala Lumpur',
    tags: ['test', 'test2'],
    uuid: '3af62b7a-c131-4321-90ca-4d928c6d521d',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: The required fields are: code, name, and zone. Please first export to get the uuid if you wish to update.'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Zone*',
    dataIndex: 'zone',
    key: 'zone'
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    key: 'uuid',
    ellipsis: true
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (areaCode: any) => {
  if (!areaCode.code) return { nullError: 'Code is required, e.g. SGR-KLANG.' }
  if (!areaCode.name) return { nullError: 'Name is required, e.g. Central - Klang.' }
  if (!areaCode.zone) return { nullError: 'Zone is required, e.g. C.' }
}

const populateAreaCodeObj = (obj: any, areaCodeUuid: string) => {
  const areaCodeObj: any = {
    category: obj.category,
    code: toUpper(obj.code?.toString().trim()),
    description: obj.description,
    name: obj.name,
    uuid: areaCodeUuid?.trim(),
    tags: obj.tags || [],
    zone: toUpper(obj.zone?.trim())
  }
  return areaCodeObj
}

const transAreaCodesHelper = {
  queryName: 'transportAreaCodes',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const transportAreaCodes = results?.data?.transportAreaCodes?.rows?.map(
        (row: any, i: number) => ({ no: i + 1, ...row, zzz: '' })
      )
      return transportAreaCodes
    } catch (error) {
      logger.error('transAreaCodesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (areaCodeData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...areaCode } = areaCodeData

    const nullInputError = findNullInputs(areaCode)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
        const transportAreaCodes = results?.data?.transportAreaCodes?.rows

        for (let i = 0; i < transportAreaCodes?.length; i++) {
          cache.uuid[transportAreaCodes[i]?.uuid] = transportAreaCodes[i]?.code
          cache.code[transportAreaCodes[i]?.code] = transportAreaCodes[i]?.uuid
        }
      } catch (error) {
        logger.error('transAreaCodesHelper cache getDataGql error', error)
        return error
      }
    }

    // Check areaCodeUuid
    let areaCodeUuid: string = ''
    if (areaCode?.uuid && isUuid(areaCode?.uuid) && cache.uuid[areaCode?.uuid]) {
      areaCodeUuid = areaCode?.uuid
    } else if (cache.code[areaCode?.code]) {
      areaCodeUuid = cache.code[areaCode?.code]
    }

    let mutationResults
    // Update
    if (areaCodeUuid?.length > 0) {
      try {
        const areaCodeObj = populateAreaCodeObj(areaCode, areaCodeUuid)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: areaCodeObj
        })
      } catch (error) {
        logger.error('glCodesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        areaCodeUuid = uuidv4()
        const areaCodeObj = populateAreaCodeObj(areaCode, areaCodeUuid)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: areaCodeObj
        })
      } catch (error) {
        logger.error('glCodesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default transAreaCodesHelper
