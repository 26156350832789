import type { Approval } from '@/types/graphql'

import { forwardRef, useState } from 'react'
import { Button, Icon, Popover, Row } from 'antd'
import startCase from 'lodash/startCase'
import moment from 'moment'

import { Actions, ApprovalTitle, ApprovedMessage, Selector, SelectorText } from './Styled'

const statusIcons = {
  approved: 'check-circle-o',
  pending: 'exclamation-circle-o'
}

interface ApprovalSelectorProps {
  title: string
  approval: Approval
  mappedType: { text: string }
  approvedApproval: { createdAt: string; approvedBy: { email: string } } | null
  onApprove: (approval: any) => void
  submitting?: boolean
}

const ApprovalSelector = forwardRef<Popover, ApprovalSelectorProps>((props, ref) => {
  const { title, approval, mappedType, approvedApproval, onApprove, submitting = false } = props

  const [showApproval, toggleShowApproval] = useState(false)

  const renderApprovalDetails = (approvedApproval, approval: Approval) => {
    if (approvedApproval) {
      const formattedDate = moment(approvedApproval.createdAt).format('Do MMM, YYYY - h:mm a')
      const approvedBy = (approvedApproval.approvedBy && approvedApproval.approvedBy.email) || '-'

      return (
        <ApprovedMessage>
          Approved by
          <span className="highlight">{approvedBy}</span>on
          <span className="highlight">{formattedDate}</span>
        </ApprovedMessage>
      )
    }

    const onClickApprove = () => {
      onApprove(approval)
    }

    return (
      <Actions>
        <Button onClick={() => toggleShowApproval(false)}>Cancel</Button>
        <Button
          id="finance-approve-voucher-button"
          onClick={onClickApprove}
          type="primary"
          loading={submitting}
        >
          Approve
        </Button>
      </Actions>
    )
  }

  const icon = approvedApproval ? statusIcons.approved : statusIcons.pending

  return (
    <Popover
      title={<ApprovalTitle>{startCase(title)}</ApprovalTitle>}
      trigger="click"
      content={renderApprovalDetails(approvedApproval, approval)}
      visible={showApproval}
      onVisibleChange={visible => toggleShowApproval(visible)}
      ref={ref}
    >
      <Selector id="approval-selector" style={{ width: '200px' }}>
        <Row type="flex">
          <SelectorText>
            <Icon type={icon} /> {startCase(mappedType.text)}
          </SelectorText>
        </Row>
      </Selector>
    </Popover>
  )
})

export default ApprovalSelector
