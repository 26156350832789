import { isEmpty } from 'lodash'

export const getTableRows = (settings: any, tableData: any) => {
  if (isEmpty(settings)) return tableData

  const setting = JSON.parse(settings)
  return tableData.filter(
    (row: any) =>
      (setting[row.key] !== false && setting[row.key] !== 0 && !setting[row.key]) ||
      setting[row.key] > 0
  )
}
