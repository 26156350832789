import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Col } from 'antd-v5'

import i18n, { languageOptions } from '@/libs/i18n'
import useAuthStore from '@/store/auth'
import { LightNotice, LogoDescription, StyledRow } from '../Styled'
import { InputWrapper, LogoWrapper, Wrapper } from './Styled'

function UniversalSignIn() {
  const { t } = useTranslation()
  const { error, loginWithRedirect } = useAuth0()

  const setLegacyMode = useAuthStore.use.setLegacyMode()

  useEffect(() => {
    setLegacyMode(false)
  }, [setLegacyMode])

  return (
    <>
      <div className="flex justify-end w-full gap-10">
        {languageOptions.map(({ label, value }) => {
          const isSelected = i18n.language === value
          return (
            <p
              key={value}
              onClick={() => i18n.changeLanguage(value)}
              style={{ cursor: 'pointer', color: isSelected ? '#1890FF' : '#151b26', padding: 5 }}
            >
              {label}
            </p>
          )
        })}
      </div>

      <StyledRow type="flex" justify="space-around" align="middle">
        <Col span={24} lg={8}>
          <Wrapper>
            <LogoWrapper>
              <img alt="swift haulage logo" src="/static/swiftLogoR.png" height={104} />
              <LogoDescription>
                {t('login.loginToContinue')}{' '}
                <a
                  style={{ fontSize: 12 }}
                  onClick={() => setLegacyMode(true)}
                  href="/auth/v2/sign-in"
                >
                  {t('login.oldLogin')}
                </a>
              </LogoDescription>
            </LogoWrapper>

            <InputWrapper>
              <div className="error-msg">
                {error && (
                  <span style={{ width: 100 }}>{error.message + ', ' + error.message}</span>
                )}
              </div>

              <Button type="primary" onClick={() => loginWithRedirect()}>
                {t('login.proceed')}
              </Button>
            </InputWrapper>

            <LightNotice>
              <p>{t('login.forOptimalSecurityMeasure')}</p>
              <p>{t('login.byClickingProceedButton')}</p>
            </LightNotice>
          </Wrapper>
        </Col>
      </StyledRow>
    </>
  )
}

export default UniversalSignIn
