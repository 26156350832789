import { Form, Select } from 'antd'

const FormItem = Form.Item
const Option = Select.Option

const paymenthMethodDs = [
  {
    text: 'Cash',
    value: 'CASH'
  },
  {
    text: 'Credit',
    value: 'CREDIT'
  }
]

const PaymentMethodFilterFormItem = (props: any) => {
  const {
    form: { getFieldDecorator },
    value
  } = props

  return (
    <FormItem label="Payment Type">
      {getFieldDecorator('paymentType', {
        initialValue: value
      })(
        <Select mode="multiple" placeholder="Please select">
          {paymenthMethodDs.map(p => (
            <Option key={p.value} value={p.value}>
              {p.text}
            </Option>
          ))}
        </Select>
      )}
    </FormItem>
  )
}

export default PaymentMethodFilterFormItem
