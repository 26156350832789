import type { JobType, Trip as TripType } from '@/types/graphql'
import { TripFormat } from '@/types/graphql'

import { Fragment, memo } from 'react'
import styled from 'styled-components'
import { v4 as uuidV4 } from 'uuid'

import Trip from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm/Trip'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'

const StyledJobDiv = styled.div`
  :last-child {
    margin-bottom: 20px;
  }

  .ant-divider {
    margin: 0;
  }

  .buttonLabel {
    margin-top: 60px;
    margin-right: 10px;
    font-size: 0.8em;
    opacity: 0.6;
  }

  .pointLabel {
    font-weight: bold;
  }
`

interface TripsProps {
  value?: any
  onChange: any
  jobUuid: string
  jobType: JobType
  requiredFields: any
  format: TripFormat
  tripsRenderCount?: number
  mode: FormMode
}

const Trips = (props: TripsProps) => {
  const {
    value,
    jobType,
    format,
    jobUuid,
    onChange = () => {},
    requiredFields,
    tripsRenderCount,
    mode
  } = props

  const onChangeTrip = (trips: TripType[]) => {
    onChange(trips)
  }

  return (
    <div key={tripsRenderCount}>
      {value?.map((t: any, index: number) => {
        return (
          <Fragment key={`trip:${t.key || t.uuid || uuidV4()}`}>
            <StyledJobDiv>
              <Trip
                mode={mode}
                jobUuid={jobUuid}
                trips={value}
                index={index}
                format={format}
                jobType={jobType}
                onChange={onChangeTrip}
                requiredFields={requiredFields}
                numberOfTrips={jobType?.tripDetails?.length || 0}
                tripDetail={jobType?.tripDetails?.[index] || undefined}
              />
            </StyledJobDiv>
          </Fragment>
        )
      })}
    </div>
  )
}

export default memo(Trips)
