import type { ReactNode } from 'react'

import { memo } from 'react'

interface SectionProps {
  title?: ReactNode | string
  children?: ReactNode | string | ReactNode[]
  removeBorder?: boolean
}

const Section = ({ title, children, removeBorder }: SectionProps) => {
  return (
    <div style={{ margin: '5px 5px 20px' }}>
      <div
        style={{
          fontWeight: 600,
          marginBottom: 10,
          padding: '6px 0px',
          fontSize: '0.95rem',
          color: 'rgb(40, 45, 50)',
          borderBottom: removeBorder ? 'none' : '1px solid rgb(230, 232, 234)'
        }}
      >
        {title}
      </div>
      {children}
    </div>
  )
}

export default memo(Section)
