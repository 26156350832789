import numeral from 'numeral'
import PropTypes from 'prop-types'

import Error from './Error'
import { ErrorSpan, StyledAnchorField, StyledDash, StyledField, StyledText } from './Styled'

const RenderText = props => {
  const {
    error,
    text = '',
    fixed = true,
    muted = false,
    toRight = false,
    multiline = false,
    isNumeric = false,
    format = '(0,0.00)'
  } = props

  let negative = false

  if (isNumeric) {
    let numberToDisplay = ''

    if (text) {
      if (fixed) {
        numberToDisplay = numeral(text).format(format)
        if (text < 0) {
          negative = true
        }
      } else {
        numberToDisplay = numeral(text).format()
      }
    } else {
      numberToDisplay = '-'
    }

    return (
      <>
        {error && (
          <ErrorSpan>
            <Error error={error} />
          </ErrorSpan>
        )}

        <StyledText muted={muted} toRight={toRight} negative={negative}>
          {numberToDisplay === '-' ? <StyledDash>-</StyledDash> : numberToDisplay}
        </StyledText>
      </>
    )
  }

  return (
    <StyledText muted={muted} toRight={toRight}>
      {error && <Error error={error} />}

      {text === '-' ? (
        <StyledDash>-</StyledDash>
      ) : multiline ? (
        text.split('\n').map(line => {
          return (
            <div key={line}>
              {line}
              <br />
            </div>
          )
        })
      ) : (
        text
      )}
    </StyledText>
  )
}

const CustomText = props => {
  const { noPadding, isLink, url, text } = props

  if (isLink) {
    return (
      <StyledAnchorField noPadding={noPadding} target="_blank" href={url}>
        {text}
      </StyledAnchorField>
    )
  }

  return <StyledField>{RenderText(props)}</StyledField>
}

RenderText.propTypes = {
  toRight: PropTypes.bool,
  isNumeric: PropTypes.bool,
  error: PropTypes.object
}

export default CustomText
