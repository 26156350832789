import type { CostItem, GetVoucherBookingCostSheetQuery, Voucher } from '@/types/graphql'

import { Col } from 'antd-v5'

import Attachments from '@/components/Voucher/Attachments'
import VoucherBookingDetails from '@/components/Voucher/Bookings/Details'
import Transactions from '@/components/Voucher/Bookings/Transactions'
import CostItems from '@/components/Voucher/CostItems'
import { InnerSection } from '@/components/Voucher/Styled'

type ViewContentProps = {
  data: GetVoucherBookingCostSheetQuery | undefined
  refetch: () => void
  onRemoveDocument: (val) => void
  onSelectSingleCostItem: (voucher: Voucher, costItem: CostItem) => void
  onSelectSingleDocument: (val) => void
  onCostItemEdited: () => void
  onCostItemAdded: () => void
}

export default function ViewContent(props: ViewContentProps) {
  const {
    data,
    refetch,
    onRemoveDocument,
    onSelectSingleCostItem,
    onSelectSingleDocument,
    onCostItemEdited,
    onCostItemAdded
  } = props

  return (
    <>
      <InnerSection gutter={20} top="0px" left="20px">
        <Col span={24}>
          <VoucherBookingDetails refetch={refetch} voucherBooking={data?.booking} />
        </Col>
      </InnerSection>

      <InnerSection gutter={20} top="0px" left="20px">
        <Col span={12}>
          <Attachments
            voucherBooking={data?.booking}
            onRemoveDocument={onRemoveDocument}
            onSelectSingleDocument={onSelectSingleDocument}
          />
        </Col>
        <Col span={12}>
          <Transactions voucherBooking={data?.booking} />
        </Col>
      </InnerSection>

      <InnerSection gutter={20} top="30px" left="20px">
        <Col span={24}>
          <CostItems
            voucherBooking={data?.booking}
            onSelectSingleCostItem={onSelectSingleCostItem}
            onCostItemEdited={onCostItemEdited}
            onCostItemAdded={onCostItemAdded}
          />
        </Col>
      </InnerSection>
    </>
  )
}
