import type { VoucherItem } from '@/types/graphql'

import { useState } from 'react'
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Col, Popconfirm, Popover, Row } from 'antd'
import isEmpty from 'lodash/isEmpty'

import { ActionWrapper, StyledAnchor, StyledButton } from '../Styled'
import EditableFieldsWrapper from './EditableFieldsWrapper'

const VoucherItemActions = props => {
  const {
    form,
    booking,
    voucherItem,
    loadingShift,
    handleShiftUp: shiftUp,
    handleShiftDown: shiftDown,
    onRemoveVoucherItem,
    handleUpdateVoucherItem
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [showVoucherItem, setShowVoucherItem] = useState(false)
  const [selectedVoucherItem, setSelectedVoucherItem] = useState<VoucherItem>()

  const handleView = () => {
    setSelectedVoucherItem(voucherItem)
    setShowVoucherItem(!showVoucherItem)
  }

  const handleCancel = () => setShowVoucherItem(false)

  const handleShiftUp = async () => await shiftUp(voucherItem)

  const handleShiftDown = async () => await shiftDown(voucherItem)

  const handleRemove = async () => {
    setIsLoading(true)
    const success = await onRemoveVoucherItem(voucherItem)
    if (!success) setIsLoading(false)
  }

  const renderEditables = () => {
    if (!showVoucherItem) return <span>Closing...</span>

    return (
      // @ts-expect-error
      <EditableFieldsWrapper
        form={form}
        booking={booking}
        onCancel={handleCancel}
        selectedVoucherItem={selectedVoucherItem}
        handleUpdateVoucherItem={handleUpdateVoucherItem}
      />
    )
  }

  const renderPopoverTitle = () => {
    if (isEmpty(selectedVoucherItem)) return

    const { code, description } = selectedVoucherItem?.costItem?.chargeItem || {}

    return (
      <Row type="flex" justify="space-between">
        <Col>
          <p>Edit voucher item</p>
        </Col>
        <Col>
          <p>{code}</p>
          <p>{description || ''}</p>
        </Col>
      </Row>
    )
  }

  return (
    <ActionWrapper>
      <Popover
        trigger="click"
        placement="right"
        visible={showVoucherItem}
        overlayStyle={{ width: 400 }}
        content={renderEditables()}
        title={renderPopoverTitle()}
        // @ts-expect-error getPopopContainer is not in the type
        getPopupContainer={trigger => trigger.parentNode}
        onVisibleChange={visible => setShowVoucherItem(visible)}
      >
        <StyledAnchor onClick={handleView}>
          <EditOutlined />
        </StyledAnchor>
      </Popover>
      <Popconfirm
        okText="Confirm"
        placement="topRight"
        onConfirm={handleRemove}
        title="All changes to this item will be lost, confirm remove ?"
      >
        <StyledAnchor>{isLoading ? <LoadingOutlined /> : <DeleteOutlined />}</StyledAnchor>
      </Popconfirm>
      <StyledButton icon="arrow-up" onClick={handleShiftUp} disabled={loadingShift} />
      <StyledButton icon="arrow-down" onClick={handleShiftDown} disabled={loadingShift} />
    </ActionWrapper>
  )
}

export default VoucherItemActions
