import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'

import AddCostItemActionUI from '@/components/Voucher/CostItems/AddCostItemActionUI'
import * as voucherActions from '@/states/reducers/voucher'

const handlers = withHandlers({
  handleOnCostItemAdded: props => (newCostItemValues, bookingUuid) => {
    if (bookingUuid) {
      const { costsheetBookings, updateCostsheetBookings, onCostItemAdded = () => {} } = props

      const updatedBookings = costsheetBookings?.map(booking => {
        const updatedBooking = { ...booking }

        if (booking.uuid === bookingUuid) {
          const updatedBookingCostItems = [...booking.costItems]

          updatedBookingCostItems.push(newCostItemValues)

          updatedBooking.costItems = updatedBookingCostItems.map(bci => bci)
        }

        return updatedBooking
      })

      updateCostsheetBookings(updatedBookings)

      onCostItemAdded()
    }
  }
})

const enhance = compose(
  connect(
    state => ({
      costsheetBookings: state.voucher.costsheetBookings
    }),
    dispatch => ({
      dispatch,
      ...bindActionCreators(
        {
          ...voucherActions
        },
        dispatch
      )
    })
  ),
  handlers
)

const AddCostItemAction = props => {
  const { bookingUuid, handleOnCostItemAdded } = props

  return <AddCostItemActionUI bookingUuid={bookingUuid} onCostItemAdded={handleOnCostItemAdded} />
}

export default enhance(AddCostItemAction)
