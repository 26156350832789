import { graphql } from '@apollo/client/react/hoc'

import CREATE_CONSOL_VOUCHER from '../schema/createConsolVoucher'

export default graphql(CREATE_CONSOL_VOUCHER, {
  props: ({ mutate }) => ({
    createConsolVoucher: params =>
      mutate({
        variables: {
          input: params
        }
      })
  })
})
