import type { FormItemLayout } from '@/components/Manage/FormItemMapper'
import type { NodeSelectorQuery, Root } from '@/types/graphql'

import { get } from 'lodash'

export const rateCompactLayout: FormItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 }
  }
}
export const onValuesChange = (props, changedValues, allValues) => {
  if (props.onChange) {
    props.onChange(allValues)
  }
}

export const nodeReducer = (node: any, path: string) => {
  const k = get(node, path)
  const keys: string[] = []

  if (k) {
    keys.push(k)
  }

  if (node?.children?.length) {
    const childrenKeys = node.children.flatMap(n => nodeReducer(n, path))
    keys.push(...childrenKeys)
  }

  return keys
}

export const getTreeTitle = (firstEntryCondition: NodeSelectorQuery, selectorKey: string) => {
  if (!firstEntryCondition) return 'new node'
  // TODO: should include all and truncate
  const { comparator, value } = firstEntryCondition //@ts-ignore
  const formattedComparator = comparator.replace('$', '').toUpperCase()

  let keyValue: string | undefined = undefined
  let formattedValue = value

  if (selectorKey) {
    const formattedSelectorKey = selectorKey.split('.')
    const lastWord = formattedSelectorKey.pop()
    const beforeLastWord = formattedSelectorKey.pop()

    keyValue = lastWord
    if (lastWord === 'length') {
      keyValue = `${beforeLastWord} count`
    }
  }

  if (typeof value === 'string' && value.includes('|')) {
    formattedValue = value.split('|')[0]
  }

  const identifier = `${formattedComparator} ${formattedValue}`
  return `${identifier}${keyValue ? ` | ${keyValue}` : ''}`
}

export const stripUnwantedObjKey = (value: Record<string, any>, unwantedKeys: string[] = []) => {
  if (!unwantedKeys.length) return value

  const unwantedObjKeyStripper = value => {
    if (typeof value === 'object' && value !== null) {
      Object.entries(value).forEach(([key, val]) => {
        if (unwantedKeys.includes(key)) {
          delete value[key]
        } else {
          unwantedObjKeyStripper(val)
        }
      })
    } else if (Array.isArray(value)) {
      value.forEach(v => unwantedObjKeyStripper(v))
    }
  }

  unwantedObjKeyStripper(value)
}

export const nodeFinder = (
  node: Record<string, any> | Record<string, any>[],
  matchValue: string,
  matchKey: string = '_id',
  nestedKey: string = 'children'
): Record<string, any> | null => {
  if (!node) return null

  if (Array.isArray(node)) {
    for (const n of node) {
      const found = nodeFinder(n, matchValue, matchKey, nestedKey)

      if (found) return found
    }

    return null
  } else if (typeof node === 'object') {
    const value = get(node, matchKey)

    if (value === matchValue) return node

    return nodeFinder(node[nestedKey], matchValue, matchKey, nestedKey)
  }

  return null
}

export const getRootTitle = (node: Root) => (node ? `${node.code}-${node.name}` : '')
