import { Component } from 'react'
import { Button, Col, Icon, Popover } from 'antd'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { compose, withState } from 'recompose'

import { documentType } from 'App/utils/labelMap'
import { NoItemsFound } from '../../Styled'
import { ItemAction, ItemDescription, StyledRow } from '../Styled'
import Action from './Action'
import { SelectableContainer, SelectableTitle } from './Styled'

class Selectables extends Component {
  renderSelectables = () => {
    const { selectedDocuments, selectableDocuments, onSelectSingleDocument, selectable } =
      this.props

    if (!selectableDocuments.length) {
      return <NoItemsFound>No documents available.</NoItemsFound>
    }

    const displayableDocuments = filter(
      selectableDocuments,
      doc => doc.status !== 'UPLOADED' && doc.status !== 'DELETED'
    )

    return (
      <>
        <SelectableTitle>Select a document</SelectableTitle>
        <SelectableContainer>
          {displayableDocuments.map((doc, index) => {
            const selectedDoc = find(
              selectedDocuments,
              selectedDoc => selectedDoc.uuid === doc.uuid
            )
            const isSelected = !isEmpty(selectedDoc)

            return (
              <StyledRow
                key={doc.uuid}
                type="flex"
                align="middle"
                justify="space-around"
                count={index.toString()}
              >
                <Col span={18}>
                  {documentType[doc.type] || doc.type}
                  <ItemDescription>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/booking/document/${doc.uuid}`}
                    >
                      <Icon type="paper-clip" />
                      {doc.document?.name}
                    </a>
                  </ItemDescription>
                </Col>
                <Col span={6}>
                  <ItemAction>
                    <Action
                      document={doc}
                      selectable={selectable}
                      isSelected={isSelected}
                      onSelectSingleDocument={onSelectSingleDocument}
                    />
                  </ItemAction>
                </Col>
              </StyledRow>
            )
          })}
        </SelectableContainer>
      </>
    )
  }

  render() {
    const { visible, handleView, toggleVisible } = this.props

    return (
      <Popover
        title={null}
        trigger="click"
        visible={visible}
        content={this.renderSelectables()}
        placement="right"
        overlayStyle={{ width: 400 }}
        getPopupContainer={trigger => trigger.parentNode}
        onVisibleChange={visible => toggleVisible(visible)}
      >
        <Button shape="circle" icon="plus" onClick={handleView} />
      </Popover>
    )
  }
}

export default compose(withState('visible', 'toggleVisible', false))(Selectables)
