export const getAllRolesGql = `
  query rolesForPermissionsExport ($input: RolesQuery) {
    roles (input: $input) {
      rows {
        uuid
        code
        name
        status
      }
    }
  }
`

export const getPermissionTypesGql = `
  query permissionTypesExport1 {
    permissionTypes {
      resources
      permissions
    }
  }
`

export const getPermissionsPerRoleGql = `
  query permissionsPerRoleExport1 ($role: String) {
    permission (role: $role) {
      resources
      permissions
    }
  }
`

export const createPermissionGql = `
  mutation createPermissionImport1 ($role: String!, $resource: String!, $permission: String!) {
    createPermission(role: $role, resource: $resource, permission: $permission) {
      resources
    }
  }
`

export const deletePermissionGql = `
  mutation deletePermissionImport1 ($role: String!, $resource: String!, $permission: String!) {
    deletePermission(role: $role, resource: $resource, permission: $permission) {
      resources
    }
  }
`
