import React, { forwardRef } from 'react'
import { Col, Form } from 'antd'

import { FieldColRow, FieldsWrapper } from './Styled'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 }
}

type InputFormProps = {
  fields: any[]
  editable: boolean
  getFieldDecorator: any
}

const InputForm = forwardRef((props: InputFormProps, ref) => {
  const { fields, editable, getFieldDecorator } = props

  return (
    <FieldsWrapper ref={ref}>
      <FieldColRow gutter={16}>
        {fields.map((field, fieldIndex) => {
          return (
            <Col key={field.cols[fieldIndex].value} span={12}>
              {field.cols.map(col => (
                <React.Fragment key={col.value}>
                  {col.type &&
                    col.type === 'hidden' &&
                    (editable
                      ? getFieldDecorator(col.value, col.attributes)(col.input)
                      : col.displayOnly)}
                  {!col.type && (
                    <FormItem label={col.label} {...formItemLayout}>
                      {editable
                        ? getFieldDecorator(col.value, col.attributes)(col.input)
                        : col.displayOnly}
                    </FormItem>
                  )}
                </React.Fragment>
              ))}
            </Col>
          )
        })}
      </FieldColRow>
    </FieldsWrapper>
  )
})

export default InputForm
