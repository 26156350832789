import type { CSSProperties } from 'react'

export const verticalStyle: CSSProperties = {
  writingMode: 'vertical-rl',
  backgroundColor: 'lightgrey',
  textAlign: 'center',
  width: '18px',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  margin: '5px 5px 0 0',
  paddingTop: '5px',
  position: 'absolute',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}

export const containerStyle: CSSProperties = {
  position: 'relative',
  minHeight: '70px',
  width: '100%',
  marginBottom: '24px'
}

export const contentStyle: CSSProperties = {
  marginLeft: '30px',
  flex: 1
}

export const typeStyle: CSSProperties = {
  marginTop: '5px',
  color: '#999',
  fontSize: '12px'
}
