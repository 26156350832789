export const getDataGql = `
  query chargeCategoriesExport1 ($query: String, $statuses: [ChargeItemStatus], $limit: Int, $offset: Int) {
    chargeCategories (query: $query, statuses: $statuses, limit: $limit, offset: $offset) {
      rows {
        code
        description
        type
        sequence
        uuid
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

export const createEntityGql = `
  mutation addChargeCategoryImport1 (
    $type: ChargeCategoryType!
    $code: String!
    $description: String!
    $sequence: String
  ){
    addChargeCategory (
      type: $type
      code: $code
      description: $description
      sequence: $sequence
    ){
      uuid
    }
  }
`

export const updateEntityGql = `
  mutation updateChargeCategoryImport1 ($input: UpdateChargeCategoryInput!){
    updateChargeCategory (input: $input){
      uuid
    }
  }
`
