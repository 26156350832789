import type { GeneratedDocumentQuery } from 'App/types/graphql'

import ResourceViewer from 'App/components/ResourceViewer'
import config from 'App/config'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import webStorage from 'App/utils/webStorage'

interface DocumentViewerProps {
  generatedDocument: GeneratedDocumentQuery['generatedDocument']
  renderRaw: boolean
}

const DocumentViewer = (props: DocumentViewerProps) => {
  const { generatedDocument, renderRaw } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const url = `${config.documentGenerator.baseUrl}/preview?document=${generatedDocument?.uuid}&rawHtml=${renderRaw}`
  const fileType = 'text/html'

  return (
    <div
      style={{
        height: '550px',
        border: 'solid 1px lightgray'
      }}
    >
      <ResourceViewer
        url={url}
        mimeType={fileType}
        fetchOptions={{
          method: 'GET',
          headers: {
            authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
            'base-company-uuid': selectedGlobalCompany.uuid
          }
        }}
      />
    </div>
  )
}

export default DocumentViewer
