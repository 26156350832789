import { gql } from '@apollo/client'

export default gql`
  mutation createConsolVoucherSlim1($input: CreateConsolVoucherInput!) {
    createConsolVoucher(input: $input) {
      uuid
      reference
    }
  }
`
