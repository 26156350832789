import type { Voucher, VoucherTypeType } from '@/types/graphql'

import { Wrapper } from '@/components/Workflow/Voucher/TypeSelections/Styled'
import TypeSelector from '@/components/Workflow/Voucher/TypeSelections/TypeSelector'

type TypeSelectionsProps = {
  section: { vouchers: Voucher[] }
  voucherTypes: VoucherTypeType
  handleCreateClick: (voucher: Voucher, section: { vouchers: Voucher[] }) => void
}

const TypeSelections = (props: TypeSelectionsProps) => {
  const { section = { vouchers: [] }, voucherTypes, handleCreateClick } = props

  return (
    <Wrapper>
      {section.vouchers.map(voucher => {
        const mappedVoucher = (voucher.type && voucherTypes[voucher.type]) || {
          text: voucher.type || ''
        }
        return (
          <TypeSelector
            text={mappedVoucher.text}
            key={voucher.transactionType}
            voucherTransaction={voucher.transactionType}
            handleClick={() => handleCreateClick(voucher, section)}
          />
        )
      })}
    </Wrapper>
  )
}

export default TypeSelections
