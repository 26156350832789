import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Button, Checkbox, Col } from 'antd'

import { ROLES_QUERY } from 'App/components/Manage/Roles/TableView/schema'
import { Actions, CheckboxItem, FilterWrapper } from './Styled'

const CheckboxGroup = Checkbox.Group

type RolesFilterProps = {
  filter: any
  setFilter: (filter: any) => void
}
const RolesFilter = ({ filter, setFilter }: RolesFilterProps) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([...(filter?.roles || '')])
  const { data } = useQuery(ROLES_QUERY, {
    variables: {
      input: {}
    }
  })

  const handleChange = values => {
    setSelectedValues(values)
  }

  const handleApply = () => {
    setFilter({
      ...filter,
      roles: selectedValues
    })
  }

  const handleReset = () => setFilter({ ...filter, roles: [] })

  if (!data) return <div style={{ padding: 5 }}>Loading...</div>

  return (
    <FilterWrapper>
      <CheckboxGroup value={selectedValues} onChange={handleChange}>
        {data.roles?.rows?.map(role => (
          /* @ts-ignore */
          <CheckboxItem key={role.code}>
            <Checkbox value={role.code} checked={selectedValues?.includes(role.code)}>
              {role.name}
            </Checkbox>
          </CheckboxItem>
        ))}
      </CheckboxGroup>
      <Actions gutter={8}>
        <Col span={12}>
          <Button type="primary" onClick={handleApply}>
            Apply
          </Button>
        </Col>
        <Col span={12}>
          <Button onClick={handleReset}>Reset</Button>
        </Col>
      </Actions>
    </FilterWrapper>
  )
}

export default RolesFilter
