import { memo, useCallback, useMemo } from 'react'
import { Icon } from 'antd'
import * as download from 'downloadjs'
import { json2csv } from 'json-2-csv'
import moment from 'moment'

import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'

const Export = memo((props: any) => {
  const { impExpUtils, selectedGlobalCompany, toggleDropdownVisibility, filter = {} } = props

  const { queryName, getExportData, options } = impExpUtils

  const companyName = useMemo(
    () => selectedGlobalCompany?.company?.name?.replace(/\s/g, '_'),
    [selectedGlobalCompany]
  )

  const findQueryError = useCallback((queryResult: any) => {
    if (queryResult?.message) {
      try {
        const errorObj = JSON.parse(queryResult?.message)

        return errorObj.errors?.[0]?.message
      } catch (error) {
        logger.error('findQueryError JSON.parse', error)
        return error
      }
    } else if (
      queryResult?.toString().indexOf('error') > -1 ||
      queryResult?.toString().indexOf('Error') > -1
    ) {
      return queryResult?.toString()
    } else return null
  }, [])

  const csv2jsonDownload = useCallback(
    (err: any, csv: any) => {
      if (err) {
        logger.error('exportAllData json2csv err', err)
        return respHandler(err, 'error')
      }

      try {
        const blob = new Blob([csv], { type: 'text/csv' })
        const fileName = `${companyName}_${queryName}_${moment().format('YYYY-MM-DD_HHmm')}hrs.csv`
        // @ts-ignore
        download(blob, fileName)
      } catch (error) {
        logger.error('exportAllData download error', error)
        respHandler(error, 'error')
      }
    },
    [companyName, queryName]
  )

  const exportAllData = useCallback(async () => {
    try {
      respHandler('Loading data...', 'load')
      toggleDropdownVisibility()

      const exportData = await getExportData(selectedGlobalCompany, filter)
      if (exportData?.length < 1) {
        return respHandler('There is no data to export.', 'warning')
      }

      const queryError = findQueryError(exportData)
      if (queryError) {
        return respHandler(queryError, 'error')
      }

      json2csv(exportData, csv2jsonDownload, options)

      respHandler('Successfully downloaded file.', 'success')
    } catch (error) {
      logger.error('exportAllData query error', error)
      return respHandler(error, 'error')
    }
  }, [selectedGlobalCompany])

  return (
    <div onClick={exportAllData}>
      <Icon type="download" /> Export
    </div>
  )
})

export default Export
