import { Divider, Typography } from 'antd-v5'
import find from 'lodash/find'
import upperCase from 'lodash/upperCase'

import { workflows } from '@/components/Workflow/Container'
import Section from '@/components/Workflow/Section'

const Workflow = props => {
  const { process, ...restProps } = props

  const mappedWorkflow = workflows[process.type] || { type: process.type, text: process.type }
  const hasDisplayableSection = find(process.sections, section => section.display === true)

  if (!mappedWorkflow || !hasDisplayableSection) {
    return <div />
  }

  return (
    <div id={process.type} style={{ marginBottom: 32 }}>
      <Divider orientation="left">
        <Typography.Title level={5} style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
          {upperCase(mappedWorkflow.text)}
        </Typography.Title>
      </Divider>

      {process.sections.map(section => {
        if (section.display) {
          return (
            <Section
              {...restProps}
              key={section.name}
              section={section}
              process={process}
              extras={mappedWorkflow.extras?.[section.type]}
            />
          )
        } else {
          return null
        }
      })}
    </div>
  )
}

export default Workflow
