import { useState } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

import { StyledAnchor } from '@/components/Voucher/Styled'

const VoucherCostItemUsage = ({ onSelectSingleCostItem, index }) => {
  const [loading, setLoading] = useState(false)

  const handleSelectSingleCostItem = async () => {
    setLoading(true)
    await onSelectSingleCostItem()
    setLoading(false)
  }

  return (
    <StyledAnchor id={`select-charge-item-${index}-anchor`} onClick={handleSelectSingleCostItem}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
    </StyledAnchor>
  )
}

export default VoucherCostItemUsage
