import capitalize from 'lodash/capitalize'

import { StatusText } from 'App/components/TableView/Styled'

interface StatusFilterProps {
  status: any
  isCaps?: boolean
}

const StatusFilter = ({ status, isCaps = true }: StatusFilterProps) => {
  const statusOptions = Object.values(status).map((st: any) => ({
    text: capitalize(st),
    value: isCaps ? st : st.toLowerCase()
  }))

  return {
    title: 'Status',
    key: 'status',
    filters: statusOptions,
    onFilter: (value, record) => record.status === value,
    render: record => <StatusText>{record.status || '-'}</StatusText>
  }
}

export default StatusFilter
