import { useState } from 'react'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DownOutlined,
  UpOutlined
} from '@ant-design/icons'
import { Col } from 'antd-v5'

import HiddenView from '@/components/DocumentViewer/Content/Approvals/Hidden'
import {
  ExpandableRow,
  StyledSelector,
  TypeName
} from '@/components/DocumentViewer/Content/Approvals/Styled'
import { documentType } from '@/utils/labelMap'

const ApprovalSelector = ({ approvalType, isApproved, approvedApproval }) => {
  const [showHidden, toggleHidden] = useState(false)
  const toggleHiddenFields = () => toggleHidden(!showHidden)

  return (
    <StyledSelector>
      <ExpandableRow type="flex" align="middle" onClick={toggleHiddenFields}>
        <Col span={2}>{isApproved ? <CheckCircleOutlined /> : <ClockCircleOutlined />}</Col>
        <Col span={21}>
          <TypeName>{documentType[approvalType] || approvalType}</TypeName>
        </Col>
        <Col span={1}>{showHidden ? <UpOutlined /> : <DownOutlined />}</Col>
      </ExpandableRow>

      <HiddenView
        showHidden={showHidden}
        isApproved={isApproved}
        approvalType={approvalType}
        approvedApproval={approvedApproval}
        toggleHiddenFields={toggleHiddenFields}
      />
    </StyledSelector>
  )
}
export default ApprovalSelector
