import { memo, useMemo } from 'react'
import { Table } from 'antd'

import { getDateScale } from 'App/components/Transport/Components/Monitoring/EmptyTrips/helper'
import { formatCurrencyAmount } from 'App/utils/voucher'
import { getLoopInterval } from './helpers'

const TripSummaryTable = memo((props: any) => {
  const { tableData, query, viewBy, loading } = props
  const loopInterval = useMemo(() => getLoopInterval(viewBy), [viewBy])
  const dates = useMemo(
    () =>
      // @ts-ignore
      getDateScale(new Date(query.startDate), new Date(query.endDate), loopInterval, 'YYYY-MM-DD'),
    [query, loopInterval]
  )

  const tableColumns = useMemo(
    () => [
      {
        title: 'Movement',
        key: 'movement',
        align: 'center',
        className: 'trip-summary-table',
        width: 80,
        fixed: 'left',
        render: (text: any, record: any) => record?.movement
      },
      {
        title: 'Total (Laden)',
        key: 'Total(L)',
        align: 'center',
        className: 'trip-summary-table',
        width: 70,
        render: (text: any, record: any) => {
          return <span>{record['Total(L)'] || ''}</span>
        }
      },
      // {
      //   title: 'Total (Return)',
      //   key: 'Total(R)',
      //   align: 'center',
      //   className: 'trip-summary-table',
      //   width: 70,
      //   render: (text: any, record: any) => {
      //     return (
      //       <Tooltip placement='top' title={tooltipTitle}>
      //         <span>
      //           {record['Total(R)']}
      //         </span>
      //       </Tooltip>
      //     )
      //   }
      // },
      {
        title: 'Total (MT)',
        key: 'Total(E)',
        align: 'center',
        className: 'trip-summary-table',
        width: 70,
        render: (text: any, record: any) => {
          return <span>{record['Total(E)'] || ''}</span>
        }
      },
      {
        title: 'Total Cost',
        key: 'Total Cost',
        align: 'center',
        className: 'trip-summary-table',
        width: 100,
        render: (text: any, record: any) => {
          return <span>{formatCurrencyAmount(record['Total Cost']) || ''}</span>
        }
      },
      {
        title: 'Total Trips',
        key: 'Total Trips',
        align: 'center',
        className: 'trip-summary-table',
        width: 70,
        render: (text: any, record: any) => {
          return <span>{record['Total Trips'] || ''}</span>
        }
      },
      {
        title: 'Total (%MT)',
        key: 'Total(% Empty / Total Trip)',
        align: 'center',
        className: 'trip-summary-table trip-summary-table-total',
        width: 70,
        render: (text: any, record: any) =>
          record['Total(% Empty / Total Trip)'] ? `${record['Total(% Empty / Total Trip)']}%` : ''
      }
    ],
    [viewBy]
  )

  dates.forEach((date: string, dateIndex: number) => {
    const foundLaden = tableColumns?.find((col: any) => col.key === `${date}(L)`)
    if (!foundLaden) {
      tableColumns.push({
        title: `${date} (Laden)`,
        key: `${date}(L)`,
        align: 'center',
        className: 'trip-summary-table',
        width: 70,
        render: (text: any, record: any) => {
          return <span>{record[`${date}(L)`] || ''}</span>
        }
      })
    }

    // const foundReturn = tableColumns?.find((col: any) => col.key === `${date}(R)`)
    // if (!foundReturn) {
    //   tableColumns.push({
    //     title: `${date} (Return)`,
    //     key: `${date}(R)`,
    //     align: 'center',
    //     className: 'trip-summary-table',
    //     width: 70,
    //     render: (text: any, record: any) => {
    //       return (
    //         <Tooltip placement='top' title={tooltipTitle}>
    //           <span>
    //             {record[`${date}(R)`]}
    //           </span>
    //         </Tooltip>
    //       )
    //     }
    //   })
    // }

    const foundEmpty = tableColumns?.find((col: any) => col.key === `${date}(E)`)
    if (!foundEmpty) {
      tableColumns.push({
        title: `${date} (MT)`,
        key: `${date}(E)`,
        align: 'center',
        className: 'trip-summary-table',
        width: 70,
        render: (text: any, record: any) => {
          return <span>{record[`${date}(E)`] || ''}</span>
        }
      })
    }

    const foundEmptyCost = tableColumns?.find((col: any) => col.key === `${date}(C)`)
    if (!foundEmptyCost) {
      tableColumns.push({
        title: `${date} (Cost)`,
        key: `${date}(C)`,
        align: 'center',
        className: 'trip-summary-table',
        width: 100,
        render: (text: any, record: any) => {
          return <span>{formatCurrencyAmount(record[`${date}(C)`]) || ''}</span>
        }
      })
    }

    const foundTotal = tableColumns?.find((col: any) => col.key === `${date}(T)`)
    if (!foundTotal) {
      tableColumns.push({
        title: `${date} (TOTAL)`,
        key: `${date}(T)`,
        align: 'center',
        className: 'trip-summary-table',
        width: 70,
        render: (text: any, record: any) => {
          return <span>{record[`${date}(T)`] || ''}</span>
        }
      })
    }

    const foundPctMTReturn = tableColumns?.find((col: any) => col.key === `${date}(% MT Return)`)
    if (!foundPctMTReturn) {
      tableColumns.push({
        title: `${date} (%MT)`,
        key: `${date}(% MT Return)`,
        align: 'center',
        className: 'trip-summary-table trip-summary-table-total',
        width: 70,
        render: (text: any, record: any) =>
          record[`${date}(% MT Return)`] ? `${record[`${date}(% MT Return)`]}%` : ''
      })
    }
  })

  return (
    <Table
      // @ts-ignore
      columns={tableColumns}
      dataSource={tableData}
      pagination={false}
      loading={loading}
      // scroll={{ x: 1200, y: 420 }}
    />
  )
})

export default TripSummaryTable
