import { Component } from 'react'
import numeral from 'numeral'

import { CurrencyText, Formatted, NumericText } from './Styled'

class FormattedCurrency extends Component {
  renderCurrency = currency => {
    if (currency) {
      return <CurrencyText>{currency}</CurrencyText>
    } else {
      return null
    }
  }

  renderNumeric = numeric => {
    if (numeric) {
      return <NumericText>{numeric}</NumericText>
    } else {
      return null
    }
  }

  render() {
    const { value, currency } = this.props

    const formattedValue = numeral(value).format('0,0.00')

    return (
      <Formatted>
        {this.renderCurrency(currency)}
        {this.renderNumeric(formattedValue)}
      </Formatted>
    )
  }
}

export default FormattedCurrency
