import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useApolloClient } from '@apollo/client'

import { openPrintableInNewTab } from '@/components/Shared/documentCreator/openPrintableInNewTab'
import { ViewAnchor } from '@/components/Voucher/Management/Styled'
import { isApproved } from '@/components/Voucher/Utils'
import voucherQuerySchema from '@/containers/voucher/schema/voucherQuery'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'
import * as voucherActions from '@/states/reducers/voucher'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const VoucherModalVoucherNumber = ({ voucher, bcUuid }) => {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const { isPortalUser } = useProcessPortalUser()
  const { updateSelectedVoucher, toggleVoucherView } = voucherActions

  const handleClick = useCallback(async () => {
    try {
      const { data } = await client.query({
        query: voucherQuerySchema,
        fetchPolicy: 'cache-first',
        variables: {
          uuid: voucher.uuid
        }
      })
      dispatch(updateSelectedVoucher(data.voucher))
      dispatch(toggleVoucherView())
    } catch (error) {
      logger.error('VoucherModalVoucherNumber voucherQuerySchema error', error)
      responseHandler(error, 'error')
    }
  }, [client, dispatch, toggleVoucherView, updateSelectedVoucher, voucher.uuid])

  const allowViewVoucher =
    isApproved(voucher) && isPortalUser
      ? () => openPrintableInNewTab(voucher.uuid, bcUuid, 'INVOICE')
      : handleClick

  return (
    <ViewAnchor onClick={allowViewVoucher}>
      {voucher.voucherNumber || voucher.uuid.substring(0, 8)}
    </ViewAnchor>
  )
}

export default VoucherModalVoucherNumber
