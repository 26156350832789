import type { Company, Voucher } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'
import { gql } from '@/types'
import { TransactionType } from '@/types/graphql'

import { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button, Col, Form, Row } from 'antd'
import { compose } from 'recompose'

import UserQuery from 'App/containers/user/query/user'
import BookingRow from './BookingRow'
import BookingTable from './BookingTable'
import BulkInvoiceByCustomerForm from './Form'

const enhance = compose(UserQuery, Form.create())

const BOOKINGS_SEARCH_QUERY = gql(`
  query invoiceBookingsByCustomer($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      rows
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

type BulkInvoiceGeneratorProps = {
  form?: WrappedFormUtils
  selectedVoucher?: Voucher
  onSelectSingleCostItem: () => void
  onCostItemAdded: () => void
  children?: React.ReactNode
}

const BulkInvoiceGenerator = (props: BulkInvoiceGeneratorProps) => {
  const { onSelectSingleCostItem, onCostItemAdded, form, selectedVoucher } = props

  const initCompany =
    selectedVoucher?.transactionType === TransactionType.Accrec
      ? selectedVoucher?.customer
      : selectedVoucher?.vendor

  const [selectedCompany, setSelectedCompany] = useState<Company | undefined | null>(initCompany)
  const [facets, setFacets] = useState<string>(
    'transCompAr:transCompPendOutstandingConsigneeInvoiceVoucher'
  )

  const [elasticBks, setElasticBks] = useState<Array<any>>([])

  const getFilters = () => {
    const obj: any = {}
    if (facets?.length) {
      facets.split(',').forEach((v: any) => {
        const words = v.trim().split(':')
        if (Array.isArray(obj[words[0]])) {
          obj[words[0]].push(words[1])
        } else {
          obj[words[0]] = [words[1]]
        }
      })
    }
    return obj
  }

  const [getBookingSearch, { loading: bookingLoading, data: bookingData }] = useLazyQuery(
    BOOKINGS_SEARCH_QUERY,
    {
      variables: {
        input: {
          q: '',
          limit: 2000,
          filter: {
            companyUuids: [selectedCompany?.uuid],
            facetFilter: getFilters()
          }
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  )

  const search = () => {
    if (!selectedCompany?.uuid) return

    setFacets(form?.getFieldValue('filter'))
    getBookingSearch()
  }

  const onHandleJobSelected = (elasticBk: any) => {
    if (!elasticBks.find((b: any) => b.uuid === elasticBk.uuid)) {
      setElasticBks([...elasticBks, elasticBk])
    }
  }

  const types: string[] = []
  const accountTypes: string[] = []
  if (selectedVoucher?.transactionType === TransactionType.Accrec) {
    types.push('billing')
    accountTypes.push('debtor')
  } else if (selectedVoucher?.transactionType === TransactionType.Accpay) {
    accountTypes.push('creditor')
  }

  return (
    <>
      <Row>
        <Col span={6}>
          <BulkInvoiceByCustomerForm
            form={form}
            initialValue={{
              company: {
                types,
                company: selectedCompany
              },
              facets
            }}
            handleCompanyChange={setSelectedCompany}
          />
          <Button style={{ float: 'right' }} onClick={search}>
            Search
          </Button>
        </Col>
        <Col span={18} style={{ paddingLeft: '20px' }}>
          <BookingTable
            bookings={bookingData?.bookingsSearchJson?.rows}
            onHandleJobSelected={onHandleJobSelected}
            loading={bookingLoading}
          />
        </Col>
      </Row>
      <br />
      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <tbody>
          <tr>
            <th style={{ width: '10%' }}>JobNo</th>
            <th style={{ width: '10%' }}>Ship/Con</th>
            <th>CostSheet</th>
          </tr>
          {elasticBks.map((r: any) => (
            <BookingRow
              // @ts-ignore
              onSelectSingleCostItem={onSelectSingleCostItem}
              onCostItemAdded={onCostItemAdded}
              key={r.uuid}
              elasticBk={r}
            />
          ))}
        </tbody>
      </table>
    </>
  )
}

// @ts-ignore
export default enhance(BulkInvoiceGenerator)
