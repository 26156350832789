import type { Booking, Voucher } from '@/types/graphql'
import type { MenuProps } from 'antd-v5'
import type { PropsWithChildren } from 'react'

import { useState } from 'react'
import { EllipsisOutlined } from '@ant-design/icons'
import { Col, Dropdown, Flex, Input, Row } from 'antd-v5'

import UserQuery from 'App/containers/user/query/user'
import BookingRow from './BookingRow'
import { exportCsv } from './helpers/ExportCsv'

const { Search } = Input

type BulkInvoiceGeneratorProps = PropsWithChildren<{
  selectedVoucher?: Voucher
  onSelectSingleCostItem?: () => void
  onCostItemAdded?: () => void
}>

const BulkInvoiceGenerator = (props: BulkInvoiceGeneratorProps) => {
  const { onSelectSingleCostItem, onCostItemAdded } = props

  const [referenceNums, setReferenceNums] = useState<Array<string>>([])
  const [bookings, setBookings] = useState<Map<number, Booking>>(new Map())

  const onSearchReferences = (value: string) => {
    if (!value) return

    setReferenceNums(value.trim().split(/[ ,]+/))
  }

  const addBookingRow = (booking: Booking, index: number) => {
    if (!booking.no) return

    const existingBookings = bookings

    existingBookings.set(index, booking)

    setBookings(existingBookings)
  }

  const triggerCsvExport = () => exportCsv(bookings)

  const items: MenuProps['items'] = [
    {
      key: 'copy',
      label: 'Copy Values To CSV',
      onClick: triggerCsvExport
    }
  ]

  return (
    <Flex vertical gap="small">
      <Row>
        <Col span={24} className="flex space-between">
          <Search
            placeholder="Comma/Tab Seperated Booking References"
            onSearch={onSearchReferences}
            loading={false}
          />
          <Dropdown menu={{ items }} trigger={['click']}>
            <EllipsisOutlined
              className="cursor-pointer"
              style={{ marginTop: '5px', marginLeft: '10px' }}
            />
          </Dropdown>
        </Col>
      </Row>

      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <tbody>
          <tr>
            <th style={{ width: '10%' }}>JobNo</th>
            <th style={{ width: '10%' }}>Ship/Con</th>
            <th>CostSheet</th>
          </tr>
          {referenceNums.map((r: string, index: number) => (
            <BookingRow
              key={r}
              onSelectSingleCostItem={onSelectSingleCostItem}
              onCostItemAdded={onCostItemAdded}
              index={index}
              referenceNum={r.trim()}
              addBookingRow={addBookingRow}
            />
          ))}
        </tbody>
      </table>
    </Flex>
  )
}

// @ts-expect-error - HOC weak typescript support
export default UserQuery(BulkInvoiceGenerator)
