import { memo, useCallback, useState } from 'react'
import { Button, Dropdown, Icon, Menu } from 'antd'

import useGlobalCompanyStore from 'App/store/globalCompany'
import impExpUtil from 'App/utils/importExport'
import Export from './Export'
import ImportFormModal from './ImportFormModal'

const ImportExport = memo((props: any) => {
  const { style, selectedEntity, filter = {} } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false)
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)

  const toggleDropdownVisibility = useCallback(() => {
    setIsDropdownMenuOpen(!isDropdownMenuOpen)
  }, [isDropdownMenuOpen])

  const toggleModalVisibility = useCallback(() => {
    setIsImportModalOpen(!isImportModalOpen)
  }, [isImportModalOpen])

  const handleClickImport = useCallback(() => {
    toggleDropdownVisibility()
    toggleModalVisibility()
  }, [toggleDropdownVisibility])

  const dropdownMenu = (
    <Menu>
      <Menu.Item>
        <Export
          filter={filter}
          // @ts-ignore
          impExpUtils={impExpUtil[selectedEntity]}
          selectedGlobalCompany={selectedGlobalCompany}
          toggleDropdownVisibility={toggleDropdownVisibility}
        />
      </Menu.Item>
      <Menu.Item>
        <div onClick={handleClickImport}>
          <Icon type="upload" /> Import
        </div>
        <ImportFormModal
          selectedGlobalCompany={selectedGlobalCompany}
          setIsDropdownMenuOpen={setIsDropdownMenuOpen}
          setIsImportModalOpen={setIsImportModalOpen}
          isImportModalOpen={isImportModalOpen}
          // @ts-ignore
          selectedEntity={selectedEntity}
          // @ts-ignore
          impExpUtils={impExpUtil[selectedEntity]}
        />
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={dropdownMenu} visible={isDropdownMenuOpen} trigger={['click']}>
      <Button
        loading={!selectedGlobalCompany?.uuid}
        onClick={toggleDropdownVisibility}
        style={style}
      >
        <Icon type="more" /> Import/Export
      </Button>
    </Dropdown>
  )
})

export default ImportExport
