import { VolumeUnitStatus } from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

const sampleData = [
  {
    name: 'm3',
    code: 'm3',
    sorting: 1,
    description: 'm3',
    status: 'ACTIVE',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: The required fields are: name (e.g. m3 or km3), code (e.g. A003), sorting (e.g. 1)'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Sorting*',
    dataIndex: 'sorting',
    key: 'sorting'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (volumeUnit: any) => {
  if (!volumeUnit.code) return { nullError: 'Code is required, e.g. A003.' }
  if (!volumeUnit.name) return { nullError: 'Name is required, e.g. ADVANCE.' }
  if (!volumeUnit.sorting) return { nullError: 'Sorting is required, e.g. 1.' }
}

const populateVolumeUnitObj = (obj: any, volumeUnitId: string) => {
  const volumeUnitObj: any = {
    uuid: volumeUnitId?.trim(),
    code: obj.code?.toString()?.trim(),
    name: obj.name,
    sorting: parseInt(obj.sorting),
    description: obj.description,
    status: obj.status || VolumeUnitStatus.Active
  }

  return volumeUnitObj
}

const volumeUnitsHelper = {
  queryName: 'volumeUnits',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const volumeUnits = results?.data?.volumeUnits?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row,
        zzz: ''
      }))
      return volumeUnits
    } catch (error) {
      logger.error('volumeUnitsHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (volumeUnitData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...volumeUnit } = volumeUnitData

    const nullInputError = findNullInputs(volumeUnit)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const volumeUnitsQuery = {
          limit: 10000
        }
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          input: volumeUnitsQuery
        })
        const volumeUnits = results?.data?.volumeUnits?.rows

        for (let i = 0; i < volumeUnits?.length; i++) {
          cache.uuid[volumeUnits[i]?.uuid] = volumeUnits[i]?.code
          cache.code[volumeUnits[i]?.code] = volumeUnits[i]?.uuid
        }
      } catch (error) {
        logger.error('volumeUnitsHelper cache getDataGql error', error)
        return error
      }
    }

    // Check volumeUnitId
    let volumeUnitId: string = ''
    if (cache.uuid[volumeUnit?.uuid]) {
      volumeUnitId = volumeUnit?.uuid
    } else if (cache.code[volumeUnit?.code]) {
      volumeUnitId = cache.code[volumeUnit?.code]
    }

    let mutationResults
    // Update
    if (volumeUnitId?.length > 0) {
      try {
        const volumeUnitObj = populateVolumeUnitObj(volumeUnit, volumeUnitId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: volumeUnitObj
        })
      } catch (error) {
        logger.error('volumeUnitsHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        volumeUnitId = uuidv4()
        const volumeUnitObj = populateVolumeUnitObj(volumeUnit, volumeUnitId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: volumeUnitObj
        })
      } catch (error) {
        logger.error('volumeUnitsHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default volumeUnitsHelper
