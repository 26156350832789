import { gql } from '@apollo/client'

export const PORTAL_COMPANIES_QUERY = gql`
  query PortalCompaniesExport1($query: String, $limit: Int, $offset: Int) {
    portalCompanies(query: $query, limit: $limit, offset: $offset) {
      rows {
        name
        status
        viewCompanies {
          code
          name
          types
        }
        bookCompanies {
          code
          name
          types
        }
        employees {
          user {
            email
          }
          roles
        }
      }
    }
  }
`
