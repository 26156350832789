import type { GetVoucherBookingCostSheetQuery } from '@/types/graphql'
import type { TableProps } from 'antd-v5'
import { TransactionType } from '@/types/graphql'

import { Table } from 'antd-v5'
import moment from 'moment'

import CompanyLabel from '@/components/Company/CompanyLabel'
import CustomText from '@/components/Voucher/CustomText'
import { SectionTitle, StyledField, TableWrapper } from '@/components/Voucher/Styled'

type TransactionsType = {
  voucherBooking: GetVoucherBookingCostSheetQuery['booking']
}

const Transactions = ({ voucherBooking }: TransactionsType) => {
  if (!voucherBooking) return null

  const dataSource = voucherBooking.vouchers?.map(item => {
    return {
      key: item?.uuid,
      details: item
    }
  })

  const columns: TableProps<any>['columns'] = [
    {
      title: <StyledField>Type</StyledField>,
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => {
        const transactionType =
          record?.details?.transactionType === TransactionType.Accpay ? 'AP' : 'AR'
        const voucherTypePostFix = record?.details?.isCreditNote
          ? ' (CN)'
          : record?.details?.isJournalVoucher
            ? ' (JV)'
            : ''

        return <CustomText text={transactionType + voucherTypePostFix} />
      }
    },
    {
      title: <StyledField>Date</StyledField>,
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => (
        <CustomText text={moment(record?.details?.accountDate).format('DD/MM/YYYY')} />
      )
    },
    {
      title: <StyledField>Party</StyledField>,
      dataIndex: 'party',
      key: 'party',
      render: (_, record) => {
        const isAp = record?.details?.transactionType === TransactionType.Accpay
        const company = isAp ? record?.details?.vendor : record?.details?.customer

        return <CustomText text={<CompanyLabel company={company} />} />
      }
    },
    {
      title: <StyledField>Voucher No.</StyledField>,
      dataIndex: 'voucherNumber',
      key: 'voucherNumber',
      render: (_, record) => {
        const voucherUrl = `/vouchers/${record?.details?.uuid}`
        return (
          <CustomText
            isLink
            text={record?.details?.voucherNumber || record?.details?.uuid.substring(0, 8)}
            url={voucherUrl}
          />
        )
      }
    },
    {
      title: <StyledField>Status</StyledField>,
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <CustomText text={record?.details?.status} />
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (_, record) => (
        <>
          <CustomText text={record?.details?.currency?.code} muted noPadding />
          <CustomText text={record?.details?.total} toRight isNumeric />
        </>
      )
    }
  ]

  return (
    <>
      <SectionTitle borderless>Transactions</SectionTitle>
      <TableWrapper>
        <Table
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          locale={{ emptyText: 'No transactions available.' }}
        />
      </TableWrapper>
    </>
  )
}

export default Transactions
