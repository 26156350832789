import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@sentry/react'
import { Spin } from 'antd-v5'
import { startCase } from 'lodash'

import Header from '@/components/Layouts/header'
import LeftMenu from '@/components/Layouts/Manage/LeftMenu'
import FeatureGuardUrl from '@/components/Shared/FeatureGuard/Url'
import NoAccess from '@/components/Shared/NoAccess'
import ErrorFallback from '@/ErrorFallback'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'
import useUserStore from '@/store/user'
import auth from '@/utils/auth'

const ManageLayout = () => {
  const location = useLocation()
  const user = useUserStore.use.loggedInUser() || auth.getProfile()

  const { loading, baseCompanyUuids } = useProcessPortalUser(user)

  useEffect(() => {
    const title = location.pathname.split('/')?.[2]?.replace('-', ' ')
    document.title = `ShipX ${!title ? '' : '|'}  ${startCase(title)}`
  }, [location])

  return (
    <>
      <Header />
      <div className="flex justify-center w-full">
        {loading ? (
          <Spin className="flex justify-center items-center max-w-7xl" />
        ) : baseCompanyUuids?.length ? (
          <div className="flex flex-row justify-center flex-1 max-w-7xl">
            <div className="flex flex-col scrollbar-none overflow-y-auto h-[95vh]">
              <LeftMenu />
            </div>
            <div className="flex-1 overflow-y-auto scrollbar-none h-[95vh]">
              <ErrorBoundary fallback={<ErrorFallback />}>
                <FeatureGuardUrl>
                  <Outlet />
                </FeatureGuardUrl>
              </ErrorBoundary>
            </div>
          </div>
        ) : (
          <NoAccess />
        )}
      </div>
    </>
  )
}

export default ManageLayout
