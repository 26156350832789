import React, { Component } from 'react'
import { Icon, Table } from 'antd'
import PropTypes from 'prop-types'

const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    width: '100px',
    className: 'xs-font'
  },
  {
    title: 'Cntr Type',
    dataIndex: 'details.containerType',
    className: 'xs-font'
  },
  {
    title: 'Size',
    dataIndex: 'details.size',
    className: 'xs-font'
  },
  {
    title: 'Weight',
    className: 'xs-font',
    render: (text, record) => {
      if (!record.details.weight) {
        return null
      }

      return `${record.details.weight} ${record.details.weightUnit}`
    }
  },
  {
    title: 'Volume',
    className: 'xs-font',
    render: (text, record) => {
      if (!record.details.volume) {
        return null
      }

      return `${record.details.volume} ${record.details.volumeUnit}`
    }
  },
  {
    title: 'UOM',
    dataIndex: 'details.uom',
    className: 'xs-font'
  },
  {
    title: 'Seal',
    dataIndex: 'details.seal',
    className: 'xs-font'
  },
  {
    title: 'Trans. Type',
    dataIndex: 'details.transportType',
    className: 'xs-font'
  },
  {
    title: 'Options',
    dataIndex: 'details.options',
    className: 'xs-font',
    render: options => {
      if (options) {
        return options.map((option, index, array) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <span title={option}>{option.substr(0, 3)}</span>
              {index === array.length - 1 ? '' : ', '}
            </React.Fragment>
          )
        })
      }
    }
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks',
    className: 'xs-font'
  },
  {
    title: 'Status',
    className: 'xs-font',
    render: (text, record) => {
      if (record.status === 'CANCELLED') {
        return <Icon type="close-circle" title="CANCELLED" style={{ color: 'red' }} />
      }

      return <Icon type="check-circle" title="ACTIVE" style={{ color: 'green' }} />
    }
  }
]

const rowKey = record => {
  return record.uuid
}

const rowClassName = () => {
  return 'xs-font'
}

const getRowSelection = props => {
  const { onChange } = props

  return {
    selectedRowKeys: props.value,
    onChange: selectedRowKeys => {
      if (onChange) {
        onChange(selectedRowKeys)
      }
    }
  }
}

class JobsSelectTable extends Component {
  render() {
    return (
      <Table
        rowSelection={getRowSelection(this.props)}
        columns={columns}
        dataSource={this.props.jobs}
        rowKey={rowKey}
        rowClassName={rowClassName}
        size="small"
        pagination={false}
      />
    )
  }
}

JobsSelectTable.propTypes = {
  jobs: PropTypes.array.isRequired
}

export default JobsSelectTable
