import type { HTMLAttributes } from 'react'

import styled from 'styled-components'

export const CategoryTitle = styled.div<HTMLAttributes<HTMLDivElement>>`
  font-size: 11px;
  font-weight: bold;
  color: rgb(40, 45, 50);
  text-transform: uppercase;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
`

export const Brief = styled.span<HTMLAttributes<HTMLSpanElement>>`
  font-size: 0.7rem;
  font-weight: 400;
  text-transform: capitalize;
  color: rgb(170, 173, 176);
  padding-left: 10px;
  cursor: pointer;
`

export const NoDocument = styled.div`
  margin: 10px 0 50px;
  letter-spacing: 0.3px;
  font-size: 12px;
  font-weight: 600;
  color: rgb(150, 152, 154);
`
