import { Form } from 'antd'
import styled from 'styled-components'

export const ActionWrapper = styled.div`
  padding: 0px 0 10px;
  text-align: right;

  .ant-btn {
    font-size: 13px;
    height: 30px;
    line-height: 28px;

    .anticon {
      font-size: 14px;
      vertical-align: -0.175em;
    }
  }
`

export const FilterWrapper = styled.div`
  padding-top: 15px;
  text-align: left;
`

export const FilterForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-form-item-label {
    line-height: normal;
    padding-bottom: 2px;

    label {
      font-size: 13.222px;
      font-weight: 500;
    }
  }

  .ant-radio-button-wrapper {
    font-size: 13.222px;
    height: 30px;
    line-height: 28px;
  }
`

export const FilterActions = styled.div`
  padding-top: 20px;
  text-align: right;

  .ant-btn {
    height: 32px;
    font-size: 13.222px;
    min-width: 100px;
  }
`
