import { isEqual } from 'lodash'

import { logger } from 'App/utils/logger'
import { isEmail, isUuid } from 'App/utils/u'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEmployeeGql } from './schema'

const sampleData = [
  {
    no: 1,
    user: {
      email: 'ali@swiftlogistics.com.my',
      name: 'test'
    },
    roles: 'OPERATIONS,OPERATIONS_SUPERVISOR,PORTAL_ADMIN',
    status: 'invited',
    uuid: '',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks = '*Note: The required fields are: email and role(s). '

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Email*',
    dataIndex: 'user.email',
    key: 'user.email'
  },
  {
    title: 'Name',
    dataIndex: 'user.name',
    key: 'user.name'
  },
  {
    title: 'Role(s)*',
    dataIndex: 'roles',
    key: 'roles'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    ellipsis: true,
    key: 'uuid'
  },
  {
    title: 'Driver Code',
    dataIndex: 'driver.code',
    key: 'driver.code'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

// cache to hold queried data, to check against data for import
const cache: any = {
  uuid: {},
  email: {}
}

const findBadInputs = (employee: any) => {
  if (!employee.user?.email) {
    return { nullError: 'Email is required, e.g. ali@swiftlogistics.com.my.' }
  }
  if (employee.user?.email) {
    const isValid = isEmail(employee.user?.email)
    if (!isValid) {
      return { nullError: 'Please enter a valid email, e.g. ali@swiftlogistics.com.my.' }
    }
  }
  if (!employee.roles) {
    return { nullError: 'Roles are required, e.g. ["OPERATIONS","OPERATIONS_SUPERVISOR"].' }
  }
}

const populateFields = (employee: any, uuid: string) => {
  const input = {
    uuid,
    users: [
      {
        email: employee.user.email,
        name: employee.user.name,
        roles: employee?.roles
      }
    ]
  }
  return input
}

const employeesHelper = {
  queryName: 'employees',

  getExportData: async (selectedGlobalCompany: any, filter: any) => {
    try {
      const response = await getGqlResponse(selectedGlobalCompany, getDataGql, {
        limit: 10000,
        companyUuids: [selectedGlobalCompany.uuid],
        ...filter
      })
      // add zzz, otherwise there will be a uuid " key instead of uuid
      const readyForImport = response?.data?.employees?.rows?.map((d: any, index: number) => ({
        no: index + 1,
        ...d,
        zzz: ''
      }))
      return readyForImport
    } catch (error) {
      logger.error('employeesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (inputData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...employee } = inputData

    const nullInputError = findBadInputs(employee)
    if (nullInputError) {
      return nullInputError
    }

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const res = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          limit: 10000,
          companyUuids: [selectedGlobalCompany.uuid]
        })
        const queriedData = res?.data?.employees?.rows

        for (let i = 0; i < queriedData?.length; i++) {
          cache.uuid[queriedData[i]?.uuid] = queriedData[i]
          cache.email[queriedData[i]?.user?.email] = queriedData[i]?.uuid
        }
      } catch (error) {
        logger.error('employeesHelper cache getDataGql error', error)
        return error
      }
    }

    // Check if already exists by uuid or email
    let isConstant = false
    let isExist = false
    let toUpdate: any = null
    if (employee?.uuid && isUuid(employee?.uuid) && cache.uuid[employee.uuid]) {
      const { driver: existingDriver, ...existingUser } = cache.uuid[employee.uuid]
      const { driver, ...current } = employee
      delete current.zzz
      delete current.no

      if (
        isEqual(current, existingUser) &&
        ((!existingDriver.code && !driver.code) || existingDriver.code === driver.code)
      ) {
        isConstant = true
      } else {
        toUpdate = {
          companyUuid: selectedGlobalCompany.uuid,
          employeeUuid: existingUser.uuid,
          name: current.user.name,
          roles: current.roles,
          email: current.user.email
        }

        if (driver.code) {
          toUpdate.driverCode = driver.code
        }
      }
    } else if (cache.email[employee?.user?.email]) {
      isExist = true
    }
    // If exists, then skip
    if (isConstant) {
      return null
    } else if (isExist) {
      throw new Error(`User already exist for ${employee.user.email}`)
    } else if (toUpdate) {
      await getGqlResponse(selectedGlobalCompany, updateEmployeeGql, toUpdate)
      return null
    } else {
      // Invite user
      try {
        const toCreate = populateFields(employee, selectedGlobalCompany.uuid)
        const res = await getGqlResponse(selectedGlobalCompany, createEntityGql, toCreate)
        const errorMsg = res?.data?.inviteToCompany?.find((r: any) => r.errorMessage)
        if (errorMsg?.errorMessage) {
          return { nullError: JSON.stringify(errorMsg.errorMessage) }
        } else {
          return null
        }
      } catch (error) {
        logger.error('employeesHelper handleImportData createEntityGql error', error)
        return error
      }
    }
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default employeesHelper
