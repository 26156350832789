import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Form, Select } from 'antd'

import bookingVoucherTypesGql from '@/components/Voucher/ModalHeader/ChangeTypeAction/ChangeTypeModal/ModalContent/bookingVoucherTypesGql'
import extractCommonVoucherTypes from '@/components/Voucher/ModalHeader/ChangeTypeAction/ChangeTypeModal/ModalContent/extractCommonVoucherTypes'
import { voucherTypes } from '@/utils/labelMap'

function ModalContent({ form, voucher }) {
  const [getBookingVoucherTypes] = useLazyQuery(bookingVoucherTypesGql)

  const [data, setData] = useState<string[]>([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    setLoading(true)
    try {
      const promises = voucher.bookings.map(b =>
        getBookingVoucherTypes({ variables: { uuid: b.uuid } })
      )

      const results = await Promise.all(promises)
      const bookings = results.map(r => r.data.booking)
      const extractedData = extractCommonVoucherTypes(bookings)

      setData(extractedData)
      setLoading(false)
    } catch (e: any) {
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>An error occurred. Please try again later.</p>
  }

  if (data.length === 0) {
    return (
      <p>
        No supported voucher types that can be changed to. Please check the bookings in your
        voucher.
      </p>
    )
  }

  return (
    <>
      <p>
        Based on the bookings in your voucher, you can change the voucher type to one of the
        following:
      </p>
      <Form>
        <Form.Item>
          {form.getFieldDecorator('type')(
            <Select>
              {data.map(v => (
                <Select.Option key={v}>
                  {(voucherTypes[v] && voucherTypes[v].text) || v}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </>
  )
}

export default Form.create()(ModalContent)
