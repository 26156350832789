export const getDataGql = `
  query transportAreaCodesExport1 (
    $limit: Int,
    $offset: Int,
    $q: String
  ) {
    transportAreaCodes (
      limit: $limit,
      offset: $offset,
      q: $q
    ) {
      rows {
        category
        code
        description
        name
        zone
        uuid
        tags
      }
    }
  }
`

export const createEntityGql = `
  mutation createTransportAreaCodeImport1 ($input: CreateTransportAreaCodeInput!) {
    createTransportAreaCode (input: $input) {
      category
      code
      description
      name
      zone
      uuid
      tags
    }
  }
`

export const updateEntityGql = `
  mutation updateTransportAreaCodeImport1 ($input: UpdateTransportAreaCodeInput!) {
    updateTransportAreaCode (input: $input) {
      category
      code
      description
      name
      zone
      uuid
      tags
    }
  }
`
