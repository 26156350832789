import Enums from 'App/types/enums'
import { ActiveStatus, GlCodeStatus } from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, getGlCodesGql, updateEntityGql } from './schema'

const sampleData = [
  {
    no: 1,
    category: 'INCENTIVE',
    code: 'WAIT',
    name: 'Waiting Charges',
    details: '{}',
    status: ActiveStatus.Active,
    isJobRequired: true,
    _id: '3af62b7a-c131-4321-90ca-4d928c6d521d',
    glCode: {
      code: 47000000,
      description: 'SILS Revenue Code',
      status: 'activated'
    },
    glCodeUuid: 'c185a699-e05a-4469-8994-8057712dc081',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: The required fields are: category (e.g. EXPENSE or INCENTIVE), code (e.g. A003), name (e.g. ADVANCE), isJobRequired (e.g. TRUE), and glCode.code (e.g. 47000000).'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Category*',
    dataIndex: 'category',
    key: 'category'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    ellipsis: true,
    render: (text: any, record: any) => JSON.stringify(record.details)
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'isJobRequired*',
    dataIndex: 'isJobRequired',
    key: 'isJobRequired'
  },
  {
    title: 'GL Code*',
    dataIndex: 'glCode.code',
    key: 'glCodeCode'
  },
  {
    title: '_id',
    dataIndex: '_id',
    key: '_id',
    ellipsis: true
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {},
  glCode: {
    code: {}
  }
}

const findNullInputs = (incentiveType: any) => {
  if (!incentiveType.category)
    return { nullError: 'Category is required, e.g. EXPENSE or INCENTIVE.' }
  if (incentiveType.category) {
    const categories = Object.keys(Enums.Incentive.Category)
    if (!categories.includes(incentiveType.category)) {
      return {
        nullError: `The provided category="${
          incentiveType.category
        }" does not exist, it should be one of the following: ${categories.join(', ')}`
      }
    }
  }
  if (!incentiveType.code) return { nullError: 'Code is required, e.g. A003.' }
  if (!incentiveType.name) return { nullError: 'Name is required, e.g. ADVANCE.' }
  if (incentiveType.details && Object.keys(incentiveType.details).length) {
    try {
      incentiveType.details = JSON.stringify(JSON.parse(JSON.stringify(incentiveType.details)))
    } catch (error) {
      return {
        nullError: 'Unable to parse details into JSON. Please use a valid JSON object or {}.'
      }
    }
  } else {
    incentiveType.details = '{}'
  }
  if (
    incentiveType?.isJobRequired?.toString()?.toLowerCase() !== 'true' &&
    incentiveType?.isJobRequired?.toString()?.toLowerCase() !== 'false'
  ) {
    return { nullError: 'isJobRequired is required, e.g. TRUE or FALSE.' }
  }
  if (!incentiveType.glCode.code) return { nullError: 'glCode.code is required, e.g. 47000000.' }
  if (
    incentiveType.status &&
    incentiveType.status !== ActiveStatus.Active &&
    incentiveType.status !== ActiveStatus.Deleted
  ) {
    return { nullError: 'status should be ACTIVE or DELETED, or please leave this field blank.' }
  }
}

const populateIncentiveTypeObj = (obj: any, incentiveTypeId: string) => {
  const incentiveTypeObj: any = {
    _id: incentiveTypeId?.trim(),
    category: obj.category,
    code: obj.code?.toString()?.trim(),
    details: obj.details,
    status: obj.status || ActiveStatus.Active,
    name: obj.name,
    glCodeUuid: obj.glCodeUuid,
    isJobRequired: !!obj?.isJobRequired?.toString()?.toLowerCase()?.includes('true')
  }
  return incentiveTypeObj
}

const incentiveTypesHelper = {
  queryName: 'incentiveTypes',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const incentiveTypesQuery = {
        limit: 10000
      }
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
        input: incentiveTypesQuery
      })
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const incentiveTypes = results?.data?.incentiveTypes?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row,
        zzz: ''
      }))
      return incentiveTypes
    } catch (error) {
      logger.error('incentiveTypesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (incentiveTypeData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...incentiveType } = incentiveTypeData

    const nullInputError = findNullInputs(incentiveType)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const incentiveTypesQuery = {
          limit: 10000
        }
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          input: incentiveTypesQuery
        })
        const incentiveTypes = results?.data?.incentiveTypes?.rows

        for (let i = 0; i < incentiveTypes?.length; i++) {
          cache.uuid[incentiveTypes[i]?._id] = incentiveTypes[i]?.code
          cache.code[incentiveTypes[i]?.code] = incentiveTypes[i]?._id
        }
      } catch (error) {
        logger.error('incentiveTypesHelper cache getDataGql error', error)
        return error
      }
    }

    // Check incentiveTypeId
    let incentiveTypeId: string = ''
    if (cache.uuid[incentiveType?._id]) {
      incentiveTypeId = incentiveType?._id
    } else if (cache.code[incentiveType?.code]) {
      incentiveTypeId = cache.code[incentiveType?.code]
    }

    if (Object.keys(cache.glCode.code)?.length < 1) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, getGlCodesGql, {
          limit: 10000,
          statuses: GlCodeStatus.Activated
        })
        const glCodes = results?.data?.glCodes?.rows

        for (let i = 0; i < glCodes?.length; i++) {
          cache.glCode.code[glCodes[i]?.code] = glCodes[i]?.uuid
        }
      } catch (error) {
        logger.error('chargeItemsHelper cache glCodesGql error', error)
        return error
      }
    }

    // Check glCode
    if (cache.glCode.code[incentiveType?.glCode?.code]) {
      incentiveType.glCodeUuid = cache.glCode.code[incentiveType?.glCode?.code]
    } else {
      return {
        nullError: `glCode.code="${incentiveType?.glCode?.code}" is not found, please create it in the GL Code page.`
      }
    }

    let mutationResults
    // Update
    if (incentiveTypeId?.length > 0) {
      try {
        const incentiveTypeObj = populateIncentiveTypeObj(incentiveType, incentiveTypeId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: incentiveTypeObj
        })
      } catch (error) {
        logger.error('incentiveTypesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        incentiveTypeId = uuidv4()
        const incentiveTypeObj = populateIncentiveTypeObj(incentiveType, incentiveTypeId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: incentiveTypeObj
        })
      } catch (error) {
        logger.error('incentiveTypesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default incentiveTypesHelper
