import { Row } from 'antd'
import styled from 'styled-components'

export const FilterWrapper = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border: 1px solid #d5dce0;
  padding: 10px 15px;
  min-width: 220px;
`

export const CheckboxItem = styled.div`
  padding: 6px 0;

  .ant-checkbox-wrapper {
    font-size: 13px;
    font-weight: 400;
    color: #151b26;
    letter-spacing: 0.1px;
    text-rendering: optimizeLegibility;
  }
`

export const Actions = styled(Row)`
  margin-top: 20px;

  .ant-btn {
    height: 30px;
    font-size: 13px;
    width: 100%;
  }
`
