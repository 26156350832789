import { Fragment } from 'react'

const Address = ({ address }) => {
  if (!address) {
    return null
  }

  const addressLines = [
    address.address1 || undefined,
    address.address2 || undefined,
    address.address3 || undefined,
    address.address4 || undefined,
    address.district || undefined,
    address.postcode || undefined,
    address.city || undefined
  ].filter(Boolean)

  return (
    <p>
      {address.name}
      <br />
      {addressLines.map(a => {
        return (
          <Fragment key={a}>
            {a}
            <br />
          </Fragment>
        )
      })}
      {address.phone && (
        <Fragment>
          Phone: {address.phone}
          <br />
        </Fragment>
      )}
      {address.fax && (
        <Fragment>
          Fax: {address.fax}
          <br />
        </Fragment>
      )}
    </p>
  )
}

export default Address
