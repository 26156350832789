import { graphql } from '@apollo/client/react/hoc'

import schema from '../schema'

export default graphql(schema.SET_BOOKING_ASSIGNEE, {
  props: ({ mutate, ownProps }) => ({
    setBookingAssignee: params =>
      mutate({
        variables: {
          input: params
        },
        refetchQueries: [
          {
            query: schema.BOOKING_QUERY,
            variables: {
              uuid: ownProps.booking.uuid
            }
          }
        ]
      })
  })
})
