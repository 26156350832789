import { Popover } from 'antd'
import startCase from 'lodash/startCase'
import { compose, withHandlers, withState } from 'recompose'

import TextInputUpdate from './TextInputUpdate'

const valueState = withState('value', 'setValue', props => {
  const { document, field } = props
  return document[field]
})

const visibleState = withState('visible', 'setVisible', false)

const handlers = withHandlers({
  handleValueUpdate: props => async value => {
    const { field } = props
    try {
      await props.onUpdate(field, value)
      props.setValue(value)
      props.setVisible(false)
    } catch (error) {
      console.log(error)
    }
  },
  handleVisibleChange: props => visible => {
    props.setVisible(visible)
  }
})

const DocumentFieldUpdate = props => {
  const { field, value } = props
  const title = startCase(field)

  return (
    <Popover
      title={title}
      content={
        <TextInputUpdate field={field} value={value} onValueUpdate={props.handleValueUpdate} />
      }
      trigger="click"
      visible={props.visible}
      onVisibleChange={props.handleVisibleChange}
    >
      <span>{value || '-'}</span>
    </Popover>
  )
}

export default compose(valueState, visibleState, handlers)(DocumentFieldUpdate)
