import moment from 'moment'

export const getActTime = (time: Date | string, isDetail?: boolean) => {
  if (isDetail && time) return moment(time).format('DD/MM/YYYY HH:mm')

  let rtnStr = ''
  if (time) {
    if (moment(time).isSame(moment(), 'day')) {
      rtnStr = moment(new Date(time)).format('HH:mm')
    } else if (moment(time).isSame(moment(), 'month')) {
      rtnStr = moment(new Date(time)).format('Do HH:mm')
    } else if (moment(time).isSame(moment(), 'year')) {
      rtnStr = moment(new Date(time)).format('Do MMM HH:mm')
    } else {
      rtnStr = moment(new Date(time)).format("'YY MMM Do HH:mm")
    }
  } else {
    rtnStr = '-'
  }
  return rtnStr
}

export const showShortHumanizeDate = (time: string | Date) => {
  return convertToShortHumanizeDate(moment(time).fromNow())
}

export const convertToShortHumanizeDate = (str: string) => {
  str = str.replace('about ', '')

  str = str.replace('an hour', ' 1h')
  str = str.replace(' hours', 'h')
  str = str.replace(' hour', 'h')

  str = str.replace('a day', ' 1d')
  str = str.replace(' days', 'd')
  str = str.replace(' day', 'd')

  str = str.replace('a month', ' 1M')
  str = str.replace(' months', ' mth')

  str = str.replace('a year', ' 1y')
  str = str.replace(' years', 'y')

  str = str.replace(' minutes', 'm')
  str = str.replace('a minute', '1m')
  str = str.replace('less than a minute ago', 'just now')

  str = str.replace('a few seconds ago', 'just now')
  str = str.replace(' seconds', 's')

  return str
}

export const getLocalDateString = (time: string): string => {
  if (!time) {
    return ''
  }
  return new Date(time).toLocaleDateString()
}

export const getLocalTimeString = (time: string): string => {
  if (!time) {
    return ''
  }
  return new Date(time).toLocaleTimeString()
}

export const getLocalDateTimeString = (time: string): string => {
  if (!time) {
    return ''
  }
  return new Date(time).toLocaleDateString() + ' ' + new Date(time).toLocaleTimeString()
}

const RelativeDateTime = (props: any) => {
  const { value } = props

  return getActTime(value)
}

export default RelativeDateTime
