import type { Vehicle } from 'App/types/graphql'
import enums from 'App/types/enums'
import { VehicleStatus } from 'App/types/graphql'

import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Select } from 'antd'
import debounce from 'lodash/debounce'

import { USER_PREFERENCE_QUERY } from 'App/components/Booking/NewBookingForm/_shared/schema'
import { getUserPreferenceValue } from 'App/components/Manage/UserSettings/UserSettingsForm'
import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'
import { VEHICLES_QUERY } from '../Schemas/schema'

const Option = Select.Option

type VehicleSelectorProps = {
  value?: any
  statuses?: Array<VehicleStatus>
  onChange?: any
  mode?: string | undefined | null
  setDriverPreference?: any
  setTrailerPreference?: any
  style?: any
  queryOnMount?: boolean
}

export const formatVehicle = (obj: any, vehicleDisplayMode?: string) => {
  // vehicleDisplayMode is from userPreferences

  if (!obj?.registration && !obj?.vehicleEntity?.registration && !obj?.vehicleName) {
    return '-'
  }

  // For VehicleSelector
  if (obj?.registration) {
    let result = `${obj?.code || ''} - ${obj?.registration || ''}`
    if (
      vehicleDisplayMode === enums.Vehicle.Display.code ||
      vehicleDisplayMode === enums.Vehicle.Display.registration
    ) {
      result = obj?.[vehicleDisplayMode] || ''
    }
    return result
  }

  // For transport legs
  const code = obj?.vehicleCode || obj?.vehicleEntity?.code
  const registration = obj?.vehicleName || obj?.vehicleEntity?.registration
  let result = `${code ? `${code} - ` : ''}${registration}`
  if (vehicleDisplayMode === enums.Vehicle.Display.code) {
    result = code || '-'
  }
  if (vehicleDisplayMode === enums.Vehicle.Display.registration) {
    result = registration || '-'
  }
  return result
}

const VehicleSelector = forwardRef((props: VehicleSelectorProps, ref: any) => {
  const { t } = useTranslation()

  const {
    mode,
    style,
    value,

    onChange,
    setDriverPreference,
    setTrailerPreference,
    queryOnMount = false,
    statuses = [VehicleStatus.Active]
  } = props

  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [searchInput, setSearchInput] = useState('')

  const {
    loading: userPrefLoad,
    error: userPrefError,
    data: userPrefData
  } = useQuery(USER_PREFERENCE_QUERY, {
    variables: {
      type: enums.UserPreference.Type.SETTINGS,
      key: enums.Vehicle.Display.key
    },
    fetchPolicy: 'cache-first'
  })

  const vehicleDisplayMode = getUserPreferenceValue(
    enums.UserPreference.Type.SETTINGS,
    enums.Vehicle.Display.key,
    userPrefData
  )

  const input = useMemo(
    () => ({
      q: (!mode && value) || searchInput || '',
      statuses,
      uuids: (mode && !searchInput && value) || [],
      limit: 20
    }),
    [mode, value, searchInput, statuses]
  )

  const [getVehicles, { data, error, loading }] = useLazyQuery(VEHICLES_QUERY, {
    variables: { ...input },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (queryOnMount || value) {
      getVehicles()
    }
  }, [queryOnMount, value])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        getVehicles()
      } catch (error) {
        // ok to fail when the component has already unmounted
      }
    }, 500),
    []
  )

  const onSelect = useCallback(
    (onSelectVal: string) => {
      const selectedVehicle = vehicles?.find((v: any) => v?.uuid === onSelectVal)

      if (selectedVehicle?.driverPreference?.length && setDriverPreference) {
        setDriverPreference(selectedVehicle?.driverPreference)
      }
      if (selectedVehicle?.trailerPreference?.length && setTrailerPreference) {
        setTrailerPreference(selectedVehicle?.trailerPreference)
      }
    },
    [setDriverPreference, setTrailerPreference, vehicles]
  )

  useEffect(() => {
    if (data?.vehicles?.rows?.length && setVehicles) {
      setVehicles(data?.vehicles?.rows)
    }
  }, [data])

  if (userPrefError || error) {
    logger.error(
      'VehicleSelector USER_PREFERENCE_QUERY or VEHICLES_QUERY error',
      userPrefError || error
    )
    responseHandler(userPrefError || error, 'error')
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return
    getVehicles()
  }

  return (
    <Select
      id="vehicle-leg-form-selector"
      ref={ref}
      showSearch
      allowClear
      // @ts-ignore
      mode={mode}
      style={style}
      onChange={onChange}
      onSelect={onSelect}
      filterOption={false}
      onFocus={fetchOnFocus}
      onSearch={handleSearch}
      value={value || undefined} // undefined to show placeholder
      loading={userPrefLoad || loading}
      placeholder={t('common.searchAVehicle')}
      notFoundContent={loading ? t('common.searching') : t('common.notFound')}
    >
      {vehicles?.map((vehicle: Vehicle) => (
        <Option key={vehicle.uuid} value={vehicle.uuid}>
          {formatVehicle(vehicle, vehicleDisplayMode)}
        </Option>
      ))}
    </Select>
  )
})

export default VehicleSelector
