import type { Maybe, TransportJob } from 'App/types/graphql'

import { memo, useCallback, useMemo } from 'react'
import { Col, Row, Typography } from 'antd'

interface LegDetailsDisplayProps {
  leg?: TransportJob
}

const LegDetailsDisplay = memo(({ leg }: LegDetailsDisplayProps) => {
  const mergeValues = useCallback(
    (array: Array<Maybe<string> | Maybe<number> | undefined>): string => {
      return array?.filter((elem: Maybe<string> | Maybe<number> | undefined) => elem)?.join(' - ')
    },
    []
  )

  const legDetails = useMemo(
    () => [
      {
        label: 'Leg',
        value: mergeValues([leg?.sequence, leg?.legStatus])
      },
      {
        label: 'Transporter',
        value: leg?.transportName
      },
      {
        label: 'Source',
        value: leg?.transportSource
      },
      {
        label: 'Driver',
        value: mergeValues([leg?.driverCode, leg?.driverName])
      },
      {
        label: 'Vehicle',
        value: mergeValues([leg?.vehicleCode, leg?.vehicleName])
      },
      {
        label: 'Trailer',
        value: mergeValues([leg?.trailerCode, leg?.trailerName])
      },
      {
        label: 'Remarks',
        value: leg?.remarks
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leg]
  )

  return (
    <Row>
      {legDetails.map((detail: any) => {
        return (
          <Row
            type="flex"
            gutter={24}
            align="middle"
            key={detail.label}
            justify="space-between"
            style={{ marginLeft: '0', marginBottom: 5 }}
          >
            <Col span={10}>
              <Typography.Text strong type="secondary">{`${detail.label} : `}</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text strong id={detail.label as string}>
                {detail.value}
              </Typography.Text>
            </Col>
          </Row>
        )
      })}
    </Row>
  )
})

export default LegDetailsDisplay
