import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { useTranslation } from 'react-i18next'
import { Form } from 'antd'

import BookingSelect from 'App/components/Select/BookingSelect'

interface BookingSelectFormProps {
  form: WrappedFormUtils
  generatedDocument: any
}

const BookingSelectForm = (props: BookingSelectFormProps) => {
  const {
    form: { getFieldDecorator },
    generatedDocument
  } = props

  const { t } = useTranslation()

  return (
    <Form>
      <Form.Item key="groups" label={t('booking.associatedBookings')}>
        {getFieldDecorator('groups', {
          // This will end up showing UUIDs in the select. Need to figure out a way to
          // display booking number instead.
          initialValue: generatedDocument.groups,
          rules: [{ required: true, message: 'There needs to be at least one association' }]
        })(
          <BookingSelect
            mode={'multiple'}
            placeholder={t('booking.associateOtherBookings')}
            simpleOptionText
          />
        )}
      </Form.Item>
    </Form>
  )
}

export default BookingSelectForm
