import type { ReportJobSummary } from 'App/types/graphql'
import type { DailyColsDataProps, DailyTableHeaders } from './types'

import moment from 'moment'

import {
  formatToDashboardNumber,
  sumByDateStr
} from 'App/components/Transport/Dashboard/helpers/general'
import { ViewOptions } from '../../Planning/TripSummary/TripSummaryTableWrapper'

// @ts-ignore
const tableRowHeaders: DailyTableHeaders = {
  mixedDeliveries: 'Deliveries (trips)',
  mixedJobs: 'Total Jobs',
  mixedPlannedJobs: 'Jobs Planned',
  mixedCompletedJobs: 'Jobs Completed',
  mixedPods: 'PODs (web, driverApp)',
  invoiced: 'Invoiced',
  mixedUpdateTimeMins: 'Avg. Update Time (min)',
  costSheetEdited: 'Cost Sheet Edited (%)',
  jobsCreatedAfterShipperDate: 'Jobs Created After Shipper Date',
  mixedJobUpdates: 'Driver Jobs Update (%)',
  totalDriversUseApp: 'Drivers using App',
  drUtil: 'Driver Utilization',
  pmUtil: 'Truck Utilization',
  totalCost: 'Est. Cost',
  totalSell: 'Est. Sell',
  totalRevenue: 'Revenue'
}

export const dailyTimeFormat: string = 'YYYY-MM-DD'

export const numberOfDays = 6

export const getDailyDateRange = (queryInput: any = {}) => {
  return {
    startDate: moment(queryInput.endDate || new Date()).subtract(6, 'days'),
    endDate: moment(queryInput.endDate || new Date())
  }
}

export const getDailyColsData = ({
  reportJobSummaries = [],
  queryInput = {},
  selectedGlobalCompany = {}
}: DailyColsDataProps) => {
  const formatDataRes: any = sumByDay(reportJobSummaries, queryInput)

  const tableColumns = [
    {
      Header: '',
      accessor: 'rowName',
      style: { textAlign: 'left', paddingLeft: '10px', minWidth: '100px' }
    }
  ]

  let tableData = Object.keys(tableRowHeaders).map(key => ({ key, rowName: tableRowHeaders[key] }))

  const { startDate, endDate } = getDailyDateRange(queryInput)

  for (let d = startDate; d <= endDate; d = moment(d).add(1, 'days')) {
    const dateStr: string = d.format(dailyTimeFormat)
    tableColumns.push({
      Header: d.format('ddd DD MMM'),
      accessor: dateStr,
      style: { textAlign: 'right', paddingLeft: '10px', minWidth: '100px' }
    })

    tableData = formatToDashboardNumber({
      tableRowHeaders,
      tableData,
      groupedReportJobSum: formatDataRes,
      dateStr,
      selectedGlobalCompany
    })
  }

  return { tableColumns, tableData }
}

export const sumByDay = (reportJobSummaries: ReportJobSummary[], queryInput: any) => {
  const { startDate, endDate } = getDailyDateRange(queryInput)
  return sumByDateStr(reportJobSummaries, startDate, endDate, ViewOptions.DAILY)
}
