import { Component } from 'react'
import { compose, withState } from 'recompose'

export default WrappedComponent => {
  class WithCreatePayment extends Component {
    handleCancel = () => {
      this.props.togglePaymentView(false)
    }

    handleView = () => {
      this.props.togglePaymentView(!this.props.showPayment)
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleView={this.handleView}
          handleCancel={this.handleCancel}
        />
      )
    }
  }

  return compose(
    withState('showPayment', 'togglePaymentView', false),
    withState('selectedPayment', 'updateSelectedPayment', {})
  )(WithCreatePayment)
}
