import { graphql } from '@apollo/client/react/hoc'

import schema from '../schema'

export default graphql(schema.UNSET_BOOKING_ASSIGNEE, {
  props: ({ mutate, ownProps }) => ({
    unsetBookingAssignee: params =>
      mutate({
        variables: params,
        refetchQueries: [
          {
            query: schema.BOOKING_QUERY,
            variables: {
              uuid: ownProps.booking.uuid
            }
          }
        ]
      })
  })
})
