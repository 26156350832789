import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Form } from 'antd'
import { Button, Input, Popconfirm, Popover } from 'antd-v5'
import { compose } from 'recompose'

import { BASIC_BOOKING_SLIM_QUERY } from '@/containers/booking/schema/basicBookingQuerySlim'
import { CHANGE_BOOKING_STATUS_SLIM } from '@/graphql/booking'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'

const enhance = compose(Form.create())

const BookingActionButton = ({ type, form, booking, buttonText, newBookingStatus }) => {
  const { t } = useTranslation()

  const [showCancelBooking, setShowCancelBooking] = useState(false)

  const [changeBookingStatus] = useMutation(CHANGE_BOOKING_STATUS_SLIM)

  const onBookingAction = async () => {
    let remarks = null

    remarks = form?.getFieldValue('description')

    if (buttonText === 'Reject' || buttonText === 'Cancel') {
      if (!remarks?.length) {
        return respHandler('Please enter a description.', 'error')
      }
    }

    respHandler('Updating booking, hang on...', 'load')

    try {
      const res = await changeBookingStatus({
        variables: {
          uuid: booking.uuid,
          status: newBookingStatus,
          remarks
        },
        refetchQueries: [
          {
            query: BASIC_BOOKING_SLIM_QUERY,
            variables: {
              uuid: booking.uuid
            }
          }
        ]
      })

      if (res?.data?.changeBookingStatus?.uuid) {
        respHandler('Updated successfully.', 'success')
      }
    } catch (error) {
      logger.error('BookingActionButton changeBookingStatus error', error)
      respHandler(error, 'error')
    }
  }

  const content = (
    <Form>
      <Form.Item name="description" label="Why:">
        {form?.getFieldDecorator('description', {
          rules: [{ required: true, message: 'Description is required.' }]
        })(<Input.TextArea name="description" rows={4} />)}
      </Form.Item>

      <div className="flex justify-end gap-10 mt-4">
        <Button onClick={() => setShowCancelBooking(false)}>{t('common.cancel')}</Button>
        <Button type="danger" onClick={onBookingAction}>
          {t('common.yes')}
        </Button>
      </div>
    </Form>
  )

  if (buttonText === 'Uncancel') {
    return (
      <Popover
        arrow={false}
        okType={type}
        trigger="click"
        content={content}
        placement="bottom"
        open={showCancelBooking}
        okText={t('common.yes')}
        cancelText={t('common.no')}
        styles={{ root: { width: 200 } }}
        title={'Uncancel this booking?'}
        onOpenChange={visible => setShowCancelBooking(visible)}
      >
        <Button type={type} ghost>
          {buttonText}
        </Button>
      </Popover>
    )
  }

  if (buttonText === 'Reject' || buttonText === 'Cancel') {
    return (
      <Popover
        arrow={false}
        trigger="click"
        content={content}
        placement="bottom"
        open={showCancelBooking}
        styles={{ root: { width: 200 } }}
        onOpenChange={visible => setShowCancelBooking(visible)}
        title={buttonText === 'Cancel' ? 'Cancel this booking?' : 'Reject this booking?'}
      >
        <Button type={type} ghost>
          {buttonText}
        </Button>
      </Popover>
    )
  }

  return (
    <Popconfirm
      arrow={false}
      okType={type}
      placement="bottom"
      okText={t('common.yes')}
      cancelText={t('common.no')}
      title={t('instructions.areYouSure')}
      onConfirm={onBookingAction}
    >
      <Button id="confirm-accept-button" type={type}>
        {buttonText}
      </Button>
    </Popconfirm>
  )
}

export default enhance(BookingActionButton)
