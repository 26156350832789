import { Component } from 'react'
import { compose, withState } from 'recompose'

const enhance = compose(
  withState('currentSize', 'updateSize', 0),
  withState('searchKeyword', 'updateSearchKeyword', '')
)

class WithSearchAndPagination extends Component {
  render() {
    const { children, render } = this.props

    const returnProps = {
      updateSize: this.props.updateSize,
      currentSize: this.props.currentSize,
      searchKeyword: this.props.searchKeyword,
      updateSearchKeyword: this.props.updateSearchKeyword
    }

    if (render) return render(returnProps)

    if (typeof children === 'function') return children(returnProps)

    return null
  }
}

export default enhance(WithSearchAndPagination)
