import type { DOMAttributes, PropsWithChildren } from 'react'

import styled from 'styled-components'

export const StyledButton = styled.span<DOMAttributes<HTMLElement> & PropsWithChildren<unknown>>`
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`
