import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Input } from 'antd-v5'
import { parse, stringify } from 'query-string'

import { getBookingQuery } from '@/components/Layouts/Search/helper'

export default function SearchBox() {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchInput, setSearchInput] = useState<string>()

  useEffect(() => {
    const fromURL = location.search.slice(1)
    const urlParams = parse(fromURL)

    if (urlParams.q) setSearchInput(urlParams?.q as string)
  }, [location.search])

  const generateQuery = () => {
    const { urlFormat } = getBookingQuery(location, { q: searchInput || '' })
    navigate(`/?${stringify(urlFormat)}`)
  }

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = e.target.value.replace(/[^\s\w-]/gi, '')
    setSearchInput(sanitizedValue)
  }, [])

  const handleOnClear = () => {
    const { urlFormat } = getBookingQuery(location, { q: undefined })
    navigate(`/?${stringify(urlFormat)}`)
  }

  const isIndexPage = location.pathname === '/'

  return (
    <div className="flex items-center h-full">
      <Input
        allowClear
        type="text"
        size="large"
        suffix={null}
        spellCheck="false"
        autoComplete="off"
        value={searchInput}
        name="searchBookings"
        autoFocus={isIndexPage}
        onClear={handleOnClear}
        onChange={handleChange}
        onPressEnter={generateQuery}
        placeholder="Search booking here..."
        style={{
          margin: 0,
          height: 34,
          fontSize: 13,
          paddingLeft: 5,
          borderRadius: 5,
          alignItems: 'center'
        }}
        id="booking-elastic-search-input"
      />
    </div>
  )
}
