import { gql } from '@/types'

import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Divider, Spin } from 'antd-v5'

import VoucherBookings from '@/components/Voucher/Bookings/'
import InputFields from '@/components/Voucher/InputFields'
import Overrides from '@/components/Voucher/Overrides/'
import Payments from '@/components/Voucher/Payments/'
import { SectionTitle, ViewSection, ViewWrapper } from '@/components/Voucher/Styled'
import VoucherItems from '@/components/Voucher/VoucherItems/'
import useGlobalCompanyStore from '@/store/globalCompany'

const PERMISSION_IS_ALLOWED_QUERY = gql(`
  query PermissionIsAllowed($resource: String, $permission: String) {
    permissionIsAllowed(resource: $resource, permission: $permission)
  }
`)

const VoucherView = (props: any) => {
  const {
    form,
    handleUpdate,
    loadingShift,
    createPayment,
    handleShiftUp,
    handleShiftDown,
    selectedVoucher,
    onRemoveDocument,
    onRemoveVoucherItem,
    onSelectSingleDocument,
    onSelectSingleCostItem,
    handleUpdateVoucherItem
  } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const isNewVoucher = useMemo(() => selectedVoucher?.status === 'NEW', [selectedVoucher])

  const voucherItemTitle = useMemo(
    () => (selectedVoucher?.transactionType === 'ACCPAY' ? 'Payment' : 'Invoice'),
    [selectedVoucher]
  )

  const { loading } = useQuery(PERMISSION_IS_ALLOWED_QUERY, {
    variables: {
      resource: 'booking',
      permission: 'view'
    },
    fetchPolicy: 'cache-first'
  })

  if (loading) return <Spin />

  return (
    <ViewWrapper>
      <InputFields
        form={form}
        handleUpdate={handleUpdate}
        selectedVoucher={selectedVoucher}
        selectedGlobalCompany={selectedGlobalCompany}
      />

      <Divider>Booking(s) Info</Divider>

      <ViewSection>
        <Col span={24}>
          <VoucherBookings
            selectedVoucher={selectedVoucher}
            onRemoveDocument={onRemoveDocument}
            onSelectSingleDocument={onSelectSingleDocument}
            onSelectSingleCostItem={onSelectSingleCostItem}
          />
        </Col>
      </ViewSection>

      <Divider>{voucherItemTitle} Items</Divider>

      <ViewSection>
        <Col span={24}>
          <VoucherItems
            loadingShift={loadingShift}
            handleShiftUp={handleShiftUp}
            handleShiftDown={handleShiftDown}
            onRemoveVoucherItem={onRemoveVoucherItem}
            handleUpdateVoucherItem={handleUpdateVoucherItem}
          />
        </Col>
      </ViewSection>

      <ViewSection top="30px" bottom="30px">
        <Col span={24}>
          {isNewVoucher ? (
            <>
              <SectionTitle borderless>Overrides</SectionTitle>
              <Overrides />
            </>
          ) : (
            <Payments createPayment={createPayment} />
          )}
        </Col>
      </ViewSection>
    </ViewWrapper>
  )
}

export default VoucherView
