import { Form, Select } from 'antd'

import { statuses } from 'App/utils/voucher'

const FormItem = Form.Item
const Option = Select.Option

const VoucherStatusFilterFormItem = (props: any) => {
  const { form, value } = props
  const { getFieldDecorator } = form

  return (
    <FormItem label="Voucher status">
      {getFieldDecorator('status', {
        initialValue: value
      })(
        <Select mode="multiple" placeholder="Please select">
          {statuses.map(p => (
            <Option key={p} value={p}>
              {p}
            </Option>
          ))}
        </Select>
      )}
    </FormItem>
  )
}

export default VoucherStatusFilterFormItem
