import { Component } from 'react'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import indexOf from 'lodash/indexOf'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'

import Container, { ItemsWrapper } from '@/components/Workflow/Request/Container'
import RequestItem from '@/components/Workflow/Request/RequestItem'
import Requisition from '@/components/Workflow/Requisition'
import { CategoryTitle, NoDocument } from '@/components/Workflow/Styled'

class Request extends Component {
  render() {
    const { booking, section, requisitions } = this.props

    const filteredRequests = filter(
      booking.requests,
      request => findIndex(requisitions, requisition => requisition.type === request.type) !== -1
    )

    const sortedRequests = sortBy(filteredRequests, request => {
      return indexOf(requisitions, r => request.type === r.type)
    })

    const requests = sortedRequests.map((req, index) => ({
      key: index,
      uuid: req.uuid,
      type: req.type,
      status: req.status,
      createdAt: req.createdAt
    }))

    return (
      <Container>
        <CategoryTitle>
          Request / Notify
          <Requisition booking={booking} requisitions={requisitions} section={section} />
        </CategoryTitle>
        <ItemsWrapper>
          {requests.length === 0 ? <NoDocument>No Request</NoDocument> : null}
          {requests.map((request, index) => {
            // eslint-disable-next-line react/no-array-index-key
            return <RequestItem key={index} request={request} />
          })}
        </ItemsWrapper>
      </Container>
    )
  }
}

Request.propTypes = {
  section: PropTypes.object,
  booking: PropTypes.object,
  requisitions: PropTypes.array
}

export default Request
