import type { SelectQueryProps } from '@/components/Shared/types'
import type { SelectProps } from 'antd/lib/select'

import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import Select from 'antd/lib/select'

interface SelectWithQueryProps extends SelectQueryProps, SelectProps {
  options?: string[] | { value: string; label: string }[]
}

const SelectWithQuery = (props: SelectWithQueryProps) => {
  const { query, variables, getData, valueFormatter, labelFormatter, ...rest } = props
  const { data, loading } = useQuery(query, {
    variables: variables,
    skip: !variables
  })

  const options = useMemo(() => {
    if (props.options && props.options.length && !query) {
      return props.options
    }

    return getData(data) || []
  }, [data, props.options])

  const fallBackMapper = (item, key) =>
    typeof item === 'string' ? item : item[key] || JSON.stringify(item)
  const valueMapper = item =>
    valueFormatter ? valueFormatter(item) : fallBackMapper(item, 'value')
  const labelMapper = item =>
    labelFormatter ? labelFormatter(item) : fallBackMapper(item, 'label')

  return (
    <Select allowClear showSearch placeholder="Select a value..." {...rest} loading={loading}>
      {options.map(item => {
        const l = labelMapper(item)
        const v = valueMapper(item)

        return (
          <Select.Option key={l || v} value={v}>
            {l}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export default SelectWithQuery
