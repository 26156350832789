import type { ApolloClient, DocumentNode, FetchPolicy, OperationVariables } from '@apollo/client'

import { useQuery } from '@apollo/client'
import { Skeleton } from 'antd'

import NoAccess from '@/components/Shared/NoAccess'
import Columns from '@/components/TableView/Columns'
import { NoPermissionWrapper, TableWrapper } from '@/components/TableView/Styled'
import { hasPermissionError } from '@/utils/u'

type TableViewProps = {
  queryProps: {
    query: DocumentNode
    variables: OperationVariables
    fetchPolicy?: FetchPolicy
  }
  dataPropKey: string
  updatePageSize: (size: number) => void
  renderColumns: any
  useRows?: boolean
  usePagination?: boolean
  selfGenerateKey?: boolean
  client?: ApolloClient<object>
  currentSize?: number
  searchKeyword?: string
}

const TableView = (props: TableViewProps) => {
  const {
    queryProps,
    dataPropKey,
    updatePageSize,
    renderColumns,
    useRows = true,
    usePagination = true,
    selfGenerateKey,
    client
  } = props

  const { data, loading, error } = useQuery(queryProps.query, {
    variables: { ...queryProps.variables },
    fetchPolicy: queryProps.fetchPolicy || 'cache-and-network',
    client
  })

  if (error) {
    if (hasPermissionError(error)) return <NoAccess />
    return <NoPermissionWrapper>{error.message}</NoPermissionWrapper>
  }

  const dataItems = (useRows ? data?.[dataPropKey]?.rows : data?.[dataPropKey]) || []
  const pagination = data?.[dataPropKey]?.pageInfo

  return (
    <TableWrapper>
      <Skeleton active loading={loading} paragraph={{ rows: 5 }}>
        <Columns
          items={dataItems}
          renderColumns={renderColumns}
          usePagination={usePagination}
          selfGenerateKey={selfGenerateKey}
          pagination={{
            total: pagination?.count,
            limit: pagination?.limit,
            offset: pagination?.offset,
            onSizeChange: size => updatePageSize(size)
          }}
        />
      </Skeleton>
    </TableWrapper>
  )
}

export default TableView
