import { gql, useQuery } from '@apollo/client'

import notify from '@/utils/notify'

type UsePermissionIsAllowedProps = {
  resource: string
  permission: string
}

const PERMISSION_IS_ALLOWED_QUERY = gql`
  query permissionIsAllowedHook1($resource: String, $permission: String) {
    permissionIsAllowed(resource: $resource, permission: $permission)
  }
`

const usePermissionIsAllowed = ({ resource, permission }: UsePermissionIsAllowedProps) => {
  const { loading, data, error, refetch } = useQuery(PERMISSION_IS_ALLOWED_QUERY, {
    variables: { resource, permission },
    fetchPolicy: 'cache-first'
  })

  if (error) {
    notify(
      `Access denied for resource='${resource}' permission='${permission}'. Please request if required.`,
      'warning'
    )
  }

  return {
    error,
    loading,
    refetch,
    hasPermission: !!data?.permissionIsAllowed
  }
}

export default usePermissionIsAllowed
