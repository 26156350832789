import type { Moment } from 'moment'
import type { MtdColsDataProps, MtdTableHeaders } from './types'

import moment from 'moment'

import {
  formatToDashboardNumber,
  sumByDateStr
} from 'App/components/Transport/Dashboard/helpers/general'
import { ViewOptions } from '../../Planning/TripSummary/TripSummaryTableWrapper'

const tableRowHeaders: MtdTableHeaders = {
  mtdDel: 'MTD Deliveries',
  totalDel: 'Total Deliveries',
  teu: 'TEU',
  mtdCost: 'MTD Est. Cost',
  totalCost: 'Est. Cost',
  mtdSell: 'MTD Est. Cost',
  totalSell: 'Est. Sell',
  mtdRevenue: 'MTD Revenue',
  totalRevenue: 'Total Revenue'
}

export const monthlyTimeFormat: string = 'YYYY/MM'

export const getLastYrMtdDateRange = (queryInput: any = {}) => {
  return {
    startDate: moment(queryInput?.endDate || new Date())
      .subtract(1, 'year')
      .startOf('month')
      .toDate(),
    endDate: moment(queryInput?.endDate || new Date())
      .subtract(1, 'year')
      .endOf('month')
      .toDate()
  }
}

export const getMtdColsData = ({
  reportJobSummaries = [],
  lastYrData = [],
  queryInput = {},
  selectedGlobalCompany = {}
}: MtdColsDataProps) => {
  const startDate: Moment = moment(queryInput.startDate)
  const endDate: Moment = moment(queryInput.endDate)
  const sumByMonthRes: any = sumByDateStr(
    reportJobSummaries,
    startDate,
    endDate,
    ViewOptions.MONTHLY
  )

  const { startDate: lastYrStart, endDate: lastYrEnd } = getLastYrMtdDateRange(queryInput)
  const lastYrSum: any = sumByDateStr(
    lastYrData,
    moment(lastYrStart),
    moment(lastYrEnd),
    ViewOptions.MONTHLY
  )

  const tableColumns = [
    {
      Header: '',
      accessor: 'rowName',
      style: { textAlign: 'left', paddingLeft: '10px', minWidth: '100px' }
    }
  ]

  let tableData = [
    {
      key: 'mtdDel',
      rowName: 'MTD Deliveries'
    },
    {
      key: 'totalDel',
      rowName: 'Total Deliveries'
    },
    {
      key: 'teu',
      rowName: 'TEU'
    },
    {
      key: 'mtdCost',
      rowName: 'MTD Est. Cost'
    },
    {
      key: 'totalCost',
      rowName: 'Est. Cost'
    },
    {
      key: 'mtdSell',
      rowName: 'MTD Est. Sell'
    },
    {
      key: 'totalSell',
      rowName: 'Est. Sell'
    },
    {
      key: 'mtdRevenue',
      rowName: 'MTD Revenue'
    },
    {
      key: 'totalRevenue',
      rowName: 'Total Revenue'
    }
  ]

  const lastYrMonthStr: string = moment(lastYrStart).format(monthlyTimeFormat)
  const addLastYrCol: boolean = startDate.isAfter(lastYrStart)

  if (addLastYrCol) {
    tableColumns.push({
      Header: lastYrMonthStr,
      accessor: lastYrMonthStr,
      style: { textAlign: 'right', paddingLeft: '10px', minWidth: '100px' }
    })

    tableData = formatToDashboardNumber({
      tableRowHeaders,
      tableData,
      groupedReportJobSum: lastYrSum,
      dateStr: lastYrMonthStr,
      selectedGlobalCompany
    })
  }

  for (let d = startDate; d <= endDate; d = moment(d).add(1, 'months')) {
    const monthString: string = d.format(monthlyTimeFormat)
    tableColumns.push({
      Header: monthString,
      accessor: monthString,
      style: { textAlign: 'right', paddingLeft: '10px', minWidth: '100px' }
    })

    tableData = formatToDashboardNumber({
      tableRowHeaders,
      tableData,
      groupedReportJobSum: sumByMonthRes,
      dateStr: monthString,
      selectedGlobalCompany
    })
  }

  return { tableColumns, tableData }
}
