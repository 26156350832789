import { useQuery } from '@apollo/client'
import { Timeline } from 'antd-v5'
import find from 'lodash/find'

// import uniq from 'lodash/uniq'

// import RenderJobSchedules from '@/components/Booking/General/RenderSchedule'
import { ScheduleWrapper } from '@/components/Booking/General/Styled'
import Chronology from '@/components/Workflow/Chronology'
// import { JOBS_FROM_BOOKING_SLIM_QUERY } from '@/containers/booking/schema/basicBookingQuerySlim'
import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from '@/containers/booking/schema/bookingForWorkflowsSlim'

// import useJobTypes from '@/hooks/useJobTypes'

const renderSchedules = booking => {
  const { chronologies, processFlow } = booking
  const bookingSchedules = processFlow.reduce((acc, item) => {
    const sectionTypes = item.sections.reduce((sectionAcc, section) => {
      if (section.chronologies) {
        const chronologiesTypes = section.chronologies.map(chrono => chrono.type)
        return [...sectionAcc, ...chronologiesTypes]
      }
      return sectionAcc
    }, [])
    return [...acc, ...sectionTypes]
  }, [])

  return bookingSchedules?.map(schedule => {
    const findChrono = find(chronologies, c => c.type === schedule)
    const chrono = findChrono
      ? { data: findChrono, type: findChrono.type, title: schedule }
      : { data: {}, type: schedule, title: schedule }

    return { children: <Chronology chrono={chrono} booking={booking} /> }
  })
}

const Schedules = ({ /* refetchBooking, */ booking }) => {
  // const { requestJobTypes, jobTypes } = useJobTypes()

  const { data: bookingData } = useQuery(BOOKING_FOR_WORKFLOWS_SLIM_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { uuid: booking.uuid }
  })

  // const { data } = useQuery(JOBS_FROM_BOOKING_SLIM_QUERY, {
  //   errorPolicy: 'all',
  //   fetchPolicy: 'cache-and-network',
  //   skip: !booking?.uuid,
  //   variables: { uuid: booking?.uuid },
  //   onCompleted: () => {
  //     const jobTypesKeys = uniq(data?.booking?.jobs?.map(job => job.type)).filter(Boolean)
  //     // @ts-ignore
  //     if (jobTypesKeys.length) requestJobTypes({ codes: jobTypesKeys })
  //   }
  // })

  // const jobs = bookingData?.booking?.jobs?.map(i => {
  //   return {
  //     job: i,
  //     trip: i.trips,
  //     jobNo: i.jobNo,
  //     details: i.details,
  //     remarks: data?.booking?.remarks
  //   }
  // })

  if (!bookingData?.booking) {
    return null
  }

  return (
    <ScheduleWrapper>
      <Timeline items={renderSchedules(bookingData?.booking)} />
      {/* <Timeline>
        <RenderJobSchedules jobs={jobs} refetch={refetchBooking} jobTypes={jobTypes} />
      </Timeline> */}
    </ScheduleWrapper>
  )
}

export default Schedules
