import { useDispatch, useSelector } from 'react-redux'

import ViewerContent from '@/components/DocumentViewer/Content'
import ViewerHeader from '@/components/DocumentViewer/Header'
import { ModalViewer } from '@/components/DocumentViewer/Styled'
import { toggleDocumentViewer } from '@/states/reducers/bookingDocument'

type DocumentViewerProps = {
  refreshBooking: () => void
  bookingQuery: any
}

export default function DocumentViewer(props: DocumentViewerProps) {
  const { bookingQuery, refreshBooking } = props

  const dispatch = useDispatch()
  const showDocumentViewer = useSelector(state => state.bookingDocument.showDocumentViewer)
  const selectedBookingDocument = useSelector(
    state => state.bookingDocument.selectedBookingDocument
  )

  const toggleDocumentViewerActions = () => dispatch(toggleDocumentViewer())
  if (!showDocumentViewer) return null

  return (
    <ModalViewer
      title={null}
      footer={null}
      closable={false}
      visible={showDocumentViewer}
      onCancel={toggleDocumentViewerActions}
    >
      <ViewerHeader
        onBack={toggleDocumentViewerActions}
        bookingDocument={selectedBookingDocument}
        bookingQuery={bookingQuery}
        refreshBooking={refreshBooking}
      />
      <ViewerContent
        bookingQuery={bookingQuery}
        bookingDocument={selectedBookingDocument}
        toggleDocumentViewer={toggleDocumentViewerActions}
        refreshBooking={refreshBooking}
      />
    </ModalViewer>
  )
}
