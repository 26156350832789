export const getDataGql = `
  query taxesExport1 ($limit: Int, $offset: Int, $showAll: Boolean) {
    taxes (limit: $limit, offset: $offset, showAll: $showAll) {
      rows {
        code
        name
        percentage
        isActive
        uuid
      }
    }
  }
`

export const createEntityGql = `
  mutation createTaxImport1 ($input: CreateTaxInput!) {
    createTax (input: $input) {
      uuid
    }
  }
`

export const updateEntityGql = `
  mutation updateTaxImport1 ($input: UpdateTaxInput!) {
    updateTax (input: $input) {
      uuid
    }
  }
`
