import type { SelectProps } from 'antd/es/select'
import { gql } from 'App/types'
import { DocumentGeneratorTemplateFieldQuery } from '@/types/graphql'

import { forwardRef, memo, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'

import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'
import { camelCaseToSpace } from 'App/utils/u'

interface Props extends SelectProps {
  camelCaseToNormal?: boolean
  enumName: string
  style?: any
  value?: any
  onChange?: any
  allowClear?: boolean
  multiple?: boolean
  id?: string
}

export const enumQueryString = `
  query enum1 ($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
      }
    }
  }
`

export const ENUM_QUERY = gql(`
  query enumForSelector($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
      }
    }
  }
`)

const EnumSelector = forwardRef((props: Props, ref: any) => {
  const {
    id,
    value,

    enumName,
    onChange,
    multiple,
    camelCaseToNormal,
    allowClear = false
  } = props

  const { loading, error, data } = useQuery(ENUM_QUERY, {
    variables: { name: enumName },
    fetchPolicy: 'cache-and-network'
  })

  const customValues = useMemo(() => DocumentGeneratorTemplateFieldQuery.CustomValues, [])

  const enums = useMemo(
    () =>
      data?.__type?.enumValues
        ?.map((t: any) => {
          if (camelCaseToNormal) {
            return {
              value: t.name,
              label: camelCaseToSpace(t.name)
            }
          }
          return {
            value: t.name,
            label: t.name
          }
        })
        .sort((a: any, b: any) =>
          a.value === customValues ? -1 : b.value === customValues ? 1 : 0
        ),
    [camelCaseToNormal, data?.__type?.enumValues, customValues]
  )

  if (error) {
    logger.error(`EnumSelector ${enumName} error`, error)
    responseHandler(error, 'error')
  }

  return (
    <Select
      {...props}
      id={id}
      ref={ref}
      value={value}
      loading={loading}
      onChange={onChange}
      allowClear={allowClear}
      mode={multiple ? 'multiple' : props.mode || undefined}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    >
      {enums?.map((t: any) => (
        <Select.Option key={t.value} value={t.value}>
          {t.label}
        </Select.Option>
      ))}
    </Select>
  )
})

export default memo(EnumSelector)
