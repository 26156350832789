export const getDataGql = `
  query jobOptionsExport1 ($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    jobOptions (limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
  }
`

export const createEntityGql = `
  mutation createJobOptionExport1 ($input: CreateJobOptionInput!) {
    createJobOption (input: $input) {
      code
      uuid
      name
      description
      sorting
      status
    }
  }
`

export const updateEntityGql = `
  mutation updateJobOptionExport1 ($input: UpdateJobOptionInput!) {
    updateJobOption (input: $input) {
      code
      uuid
      name
      description
      sorting
      status
    }
  }
`
