import { gql } from '@/types'

export const BOOKING_TYPES_QUERY = gql(`
  query bookingTypesMain($limit: Int, $offset: Int, $q: String, $statuses: [BookingTypeStatus]) {
    bookingTypes(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        sorting
        status
        allowedJobTypes
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const VIEW_BOOKING_TYPE_GQL = gql(`
  query bookingType1($uuid: UUID) {
    bookingType(uuid: $uuid) {
      code
      name
      description
      flowType
      sorting
      status
      allowedJobTypes
      billing {
        desc
        chargeItemUuid
        billManner

        sellRate
        sellRateId
        costRate
        costRateId

        incentiveTreeId
        incentiviseBy
      }
      companies {
        uuid
        name
      }
      dynamicFields {
        key
        type
        query
        companyTypes
        control
        searchable
        multiple
        enumName
        customValues
        isMultiline
        isBlockDuplicate
        isCapitalize
        isOperationsOnly
        isHideFromNewBooking
      }
    }
  }
`)

export const CREATE_BOOKING_TYPE_GQL = gql(`
  mutation createBookingType($input: UpdateBookingTypeInput!) {
    createBookingType(input: $input) {
      uuid
    }
  }
`)

export const UPDATE_BOOKING_TYPE_GQL = gql(`
  mutation updateBookingType($input: UpdateBookingTypeInput!) {
    updateBookingType(input: $input) {
      code
      name
      description
      flowType
      sorting
      status
      allowedJobTypes
      companies {
        uuid
        name
      }
      billing {
        desc
        chargeItemUuid
        billManner

        sellRate
        sellRateId
        costRate
        costRateId

        incentiveTreeId
        incentiviseBy
      }
      dynamicFields {
        key
        type
        query
        control
        searchable
        multiple
        enumName
        customValues
        isMultiline
        isCapitalize
        isOperationsOnly
        isHideFromNewBooking
      }
    }
  }
`)

export const DELETE_BOOKING_TYPE_GQL = gql(`
  mutation deleteBookingType1($uuid: UUID!) {
    deleteBookingType(uuid: $uuid) {
      success
    }
  }
`)

export const UNDELETE_BOOKING_TYPE_GQL = gql(`
  mutation undeleteBookingType1($uuid: UUID!) {
    undeleteBookingType(uuid: $uuid) {
      success
    }
  }
`)
