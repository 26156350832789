import type { PropsWithChildren } from 'react'

import styled from 'styled-components'

export const ColWrapper = styled.div<PropsWithChildren<{ width?: string; full?: boolean }>>`
  width: ${props => (props.full ? '100%' : props => (props.width ? props.width : '500px'))};
`

export const Divider = styled.div`
  font-size: 17.222px;
  font-weight: bold;
  color: #111;
  padding: 10px 0;
  margin: 60px 0 20px;
  border-bottom: 1px solid rgb(230, 230, 230);
`
