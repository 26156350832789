import { Badge, Typography } from 'antd-v5'

interface BadgeProps {
  count: number
  text?: string
}

const CustomBadge = (props: BadgeProps) => {
  const { count, text } = props
  return (
    <div className="flex items-center justify-start">
      <Badge color="rgb(185, 185, 185)" overflowCount={50000} count={count} showZero />
      <Typography.Text className="ml-1">{text}</Typography.Text>
    </div>
  )
}
export default CustomBadge
