import includes from 'lodash/includes'

import PermissionCheckBox from '@/components/Manage/Permissions/TableView/PermissionCheckBox'
import { NotAvailable, PermissionItem } from '@/components/Manage/Permissions/TableView/Styled'

export type PermissionProps = {
  selectedRole?: string
  resourceType: string
  resourceName: string
  permissions: string[]
  allowedPermissions?: string[]
}

const Permission = (props: PermissionProps) => {
  const { selectedRole, resourceType, permissions, allowedPermissions } = props

  const isAllowed = includes(allowedPermissions, resourceType)

  if (!includes(permissions, resourceType)) {
    return (
      <PermissionItem>
        <NotAvailable>N/A</NotAvailable>
      </PermissionItem>
    )
  }

  return (
    <PermissionItem>
      <PermissionCheckBox {...props} isAllowed={isAllowed} selectedRole={selectedRole} />
    </PermissionItem>
  )
}

export default Permission
