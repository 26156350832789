import type { Driver, Leg, Vehicle } from 'App/types/graphql'

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface UnplannedState {
  isLegModalVisible: boolean
  setIsLegModalVisible: (boolean) => void

  selectedDriver: Driver | undefined
  setSelectedDriver: (driver: Driver) => void

  selectedVehicle: Vehicle | undefined
  setSelectedVehicle: (vehicle: Vehicle) => void

  selectedUnplannedLegs: Leg[]
  setSelectedUnplannedLegs: (legs: Leg[]) => void
}

export const useUnplannedStore = create<UnplannedState>()(
  devtools(
    set => ({
      isLegModalVisible: false,
      setIsLegModalVisible: boolean => set({ isLegModalVisible: boolean }),

      selectedDriver: undefined,
      setSelectedDriver: (driver: Driver) => set({ selectedDriver: driver }),

      selectedVehicle: undefined,
      setSelectedVehicle: (vehicle: Vehicle) => set({ selectedVehicle: vehicle }),

      selectedUnplannedLegs: [],
      setSelectedUnplannedLegs: (legs: Leg[]) => set({ selectedUnplannedLegs: legs })
    }),
    { name: 'Unplanned Store' }
  )
)
