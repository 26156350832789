import { PaymentStatus, VoucherStatus } from '@/types/graphql'

import { Col, Table } from 'antd'
import sumBy from 'lodash/sumBy'
import numeral from 'numeral'

import FormattedCurrency from '@/components/Formatted/Currency'
import CreatePayment from '@/components/Voucher/Payments/Create'
import { NoItemsFound, SectionTitle, Summary, TableWrapper } from '@/components/Voucher/Styled'

const { LabelItem, ValueItem, SummaryItem, SummaryLabels, SummaryValues, SummaryWrapper } = Summary

export default function PaymentsTableView(props) {
  const { columns, dataSource, selectedVoucher, voucherPayments, createPayment } = props

  const totalPaid = sumBy(
    voucherPayments,
    vp => vp.payment?.status !== PaymentStatus.Voided && numeral(vp.amount).value()
  )

  const summaries = [
    {
      label: 'Total Paid',
      value: totalPaid,
      state: 'normal'
    },
    {
      label: 'Balance',
      value: selectedVoucher?.balance,
      state: 'highlight'
    }
  ]

  return (
    <>
      <SectionTitle borderless>
        Payments
        {selectedVoucher?.status !== VoucherStatus.Deleted && (
          <CreatePayment createPayment={createPayment} />
        )}
      </SectionTitle>

      <TableWrapper>
        {!voucherPayments.length ? (
          <NoItemsFound>No payments available.</NoItemsFound>
        ) : (
          <Table
            columns={columns}
            pagination={false}
            dataSource={dataSource}
            locale={{ emptyText: 'No items selected.' }}
          />
        )}
      </TableWrapper>

      <SummaryWrapper>
        <Col span={18} />
        <Col span={6}>
          {summaries.map(summary => (
            <SummaryItem key={summary.value} state={summary.state}>
              <SummaryLabels span={12}>
                <LabelItem>{summary.label} : </LabelItem>
              </SummaryLabels>
              <SummaryValues span={12}>
                <ValueItem>
                  <FormattedCurrency
                    value={summary.value}
                    currency={selectedVoucher?.currency?.code}
                  />
                </ValueItem>
              </SummaryValues>
            </SummaryItem>
          ))}
        </Col>
      </SummaryWrapper>
    </>
  )
}
