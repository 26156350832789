import { PureComponent } from 'react'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { Skeleton } from 'antd'

import { TableViewWrapper } from '../../Styled'
import TableViewData from './Data'

class TableView extends PureComponent {
  render() {
    const { filters, updateFilters, currentSize, updatePageSize, searchKeyword } = this.props

    return (
      <TableViewWrapper>
        <Query
          query={companiesQuery}
          variables={{ query: searchKeyword, offset: currentSize * 20, limit: 20, ...filters }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data }) => {
            if (error) {
              return null
            }

            const companies = data?.companies?.rows || []
            const pagination = data?.companies?.pageInfo

            return (
              <Skeleton active loading={loading} paragraph={{ rows: 5 }}>
                <TableViewData
                  items={companies}
                  filters={filters}
                  updateFilters={updateFilters}
                  pagination={{
                    total: pagination?.count,
                    limit: pagination?.limit,
                    offset: pagination?.offset,
                    onSizeChange: size => updatePageSize(size)
                  }}
                />
              </Skeleton>
            )
          }}
        </Query>
      </TableViewWrapper>
    )
  }
}

const companiesQuery = gql`
  query companies10(
    $types: [CompanyType!]
    $countryAlpha3: LimitedString
    $query: String
    $limit: Int
    $offset: Int
    $statuses: [CompanyStatus!]
  ) {
    companies(
      types: $types
      countryAlpha3: $countryAlpha3
      query: $query
      limit: $limit
      offset: $offset
      statuses: $statuses
    ) {
      rows {
        uuid
        name
        accountUuid
        ownerUuid
        code
        status
        types
        debtorCode
        creditorCode
        country {
          name
          alpha3
        }
      }

      pageInfo {
        limit
        offset
        count
      }
    }
  }
`

export default TableView
