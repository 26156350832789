import type { NormalizedCacheObject } from '@apollo/client'

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { debounce } from 'lodash'

import config from '@/config'
import useAuthStore from '@/store/auth'
import useGlobalCompanyStore from '@/store/globalCompany'
import auth from '@/utils/auth'

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

const debouncedRefreshToken = debounce(() => useAuthStore.getState().triggerRefreshToken(), 3000, {
  leading: true,
  trailing: false
})

function create(initialState: NormalizedCacheObject) {
  const httpLink = new HttpLink({
    uri: config.gateway.graphqlUrl,
    credentials: 'same-origin'
  })

  const middlewareLink = new ApolloLink((operation, forward) => {
    const currentHeaders = operation.getContext()?.headers || {}

    if (auth.jwtIsExpiring()) {
      debouncedRefreshToken()
    }

    const token = auth.getToken(currentHeaders)
    const newHeaders = {
      authorization: token ? `JWT ${token}` : null
    }

    let globCompany = useGlobalCompanyStore.getState().selectedGlobalCompany

    if (!globCompany.uuid) {
      const profile = auth.getProfile(currentHeaders)

      if (profile?.baseCompanyUuids?.length) {
        globCompany = { uuid: profile.baseCompanyUuids[0] }
      } else {
        console.error(
          'Seems to be something wrong loading the profiles from you header.',
          currentHeaders,
          profile
        )
      }
    }

    newHeaders['base-company-uuid'] = globCompany?.uuid
    operation.setContext({ headers: newHeaders })

    return forward(operation)
  })

  const errorLink = onError(({ graphQLErrors }) => auth.handleLoggedOutError({ graphQLErrors }))

  const link = ApolloLink.from([middlewareLink, errorLink, httpLink])

  return new ApolloClient({
    connectToDevTools: true,
    ssrMode: false,
    link,
    cache: new InMemoryCache({
      possibleTypes: {
        events: ['BookingEvent', 'ContainerEvent', 'MessageEvent']
      },
      typePolicies: {
        Booking: {
          keyFields: obj => {
            const f: string[] = []

            if (obj.uuid) f.push('uuid')
            if (obj.no) f.push('no')

            return f
          }
        }
      }
    }).restore(initialState)
  })
}

export default function initApollo(initialState = {}) {
  if (!apolloClient) apolloClient = create(initialState)

  return apolloClient
}
