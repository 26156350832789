import { useState } from 'react'
import { Icon } from 'antd'

import useDownloadEffect from 'App/hooks//useDownloadEffect'
import useCheckingEffect from 'App/hooks/useCheckingEffect'
import useQueueEffect from 'App/hooks/useQueueEffect'
import useGlobalCompanyStore from 'App/store/globalCompany'
import respHandler from 'App/utils/responseHandler'
import { StyledButton } from '../../../ButtonWrapper'

const useAsyncPdfDownload = (consolVoucher, type, selectedGlobalCompany) => {
  const [state, setState] = useState({
    status: 'none', // [none, generating, checking, downloading, downloaded]
    pdfUuid: null
  })

  useQueueEffect(state, setState, consolVoucher, type, selectedGlobalCompany)
  useCheckingEffect(state, setState)
  useDownloadEffect(state, setState, consolVoucher)

  const trigger = () => {
    respHandler('Downloading PDF, hang on...', 'load')
    setState({ status: 'queueing', pdfUuid: null })
  }

  return [state, trigger]
}

const ConsolVoucherPdfAsyncDownloadAction = ({ consolVoucher, type }) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const [state, trigger] = useAsyncPdfDownload(consolVoucher, type, selectedGlobalCompany)

  return (
    <span>
      <StyledButton onClick={trigger}>
        <Icon
          type={
            state.status === 'generating' || state.status === 'checking' ? 'loading' : 'download'
          }
        />
      </StyledButton>
    </span>
  )
}

export default ConsolVoucherPdfAsyncDownloadAction
