import type { DynamicField } from '@/types/graphql'
import { DynamicFieldQuery, DynamicFieldType } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { PaperClipOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Descriptions, Row } from 'antd'
import { Col, Form, Layout, Typography } from 'antd-v5'
import styled from 'styled-components'

import { VIEW_BOOKING_TYPE_GQL } from '@/components/Booking/General'
import { RenderFields } from '@/components/Booking/General/FieldDisplayHelper'
import JobsAndTrips from '@/components/Booking/JobsAndTrips'
import { collectFiles } from '@/components/Booking/NewBookingForm/SubmitAction/helper'

const FilePreviewContainer = styled.div`
  padding: 12px;
  margin-bottom: px;
`

const FileList = styled.div`
  display: flex;
  flex-direction: column;
`

const FileItem = styled.div<{ key: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 0;
`

const FileLink = styled.a`
  color: rgb(158, 158, 158);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const FileSection = ({ files }: { files: any[] }) => {
  if (!files.length) return null

  const formatKey = (key: string) => {
    return key
      .split(/(?=[A-Z])/)
      .join(' ')
      .replace(/^\w/, c => c.toUpperCase())
  }

  const openFileInNewWindow = (fileUrl: string) => {
    window.open(fileUrl, '_blank')
  }

  return (
    <FilePreviewContainer>
      <Typography.Title level={5}>Uploaded Documents</Typography.Title>
      <FileList>
        {files.map((file, index) => (
          // @ts-ignore
          <FileItem key={`${file.key}-${index}`}>
            <PaperClipOutlined />
            <Typography.Text strong>{formatKey(file.key)}:</Typography.Text>
            {file.files.map((f: any, i: number) => (
              // @ts-ignore
              <FileLink
                key={i}
                onClick={() => openFileInNewWindow(f.preview || URL.createObjectURL(f))}
              >
                {f.name || `File ${i + 1}`}
              </FileLink>
            ))}
          </FileItem>
        ))}
      </FileList>
    </FilePreviewContainer>
  )
}

const ConfirmationSubForm = ({ form }: any) => {
  const { getFieldValue, getFieldsValue } = form
  const [booking, setBooking] = useState<any>({})
  const [files, setFiles] = useState<any>([])
  const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([])

  // Watch all form values
  const formValues = Form.useWatch([], form)

  useEffect(() => {
    const values = getFieldsValue()
    const bk = {
      ...values,
      details: values.details || {}
    }
    setBooking(bk)
    setFiles(collectFiles(bk))
  }, [formValues])

  useQuery(VIEW_BOOKING_TYPE_GQL, {
    fetchPolicy: 'cache-first',
    variables: { code: getFieldValue('type') },
    onCompleted: data => {
      const compulsoryFields = [
        {
          key: 'billTo',
          query: DynamicFieldQuery.Company
        }
      ]
      setDynamicFields(
        compulsoryFields.concat(
          // @ts-ignore
          data.bookingType.dynamicFields.filter(
            (field: DynamicField) => field.type !== DynamicFieldType.Document
          )
        )
      )
    }
  })

  return (
    <>
      <Row style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <Layout hasSider style={{ background: 'transparent' }}>
          <Layout.Content>
            <Row style={{ marginTop: 2 }}>
              {[0, 1, 2].map(colIndex => (
                <Col key={colIndex} xs={24} sm={12} lg={8}>
                  {dynamicFields
                    .slice(
                      Math.floor((colIndex * dynamicFields.length) / 3),
                      Math.floor(((colIndex + 1) * dynamicFields.length) / 3)
                    )
                    .map((field: DynamicField) => RenderFields(booking, field))}
                </Col>
              ))}
            </Row>
          </Layout.Content>
        </Layout>
      </Row>

      <FileSection files={files} />

      <Descriptions title="Job Details">{}</Descriptions>

      <JobsAndTrips booking={booking} hideFunctions={true} />
    </>
  )
}

export default ConfirmationSubForm
