import { CompanyType } from 'App/types/graphql'

import { useState } from 'react'
import { Button, Checkbox, Col } from 'antd'

import { camelCaseToSpace } from 'App/utils/u'
import { Actions, CheckboxItem, FilterWrapper } from './Styled'

const CheckboxGroup = Checkbox.Group

const TypesFilter = (props: any) => {
  const [selectedTypes, setSelectedTypes] = useState(props.filters && props.filters.types)

  const handleSelect = (checkedValues: any) => {
    setSelectedTypes(checkedValues)
  }

  const handleReset = () => {
    setSelectedTypes([])

    const updatedFilters = {
      ...props.filters,
      types: []
    }

    props.updateFilters(updatedFilters)
  }

  const handleApply = () => {
    const updatedFilters = {
      ...props.filters,
      types: selectedTypes
    }

    props.updateFilters(updatedFilters)
  }

  return (
    <FilterWrapper>
      <CheckboxGroup value={selectedTypes} onChange={handleSelect}>
        {Object.values(CompanyType).map(type => (
          /* @ts-ignore */
          <CheckboxItem key={type}>
            <Checkbox value={type}>{camelCaseToSpace(type)}</Checkbox>
          </CheckboxItem>
        ))}
      </CheckboxGroup>
      <Actions gutter={8}>
        <Col span={12}>
          <Button type="primary" onClick={handleApply}>
            Apply
          </Button>
        </Col>
        <Col span={12}>
          <Button onClick={handleReset}>Reset</Button>
        </Col>
      </Actions>
    </FilterWrapper>
  )
}

export default TypesFilter
