import React, { memo, useCallback, useMemo, useState } from 'react'
import { Button, Icon, Modal, Popconfirm, Row, Table, Typography, Upload } from 'antd'
import * as download from 'downloadjs'
import { csv2json, json2csv } from 'json-2-csv'
import { cloneDeep } from 'lodash'

import { ActionItem, Actions } from 'App/components/Manage/Styled'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'

const { Dragger } = Upload

interface Props {
  setIsDropdownMenuOpen: any
  setIsImportModalOpen: any
  isImportModalOpen: boolean
  impExpUtils: any
  selectedGlobalCompany: any
}

const ImportFormModal: React.FC<Props> = memo((props: Props) => {
  const {
    selectedGlobalCompany,
    setIsDropdownMenuOpen,
    setIsImportModalOpen,
    isImportModalOpen,
    impExpUtils
  } = props

  const { queryName, handleImportData, mapFromCsv, sampleData, remarks, tableColumns } = impExpUtils

  const [tableData, setTableData] = useState<any[]>([])
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isImportLoading, setIsImportLoading] = useState(false)
  const [importCount, setImportCount] = useState(0)
  const [importError, setImportError] = useState('')

  const csv2jsoDownload = useCallback(
    (err: any, csv: any) => {
      if (err) {
        logger.error('ImportFormModal csv2jsoDownload err', err)
        return respHandler(err, 'error')
      }

      try {
        const blob = new Blob([csv], { type: 'text/csv' })
        const fileName = `${queryName}_sample_data.csv`
        // @ts-ignore
        download(blob, fileName)
      } catch (error) {
        logger.error('ImportFormModal downloadSampleData error', error)
        return respHandler(error, 'error')
      }
    },
    [queryName]
  )

  const downloadSampleData = useCallback(() => {
    json2csv(sampleData, csv2jsoDownload)
  }, [sampleData, csv2jsoDownload])

  const csv2jsonUpload = useCallback((err: any, csv: any) => {
    if (err) {
      logger.error('ImportFormModal csv2jsonUpload err', err)
      setImportCount(0)
      setImportError('')
      setIsTableLoading(false)
      setIsImportLoading(false)
      return respHandler(err, 'error')
    }

    const clonedData = cloneDeep(csv)
    const formattedData = mapFromCsv(clonedData)

    setTableData(formattedData)
    setImportCount(0)
    setImportError('')
    setIsTableLoading(false)
    setIsImportLoading(false)
  }, [])

  const draggerProps = useMemo(
    () => ({
      accept: '.csv',
      name: 'file',
      multiple: false,
      beforeUpload: (file: any) => {
        const reader = new FileReader()

        reader.onload = (e: any) => {
          setIsTableLoading(true)

          csv2json(e.target.result, csv2jsonUpload)
        }
        reader.readAsText(file)

        // Prevent upload
        return false
      },
      onChange(info: any) {
        const { status } = info.file

        if (status === 'error') {
          respHandler(`${info.file.name} file upload failed.`, 'error')
        }

        if (status === 'uploading') {
          respHandler('Loading data...', 'load')
        }

        if (status === 'done') {
          respHandler(`${info.file.name} file uploaded successfully.`, 'success')
        }
      }
    }),
    []
  )

  const findImportError = useCallback((gqlMutationResult: any) => {
    if (gqlMutationResult?.nullError) {
      return gqlMutationResult?.nullError
    } else if (gqlMutationResult?.inputError) {
      return gqlMutationResult?.inputError
    } else if (gqlMutationResult?.message) {
      try {
        if (!gqlMutationResult?.message?.includes('errors')) {
          return gqlMutationResult?.message
        }

        const errorObj = JSON.parse(gqlMutationResult?.message)

        return errorObj?.errors?.[0]?.message
      } catch (error) {
        logger.error('ImportFormModal findImportError JSON.parse', error)
        return error
      }
    } else if (gqlMutationResult?.errors?.[0]?.extensions?.response?.body?.errors?.[0]?.message) {
      logger.error(
        'ImportFormModal findImportError gqlMutationResult.errors',
        gqlMutationResult?.errors?.[0]?.extensions?.response?.body?.errors?.[0]?.message
      )
      return gqlMutationResult?.errors?.[0]?.extensions?.response?.body?.errors?.[0]?.message
    } else if (gqlMutationResult?.errors?.[0]?.message) {
      logger.error(
        'ImportFormModal findImportError gqlMutationResult.errors',
        gqlMutationResult?.errors?.[0]?.message
      )
      return gqlMutationResult?.errors?.[0]?.message
    } else if (
      (gqlMutationResult && JSON.stringify(gqlMutationResult).includes('error')) ||
      (gqlMutationResult && JSON.stringify(gqlMutationResult).includes('Error'))
    ) {
      for (const key in gqlMutationResult) {
        if (gqlMutationResult[key]?.errors && gqlMutationResult[key]?.errors?.[0]?.message) {
          return gqlMutationResult[key]?.errors?.[0]?.message
        }
      }

      logger.error(
        'ImportFormModal findImportError unknown error',
        JSON.stringify(gqlMutationResult)
      )
      return JSON.stringify(gqlMutationResult)
    } else if (
      gqlMutationResult?.toString().includes('error') ||
      gqlMutationResult?.toString().includes('Error')
    ) {
      // where Object.keys(gqlMutationResult) = []
      logger.error('ImportFormModal findImportError unknown error', gqlMutationResult?.toString())
      return gqlMutationResult?.toString()
    } else return null
  }, [])

  const handleImportError = useCallback(
    (error: any, i: number) => {
      const errMsg = error
      tableData[i].importStatus = 'error'
      setImportError(
        ` Failed at No. ${tableData[i]?.key}: ${
          tableData[i]?.name || tableData[i]?.code || tableData[i]?.roleCode
        }. ${errMsg}`
      )
      setIsImportLoading(false)
      return respHandler(errMsg, 'error')
    },
    [tableData]
  )

  const handleModalClose = useCallback(async () => {
    await setIsImportModalOpen(false)
    await setIsDropdownMenuOpen((state: boolean) => !state)
    await setTimeout(
      setIsDropdownMenuOpen((state: boolean) => !state),
      100
    )
  }, [])

  const importAllData = useCallback(async () => {
    if (tableData?.length === 0) {
      return respHandler('There is no data to import.', 'warning')
    }

    setIsImportLoading(true)
    setImportCount(0)
    setImportError('')

    for (let i = 0; i < tableData?.length; i++) {
      try {
        const importResults = await handleImportData(tableData[i], selectedGlobalCompany)
        const importError = findImportError(importResults)
        if (importError) {
          return handleImportError(importError, i)
        }

        tableData[i].importStatus = 'done'
        setImportCount((count: number) => count + 1)
        respHandler(
          `Successfully imported No. ${tableData[i]?.key}: ${
            tableData[i]?.name ||
            tableData[i]?.code ||
            tableData[i]?.roleCode ||
            tableData[i]?.user?.email
          }.`,
          'success'
        )
      } catch (error) {
        logger.error('ImportFormModal importAllData unknown error', error)
        tableData[i].importStatus = 'error'
        setIsImportLoading(false)
        return respHandler(error, 'error')
      }
    }

    setIsImportLoading(false)
  }, [tableData, selectedGlobalCompany])

  return (
    <Modal
      width="97%"
      title="Import Data"
      visible={isImportModalOpen}
      onCancel={handleModalClose}
      footer={null}
    >
      <Row type="flex" justify="space-around" align="middle">
        <Button onClick={downloadSampleData}>
          <Icon type="download" /> Download Sample Data
        </Button>
      </Row>
      <Row type="flex" justify="space-around" align="middle" style={{ marginTop: '20px' }}>
        <div style={{ width: '100%' }}>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <Icon type="file-add" />
            </p>
            <p className="ant-upload-text">Click or drag a .csv file here to upload</p>
            <p className="ant-upload-hint" style={{ margin: '10px' }}>
              {remarks}
            </p>
          </Dragger>
        </div>
      </Row>
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        style={{ marginTop: 42, overflowX: 'auto' }}
      >
        <Table columns={tableColumns} dataSource={tableData} loading={isTableLoading} />
      </Row>

      {tableData?.length > 0 && (
        <Row type="flex" justify="space-around" align="middle" style={{ marginTop: '20px' }}>
          <span>
            Imported {importCount} out of {tableData?.length}.
            {importError && <span style={{ color: 'red' }}> {importError}</span>}
          </span>
        </Row>
      )}

      <Actions>
        <ActionItem span={24} align="right">
          <Button onClick={handleModalClose}>Close</Button>
          <Popconfirm
            onConfirm={importAllData}
            title={[
              <Row key="Popconfirm Title">
                <Typography.Paragraph>Are you sure?</Typography.Paragraph>
                <Typography.Paragraph>If you are not sure, please consult IT.</Typography.Paragraph>
              </Row>
            ]}
            okText="OK"
            okType="primary"
            cancelText="Back"
          >
            <Button type="primary" loading={isImportLoading}>
              Import All
            </Button>
          </Popconfirm>
        </ActionItem>
      </Actions>
    </Modal>
  )
})

export default ImportFormModal
