import { LinkOutlined, QrcodeOutlined, RightOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Col, message, Modal, Row, Tooltip } from 'antd-v5'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import groupBy from 'lodash/groupBy'
import startCase from 'lodash/startCase'
import uniqBy from 'lodash/uniqBy'
import QRCode from 'qrcode.react'
import { compose } from 'recompose'

import Icon from '@/components/Icons/FA'
import Chronology from '@/components/Workflow/Chronology'
import MermaidLink from '@/components/Workflow/MermaidLink'
import Progress from '@/components/Workflow/Progress'
import Request from '@/components/Workflow/Request'
import withSection from '@/components/Workflow/Section/Container'
import labels from '@/components/Workflow/Section/labels'
import styles from '@/components/Workflow/Section/Section.module.css'
import { Content, ReferenceLabel, Title, Wrapper } from '@/components/Workflow/Section/Styled'
import { CategoryTitle } from '@/components/Workflow/Styled'
import Uploaded from '@/components/Workflow/Uploaded'
import Voucher from '@/components/Workflow/Voucher'
import config from '@/config'
import Auth0 from '@/hocs/auth0'
import { IS_SERVER } from '@/utils/website'

const enhance = compose(Auth0, withSection)

const fallbackCopyTextToClipboard = text => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')

    if (successful) {
      message.success('Link copied to clipboard.', 3)
    } else {
      message.error('Unable to copy link. Please use a modern browser.', 5)
    }
  } catch (err) {
    console.error(err)
    message.error('Unable to copy link. Please use a modern browser.', 5)
  }

  document.body.removeChild(textArea)
}

const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      message.success('Link copied to clipboard.', 3)
    },
    function () {
      message.error('Unable to copy link. Please use a modern browser.', 5)
    }
  )
}

const handleCopyClick = text => e => {
  e.preventDefault()
  copyTextToClipboard(text)
}

const handleQRCodeClick = link => e => {
  e.preventDefault()
  const content = (
    <div>
      <p>Scan the QR code below to go to this booking:</p>
      <QRCode value={link} size={192} />
    </div>
  )

  Modal.info({
    title: 'QR Code',
    content,
    okText: 'Close',
    maskClosable: true
  })
}

const Section = props => {
  const renderUploadedDocs = () => {
    const { section, booking, sectionStates, uploadActions } = props

    if (!section.bookingDocuments) {
      return <div />
    }

    const voucherDocuments: any = []

    booking.vouchers?.forEach(bv => {
      if (bv.bookingDocuments) bv.bookingDocuments.forEach(bd => voucherDocuments.push(bd))
    })

    const highlightedDocuments = filter(
      sectionStates.uploadedDocs,
      ud => findIndex(voucherDocuments, (vd: { type: string }) => vd.type === ud.type) !== -1
    )

    return (
      <Uploaded
        section={section}
        booking={booking}
        uploadActions={uploadActions}
        requiredDocs={section.bookingDocuments}
        uploadedDocs={sectionStates.uploadedDocs || []}
        highlightedDocuments={uniqBy(highlightedDocuments, 'type')}
      />
    )
  }

  const renderVouchers = () => {
    const { section, sectionStates, data } = props

    if (section.vouchers === null) {
      return <div />
    }

    return (
      <Voucher
        data={data}
        section={section}
        type={section.name}
        steps={section.steps}
        viewableVouchers={sectionStates.vouchers || []}
      />
    )
  }

  const renderRequests = () => {
    const { section, booking, sectionStates, data } = props

    if (section.requests === null) {
      return <div />
    }

    return (
      <Request
        allowCreate
        data={data}
        booking={booking}
        section={section}
        type={section.name}
        steps={section.steps}
        requisitions={sectionStates.requisitions}
      />
    )
  }

  const renderChronologies = () => {
    const { sectionStates, section, booking } = props

    if (!section.chronologies) {
      return null
    }

    const bookingChronoGroups =
      sectionStates.chronologies && groupBy(sectionStates.chronologies, c => c.reference)

    return (
      <>
        <div style={{ marginBottom: 30 }}>
          <Row>
            <Col span={24}>
              <CategoryTitle>Chronologies</CategoryTitle>
            </Col>
          </Row>

          {!Object.keys(bookingChronoGroups).length && (
            <Row>
              {section.chronologies.map(sectionChrono => {
                const chrono = {
                  ...sectionChrono,
                  title: labels[sectionChrono.type] || sectionChrono.type
                }
                return (
                  <Col key={sectionChrono.type} span={6}>
                    <Chronology booking={booking} chrono={chrono} />
                  </Col>
                )
              })}
            </Row>
          )}
          {Object.keys(bookingChronoGroups).map(key => {
            return (
              <Row key={key} style={{ padding: 5, marginBottom: 5 }}>
                <Col span={24} style={{ marginBottom: 5 }}>
                  <ReferenceLabel>
                    <RightOutlined />
                    {key || <i>(empty reference)</i>}
                  </ReferenceLabel>
                </Col>

                {section.chronologies.map((sectionChrono, index) => {
                  const existingChronoData = bookingChronoGroups[key].find(
                    c => c.type === sectionChrono.type
                  ) || { reference: key }

                  const chrono = {
                    ...sectionChrono,
                    title: labels[sectionChrono.type] || sectionChrono.type,
                    data: existingChronoData
                  }

                  return (
                    <Col key={sectionChrono.type} span={(index + 1) % 5 !== 0 ? 5 : 4}>
                      <Chronology booking={booking} chrono={chrono} />
                    </Col>
                  )
                })}
              </Row>
            )
          })}
        </div>
      </>
    )
  }

  const { section, labels: sectionLabels, booking, process, extras: Extras } = props
  const mappedSection = sectionLabels[section.name]

  const iconType = mappedSection?.icon || 'bars'

  const title = mappedSection?.text || section.name

  const domain = IS_SERVER ? '' : window?.location?.origin

  const bookingLink = `${domain}/bookings/${booking.uuid}#${section.name}`

  const whatsappLink = `${config.whatsapp.apiLinkFront}${encodeURIComponent(bookingLink)}`

  const iconStyle = { color: 'gray', marginLeft: 10 }

  return (
    <Wrapper>
      <Title>
        <div className={styles.container}>
          <div className={styles.left}>
            <Icon type={iconType} style={{ marginRight: 5 }} /> {startCase(title)}
          </div>

          <div className={styles.right}>
            <MermaidLink type={booking.type} process={process.type} />

            <Tooltip title="Copy to clipboard">
              <a
                href={bookingLink}
                rel="noopener noreferrer"
                onClick={handleCopyClick(bookingLink)}
              >
                <LinkOutlined style={iconStyle} />
              </a>
            </Tooltip>

            <Tooltip title="Generate QR code">
              <a
                href={bookingLink}
                rel="noopener noreferrer"
                onClick={handleQRCodeClick(bookingLink)}
              >
                <QrcodeOutlined style={iconStyle} />
              </a>
            </Tooltip>

            <Tooltip title="Send WhatsApp message">
              <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                <WhatsAppOutlined style={iconStyle} />
              </a>
            </Tooltip>
          </div>
        </div>
      </Title>

      <Content id={section.name}>
        <Progress steps={section.steps} />
        <Row>
          <Col span={24}>
            {renderChronologies()}
            {renderUploadedDocs()}
            {renderRequests()}
            {renderVouchers()}
          </Col>
        </Row>
        {Extras && <Extras {...props} />}
      </Content>
    </Wrapper>
  )
}

export default enhance(Section)
