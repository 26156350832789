import { ExclamationCircleOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd-v5'

const getIcon = (urgency: string) => {
  switch (urgency) {
    case 'ERROR': {
      return <ExclamationCircleOutlined style={{ color: 'red' }} />
    }
    case 'WARN': {
      return <WarningOutlined style={{ color: 'orange' }} />
    }
    default:
    case 'INFO': {
      return <InfoCircleOutlined style={{ color: 'deepskyblue' }} />
    }
  }
}

const Error = ({ error }) => {
  if (!error) return null

  return (
    <Tooltip
      title={`${error.msg}. Expected ${error.expectedValue}, received ${error.recievedValue}.`}
    >
      {getIcon(error.urgency)}
    </Tooltip>
  )
}

export default Error
