import type { ReactNode } from 'react'

import { memo } from 'react'

function EmptyAnchor({ children }: { children: ReactNode }) {
  return (
    <a href="#foo" className="cursor-pointer" onClick={e => e.preventDefault()}>
      {children}
    </a>
  )
}

export default memo(EmptyAnchor)
