export default [
  {
    key: 'transactionType',
    label: 'Transaction Type'
  },
  {
    key: 'voucherType',
    label: 'Voucher Type'
  },
  {
    key: 'vendorUuid',
    label: 'Vendor'
  },
  {
    key: 'customerUuid',
    label: 'Customer'
  },
  {
    key: 'paymentType',
    label: 'Payment Type'
  },
  {
    key: 'status',
    label: 'Voucher Status'
  },
  {
    key: 'approvedCount',
    label: 'Approved Count'
  },
  {
    key: 'issueDate',
    label: 'Issue Date'
  },
  {
    key: 'accountDate',
    label: 'Account Date'
  },
  {
    key: 'dueDate',
    label: 'Aging'
  },
  {
    key: 'total',
    label: 'Total Value'
  },
  {
    key: 'paymentPaid',
    label: 'Payment Paid'
  },
  {
    key: 'bookingUuid',
    label: 'Booking No.'
  },
  {
    key: 'isCreditNote',
    label: 'Credit Note'
  },
  {
    key: 'isJournalVoucher',
    label: 'Journal Voucher'
  }
]
