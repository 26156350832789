import type { PermissionProps } from './Permission'

import { Mutation } from '@apollo/client/react/components'
import { Checkbox, Icon, notification } from 'antd'

import {
  CREATE_PERMISSION,
  DELETE_PERMISSION,
  PERMISSION_QUERY
} from '@/components/Manage/Permissions/TableView/schema'
import handleResponse from '@/utils/responseHandler'

type PermissionCheckBoxProps = PermissionProps & {
  displayName?: string
  isAllowed?: boolean
}

const PermissionCheckBox = (props: PermissionCheckBoxProps) => {
  const { displayName, isAllowed, resourceName, resourceType, selectedRole } = props

  const handleCheck = (checked, onCheck) => {
    onCheck({
      variables: {
        role: selectedRole,
        resource: resourceName,
        permission: resourceType
      }
    })
  }

  const handleCompleted = isAllowed => {
    notification.success({
      message: `Permission ${isAllowed ? 'Revoked' : 'Created'}`,
      description: `Permission ${resourceType} in ${resourceName} has been ${
        isAllowed ? 'revoked' : 'created'
      }.`
    })
  }

  const handleError = error => handleResponse(error, 'error')

  return (
    <Mutation
      onError={handleError}
      onCompleted={() => handleCompleted(isAllowed)}
      mutation={isAllowed ? DELETE_PERMISSION : CREATE_PERMISSION}
      refetchQueries={[{ query: PERMISSION_QUERY, variables: { role: selectedRole } }]}
    >
      {(onCheck, { loading }) => {
        if (loading) return <Icon type="loading" />

        return (
          <Checkbox checked={isAllowed} onChange={e => handleCheck(e.target.checked, onCheck)}>
            {displayName}
          </Checkbox>
        )
      }}
    </Mutation>
  )
}

export default PermissionCheckBox
