import { CompanyStatus } from 'App/types/graphql'

import { memo, useCallback, useMemo } from 'react'
import { Form, Input, InputNumber, Radio, Row } from 'antd'

import AddressTagsSelect from 'App/components/Select/AddressTagsSelect'
import AddressTypesSelect from 'App/components/Select/AddressTypesSelect'
import SearchLocationInput from 'App/components/Select/MapSearch'
import TransportZonesSelect from 'App/components/Select/TransportZonesSelect'
import AreaCodeSelect from 'App/components/Select/TypeToFetch/AreaCodeSelect'
import CountrySelect from 'App/components/Select/TypeToFetch/CountrySelect'
import useGlobalCompanyStore from 'App/store/globalCompany'

type YardAddressFormProps = {
  form?: any
  frontGoogleMapsUrl?: string
  setGoogleMapsURL: (url: string) => void
  style?: any
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 }
  }
}

const YardAddressForm = memo((props: YardAddressFormProps) => {
  const { form, frontGoogleMapsUrl, setGoogleMapsURL } = props
  const { getFieldDecorator } = form

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const defaultCountryAlpha3 = useMemo(
    () => selectedGlobalCompany?.country?.alpha3,
    [selectedGlobalCompany]
  )

  const getGoogleMapsData = useCallback(
    async (searchRes: any) => {
      await form.setFieldsValue({
        address_name: searchRes?.name || '',
        address_address1: searchRes?.address1 || '',
        address_address2: searchRes?.address2 || '',
        address_address3: searchRes?.address3 || '',
        address_city: searchRes?.city || '',
        address_district: searchRes?.district || '',
        address_postCode: searchRes?.postCode || '',
        address_latitude: searchRes?.latitude || '',
        address_longitude: searchRes?.longitude || '',
        address_placeId: searchRes?.placeId || '',
        address_plusCode: searchRes?.plusCode || ''
      })
      setGoogleMapsURL(`${frontGoogleMapsUrl}${searchRes?.placeId ? searchRes?.placeId : ''}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  )

  return (
    <>
      <Row style={{ marginTop: '20px', marginLeft: '42px', marginRight: '42px' }}>
        <SearchLocationInput onClickSearchResult={getGoogleMapsData} />
      </Row>

      <Form.Item label="Name" {...formItemLayout}>
        {getFieldDecorator('address_name', {
          rules: [
            {
              required: true,
              message: 'Address name is required.'
            }
          ]
        })(<Input autoComplete="off" placeholder="Enter address name..." />)}
      </Form.Item>
      <Form.Item label="Types" {...formItemLayout}>
        {getFieldDecorator('address_type', {})(<AddressTypesSelect />)}
      </Form.Item>
      <Form.Item label="Country" {...formItemLayout}>
        {getFieldDecorator('address_countryAlpha3', {
          initialValue: defaultCountryAlpha3,
          rules: [
            {
              required: true,
              message: 'Country is required.'
            }
          ]
        })(<CountrySelect useAlpha3 />)}
      </Form.Item>
      <Form.Item label="Address 1" {...formItemLayout}>
        {getFieldDecorator('address_address1', {
          rules: [
            {
              required: true,
              message: 'Address 1 is required.'
            }
          ]
        })(<Input autoComplete="off" placeholder="Enter address 1..." />)}
      </Form.Item>
      <Form.Item label="Address 2" {...formItemLayout}>
        {getFieldDecorator(
          'address_address2',
          {}
        )(<Input autoComplete="off" placeholder="Enter address 2..." />)}
      </Form.Item>
      <Form.Item label="Address 3" {...formItemLayout}>
        {getFieldDecorator(
          'address_address3',
          {}
        )(<Input autoComplete="off" placeholder="Enter address 3..." />)}
      </Form.Item>
      <Form.Item label="Address 4" {...formItemLayout}>
        {getFieldDecorator(
          'address_address4',
          {}
        )(<Input autoComplete="off" placeholder="Enter address 4..." />)}
      </Form.Item>
      <Form.Item label="City" {...formItemLayout}>
        {getFieldDecorator(
          'address_city',
          {}
        )(<Input autoComplete="off" placeholder="Enter a city..." />)}
      </Form.Item>
      <Form.Item label="District" {...formItemLayout}>
        {getFieldDecorator(
          'address_district',
          {}
        )(<Input autoComplete="off" placeholder="Enter a state..." />)}
      </Form.Item>
      <Form.Item label="Post code" {...formItemLayout}>
        {getFieldDecorator(
          'address_postCode',
          {}
        )(<Input autoComplete="off" placeholder="Enter a post code..." />)}
      </Form.Item>
      <Form.Item label="Area code" {...formItemLayout}>
        {getFieldDecorator('address_areaCode', {
          // rules: [{
          //   required: true,
          //   message: 'Area code is required.'
          // }]
        })(<AreaCodeSelect placeholder="Select an area code..." />)}
      </Form.Item>
      <Form.Item label="Zone" {...formItemLayout}>
        {getFieldDecorator('address_zone', {
          rules: [
            {
              required: true,
              message: 'Zone is required.'
            }
          ]
        })(<TransportZonesSelect />)}
      </Form.Item>
      <Form.Item label="Latitude" {...formItemLayout}>
        {getFieldDecorator('address_latitude', {
          // rules: [{
          //   required: true,
          //   message: 'Latitude is required.'
          // }]
        })(<InputNumber min={-90} max={90} step={0.1} style={{ width: '100%' }} />)}
      </Form.Item>
      <Form.Item label="Longitude" {...formItemLayout}>
        {getFieldDecorator('address_longitude', {
          // rules: [{
          //   required: true,
          //   message: 'Longitude is required.'
          // }]
        })(<InputNumber min={-180} max={180} step={0.1} style={{ width: '100%' }} />)}
      </Form.Item>
      <Form.Item label="Google Place Id" {...formItemLayout}>
        {getFieldDecorator('address_placeId', {})(<Input autoComplete="off" />)}
      </Form.Item>
      <Form.Item label="Plus Code" {...formItemLayout}>
        {getFieldDecorator('address_plusCode', {})(<Input autoComplete="off" />)}
      </Form.Item>
      <Form.Item label="Phone" {...formItemLayout}>
        {getFieldDecorator('address_phone', {})(<Input autoComplete="off" />)}
      </Form.Item>
      <Form.Item label="Fax" {...formItemLayout}>
        {getFieldDecorator('address_fax', {})(<Input autoComplete="off" />)}
      </Form.Item>
      <Form.Item label="Tags" {...formItemLayout}>
        {getFieldDecorator('address_tags', {})(<AddressTagsSelect />)}
      </Form.Item>
      <Form.Item label="Status" {...formItemLayout} style={{ textAlign: 'left' }}>
        {getFieldDecorator('address_status', {
          initialValue: CompanyStatus.Activated
        })(
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={CompanyStatus.Activated}>{CompanyStatus.Activated}</Radio.Button>
            <Radio.Button value={CompanyStatus.Deleted}>{CompanyStatus.Deleted}</Radio.Button>
          </Radio.Group>
        )}
      </Form.Item>
    </>
  )
})

export default YardAddressForm
