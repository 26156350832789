import CrudTypesTableView from 'App/components/Manage/Shared/CrudType/TableView'
import { BOOKING_TYPES_QUERY } from '../schema'

const BookingTypesTableView = () => (
  <CrudTypesTableView
    gqlQuery={BOOKING_TYPES_QUERY}
    crudType="bookingType"
    articleUrl="https://shipxhelp.freshdesk.com/support/solutions/articles/47001259855-booking-type"
  />
)

export default BookingTypesTableView
