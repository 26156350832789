export const getDataGql = `
  query transportRates3 (
    $customerUuid: UUID,
    $q: String,
    $limit: Int,
    $offset: Int
    $vendorUuid: UUID,
    $from: [String],
    $to: [String],
  ) {
    transportRates (
      customerUuid: $customerUuid,
      q: $q,
      limit: $limit,
      offset: $offset,
      vendorUuid: $vendorUuid,
      from: $from,
      to: $to
    ) {
      rows {
        customer {
          code
          name
          uuid
        }
        distance
        from
        name
        rates {
          cargoType
          rateType
          rate
          size
          transactionType
          transportType
          type
          rateUom
          rateRule
          rateValue
        }
        to
        type
        uuid
        vendor {
          code
          name
          uuid
        }
      }
    }
  }
`

export const getTransportAreaCodesGql = `
  query getTransportAreaCodes2 (
    $limit: Int,
    $offset: Int,
    $q: String
  ) {
    transportAreaCodes (
      limit: $limit,
      offset: $offset,
      q: $q
    ) {
      rows {
        uuid
        code
        name
        zone
      }
    }
  }
`

export const getCompaniesGql = `
  query companies1 (
    $types: [CompanyType!],
    $countryAlpha3: LimitedString,
    $query: String,
    $limit: Int,
    $offset: Int
    $statuses: [CompanyStatus!]
  ) {
    companies (
      types: $types,
      countryAlpha3: $countryAlpha3,
      query: $query,
      limit: $limit,
      offset: $offset,
      statuses: $statuses
    ) {
      rows {
        code
        name
        status
        uuid
      }
    }
  }
`

export const createEntityGql = `
  mutation createTransportRate3 ($input: CreateTransportRateInput!) {
    createTransportRate (input: $input) {
      from
      name
      to
      uuid
    }
  }
`

export const updateEntityGql = `
  mutation updateTransportRate3 ($input: UpdateTransportRateInput!) {
    updateTransportRate (input: $input) {
      from
      name
      to
      uuid
    }
  }
`
