import { DocumentStatus } from '@/types/graphql'

import { useState } from 'react'
import { connect } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd-v5'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'

import { Brief, CategoryTitle } from '@/components/Workflow/Styled'
import Container from '@/components/Workflow/Uploaded/Container'
import Preview from '@/components/Workflow/Uploaded/Preview'
import UploadedItems from '@/components/Workflow/Uploaded/UploadedItems'
import UploadZone from '@/components/Workflow/Uploaded/UploadZone'
import * as bookingDocumentActions from '@/states/reducers/bookingDocument'

const Uploaded = props => {
  const {
    updateSelectedBookingDocument,
    toggleDocumentViewer,
    state,
    section,
    uploadActions,
    uploadedDocs = [],
    requiredDocs = [],
    booking,
    highlightedDocuments
  } = props

  const [showPreview, setShowPreview] = useState(false)
  const [showDeleted, setShowDeleted] = useState(false)
  const [showUploadZone, setShowUploadZone] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState({})

  const handleOpenViewer = selected => {
    if (selected) {
      updateSelectedBookingDocument(selected)
      toggleDocumentViewer()
    }
  }

  const hasDeleted = uploadedDocs.find(doc => doc.status === DocumentStatus.Deleted)

  return (
    <Container>
      <CategoryTitle className="flex items-center">
        <span>Documents</span>
        {hasDeleted && (
          <Brief onClick={() => setShowDeleted(!showDeleted)}>
            {showDeleted ? 'Hide' : 'Show'} Deleted
          </Brief>
        )}
        <Button
          shape="circle"
          className="ml-2"
          icon={<PlusOutlined />}
          onClick={() => setShowUploadZone(true)}
        />
      </CategoryTitle>

      <UploadedItems
        uploadedDocs={uploadedDocs}
        section={section}
        booking={booking}
        showDeleted={showDeleted}
        highlightedDocuments={highlightedDocuments}
        handleOpenViewer={handleOpenViewer}
      />

      <Preview
        state={state}
        section={section}
        showPreview={showPreview}
        togglePreview={setShowPreview}
        uploadActions={uploadActions}
        selectedDocument={selectedDocument}
        toggleSelectedDocument={setSelectedDocument}
      />

      <UploadZone
        visible={showUploadZone}
        requiredDocs={requiredDocs}
        uploadActions={uploadActions}
        toggleUploadZone={setShowUploadZone}
      />
    </Container>
  )
}

export default compose(
  connect(
    state => ({ selectedVoucher: state.voucher.selectedVoucher }),
    // @ts-expect-error
    dispatch => ({ dispatch, ...bindActionCreators({ ...bookingDocumentActions }, dispatch) })
  )
)(Uploaded)
