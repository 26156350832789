export const getDataGql = `
  query glCodesExport1 ($q: String, $limit: Int, $offset: Int, $status: GlCodeStatus) {
    glCodes (q: $q, limit: $limit, offset: $offset, status: $status) {
      rows {
        code
        description
        status
        uuid
      }
    }
  }
`

export const getExistingGlCodeGql = `
  query getExistingGlCode1 ($uuid: UUID!) {
    glCode (uuid: $uuid) {
      uuid
      code
    }
  }
`

export const createEntityGql = `
  mutation createGlCodeImport1 ($input: CreateGlCodeInput!) {
    createGlCode (input: $input) {
      uuid
    }
  }
`

export const updateEntityGql = `
  mutation updateGlCodeImport1 ($input: UpdateGlCodeInput!) {
    updateGlCode (input: $input) {
      uuid
    }
  }
`
