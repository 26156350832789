import { forwardRef, memo } from 'react'
import { Select } from 'antd'
import { v4 as uuidv4 } from 'uuid'

const SharedSelector = forwardRef((props: any, ref: any) => {
  const {
    mode,
    style,
    value,
    dataKey,
    loading,
    onChange,
    onSearch,
    onSelect,
    dataArray,
    allowClear,
    showSearch,
    filterOption = false,
    placeholder = 'Please select...'
  } = props

  return (
    <Select
      ref={ref}
      mode={mode}
      style={style}
      value={value}
      loading={loading}
      onChange={onChange}
      onSearch={onSearch}
      onSelect={onSelect}
      allowClear={allowClear}
      showSearch={showSearch}
      placeholder={placeholder}
      filterOption={filterOption}
    >
      {dataArray?.map((data: any) => {
        return (
          <Select.Option key={uuidv4()} value={dataKey ? data[dataKey] : data}>
            {data.label ? data.label : dataKey ? data[dataKey] : data}
          </Select.Option>
        )
      })}
    </Select>
  )
})

export default memo(SharedSelector)
