import type { ResultProps } from 'antd-v5'

import { memo } from 'react'
import { LockOutlined } from '@ant-design/icons'
import { Result, Typography } from 'antd-v5'

interface NoAccessProps extends ResultProps {
  text?: React.ReactNode | string
}

const NoAccess = (props: NoAccessProps) => {
  const { text = 'No Access' } = props

  return (
    <Result
      {...props}
      icon={<LockOutlined style={{ color: 'lightgray' }} />}
      extra={
        <Typography.Title level={5} style={{ color: 'lightgray' }}>
          {text}
        </Typography.Title>
      }
    />
  )
}

export default memo(NoAccess)
