import { graphql } from '@apollo/client/react/hoc'

import schema from '../schema'

export default graphql(schema.LINK_DOCUMENT_TO_VOUCHER, {
  props: ({ mutate }) => ({
    linkDocumentToVoucher: params =>
      mutate({
        variables: params
      })
  })
})
