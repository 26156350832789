import type { BookingType } from 'App/types/graphql'
import {
  BookingTypeStatus,
  DynamicFieldControl,
  DynamicFieldQuery,
  DynamicFieldType
} from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

const sampleData = [
  {
    uuid: uuidv4(),
    code: 'IMPORT',
    name: 'IMPORT',
    description: 'IMPORT BOOKING TYPE',
    flowType: 'fwdMysImport',
    sorting: 1,
    status: BookingTypeStatus.Active,
    billing: [
      {
        billManner: 'ByBooking',
        desc: 'By Booking Desc',
        chargeItemUuid: 'sampleChargeItemUuid',
        costRate: 1.0,
        costRateId: 'sampleCostRateId',
        sellRate: 1.0,
        sellRateId: 'sampleSellRateId'
      }
    ],
    dynamicFields: [
      {
        key: '1',
        type: DynamicFieldType.Text,
        query: DynamicFieldQuery.Department,
        control: DynamicFieldControl.Required,
        enumName: undefined,
        searchable: undefined,
        multiple: undefined,
        customValues: 'EAST\nSOUTH\nWEST\nNORTH\nCENTRAL\n'
      }
    ],
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: The required fields are: [code (e.g. IMPORT), name (e.g. IMPORT), sorting (e.g. 1)]; dynamicFields is optional and if provided, [key, type, control, searchable] are required for each dynamic field.'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no'
  },
  {
    title: 'UUID',
    dataIndex: 'uuid',
    key: 'uuid'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Sorting*',
    dataIndex: 'sorting',
    key: 'sorting'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Billing',
    dataIndex: 'billing',
    key: 'billing',
    ellipsis: true,
    render: (text: any, record: any) => JSON.stringify(record.billing)
  },
  {
    title: 'Dynamic Fields',
    dataIndex: 'dynamicFields',
    key: 'dynamicFields',
    ellipsis: true,
    render: (text: any, record: any) => JSON.stringify(record.dynamicFields)
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (bookingType: BookingType): any => {
  if (!bookingType.code) return { nullError: 'Code is required, e.g. IMPORT.' }
  if (!bookingType.name) return { nullError: 'Name is required, e.g. IMPORT.' }
  if (!bookingType.sorting) return { nullError: 'Sorting is required, e.g. 1.' }

  if (bookingType.dynamicFields?.filter(Boolean).length) {
    try {
      for (const dynamicField of bookingType.dynamicFields) {
        JSON.stringify(JSON.parse(JSON.stringify(dynamicField)))
      }
    } catch (error) {
      return {
        nullError: 'Unable to parse dynamicFields into JSON. Please use a valid JSON object or {}.'
      }
    }
  } else {
    // @ts-ignore
    bookingType.dynamicFields = '[]'
  }
}

const populatebookingTypeObj = (obj: BookingType, bookingTypeId: string) => {
  const bookingTypeObj: any = {
    uuid: bookingTypeId?.trim(),
    code: obj.code?.toString()?.trim(),
    name: obj.name, // @ts-ignore
    sorting: parseInt(obj.sorting || '0'),
    flowType: obj.flowType,
    description: obj.description,
    status: obj.status || BookingTypeStatus.Active,
    billing: obj?.billing || [],
    dynamicFields: obj?.dynamicFields || []
  }

  return bookingTypeObj
}

const bookingTypesHelper = {
  queryName: 'bookingTypes',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const bookingTypes = results?.data?.bookingTypes?.rows?.map(
        (row: BookingType, i: number) => ({
          no: i + 1,
          ...row,
          zzz: ''
        })
      )
      return bookingTypes
    } catch (error) {
      logger.error('bookingTypesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (bookingTypeData: any, selectedGlobalCompany: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { importStatus, key, ...bookingType } = bookingTypeData

    const nullInputError = findNullInputs(bookingType)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const bookingTypesQuery = {
          limit: 10000
        }
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          input: bookingTypesQuery
        })
        const bookingTypes = results?.data?.bookingTypes?.rows

        for (let i = 0; i < bookingTypes?.length; i++) {
          cache.uuid[bookingTypes[i]?.uuid] = bookingTypes[i]?.code
          cache.code[bookingTypes[i]?.code] = bookingTypes[i]?.uuid
        }
      } catch (error) {
        logger.error('bookingTypesHelper cache getDataGql error', error)
        return error
      }
    }

    // Check bookingTypeId
    let bookingTypeId: string = ''
    if (cache.uuid[bookingType?.uuid]) {
      bookingTypeId = bookingType?.uuid
    } else if (cache.code[bookingType?.code]) {
      bookingTypeId = cache.code[bookingType?.code]
    }

    let mutationResults
    // Update
    if (bookingTypeId?.length > 0) {
      try {
        const bookingTypeObj = populatebookingTypeObj(bookingType, bookingTypeId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: bookingTypeObj
        })
      } catch (error) {
        logger.error('bookingTypesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        bookingTypeId = uuidv4()
        const bookingTypeObj = populatebookingTypeObj(bookingType, bookingTypeId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: bookingTypeObj
        })
      } catch (error) {
        logger.error('bookingTypesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default bookingTypesHelper
