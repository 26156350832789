import type { FormComponentProps } from 'antd/es/form'
import type { DynamicField, Trip } from 'App/types/graphql'
import { DocumentCreatorTemplateStatus, TripStatus } from 'App/types/graphql'

import { useEffect, useState } from 'react'
import { Col, Row, Typography } from 'antd-v5'

import { DisplayAddress, RenderFields } from '@/components/Booking/General/FieldDisplayHelper'
import DocumentCreatorPrinter from '@/components/Shared/DocumentCreatorPrinter'
import { containerStyle, contentStyle, verticalStyle } from './styles'

interface JobFormProps extends FormComponentProps {
  trips: Trip[]
  tripDynamicFields: DynamicField[]
  hideDeleted?: boolean
  hideFunctions?: boolean
  index: number
}

const TripsDetailsDisplay = (props: JobFormProps) => {
  const { trips, tripDynamicFields, hideDeleted = true, hideFunctions = false } = props
  const [filteredTrips, setFilteredTrips] = useState<Trip[]>([])

  useEffect(() => {
    if (!trips) return
    if (hideDeleted)
      setFilteredTrips(
        trips.filter(
          job =>
            job && ![TripStatus.Cancelled, TripStatus.Deleted].includes(job.status as TripStatus)
        )
      )
    else setFilteredTrips(trips)
  }, [hideDeleted, trips])

  return (
    <>
      {filteredTrips?.map((trip, index) => {
        const isCancelledTrip = [TripStatus.Cancelled, TripStatus.Deleted].includes(
          trip.status as TripStatus
        )
        const style = isCancelledTrip
          ? { ...containerStyle, backgroundColor: '#F2B1B1' }
          : containerStyle

        return (
          <div key={trip?.uuid} style={style}>
            <div style={verticalStyle}>{trip.type || `Trip ${index + 1}`}</div>
            <div style={contentStyle}>
              {!hideFunctions && (
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Typography.Text style={{ paddingRight: '5px' }}>{trip.status}</Typography.Text>
                    <DocumentCreatorPrinter
                      job={trip}
                      variables={{
                        qs: ['TRIP_DOCUMENT'],
                        statuses: [DocumentCreatorTemplateStatus.Activated]
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={12}>{DisplayAddress(trip?.from || trip?.fromUuid)}</Col>
                <Col span={12}>{DisplayAddress(trip?.to || trip.toUuid)}</Col>
              </Row>
              <Row gutter={[16, { xs: 20, lg: 48 }]} style={{ marginTop: 2 }}>
                {[0, 1, 2].map(colIndex => (
                  <Col key={colIndex} xs={24} sm={12} lg={8}>
                    {tripDynamicFields
                      .slice(
                        Math.floor((colIndex * tripDynamicFields.length) / 3),
                        Math.floor(((colIndex + 1) * tripDynamicFields.length) / 3)
                      )
                      .map((field: DynamicField) => RenderFields(trip, field))}
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default TripsDetailsDisplay
