import { gql } from '@/types'

export default gql(`
  mutation makeBooking2($input: MakeBookingInput!) {
    makeBooking(input: $input) {
      uuid
      no
      jobs{
        uuid
        trips{
          uuid
        }
      }
    }
  }
`)
