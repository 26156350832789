import { useApolloClient } from '@apollo/client'
import { Form, Select } from 'antd'
import debounce from 'lodash/debounce'
import { compose, lifecycle, withHandlers, withState } from 'recompose'

import COMPANY_QUERY from 'App/containers/company/schema/company'
import { COMPANIES_QUERY_SLIM } from 'App/graphql/company'

const withMount = lifecycle({
  async componentDidMount() {
    const { value = [] } = this.props

    if (value.length === 0) {
      return
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const client = useApolloClient()

    const queries = await Promise.all(
      value.map(v => {
        return client.query({
          query: COMPANY_QUERY,
          variables: {
            uuid: v
          }
        })
      })
    )

    const companies = queries.map(q => {
      const company = q.data.company
      return {
        key: company.uuid,
        label: `${company.code} - ${company.name}`
      }
    })

    this.setState({
      initialCompanies: companies
    })
  }
})

const searchResultState = withState('searchResult', 'setSearchResult', [])

const eventHandlers = withHandlers({
  handleSearch: props => async value => {
    const { setSearchResult } = props

    const client = useApolloClient()

    try {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: COMPANIES_QUERY_SLIM,
        variables: {
          query: value,
          portalCompanyQueryType: 'all',
          limit: 5,
          types: ['shipperConsignee', 'billing']
        }
      })

      setSearchResult(data.companies.rows)
    } catch (error) {
      console.log(error)
    }
  }
})

const enhance = compose(withMount, searchResultState, eventHandlers)

const FormItem = Form.Item
const Option = Select.Option

const CustomerFilterFormItem = props => {
  const {
    form: { getFieldDecorator },
    initialCompanies,
    handleSearch,
    searchResult
  } = props

  return (
    <FormItem label="Customer">
      {getFieldDecorator('customerUuid', {
        initialValue: initialCompanies
      })(
        <Select
          mode="multiple"
          notFoundContent="Not found"
          filterOption={false}
          labelInValue
          onSearch={debounce(handleSearch, 800)}
          placeholder="Search a company..."
        >
          {searchResult.map(r => {
            return (
              <Option key={r.uuid} value={r.uuid}>
                {r.code} - {r.name}
              </Option>
            )
          })}
        </Select>
      )}
    </FormItem>
  )
}

export default enhance(CustomerFilterFormItem)
