import type { PropsWithChildren } from 'react'

import styled from 'styled-components'

export const StyledMenu = styled.div`
  width: 210px;
`

export const MenuBody = styled.div`
  text-align: center;
  padding: 15px 10px;
`

export const MenuSelector = styled.span<PropsWithChildren<{ selected?: boolean }>>`
  display: block;
  text-align: left;
  padding: 6px 10px;
  margin-bottom: 10px;
  font-size: 13.5px;
  font-weight: ${props => (props.selected ? 'bold' : '400')};
  color: #111;
  letter-spacing: 0.3px;
  background: transparent;

  &:hover {
    color: ${props => (props.selected ? 'black' : 'rgb(160, 160, 160)')};
  }
`

export const GroupLabel = styled.div`
  text-align: left;
  font-size: 10.5px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: rgb(180, 180, 180);
  text-transform: uppercase;
  padding: 30px 10px 10px;
`

export const Body = styled.div<PropsWithChildren<unknown>>`
  position: fixed;
  left: 0px;
  top: 56px;
  right: 0;
  bottom: 0;
  overflow: auto;
`

export const View = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 10px;
  height: 100vh;
`

export const Content = styled.div`
  position: absolute;
  left: 210px;
  top: 0;
  bottom: 0;
  right: 0;
`
