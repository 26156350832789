import type {
  DriverTypes,
  ExportDriversIncentiveTypes,
  ExportIncentivesTypes,
  FileDateType,
  IncentiveTypes,
  QueryShiftObjType
} from './types'

import * as download from 'downloadjs'
import { json2csv } from 'json-2-csv'
import { uniq } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import mapValues from 'lodash/mapValues'
import { utils, writeFile } from 'xlsx'

import { getLocalDateTimeString } from 'App/components/RelativeDateTime'
import { getGqlResponse } from 'App/utils/importExport/helpers'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import { round2Digits } from 'App/utils/voucher'
import { SHIFTS_JSON_EXPORT1 } from './schema'

let fileDate: FileDateType
let exportType: string = 'Shifts'

export const json2xlsx = (
  dataName: string,
  dataArray: any,
  exportType: string,
  fileDate: FileDateType
) => {
  try {
    const newArray: any = cloneDeep(dataArray)
    let compatibleData: any = []
    let headers: any = []

    if (newArray?.length) {
      newArray.forEach((obj: any, index: number) => {
        Object.keys(obj)?.forEach((item: string) => {
          if (obj[item] && typeof obj[item] === 'object') {
            Object.keys(obj[item])?.forEach((key: string) => {
              newArray[index][`${item}.${key}`] = obj[item][key]
            })

            delete newArray[index][item]
          }
        })
      })
      compatibleData = newArray
    } else {
      compatibleData = [...compatibleData, newArray]
    }

    Object.keys(compatibleData[0])?.forEach((key: string) => {
      headers = [...headers, key]
    })

    const wb = utils.book_new()
    const ws1 = utils.json_to_sheet(compatibleData, {
      // @ts-ignore
      headers
    })

    utils.book_append_sheet(wb, ws1, dataName)

    const fileName = `${dataName}_${exportType}_from_${fileDate.start}_to_${fileDate.end}.xlsx`
    writeFile(wb, fileName)

    respHandler('Successfully exported file.', 'success')
    // Returning false as downloading of file is already taken care of
    return false
  } catch (error) {
    logger.error('Export Shifts json2xlsx error', error)
    return respHandler(error, 'error')
  }
}

const csv2jsonDownload = (err: any, csv: string) => {
  if (err) {
    logger.error('Export Shifts csv2jsoDownload err', err)
    return respHandler(err, 'error')
  }

  try {
    const blob = new Blob([csv], { type: 'text/csv' })
    const fileName = `Shifts_${exportType}_from_${fileDate.start}_to_${fileDate.end}.csv`
    // @ts-ignore
    download(blob, fileName)
    respHandler('Successfully exported file.', 'success')
  } catch (error) {
    logger.error('Export Shifts csv2jsonDownload error', error)
    return respHandler(error, 'error')
  }
}

const getDataByShifts = async (shiftsData: any) => {
  exportType = 'All_Shifts'

  const formattedData = shiftsData?.map((shiftObj: any, shiftIndex: number) => {
    return {
      no: shiftIndex + 1,
      driverCode: shiftObj.driver?.code,
      driverName: shiftObj.driver?.name,
      driverUuid: shiftObj.driver?.uuid,
      vehicleCode: shiftObj.vehicle?.code,
      vehicleRegistration: shiftObj.vehicle?.registration,
      vehicleUuid: shiftObj.vehicle?.uuid,
      shiftNo: shiftObj.no,
      start: getLocalDateTimeString(shiftObj.start),
      end: getLocalDateTimeString(shiftObj.end),
      breakDuration: shiftObj.breakDuration,
      duration_sec: shiftObj.duration,
      status: shiftObj.status,
      createdAt: getLocalDateTimeString(shiftObj.createdAt),
      updatedAt: getLocalDateTimeString(shiftObj.updatedAt),
      updatedBy: shiftObj.updatedBy?.email,
      approvedAt: getLocalDateTimeString(shiftObj.approvedAt),
      approvedBy: shiftObj.approvedBy?.email,
      approvedFinanceAt: getLocalDateTimeString(shiftObj.approvedFinanceAt),
      approvedFinanceBy: shiftObj.approvedFinanceBy?.email,
      remarks: shiftObj.remarks,
      shiftUuid: shiftObj._id
    }
  })

  shiftsData?.forEach((shiftObj: any) => {
    shiftObj.incentiveCategorySum?.forEach((cat: any) => {
      const currentShift = formattedData?.find((obj: any) => obj.shiftUuid === shiftObj._id)
      if (!currentShift?.[cat.category]) {
        currentShift[cat.category] = 0
      }

      const currentAmount = cat.amount && round2Digits(cat.amount)
      currentShift[cat.category] += currentAmount
    })
  })

  return formattedData
}

const getDataByIncentiveCategory = async (shiftsData: any) => {
  exportType = 'By_Incentive_Category'
  const formattedData: any = { no: 1 }

  shiftsData?.forEach((shiftObj: any) => {
    mapValues(shiftObj.incentiveCategorySum, (value: any) => {
      if (!formattedData[value.category]) {
        formattedData[value.category] = 0
      }

      const currentAmount = value.amount && round2Digits(value.amount)
      formattedData[value.category] += currentAmount
    })
  })

  return formattedData
}

const getDataByIncentiveType = async (shiftsData: any) => {
  exportType = 'By_Incentive_Types'
  let formattedData: any = []

  shiftsData?.forEach((shiftObj: any) => {
    mapValues(shiftObj.incentiveTypeSum, (value: any) => {
      const currentType = formattedData?.find((obj: any) => obj._id === value.typeUuid)
      if (!currentType) {
        formattedData = [
          ...formattedData,
          {
            ...value.type,
            amount: value.amount && round2Digits(value.amount)
          }
        ]
      } else {
        const currentAmount = value.amount && round2Digits(value.amount)
        currentType.amount += currentAmount
      }
    })
  })

  const addIndex = formattedData?.map((item: any, index: number) => {
    return {
      no: index + 1,
      ...item
    }
  })

  return addIndex
}

const getDataByDriver = async (shiftsData: any) => {
  exportType = 'By_Driver'

  let formattedData: any = []

  shiftsData?.forEach((shiftObj: any) => {
    const currentDriver = formattedData?.find((obj: any) => obj.uuid === shiftObj.driver.uuid)
    if (!currentDriver) {
      formattedData = [...formattedData, shiftObj.driver]
    }

    shiftObj.incentiveCategorySum?.forEach((cat: any) => {
      const thisDriver = formattedData?.find((obj: any) => obj.uuid === shiftObj.driver.uuid)
      if (!thisDriver[cat.category]) {
        thisDriver[cat.category] = 0
      }

      const currentAmount = cat.amount && round2Digits(cat.amount)
      thisDriver[cat.category] += currentAmount
    })
  })

  const addIndex = formattedData?.map((item: any, index: number) => {
    return {
      no: index + 1,
      ...item
    }
  })

  return addIndex
}

const getDataByDriverIncentiveType = async (shiftsData: any) => {
  exportType = 'By_Driver_with_Incentive_Types'
  let exportDriversIncentiveTypes: ExportDriversIncentiveTypes = []
  let selectedIncentiveTypes: IncentiveTypes = []
  let selectedDrivers: DriverTypes = []

  shiftsData?.forEach((shiftObj: any) => {
    mapValues(shiftObj.incentiveTypeSum, (value: any) => {
      const currentType = selectedIncentiveTypes?.find((obj: any) => obj._id === value.typeUuid)
      if (!currentType) {
        selectedIncentiveTypes = [...selectedIncentiveTypes, value.type]
      }
    })

    const currentDriver = selectedDrivers?.find((obj: any) => obj.uuid === shiftObj.driver.uuid)
    if (!currentDriver) {
      selectedDrivers = [
        ...selectedDrivers,
        {
          code: shiftObj.driver?.code,
          name: shiftObj.driver?.name,
          uuid: shiftObj.driver?.uuid
        }
      ]
    }
  })

  selectedDrivers?.forEach((driverObj: any) => {
    const currentDriver = exportDriversIncentiveTypes?.find(
      (obj: any) => obj.uuid === driverObj.uuid
    )
    if (!currentDriver) {
      selectedIncentiveTypes?.forEach((incentiveType: any) => {
        exportDriversIncentiveTypes = [
          ...exportDriversIncentiveTypes,
          {
            driverCode: driverObj?.code,
            driverName: driverObj?.name,
            driverUuid: driverObj?.uuid,
            incentiveCode: incentiveType?.code,
            incentiveName: incentiveType?.name,
            incentiveCategory: incentiveType?.category,
            incentiveDetails: incentiveType?.details,
            incentiveTypeAmount: 0,
            incentiveTypeId: incentiveType?._id
          }
        ]
      })
    }
  })

  shiftsData?.forEach((shiftObj: any) => {
    selectedIncentiveTypes?.forEach((incentiveType: any) => {
      const ictTypeAmount = shiftObj.incentiveTypeSum?.find(
        (obj: any) => obj.typeUuid === incentiveType?._id
      )?.amount
      const roundedAmount = ictTypeAmount && round2Digits(ictTypeAmount)

      exportDriversIncentiveTypes?.forEach((subObj: any) => {
        if (
          roundedAmount &&
          subObj.driverUuid === shiftObj.driver.uuid &&
          subObj.incentiveTypeId === incentiveType?._id
        ) {
          subObj.incentiveTypeAmount += roundedAmount
        }
      })
    })
  })

  const addIndex = exportDriversIncentiveTypes?.map((item: any, index: number) => {
    return {
      no: index + 1,
      driverCode: item?.driverCode,
      driverName: item?.driverName,
      driverUuid: item?.driverUuid,
      incentiveCode: item?.incentiveCode,
      incentiveName: item?.incentiveName,
      incentiveCategory: item?.incentiveCategory,
      incentiveDetails: item?.incentiveDetails,
      incentiveTypeAmount: item?.incentiveTypeAmount,
      incentiveTypeId: item?.incentiveTypeId
    }
  })

  return addIndex
}

const getDataByIncentives = (shiftsData: any) => {
  exportType = 'By_Incentives'

  let exportIncentives: ExportIncentivesTypes = []

  shiftsData?.forEach((shiftObj: any) => {
    shiftObj.incentives?.forEach((incentive: any) => {
      exportIncentives = [
        ...exportIncentives,
        {
          bookingNo: incentive.transportJob?.bookingNo,
          jobNo: incentive.transportJob?.jobNo,
          shipperName: incentive.transportJob?.shipperName,
          consigneeName: incentive.transportJob?.consigneeName,
          trip: incentive.transportJob?.tripSequence,
          tripType: incentive.transportJob?.tripType,
          leg: incentive.transportJob?.sequence,
          legUuid: incentive.transportJob?.legUuid,
          driverCode: incentive.driverCode,
          driverName: incentive.driverName,
          driverUuid: shiftObj.driverUuid,
          vehicleCode: incentive.vehicleCode,
          vehicleRegistration: incentive.vehicleName,
          vehicleUuid: shiftObj.vehicleUuid,
          incentiveCode: incentive.type?.code,
          incentiveName: incentive.type?.name,
          incentiveCategory: incentive.type?.category,
          incentiveDate: getLocalDateTimeString(incentive.date),
          incentiveAmount: incentive.amount?.toFixed(2),
          incentiveStatus: incentive.status,
          incentiveCreatedAt: getLocalDateTimeString(incentive.createdAt),
          incentiveRemarks: incentive.remarks,
          incentiveUuid: incentive._id,
          shiftNo: shiftObj.no,
          shiftStart: getLocalDateTimeString(shiftObj.start),
          shiftEnd: getLocalDateTimeString(shiftObj.end),
          shiftStatus: shiftObj.status,
          shiftUpdatedAt: getLocalDateTimeString(shiftObj.updatedAt),
          shiftUpdatedBy: shiftObj.updatedBy?.email,
          shiftApprovedAt: getLocalDateTimeString(shiftObj.approvedAt),
          shiftApprovedBy: shiftObj.approvedBy?.email,
          shiftApprovedFinanceAt: getLocalDateTimeString(shiftObj.approvedFinanceAt),
          shiftApprovedFinanceBy: shiftObj.approvedFinanceBy?.email,
          shiftRemarks: shiftObj.remarks,
          shiftUuid: incentive.shiftUuid,
          voucherNo: incentive.incentiveVoucher?.voucherNo,
          voucherDate: incentive.incentiveVoucher?.date
            ? new Date(incentive?.incentiveVoucher.date).toLocaleDateString()
            : '-',
          voucherStatus: incentive.incentiveVoucher?.status,
          voucherUpdatedAt: getLocalDateTimeString(incentive.incentiveVoucher?.updatedAt),
          voucherUpdatedBy: incentive.incentiveVoucher?.updatedBy?.email,
          voucherApprovedAt: getLocalDateTimeString(incentive.incentiveVoucher?.approvedAt),
          voucherApprovedBy: incentive.incentiveVoucher?.approvedBy?.email,
          voucherApprovedFinanceAt: getLocalDateTimeString(
            incentive.incentiveVoucher?.approvedFinanceAt
          ),
          voucherApprovedFinanceBy: incentive.incentiveVoucher?.approvedFinanceBy?.email,
          voucherRemarks: incentive.incentiveVoucher?.remarks,
          voucherUuid: incentive.incentiveVoucher?._id
        }
      ]
    })
  })

  const addIndex = exportIncentives?.map((item: any, index: number) => {
    return {
      no: index + 1,
      ...item
    }
  })

  return addIndex
}

const getDataByDriverSummary = (shiftsData: any) => {
  exportType = 'By_Driver_Summary'
  let shiftsByDrivers: any = []

  shiftsData?.forEach((shiftObj: any) => {
    const currentDriver = shiftsByDrivers?.find(
      (obj: any) => obj.driverUuid === shiftObj.driverUuid
    )
    if (!currentDriver) {
      shiftsByDrivers = [
        ...shiftsByDrivers,
        {
          driverCode: '',
          driverName: '',
          driverUuid: shiftObj.driverUuid,
          vehicleCode: [],
          vehicleRegistration: [],
          voucherNo: [],
          voucherDate: [],
          shiftNo: [],
          jobNo: []
        }
      ]
    }

    shiftObj.incentives?.forEach((incentive: any) => {
      const thisDriver = shiftsByDrivers?.find(
        (obj: any) => obj.driverUuid === incentive.driverUuid
      )
      if (thisDriver) {
        thisDriver.driverCode = incentive.driverCode
        thisDriver.driverName = incentive.driverName
        thisDriver.vehicleCode.push(incentive.vehicleCode)
        thisDriver.vehicleRegistration.push(incentive.vehicleName)
        thisDriver.voucherNo.push(incentive.incentiveVoucher?.voucherNo || '-')
        thisDriver.voucherDate.push(
          incentive.incentiveVoucher?.date
            ? new Date(incentive.incentiveVoucher.date).toLocaleDateString()
            : '-'
        )
        thisDriver.shiftNo.push(shiftObj.no || '-')
        thisDriver.jobNo.push(incentive.transportJob?.jobNo || '-')
      }
    })

    shiftObj.incentiveCategorySum?.forEach((cat: any) => {
      const thisDriver = shiftsByDrivers?.find((obj: any) => obj.driverUuid === shiftObj.driverUuid)
      if (!thisDriver[cat.category]) {
        thisDriver[cat.category] = 0
      }

      const currentAmount = cat.amount && round2Digits(cat.amount)
      thisDriver[cat.category] += currentAmount
    })
  })

  const addIndex = shiftsByDrivers?.map((driver: any, index: number) => {
    const uniqVehicleCodes = uniq(
      driver.vehicleCode?.filter((code: string) => code && code !== '-')
    )
    const uniqVehicleReg = uniq(
      driver.vehicleRegistration?.filter((reg: string) => reg && reg !== '-')
    )
    const uniqVoucherNos = uniq(driver.voucherNo?.filter((no: string) => no && no !== '-'))
    const uniqVoucherDates = uniq(
      driver.voucherDate?.filter((date: string) => date && date !== '-')
    )
    const uniqShiftNos = uniq(driver.shiftNo?.filter((no: string) => no && no !== '-'))
    const uniqJobNos = uniq(driver.jobNo?.filter((no: string) => no && no !== '-'))

    return {
      no: index + 1,
      ...driver,
      vehicleCode: uniqVehicleCodes?.join(', '),
      vehicleRegistration: uniqVehicleReg?.join(', '),
      voucherNo: uniqVoucherNos?.join(', '),
      voucherDate: uniqVoucherDates?.join(', '),
      shiftNo: uniqShiftNos?.join(', '),
      jobNo: uniqJobNos?.join(', ')
    }
  })

  return addIndex
}

export const exportShiftsIncentives = async (
  selectedGlobalCompany: any,
  queryShiftObj: QueryShiftObjType,
  setIsLoading: any,
  exportBy: string = 'byShifts',
  fileFormat: string = 'xlsx'
) => {
  try {
    setIsLoading(true)
    respHandler('Loading...', 'load')

    fileDate = {
      start: queryShiftObj?.startStart
        ? new Date(queryShiftObj.startStart).toLocaleDateString()
        : 'beginning-of-time',
      end: queryShiftObj?.startEnd
        ? new Date(queryShiftObj.startEnd).toLocaleDateString()
        : new Date().toLocaleDateString()
    }

    let querySize: number = 200
    let queryInput: any = {
      ...queryShiftObj,
      limit: querySize,
      offset: 0,
      jsonQueryOptions: [
        'transportJobs',
        'drivers',
        'vehicles',
        'users',
        'incentiveTypes',
        'incentives'
      ]
    }
    const gqlRes: string = 'shiftsJson'
    let shiftsData: any = []

    if (exportBy === 'byIncentives' || exportBy === 'byDriverSummary') {
      querySize = 200
      queryInput = {
        ...queryShiftObj,
        limit: querySize,
        jsonQueryOptions: ['transportJobs', 'users', 'incentiveTypes', 'incentives']
      }
    }
    if (exportBy === 'byShifts') {
      querySize = 500
      queryInput = {
        ...queryShiftObj,
        limit: querySize,
        jsonQueryOptions: ['drivers', 'vehicles', 'users', 'incentiveTypes']
      }
    }
    if (exportBy === 'byIncentiveCategory' || exportBy === 'byIncentiveType') {
      querySize = 500
      queryInput = { ...queryShiftObj, limit: querySize, jsonQueryOptions: ['incentiveTypes'] }
    }
    if (exportBy === 'byDriver' || exportBy === 'byDriverIncentiveType') {
      querySize = 500
      queryInput = {
        ...queryShiftObj,
        limit: querySize,
        jsonQueryOptions: ['drivers', 'incentiveTypes']
      }
    }

    const shiftResults = await getGqlResponse(selectedGlobalCompany, SHIFTS_JSON_EXPORT1, {
      input: queryInput
    })
    const shifts =
      shiftResults?.data?.[gqlRes]?.rows?.length && cloneDeep(shiftResults?.data?.[gqlRes]?.rows)
    if (!shifts?.length) {
      setIsLoading(false)
      return respHandler('No data to export.', 'warning')
    }
    shiftsData = shiftsData.concat(shifts)

    const totalShifts = shiftResults?.data?.[gqlRes]?.pageInfo?.count
    if (totalShifts > querySize) {
      const end = Math.ceil(totalShifts / querySize)
      for (let i = 1; i < end; i++) {
        respHandler(`Loading ${Math.floor((i / end) * 100)}%...`, 'load')
        const queryShiftsInput = { ...queryInput, limit: querySize, offset: i }
        const shiftResults = await getGqlResponse(selectedGlobalCompany, SHIFTS_JSON_EXPORT1, {
          input: queryShiftsInput
        })
        const shifts =
          shiftResults?.data?.[gqlRes]?.rows?.length &&
          cloneDeep(shiftResults?.data?.[gqlRes]?.rows)
        shiftsData = shiftsData.concat(shifts)
      }
    }

    respHandler('Processing the data...', 'load')
    let formattedData: any
    if (exportBy === 'byIncentives') {
      formattedData = await getDataByIncentives(shiftsData)
    }
    if (exportBy === 'byShifts') {
      formattedData = await getDataByShifts(shiftsData)
    }
    if (exportBy === 'byIncentiveCategory') {
      formattedData = await getDataByIncentiveCategory(shiftsData)
    }
    if (exportBy === 'byIncentiveType') {
      formattedData = await getDataByIncentiveType(shiftsData)
    }
    if (exportBy === 'byDriver') {
      formattedData = await getDataByDriver(shiftsData)
    }
    if (exportBy === 'byDriverIncentiveType') {
      formattedData = await getDataByDriverIncentiveType(shiftsData)
    }
    if (exportBy === 'byDriverSummary') {
      formattedData = await getDataByDriverSummary(shiftsData)
    }

    if (fileFormat === 'xlsx') {
      json2xlsx('Shifts', formattedData, exportType, fileDate)
    }
    if (fileFormat === 'csv') {
      // @ts-ignore
      json2csv(formattedData, csv2jsonDownload)
    }
    setIsLoading(false)
  } catch (error) {
    setIsLoading(false)
    logger.error('exportShiftsIncentives error', error)
    return respHandler(error, 'error')
  }
}
