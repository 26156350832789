import { gql } from '@apollo/client'

export const NODE_BODY = gql(`
  fragment NodeBody on Node {
    _id
    rootId
    calculator {
      aggregator
      operator
      nodeValue
      nodeValueType
      chargeItemCode
      remarks
      unit
      isQuantity
    }
    status
    splitBookingBy
    isTraverseAll
  }
`)

export const ROOT_BODY = gql(`
    fragment RootBody on Root {
      _id
      name
      code
      description
      status
      treeType
      layout {
        nodeId
        x
        y
      }
  }
`)

export const NODE_SELECTOR_BODY = gql(`
  fragment NodeSelectorBody on NodeSelector {
    key
    isOr
    query {
      comparator
      value
    }
  }
  `)
