import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as voucherActions from 'App/states/reducers/voucher'

export default WrappedComponent => {
  class WithModalHeader extends Component {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return compose(
    connect(
      state => ({
        selectedVoucher: state.voucher.selectedVoucher
      }),
      dispatch => ({
        dispatch,
        ...bindActionCreators(
          {
            ...voucherActions
          },
          dispatch
        )
      })
    )
  )(WithModalHeader)
}
