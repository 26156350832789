import type { CollapseProps } from 'antd-v5'

import { memo } from 'react'
import { Collapse } from 'antd-v5'

import VoucherFilterForm from '@/components/Voucher/Management/VoucherFilter/VoucherFilterForm'

const VoucherFilter = props => {
  const { refetch, query, setQuery, loggedInUser } = props

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Filter',
      children: (
        <VoucherFilterForm
          loggedInUser={loggedInUser}
          setQuery={setQuery}
          refetch={refetch}
          query={query}
        />
      )
    }
  ]

  return <Collapse items={items} defaultActiveKey={['1']} />
}

export default memo(VoucherFilter)
