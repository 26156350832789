import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { compose, withProps } from 'recompose'

import ROTAddAction from '@/components/Booking/General/ROTAddAction'
import ROTTable from '@/components/Booking/General/ROTTable'
import { BASIC_BOOKING_OPTIMISED_ROT } from '@/components/Booking/General/schema'
import Section from '@/components/Booking/Section'
import { CategoryTitle } from '@/components/Workflow/Styled'
import documentCreatorsQuery from '@/containers/documentCreator/query/documentCreatorsQuery'

const bookingUuidProps = withProps((ownerProps: { booking }) => {
  return { bookingUuid: ownerProps.booking.uuid }
})

const enhance = compose(bookingUuidProps, documentCreatorsQuery)

const ROTSection = ({ documentCreatorsQuery }: any) => {
  const { uuid } = useParams()

  const { data: bookingData, refetch: bookingRefetch } = useQuery(BASIC_BOOKING_OPTIMISED_ROT, {
    variables: { uuid }
  })

  const handleROTAdded = () => {
    documentCreatorsQuery.refetch()
    bookingRefetch()
  }

  const handleChange = () => {
    documentCreatorsQuery.refetch()
    bookingRefetch()
  }

  const documentCreators = (documentCreatorsQuery?.documentCreators?.rows || []).filter(
    dc => dc.type === 'ROT'
  )

  return (
    <Section
      removeBorder
      title={
        <>
          <CategoryTitle>
            ROT
            <ROTAddAction booking={bookingData?.booking} onAdded={handleROTAdded} />
          </CategoryTitle>
        </>
      }
    >
      <ROTTable documentCreators={documentCreators} onChange={handleChange} />
    </Section>
  )
}

export default enhance(ROTSection)
