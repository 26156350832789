import CrudTypesTableView from 'App/components/Manage/Shared/CrudType/TableView'
import { JOB_TYPES_QUERY } from '../schema'

const JobTypesTableView = () => {
  return (
    <CrudTypesTableView
      gqlQuery={JOB_TYPES_QUERY}
      crudType="jobType"
      articleUrl="https://shipxhelp.freshdesk.com/en/support/solutions/articles/47001259856-job-type"
    />
  )
}

export default JobTypesTableView
