import { graphql } from '@apollo/client/react/hoc'

import schema from '../schema'

export default graphql(schema.ADD_PAYMENT, {
  props: ({ mutate }) => ({
    addPayment: params =>
      mutate({
        variables: {
          input: params
        }
      })
  })
})
