export const getDataGql = `
  query incentiveTypesExport1 ($input: IncentiveTypeQueryInput!) {
    incentiveTypes (input: $input) {
      rows {
        category
        code
        name
        details
        status
        isJobRequired
        _id
        glCode {
          code
          description
          status
        }
        glCodeUuid
      }
    }
  }
`

export const createEntityGql = `
  mutation createIncentiveTypeImport ($input: CreateIncentiveTypeInput) {
    createIncentiveType (input: $input) {
      _id
      category
      code
      details
      name
      status
      glCodeUuid
      isJobRequired
    }
  }
`

export const updateEntityGql = `
  mutation updateIncentiveTypeImport ($input: UpdateIncentiveTypeInput) {
    updateIncentiveType (input: $input) {
      _id
      category
      code
      details
      name
      status
      glCodeUuid
      isJobRequired
    }
  }
`

export const getGlCodesGql = `
  query glCodesExport2 ($q: String, $limit: Int, $offset: Int, $status: GlCodeStatus) {
    glCodes (q: $q, limit: $limit, offset: $offset, status: $status) {
      rows {
        code
        status
        uuid
      }
    }
  }
`
