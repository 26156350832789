import { connect } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import { bindActionCreators, compose } from 'redux'

import voucherBookingSchema from '@/containers/bulk/voucherBookingData/schema'
import * as voucherActions from '@/states/reducers/voucher'
import useGlobalCompanyStore from '@/store/globalCompany'
import handleResp from '@/utils/responseHandler'

export default WrappedComponent => {
  const WithVoucherBookings = props => {
    const {
      selectedVoucher,
      voucherBookings,
      updateSelectedVoucher,
      updateVoucherBookings,
      selectedVoucherTemplate
    } = props

    const client = useApolloClient()
    const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

    const handleSelect = async bookingUuid => {
      const selectedVoucherBooking = find(voucherBookings, booking => booking.uuid === bookingUuid)

      if (!selectedVoucherBooking) {
        try {
          const { data } = await client.query({
            fetchPolicy: 'network-only',
            query: voucherBookingSchema,
            variables: { bookingUuid }
          })

          if (data?.booking) {
            const selectableDocuments = filter(
              data.booking.bookingDocuments,
              bDoc =>
                findIndex(selectedVoucherTemplate.bookingDocuments, vDoc => vDoc === bDoc.type) !==
                -1
            )

            const updatedVoucher = { ...selectedVoucher }
            const updatedVoucherBookings = [...voucherBookings]

            updatedVoucherBookings.push({
              ...data.booking,
              costItems: data.costItems
            })

            updatedVoucher.bookingDocuments = [
              ...selectedVoucher.bookingDocuments,
              ...selectableDocuments
            ]

            updateVoucherBookings(updatedVoucherBookings)
            updateSelectedVoucher(updatedVoucher)
          }
        } catch (error) {
          handleResp(error, 'error')
        }
      }
    }

    return (
      <WrappedComponent
        {...props}
        handleSelect={handleSelect}
        selectedGlobalCompany={selectedGlobalCompany}
      />
    )
  }

  return compose(
    connect(
      state => ({
        costsheetBookings: state.voucher.costsheetBookings,
        selectedVoucher: state.voucher.selectedVoucher,
        voucherBookings: state.voucher.voucherBookings,
        linkableBookings: state.voucher.linkableBookings,
        voucherBookingsLoading: state.voucher.voucherBookingsLoading,
        selectedVoucherBooking: state.voucher.selectedVoucherBooking,
        selectedVoucherTemplate: state.voucher.selectedVoucherTemplate
      }),
      // @ts-expect-error redux weak types
      dispatch => ({ dispatch, ...bindActionCreators({ ...voucherActions }, dispatch) })
    )
  )(WithVoucherBookings)
}
