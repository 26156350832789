import type { User } from '@/types/graphql'

import { PlusOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Popover, Row, Select, Tooltip } from 'antd-v5'
import isEmpty from 'lodash/isEmpty'

import * as SelectWrapper from '@/components/Booking/Assignee/SelectWrapper'
import { AvatarSelector, OptionLabel, OptionSubLabel } from '@/components/Booking/Assignee/Styled'

interface SingleSelectProps {
  options: User[]
  selectedUser: User | undefined
  handleSelect: (value: string | null) => void
}

const SingleSelect = ({ options, selectedUser, handleSelect }: SingleSelectProps) => {
  const filterUsers = (input: string, { props }: any) => {
    return (
      props.user?.email?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      props.user?.nickname?.toLowerCase().indexOf(input.toLowerCase()) >= 0
    )
  }

  const Selector = (
    <SelectWrapper.Wrapper>
      <SelectWrapper.Title>Assign to</SelectWrapper.Title>
      <Select
        allowClear
        mode="multiple"
        suffixIcon={null}
        onSelect={handleSelect}
        filterOption={filterUsers}
        optionLabelProp="nickname"
        placeholder="Select an assignee"
        defaultValue={selectedUser?.nickname}
      >
        {options.map(option => (
          <Select.Option
            user={option}
            key={option.uuid}
            value={option.uuid}
            nickname={option.nickname}
          >
            <Row justify="space-around" align="middle">
              <Col span={5}>
                <Avatar src={option.picture ?? ''} />
              </Col>
              <Col span={19}>
                <OptionLabel>{option.nickname}</OptionLabel>
                <OptionSubLabel>{option.email}</OptionSubLabel>
              </Col>
            </Row>
          </Select.Option>
        ))}
      </Select>
    </SelectWrapper.Wrapper>
  )

  return (
    <Popover arrow={false} trigger="click" content={Selector} placement="bottomRight">
      {isEmpty(selectedUser) ? (
        <Button icon={<PlusOutlined />} type="dashed" shape="circle" />
      ) : (
        <Tooltip title={selectedUser?.email}>
          <AvatarSelector>
            <Avatar src={selectedUser?.picture ?? ''} />
          </AvatarSelector>
        </Tooltip>
      )}
    </Popover>
  )
}

export default SingleSelect
