import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd-v5'
import { compose, withStateHandlers } from 'recompose'

import AddCostItemForm from '@/components/Voucher/CostItems/AddCostItemForm'
import { CostItemModal } from '@/components/Voucher/CostItems/Styled'

const stateHandlers = withStateHandlers(
  {
    visible: false
  },
  {
    handleClick: () => () => ({
      visible: true
    }),
    handleOnVisibleChange: () => visible => ({
      visible
    }),
    handleCostItemAdded: (state, props) => (costItem, bookingUuid) => {
      const { onCostItemAdded = () => {} } = props

      onCostItemAdded(costItem, bookingUuid)

      return {
        visible: false
      }
    }
  }
)

const enhance = compose(stateHandlers)

const AddCostItemAction = props => {
  const { bookingUuid, visible, handleClick, handleOnVisibleChange, handleCostItemAdded } = props

  return (
    <>
      <Button
        id="invoice-add-new-cost-item-button"
        icon={<PlusOutlined />}
        shape="circle"
        onClick={handleClick}
      />
      <CostItemModal
        width={600}
        footer={null}
        destroyOnClose
        visible={visible}
        title="Add new cost item"
        onCancel={() => handleOnVisibleChange(false)}
      >
        <AddCostItemForm bookingUuid={bookingUuid} onCostItemAdded={handleCostItemAdded} />
      </CostItemModal>
    </>
  )
}

export default enhance(AddCostItemAction)
