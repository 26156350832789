import type { PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'

const VisibilityDiv = styled.div<PropsWithChildren<{ visible?: boolean }>>`
  ${props =>
    props.visible === false &&
    css`
      display: none;
    `};
`

export default VisibilityDiv
