import type { PropsWithChildren } from 'react'

import { memo, useCallback, useEffect } from 'react'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd-v5'
import cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import DuplicateButton from './DuplicateButton'
import JobForm from './JobForm'

const StyledJobDiv = styled.div<PropsWithChildren<unknown>>`
  margin-bottom: 16px;

  .ant-divider {
    margin: 0;
  }
`
const StyledButtonContainer = styled.div`
  gap: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const JobsForm = props => {
  const { value, onChange = () => {} } = props

  const handleAddNew = useCallback(() => {
    const newValue = [
      ...value,
      {
        details: {},
        key: uuidv4(),
        trips: [{ companyUuid: undefined, addressUuid: undefined, key: uuidv4() }]
      }
    ]
    onChange(newValue)
  }, [onChange, value])

  const handleDuplicate = useCallback(
    (index, numberOfDuplicate = 1) => {
      const duplicate = {
        key: uuidv4(),
        no: value[index].no,
        type: value[index].type,
        remarks: value[index].remarks,
        trips: cloneDeep(value[index].trips),
        details: cloneDeep(value[index].details),
        tripFormat: value[index].tripFormat
      }

      let newValue = [...value]

      for (let i = 0; i < numberOfDuplicate; i++) {
        newValue = [...newValue, duplicate]
      }

      onChange(newValue)
    },
    [onChange, value]
  )

  const handleRemove = useCallback(
    index => {
      if (value.length === 1) {
        return
      }
      const removeJob = value.filter((j, jobIndex) => jobIndex !== index)
      onChange(removeJob)
    },
    [onChange, value]
  )

  useEffect(() => {
    const withPoints = value?.map(v => {
      return {
        ...v,
        trips: v.trips?.length
          ? v.trips
          : [{ companyUuid: undefined, addressUuid: undefined, key: uuidv4() }]
      }
    })
    onChange(withPoints)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange])

  return (
    <>
      <Divider>{`Total Jobs : ${value?.length}`}</Divider>

      {value?.map((val, index, valArray) => {
        if (val.details) delete val.details.__typename
        val.key = uuidv4()

        return (
          <StyledJobDiv key={val.key || uuidv4()}>
            <Divider>{`Job #${index + 1}`}</Divider>

            <JobForm
              key={val.key}
              currentJob={val}
              jobIndex={index}
              allJobs={valArray}
              isNewBooking
            />

            <StyledButtonContainer>
              <span style={{ fontSize: '0.8em', opacity: 0.6 }}>Job:</span>

              <DuplicateButton handleDuplicate={handleDuplicate} index={index} />

              {index === value.length - 1 && (
                <Button icon={<PlusOutlined />} onClick={handleAddNew} />
              )}

              {value.length !== 1 && (
                <Button
                  type="dashed"
                  icon={<MinusOutlined />}
                  onClick={() => handleRemove(index)}
                />
              )}
            </StyledButtonContainer>
          </StyledJobDiv>
        )
      })}
    </>
  )
}

export default memo(JobsForm)
