import type { Moment } from 'moment'

import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BackwardOutlined, ForwardOutlined } from '@ant-design/icons'
import { Button, DatePicker, Popover } from 'antd'
import moment from 'moment'

import ReactTable from 'App/components/Transport/Components/Shared/ReactTable'
import { FilterConfidential } from 'App/components/Transport/Dashboard/Components/MTDTable'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { useOverviewDashboardStore } from 'App/store/overviewDashboard'
import { getDailyColsData } from '../helpers/daily'
import { getTableRows } from '../helpers/getTableRows'

const { WeekPicker } = DatePicker

const styleTableCells = (options: any) => {
  const { cell } = options
  let style = { ...cell.column.style }

  if (cell.value?.startsWith && /^-\d/.test(cell.value)) {
    style = { ...style, color: 'red' }
  }

  return style
}

const styleTableHeaders = () => {
  return { textAlign: 'right', paddingLeft: '10px', minWidth: '100px' }
}

const DailyTable = ({ data, query, settings, hasConfidentialPermission }: any) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const [endDate, setEndDate] = useState(query.endDate || moment())
  const { t } = useTranslation()
  const isShowDifference = useOverviewDashboardStore.use.isShowDifference()
  const setIsShowDifference = useOverviewDashboardStore.use.setIsShowDifference()

  const { tableColumns, tableData } = getDailyColsData({
    reportJobSummaries: data?.reportJobSummaries?.rows,
    queryInput: { ...query, endDate },
    selectedGlobalCompany
  })
  let resultData: any = tableData
  resultData = getTableRows(settings, tableData)

  const handleWeekChange = (date: Moment | null) => {
    date && setEndDate(date?.add(6, 'days'))
  }

  resultData = FilterConfidential(resultData, hasConfidentialPermission)

  return (
    <>
      <span style={{ display: 'flex', justifyContent: 'space-between', marginRight: '100px' }}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Popover content={t('common.backOneWeek')}>
            <BackwardOutlined
              style={{ fontSize: '20px' }}
              onClick={() => endDate && setEndDate(endDate.clone().subtract(7, 'days'))}
            />
          </Popover>
          <WeekPicker value={endDate} onChange={handleWeekChange} placeholder="Select week" />
          <Popover content={t('common.forwardOneWeek')}>
            <ForwardOutlined
              style={{ fontSize: '20px' }}
              onClick={() => endDate && setEndDate(endDate.clone().add(7, 'days'))}
            />
          </Popover>
        </div>
        <Button onClick={() => setIsShowDifference(!isShowDifference)}>
          {isShowDifference ? t('common.hideDifference') : t('common.showDifference')}
        </Button>
      </span>
      <ReactTable
        tableColumns={tableColumns}
        tableData={resultData}
        styleTableCells={styleTableCells}
        styleTableHeaders={styleTableHeaders}
      />
    </>
  )
}

export default memo(DailyTable)
