import { useState } from 'react'
import { EditOutlined } from '@ant-design/icons'

import DocumentCreatorModal from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal'
import { StyledButton } from '@/components/ButtonWrapper'

interface EditActionProps {
  generatedDocumentUuid: string
  refetch: (args?: any) => void
}

const EditAction = (props: EditActionProps) => {
  const { generatedDocumentUuid, refetch } = props

  const [visible, setVisible] = useState(false)

  const handleAfterClose = () => {
    setVisible(false)
    refetch()
  }

  return (
    <>
      <StyledButton onClick={() => setVisible(true)}>
        <EditOutlined />
      </StyledButton>
      {visible && (
        <DocumentCreatorModal
          generatedDocumentUuid={generatedDocumentUuid}
          afterClose={handleAfterClose}
        />
      )}
    </>
  )
}

export default EditAction
