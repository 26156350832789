import { memo, useMemo } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { Select } from 'antd'

import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import { logger } from 'App/utils/logger'
import webStorage from 'App/utils/webStorage'
import viewerGql from './viewerGql'

const viewerGraphql = graphql(viewerGql, {
  name: 'viewerGraphql',
  options: () => ({
    variables: {
      auth0UserId: webStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE)?.sub
    }
  })
})

const Option = Select.Option

const ViewerBaseCompanySelect = memo(props => {
  const {
    viewerGraphql,
    style = {},
    value,
    onChange,
    allowClear,
    mode = 'multiple',
    placeholder = 'Select a base company...'
  } = props

  const combinedStyle = useMemo(
    () => ({
      width: '100%',
      ...style
    }),
    [style]
  )

  if (!viewerGraphql || viewerGraphql.loading) {
    return <Select loading style={combinedStyle} />
  }

  const { error, viewer } = viewerGraphql

  if (error) {
    logger.error('ViewerBaseCompanySelect Error.', error)
    return (
      <Select disabled placeholder="Error while loading. Please try again." style={combinedStyle} />
    )
  }

  return (
    <Select
      allowClear={allowClear}
      mode={mode}
      placeholder={placeholder}
      style={combinedStyle}
      value={value}
      onChange={onChange}
      notFoundContent="Not found."
    >
      {viewer?.baseCompanies?.map(baseCompany => {
        return (
          <Option key={baseCompany.uuid} value={baseCompany.uuid}>
            {baseCompany.name}
          </Option>
        )
      })}
    </Select>
  )
})

export default viewerGraphql(ViewerBaseCompanySelect)
