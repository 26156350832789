export const getDataGql = `
  query employeesExport1 ($query: String, $limit: Int, $offset: Int, $companyUuids: [UUID], $roles: [String]) {
    employees (query: $query, limit: $limit, offset: $offset, companyUuids: $companyUuids, roles: $roles) {
      rows {
        user {
          email
          name
        }
        driver {
          code
        }
        roles
        status
        uuid
      }
    }
  }
`

export const createEntityGql = `
  mutation employeesImport1 ($uuid: UUID!, $users: [InviteUser]!) {
    inviteToCompany (uuid: $uuid, users: $users) {
      email
      roles
      user {
        uuid
      }
      errorMessage
    }
  }
`

export const updateEmployeeGql = `
  mutation changeEmployeeRole(
    $companyUuid: UUID!
    $userUuid: UUID
    $employeeUuid: UUID
    $roles: [String]!
    $name: String!
    $email: String
    $driverCode: String
  ) {
    changeEmployeeRole(companyUuid: $companyUuid, userUuid: $userUuid, roles: $roles, name: $name, employeeUuid: $employeeUuid, email: $email, driverCode: $driverCode) {
      uuid
    }
  }
`
