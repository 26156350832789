import { useTranslation } from 'react-i18next'

import config from '@/config'
import useGlobalCompanyStore from '@/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import webStorage from '@/utils/webStorage'

const EntitySystemLink = () => {
  const { t } = useTranslation()
  const token = webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const url = `${config.entity.appUrl}/access#token=${token}&baseCompanyUuid=${selectedGlobalCompany.uuid}`

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'rgb(120,120,120)', textDecoration: 'none' }}
    >
      {t('header.entitySystem')}
    </a>
  )
}

export default EntitySystemLink
