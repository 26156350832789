import type { TemplateVariableProps } from '@/hooks/useDocumentTemplates'
import type { DocumentCreatorTemplate, Job } from '@/types/graphql'
import type { DropdownProps, MenuProps } from 'antd-v5'

import { PrinterOutlined } from '@ant-design/icons'
import { Dropdown, Typography } from 'antd-v5'

import { openPrintableInNewTab } from '@/components/Shared/documentCreator/openPrintableInNewTab'
import { useDocumentTemplates } from '@/hooks/useDocumentTemplates'
import useGlobalCompanyStore from '@/store/globalCompany'

type DocumentCreatorPrinterProps = DropdownProps & {
  job: Job
  variables: TemplateVariableProps
  alwaysShowIcon?: boolean
}

const DocumentCreatorPrinter = (props: DocumentCreatorPrinterProps) => {
  const { job, variables, alwaysShowIcon = false, placement = 'bottom' } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const { templates } = useDocumentTemplates(variables)

  const dropdownItems: MenuProps['items'] = templates?.map((t: DocumentCreatorTemplate) => ({
    key: `viewDoc-${t.uuid}`,
    label: (
      <>
        <PrinterOutlined style={{ fontSize: 16, color: '#858585' }} />
        <Typography.Text style={{ fontSize: 12 }}>{t.type}</Typography.Text>
      </>
    ),
    onClick: () => openPrintableInNewTab(job?.uuid, selectedGlobalCompany?.uuid, t.type || '')
  }))

  return (
    <>
      {dropdownItems?.length || alwaysShowIcon ? (
        <Dropdown placement={placement} menu={{ items: dropdownItems }}>
          <PrinterOutlined
            style={{ padding: 8, cursor: 'pointer', color: '#858585', fontSize: 18 }}
          />
        </Dropdown>
      ) : (
        <></>
      )}
    </>
  )
}

export default DocumentCreatorPrinter
