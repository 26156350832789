import { memo } from 'react'
import { Button } from 'antd'
import { compose, withHandlers } from 'recompose'

import changeConsolVoucherStatus from 'App/containers/consolVoucher/mutation/changeConsolVoucherStatus'
import { logger } from 'App/utils/logger'
import handleResponse from 'App/utils/responseHandler'

const handlers = withHandlers({
  changeStatus: props => async () => {
    const { status, changeConsolVoucherStatus, consolVoucher, onSuccess } = props
    try {
      await changeConsolVoucherStatus(consolVoucher.uuid, status)

      if (onSuccess) {
        handleResponse('Successfully updated status.', 'success')
        onSuccess()
      }
    } catch (error) {
      logger.error('changeConsolVoucherStatus Error.', error)
      handleResponse(error, 'error')
    }
  }
})

const enhance = compose(changeConsolVoucherStatus, handlers)

const statusText = {
  CANCELLED: 'Delete',
  SUBMITTED: 'Submit for Approval',
  APPROVED: 'Approve',
  VOID: 'Void'
}

const ConsolVoucherChangeStatusButton = memo(props => {
  const { status, buttonProps, changeStatus } = props

  return (
    <Button {...buttonProps} onClick={changeStatus}>
      {statusText[status]}
    </Button>
  )
})

export default enhance(ConsolVoucherChangeStatusButton)
