export const getDataGql = `
  query transportJobTypesExport1 ($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    transportJobTypes (limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
  }
`

export const createEntityGql = `
  mutation createTransportJobTypeExport1 ($input: CreateTransportJobTypeInput!) {
    createTransportJobType (input: $input) {
      code
      uuid
      name
      description
      sorting
      status
    }
  }
`

export const updateEntityGql = `
    mutation updateTransportJobTypeExport1 ($input: UpdateTransportJobTypeInput!) {
      updateTransportJobType (input: $input) {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
`
