import { logger } from 'App/utils/logger'
import { findGqlError, getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

type MutationResultsType = {
  update?: any
  create?: any
}

const sampleData = [
  {
    no: 1,
    code: 'E97025-110',
    description: 'test',
    sequence: 1,
    type: 'department',
    uuid: '',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks = '*Note: The required fields are: code, type, and description. '

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Type*',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Description*',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Sequence',
    dataIndex: 'sequence',
    key: 'sequence'
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    ellipsis: true,
    key: 'uuid'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

// cache to hold queried data, to check against data for import
const cache: any = {
  uuid: {},
  code: {}
}

const findBadInputs = (chargeCategory: any) => {
  if (!chargeCategory.code) {
    return { nullError: 'Charge category code is required, e.g. E97025-110.' }
  }
  if (!chargeCategory.type) {
    return { nullError: 'Charge category type is required, e.g. DEPARTMENT.' }
  }
  if (!chargeCategory.description) {
    return { nullError: 'Charge category description is required, e.g. CS - CURTAIN SIDER.' }
  }
}

const populateFields = (obj: any, uuid: string, isUpdate: boolean) => {
  const fields: any = {
    type: obj?.type,
    code: obj?.code?.toString()?.trim(),
    sequence: obj?.sequence?.toString(),
    description: obj?.description?.toString()
  }

  if (isUpdate) {
    fields.uuid = uuid?.trim()
  }

  return fields
}

const chargeCategoriesHelper = {
  queryName: 'chargeCategories',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const response = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      // add zzz, otherwise there will be a "uuid " key instead of uuid
      const readyForImport = response?.data?.chargeCategories?.rows?.map((d: any, i: number) => ({
        no: i + 1,
        ...d,
        zzz: ''
      }))
      return readyForImport
    } catch (error) {
      logger.error('chargeCategoriesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (inputData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...chargeCategory } = inputData

    const nullInputError = findBadInputs(chargeCategory)
    if (nullInputError) {
      return nullInputError
    }

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const res = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
        const queriedData = res?.data?.chargeCategories?.rows

        for (let i = 0; i < queriedData?.length; i++) {
          cache.uuid[queriedData[i]?.uuid] = queriedData[i]
          cache.code[queriedData[i]?.code] = queriedData[i]?.uuid
        }
      } catch (error) {
        logger.error('chargeCategoriesHelper cache getDataGql error', error)
        return error
      }
    }

    // Check if already exists by uuid or code
    let existingUuid: string = ''
    if (cache.uuid[chargeCategory.uuid]) {
      existingUuid = chargeCategory.uuid
    } else if (cache.code[chargeCategory?.code]) {
      existingUuid = cache.code[chargeCategory.code]
    }

    const mutationResults: MutationResultsType = {}
    // If exists, then update
    if (existingUuid?.length > 0) {
      try {
        const toUpdate = populateFields(chargeCategory, existingUuid, true)
        mutationResults.update = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: toUpdate
        })
      } catch (error) {
        logger.error('chargeCategoriesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create new
      try {
        const toCreate = populateFields(chargeCategory, existingUuid, false)
        mutationResults.create = await getGqlResponse(
          selectedGlobalCompany,
          createEntityGql,
          toCreate
        )
      } catch (error) {
        logger.error('chargeCategoriesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    const errorObj = findGqlError(mutationResults)
    if (errorObj) {
      return errorObj
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default chargeCategoriesHelper
