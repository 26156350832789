import type { Job } from '@/types/graphql'

import { useState } from 'react'
import { RedoOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd-v5'

import RolloverModal from '@/components/Workflow/RolloverAction/RolloverModal'

type RolloverActionProps = {
  job: Job
}

const RolloverAction = ({ job }: RolloverActionProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const onActionClick = () => setIsModalVisible(true)
  const onModalClose = () => setIsModalVisible(false)

  return (
    <>
      <Tooltip title="Rollover">
        <RedoOutlined className="cursor-pointer ml-4" onClick={onActionClick} />
      </Tooltip>

      {isModalVisible && <RolloverModal job={job} onClose={onModalClose} />}
    </>
  )
}

export default RolloverAction
