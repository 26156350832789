import type { PropsWithChildren } from 'react'

import { Row } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 30px;
`

export const StyledSelector = styled.div`
  font-size: 13.5px;
  font-weight: 400;
  color: #1b2733;
  padding: 10px 0;

  .anticon {
    font-size: 18px;
    margin-right: 10px;
    vertical-align: -0.225em;
  }

  .anticon-clock-circle {
    color: rgb(180, 180, 180);
  }

  .anticon-check-circle {
    color: #52c41a;
  }

  .anticon-down,
  .anticon-up {
    font-size: 14px;
    color: rgb(140, 140, 140);
  }
`

export const ExpandableRow = styled(Row)`
  cursor: pointer;
`

export const TypeName = styled.div`
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const HiddenFields = styled.div<PropsWithChildren<{ border?: boolean }>>`
  padding: 10px 0 0;
  ${props => props.border && 'border-bottom: 1px solid rgb(220, 220, 220);'}

  .ant-btn {
    height: 28px;
    font-size: 13.222px;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
  }
`

export const HiddenActions = styled.div`
  text-align: center;
  line-height: 30px;
`
