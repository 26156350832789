export const getDataGql = `
  query containerGradesExport1 ($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    containerGrades (limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
  }
`

export const createEntityGql = `
  mutation createContainerGradeExport1 ($input: CreateContainerGradeInput!) {
    createContainerGrade (input: $input) {
      code
      uuid
      name
      description
      sorting
      status
    }
  }
`

export const updateEntityGql = `
    mutation updateContainerGradeExport1 ($input: UpdateContainerGradeInput!) {
      updateContainerGrade (input: $input) {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
`
