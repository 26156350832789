import { CheckCircleOutlined } from '@ant-design/icons'
import { Icon } from 'antd'

const documentStates = {
  VERIFIED: 'check',
  UPLOADED: 'warning'
}

const StatusIcon = props => {
  const { uploadedDoc } = props
  const iconName = documentStates[uploadedDoc.status]

  if (uploadedDoc.approvals && uploadedDoc.approvals.length > 0) {
    return (
      <>
        {uploadedDoc.approvals.map(a => (
          <CheckCircleOutlined key={a.uuid} />
        ))}
      </>
    )
  }

  return <Icon type={iconName} />
}

export default StatusIcon
