import { memo } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row } from 'antd'
import debounce from 'lodash/debounce'

import { ActionWrapper } from 'App/components/Manage/Styled'
import ImportExport from 'App/components/Shared/ImportExport'

type TableViewActionsProps = {
  refetch: () => void
  setSearchKeyword: (value: string) => void
  exportSelectedEntity: string
  createUrl: string
}

const TableViewActions = memo((props: TableViewActionsProps) => {
  const { refetch, setSearchKeyword, exportSelectedEntity, createUrl } = props

  const handleInput = debounce((value: string) => {
    setSearchKeyword(value)
    refetch()
  }, 500)

  return (
    <ActionWrapper>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={15}>
          <Input
            allowClear
            prefix={<SearchOutlined />}
            placeholder="Search something here..."
            onChange={e => handleInput(e.target.value)}
          />
        </Col>
        <Col span={6}>
          <ImportExport selectedEntity={exportSelectedEntity} />
        </Col>
        <Col span={3}>
          <Button icon="plus" href={createUrl}>
            Create
          </Button>
        </Col>
      </Row>
    </ActionWrapper>
  )
})

export default TableViewActions
