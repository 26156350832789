import { useEffect } from 'react'
import { Modal } from 'antd-v5'

import config from '@/config'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'
import pJson from '../../../../package.json'

const clearCacheModal = (latestVersion, currentVersion) => {
  console.table({ latestVersion, currentVersion, packageVersion: pJson.version })

  return Modal.info({
    title: `New Version Available: ${latestVersion}`,
    content: `A new version of the application is available. The browser will refresh to update from version ${currentVersion} to ${latestVersion}. Any unsaved changes will be lost.`,
    onOk: function () {
      if (caches) {
        caches.keys().then(function (names) {
          for (const name of names) caches.delete(name)
        })
      }
      window.location.reload()
    }
  })
}

const errorModal = error => {
  Modal.info({
    title: 'An error Occured',
    content: `${error}`
  })
}

const semverGreaterThan = (latestVersion, currentVersion, noUpdates) => {
  if (!latestVersion || !currentVersion) return

  const latestVArr = latestVersion.replace('-rc', '').split('.')
  const currentVArr = currentVersion?.replace('-rc', '').split('.')
  if (!latestVArr || !currentVArr) {
    return
  }

  for (let i = 0; i < latestVArr.length; i++) {
    if (!!currentVArr[i] && latestVArr[i] > currentVArr[i]) {
      return clearCacheModal(latestVersion, currentVersion)
    }
  }

  if (noUpdates) {
    noUpdates()
  }

  console.log('App is up-to-date')
}

export const handleCheckUpdate = (initState, noUpdates, revertState) => {
  if (initState) initState()
  fetch('/meta.json?foo=bar')
    .then(response => response.json())
    .then(meta => {
      const latestVersion = meta.version
      const currentVersion = config.version
      semverGreaterThan(latestVersion, currentVersion, noUpdates)
    })
    .then(() => {
      if (revertState) {
        setTimeout(() => {
          revertState()
        }, 5000)
      }
    })
    .catch(error => {
      errorModal(error.message)
      if (revertState) revertState()
    })
}

export default function CacheBuster({ children }) {
  useEffect(() => {
    fetch('/meta.json?foo=bar')
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version
        const currentVersion = config.version
        semverGreaterThan(latestVersion, currentVersion, null)
      })
      .catch(error => {
        respHandler(error, 'error')
        logger.error('Error occured while checking for updates', error)
      })
  }, [])

  return children
}
