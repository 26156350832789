import { TransactionType } from '@/types/graphql'

import { DollarOutlined, FileOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd-v5'
import startCase from 'lodash/startCase'

import { Selector, SelectorText } from '@/components/Workflow/Voucher/TypeSelections/Styled'

const VoucherTypeSelector = ({ text, handleClick, voucherTransaction }) => {
  return (
    <Selector id={`voucher-option-${voucherTransaction}`} onClick={handleClick}>
      <Row>
        <Col span={3}>
          {voucherTransaction === TransactionType.Accpay ? <FileOutlined /> : <DollarOutlined />}
        </Col>
        <Col span={21}>
          <SelectorText>{startCase(text)}</SelectorText>
        </Col>
      </Row>
    </Selector>
  )
}

export default VoucherTypeSelector
