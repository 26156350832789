import React, { useEffect, useState } from 'react'
import { omit } from 'lodash'

type DynamicComponentHookProps = {
  componentName: string
}

export const useDynamicComponent = ({ componentName }: DynamicComponentHookProps) => {
  const [Component, setComponent] = useState<React.ComponentType | null>(null)
  const [variables, setVariables] = useState<Record<string, unknown>>({})
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    let isMounted = true

    const loadComponent = async () => {
      try {
        const module = await import(`@/plugins/${componentName}`)

        if (isMounted) {
          setComponent(() => module?.default)
          setVariables(omit(module, 'default'))
        }
      } catch (error) {
        if (isMounted) {
          setError(`Component ${componentName} not found`)
          console.error(error)
        }
      }
    }

    if (componentName) {
      loadComponent()
    }

    return () => {
      isMounted = false
    }
  }, [componentName])

  return { Component, error, variables }
}
