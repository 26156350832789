import { connect } from 'react-redux'
import { Form } from 'antd'
import { bindActionCreators, compose } from 'redux'

import { withBooking } from '@/contexts/booking'
import * as voucherActions from '@/states/reducers/voucher'

export default WrappedComponent => {
  const WithVoucherItems = props => <WrappedComponent {...props} />

  return compose(
    withBooking,
    Form.create(),
    connect(
      state => ({
        voucherBookings: state.voucher.voucherBookings,
        selectedVoucher: state.voucher.selectedVoucher,
        selectableDocuments: state.voucher.selectableDocuments,
        showDeletedVoucherItems: state.voucher.showDeletedVoucherItems
      }),
      // @ts-expect-error redux weak types
      dispatch => ({ dispatch, ...bindActionCreators({ ...voucherActions }, dispatch) })
    )
  )(WithVoucherItems)
}
