import { useCallback } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'

import { QUERY_REQUISITION_TEMPLATE, SEND_REQUISITION } from '@/graphql/template'
import handleResponse from '@/utils/responseHandler'

export const useRequisition = props => {
  const { booking, refetch } = props || {}
  const client = useApolloClient()

  const [sendRequisitionMutation] = useMutation(SEND_REQUISITION)

  const handleSendRequisition = useCallback(
    async params => {
      if (params) {
        try {
          handleResponse('Sending request, hang on...', 'load')

          await sendRequisitionMutation({
            variables: { input: params }
          })

          if (refetch && booking?.uuid) {
            await refetch({ uuid: booking.uuid })
          }

          handleResponse('Sent successfully.', 'success')
        } catch (error) {
          handleResponse(error, 'error')
        }
      }
    },
    [booking?.uuid, refetch, sendRequisitionMutation]
  )

  const getTemplate = useCallback(
    async type => {
      if (booking && type) {
        const resp = await client.query({
          query: QUERY_REQUISITION_TEMPLATE,
          fetchPolicy: 'network-only',
          variables: {
            uuid: booking.uuid,
            bookingType: booking.type,
            type
          }
        })

        return resp
      } else {
        console.log('booking or type is missing')
      }
    },
    [client, booking]
  )

  return {
    getTemplate,
    handleSendRequisition
  }
}
