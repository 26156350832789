import { gql } from '@apollo/client'

export const REPORT_JOB_SUMMARIES_QUERY = gql`
  query reportJobSummariesMTD($input: ReportJobSummariesQueryInput) {
    reportJobSummaries(input: $input) {
      rows {
        _id
        date
        bcUuid
        jobUuids
        depts {
          dept
          revenue
          sell
          cost
          trips
          teu
        }
        zones {
          from
          to
          revenue
          sell
          cost
          trips
          teu
        }
        pmUtil {
          dept
          c
          tot
        }
        drUtil {
          dept
          c
          tot
        }
        metrics
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`
