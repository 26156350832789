import { Component } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd-v5'

import withCreatePayment from './Container'
import EditableFields from './EditableFields'

class CreateVoucherPayment extends Component {
  render() {
    const {
      handleView,
      showPayment,
      handleCancel,
      createPayment,
      selectedPayment,
      togglePaymentView
    } = this.props

    return (
      <Popover
        arrow={false}
        title="Create Payment"
        trigger="click"
        open={showPayment}
        placement="right"
        content={
          <EditableFields
            onCancel={handleCancel}
            createPayment={createPayment}
            selectedPayment={selectedPayment}
            togglePaymentView={togglePaymentView}
          />
        }
        styles={{ root: { width: 400 } }}
        onOpenChange={visible => togglePaymentView(visible)}
        getPopupContainer={trigger => trigger.parentNode}
      >
        <Button shape="circle" icon={<PlusOutlined />} onClick={handleView} />
      </Popover>
    )
  }
}

export default withCreatePayment(CreateVoucherPayment)
