import { Outlet } from 'react-router-dom'

import { Body, Content, View } from '@/components/Layouts/SuperAdminLayout/Styled'
import SuperAdminMenu from '@/components/Layouts/SuperAdminLayout/SuperAdminMenu'

const SuperAdminLayout = () => {
  return (
    <>
      <Body>
        <View>
          <SuperAdminMenu />
          <Content>
            <Outlet />
          </Content>
        </View>
      </Body>
    </>
  )
}

export default SuperAdminLayout
