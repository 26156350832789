import { Component } from 'react'
import { Col, Select } from 'antd'
import PropTypes from 'prop-types'

import { BorderedRow, TypeText } from './Styled'

const Option = Select.Option

class Receiver extends Component {
  render() {
    const { receivers = [], value = [], type } = this.props

    return (
      <BorderedRow>
        <Col span={2}>
          <TypeText>{type} : </TypeText>
        </Col>
        <Col span={22}>
          <Select mode="tags" onChange={this.props.onChange} value={value}>
            {receivers.map(receiver => {
              return <Option key={receiver}>{receiver}</Option>
            })}
          </Select>
        </Col>
      </BorderedRow>
    )
  }
}

Receiver.propTypes = {
  type: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  receivers: PropTypes.array
}

export default Receiver
