import { gql } from '@apollo/client'

export default gql`
  query consolVouchersSlim1($input: searchConsolVouchersInput, $limit: Int, $offset: Int) {
    consolVouchers(input: $input, limit: $limit, offset: $offset) {
      rows {
        uuid
        reference
        type
        issueDate
        status
        vouchers {
          uuid
          type
          invoiceNumber
          voucherNumber
          subTotal
          taxTotal
          total
          address {
            uuid
            name
            type
            address1
            address2
            address3
            address4
            city
            district
            postCode
            phone
            fax
            status
            countryAlpha3
          }
          transactionType
          vendor {
            name
            creditorCode
          }
          customer {
            name
            debtorCode
          }
          issueDate
        }
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`
