import { Component } from 'react'
import { Button, Checkbox, Col } from 'antd'

import { statuses } from 'App/utils/company'
import { Actions, CheckboxItem, FilterWrapper } from './Styled'

const CheckboxGroup = Checkbox.Group

class TypesFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedStatuses: props.filters && props.filters.statuses
    }
  }

  handleSelect = checkedValues => {
    this.setState({
      selectedStatuses: checkedValues
    })
  }

  handleReset = () => {
    this.setState({
      selectedStatuses: ['activated']
    })

    const updatedFilters = {
      ...this.props.filters,
      statuses: ['activated']
    }

    this.props.updateFilters(updatedFilters)
  }

  handleApply = () => {
    const updatedFilters = {
      ...this.props.filters,
      statuses: this.state.selectedStatuses
    }

    this.props.updateFilters(updatedFilters)
  }

  render() {
    const { selectedStatuses } = this.state

    return (
      <FilterWrapper>
        <CheckboxGroup value={selectedStatuses} onChange={this.handleSelect}>
          {statuses.map(status => (
            <CheckboxItem key={status.value}>
              <Checkbox value={status.value}>{status.label}</Checkbox>
            </CheckboxItem>
          ))}
        </CheckboxGroup>
        <Actions gutter={8}>
          <Col span={12}>
            <Button type="primary" onClick={this.handleApply}>
              Apply
            </Button>
          </Col>
          <Col span={12}>
            <Button onClick={this.handleReset}>Reset</Button>
          </Col>
        </Actions>
      </FilterWrapper>
    )
  }
}

export default TypesFilter
