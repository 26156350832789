import type { User } from '@/types/graphql'

import withAssignee from '@/components/Booking/Assignee/Container'
import SingleSelect from '@/components/Booking/Assignee/SingleSelect'
import { Wrapper } from '@/components/Booking/Assignee/Styled'

interface AssigneeProps {
  usersQuery?: any
  handleSelect: (user: string | null) => void
  selectedUser?: User
}

const Assignee = ({ usersQuery, handleSelect, selectedUser }: AssigneeProps) => {
  const options = usersQuery?.users?.rows || []

  return (
    <Wrapper>
      <SingleSelect options={options} handleSelect={handleSelect} selectedUser={selectedUser} />
    </Wrapper>
  )
}

export default withAssignee(Assignee)
