import { CloseOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'

import HeaderOptions from '@/components/DocumentViewer/Header/Options'
import {
  Actions,
  DocumentDetails,
  DocumentName,
  DocumentType,
  StyledHeader
} from '@/components/DocumentViewer/Header/Styled'
import { documentType } from '@/utils/labelMap'

const ViewerHeader = ({ bookingDocument, onBack, bookingQuery, refreshBooking }) => {
  const type = documentType[bookingDocument.type] || bookingDocument.type

  const docPOD = bookingQuery?.booking?.jobs.flatMap(job =>
    job.trips
      .filter(trip => trip.uuid === bookingDocument.tripUuid)
      .map(trip => {
        const jobNoPart = job.jobNo.slice(0, job.jobNo.length)
        return [job.no ? job.no : jobNoPart, trip.sequence].filter(Boolean).join('-')
      })
  )

  return (
    <StyledHeader type="flex" align="middle">
      <Col span={14}>
        <Row type="flex" align="middle">
          <DocumentDetails>
            <DocumentName>
              {bookingDocument.document?.name} - {docPOD}
            </DocumentName>
            <DocumentType>{type}</DocumentType>
          </DocumentDetails>
        </Row>
      </Col>
      <Col span={10}>
        <Row type="flex" align="middle" justify="end">
          <Actions>
            <HeaderOptions
              bookingDocument={bookingDocument}
              toggleDocumentViewer={onBack}
              refreshBooking={refreshBooking}
            />
          </Actions>
          <Col>
            <CloseOutlined onClick={onBack} />
          </Col>
        </Row>
      </Col>
    </StyledHeader>
  )
}

export default ViewerHeader
