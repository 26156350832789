import { graphql } from '@apollo/client/react/hoc'

import CONSOL_VOUCHERS_QUERY from 'App/containers/consolVoucher/schema/consolVouchersQuery'

export default graphql(CONSOL_VOUCHERS_QUERY, {
  name: 'consolVouchersQuery',
  options: () => ({
    variables: {
      input: undefined
    }
  })
})
