import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'

import voucherQuerySchema from '@/containers/voucher/schema/voucherQuery'
import * as voucherActions from '@/states/reducers/voucher'

const enhance = compose(
  connect(
    state => ({
      visible: state.voucher.showVoucher
    }),
    dispatch => ({
      dispatch,
      // @ts-ignore
      ...bindActionCreators({ ...voucherActions }, dispatch)
    })
  )
)

const VoucherUrlLoader = props => {
  const { uuid } = useParams()
  const client = useApolloClient()

  useEffect(() => {
    const loadVoucher = async () => {
      const { updateSelectedVoucher, toggleVoucherView } = props
      const voucherUuid = uuid

      if (!voucherUuid) return

      try {
        const { data } = await client.query({
          query: voucherQuerySchema,
          variables: { uuid: voucherUuid }
        })

        updateSelectedVoucher(data.voucher)
        toggleVoucherView()
      } catch (error) {
        console.log('VoucherUrlLoader voucherQuerySchema error:', error)
      }
    }

    loadVoucher()

    // eslint-disable-next-line
  }, [])

  return null
}

export default enhance(VoucherUrlLoader)
