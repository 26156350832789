import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import createSelectors from 'App/store/createSelectors'

type OverviewDashboardState = {
  isShowDifference: any
  setIsShowDifference: (isShowDifference: boolean) => void
}

export const useOverviewDashboardStore = createSelectors(
  create<OverviewDashboardState>()(
    persist(
      set => ({
        isShowDifference: false,
        setIsShowDifference: (isShowDifference: boolean) => set({ isShowDifference })
      }),
      { name: 'overview-dashboard-storage' }
    )
  )
)
