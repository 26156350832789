import { DocumentStatus } from '@/types/graphql'

import { DollarOutlined, MailOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd-v5'
import startCase from 'lodash/startCase'

import Container, {
  DocDescription,
  DocFileName,
  DocHeader,
  DocName,
  DocUsage,
  LeftIcon
} from '@/components/Workflow/Document/Container'
import StatusIcons from '@/components/Workflow/Document/StatusIcons'
import { usePersistedBookingData } from '@/store/bookingData'
import { documentType } from '@/utils/labelMap'

const Document = props => {
  const { uploadedDoc, isHighlighted, documentApproval, vouchers = [], requests = [] } = props

  const { bookingData } = usePersistedBookingData()

  const isDeleted = uploadedDoc?.status === DocumentStatus.Deleted
  const isVerified = uploadedDoc?.status === DocumentStatus.Verified

  const docPOD = bookingData?.jobs?.flatMap(job =>
    job?.trips
      ?.filter(trip => trip.uuid === uploadedDoc.tripUuid)
      .map(trip => {
        const jobNoPart = job.jobNo.slice(10, job.jobNo.length)
        return [job.no ? job.no : jobNoPart, trip.sequence].filter(Boolean).join('-')
      })
  )

  return (
    <Container
      verified={isVerified}
      deleted={isDeleted}
      highlighted={isHighlighted}
      onClick={props.onClick}
    >
      <DocHeader>
        <Tooltip title={startCase(uploadedDoc.type)}>
          <DocName>{startCase(documentType[uploadedDoc.type] || uploadedDoc.type)}</DocName>
        </Tooltip>
        <DocUsage>
          {requests.length > 0 && (
            <Tooltip title="Attached to requests.">
              <MailOutlined />
            </Tooltip>
          )}
          {vouchers.length > 0 && (
            <Tooltip title="Attached to vouchers.">
              <DollarOutlined />
            </Tooltip>
          )}
        </DocUsage>
      </DocHeader>
      <DocDescription>
        <DocFileName>
          <LeftIcon type={isDeleted ? 'delete' : 'paper-clip'} />
          {uploadedDoc.document.name}
          <br />
          {docPOD}
        </DocFileName>
        <StatusIcons uploadedDoc={uploadedDoc} documentApproval={documentApproval || {}} />
      </DocDescription>
    </Container>
  )
}

export default Document
