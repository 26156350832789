import type { TripSummary } from 'App/types/graphql'

import { groupBy, pick, reduce } from 'lodash'
import moment from 'moment'

export const transform = (data: [any]) => {
  const summary: any = []

  data.forEach((e: any) => {
    const mtd = parseInt(moment().format('DD'))

    const ext = {
      zone: `${e.from}-${e.to}`,
      month: moment(new Date(e.date)).format('YY-MM'),
      year: moment(new Date(e.date)).format('YYYY'),
      mtd: !!(parseInt(moment(new Date(e.date)).format('DD')) <= mtd)
      // month: moment(new Date(e.date)).format('YY-MM'),
    }
    // Department
    summary.push({ ...e, ...ext })
  })

  return summary
}

export enum ScaleType {
  month = 'month',
  week = 'week',
  day = 'day',
  mtd = 'mtd'
}

export const getDateFormat = (type: string) => {
  return ['month', 'mtd'].includes(type) ? 'YYYY-MM' : 'YYYY-MM-DD'
}

export const getDateScale = (
  start: Date,
  end: Date,
  type: ScaleType = ScaleType.month,
  formatOverride = ''
): [string] => {
  const scale = []
  const dateFormat = formatOverride || getDateFormat(type)

  // @ts-ignore
  let currentDate = moment(start).startOf(type)

  // @ts-ignore
  while (moment(end).endOf(type).isAfter(currentDate)) {
    //@ts-ignore
    scale.push(currentDate.format(dateFormat))
    // @ts-ignore
    currentDate = moment(currentDate).add(1, type)
  }

  // @ts-ignore
  return scale
}

const generateEmptyGraphData = (
  dateScale: string,
  baseCompanyUuids: [string],
  firstData?: TripSummary
) => {
  const bcCount: any = {}
  baseCompanyUuids.forEach((bcUuid: string) => {
    bcCount[`${bcUuid}:emptyCount`] = 0
    bcCount[`${bcUuid}:ladenCount`] = 0
  })
  const mtd = parseInt(moment().format('DD'))
  // @ts-ignore
  return {
    ...pick(firstData, ['from', 'to', 'transportSource', 'zone']),
    ...bcCount,
    date: dateScale,
    month: parseInt(moment(new Date(dateScale)).format('MM')),
    mtd: !!(parseInt(moment(new Date(dateScale)).format('DD')) <= mtd),
    year: parseInt(moment(new Date(dateScale)).format('YY'))
  }
}

export const prepToGraphData = (
  data: TripSummary[],
  scales: [string],
  type: ScaleType,
  baseCompanyUuids: [string]
) => {
  const dateFormat = ['month', 'mtd'].includes(type) ? 'YYYY-MM' : 'YYYY-MM-DD'
  // @ts-ignore
  const myData = groupBy(data, (d: TripSummary) =>
    //@ts-ignore
    moment(new Date(d.date)).startOf(type).format(dateFormat)
  )
  let max = 0

  const reduced = scales.map((scale: string) => {
    if (!myData[scale]) {
      // @ts-ignore
      myData[scale] = []
    }

    const rtn: any = generateEmptyGraphData(scale, baseCompanyUuids, data?.[0])
    let locMax = 0

    for (let i = 0; i < myData[scale].length; i++) {
      const a: any = myData[scale][i]

      rtn[`${a.baseCompanyUuid}:emptyCount`] += a.emptyCount
      rtn[`${a.baseCompanyUuid}:ladenCount`] += a.ladenCount
      locMax += a.emptyCount + a.ladenCount
    }

    max = Math.max(max, locMax)

    return rtn
  })

  return { reduced, max }
}

export const convertToDivergingGraphData = (
  rightData: any,
  leftData: any,
  baseCompanyUuids: [string],
  scales: [string],
  tripTypeFilter: string[]
) => {
  const rows: any = []

  scales.forEach((scaleName: string, index: number) => {
    const row: any = { name: scaleName, boxes: [] }

    let leftCount = 0
    let rightCount = 0

    if (!tripTypeFilter.length || tripTypeFilter.includes('laden')) {
      baseCompanyUuids.forEach((bcUuid: string) => {
        row[`left:${bcUuid}:ladenCount`] = -1 * leftData[index][`${bcUuid}:ladenCount`]
        row[`right:${bcUuid}:ladenCount`] = rightData[index][`${bcUuid}:ladenCount`]

        row.boxes.push({
          name: `${bcUuid} Laden`,
          x0: leftCount + row[`left:${bcUuid}:ladenCount`],
          x1: leftCount
        })
        leftCount += row[`left:${bcUuid}:ladenCount`]

        row.boxes.push({
          name: `${bcUuid} Laden`,
          x0: rightCount,
          x1: rightCount + row[`right:${bcUuid}:ladenCount`]
        })
        rightCount += row[`right:${bcUuid}:ladenCount`]
      })
    }

    if (!tripTypeFilter.length || tripTypeFilter.includes('empty')) {
      baseCompanyUuids.forEach((bcUuid: string) => {
        row[`left:${bcUuid}:emptyCount`] = -1 * leftData[index][`${bcUuid}:emptyCount`]
        row[`right:${bcUuid}:emptyCount`] = rightData[index][`${bcUuid}:emptyCount`]

        row.boxes.push({
          name: `${bcUuid} Empty`,
          x0: leftCount + row[`left:${bcUuid}:emptyCount`],
          x1: leftCount
        })
        leftCount += row[`left:${bcUuid}:emptyCount`]

        row.boxes.push({
          name: `${bcUuid} Empty`,
          x0: rightCount,
          x1: rightCount + row[`right:${bcUuid}:emptyCount`]
        })
        rightCount += row[`right:${bcUuid}:emptyCount`]
      })
    }

    rows.push(row)
  })

  return rows
}

export const buildDomainAndScales = (baseCompanyUuids: [string], tripTypeFilter: string[]) => {
  const ladenScale = ['#292f6f', /* '#3d45a3', */ '#636bc5', '#979cd8', '#cbceec']
  const emptyScale = ['#e25646', /* '#e8786b', */ '#ee9a90', '#f4bbb5', '#f9ddda']

  const domains: string[] = []
  const colorScale: string[] = []

  if (!tripTypeFilter.length || tripTypeFilter.includes('laden')) {
    baseCompanyUuids.forEach((bcUuid: string, index: number) => {
      // domains.unshift(`left:${bcUuid}:ladenCount`)
      domains.push(`right:${bcUuid}:ladenCount`)
      // colorScale.unshift(ladenScale[index])
      colorScale.push(ladenScale[index])
    })
  }

  if (!tripTypeFilter.length || tripTypeFilter.includes('empty')) {
    baseCompanyUuids.forEach((bcUuid: string, index: number) => {
      // domains.unshift(`left:${bcUuid}:emptyCount`)
      domains.push(`right:${bcUuid}:emptyCount`)
      // colorScale.unshift(emptyScale[index])
      colorScale.push(emptyScale[index])
    })
  }

  return {
    domains,
    colorScale
  }
}

export const getBarXScale = (selectedZones: string[], dateScale: string[]) => {
  const rtn: any = []
  selectedZones.forEach((zone: string) => {
    const revZone = zone?.split('-').reverse().join('-')
    dateScale.forEach((date: string) => {
      rtn.push(`${zone} ${date}`)
    })
    dateScale.forEach((date: string) => {
      rtn.push(`${revZone} ${date}`)
    })
  })

  return rtn
}

export const convertToBarChartData = (
  data: [TripSummary],
  type: ScaleType,
  selectedZones: string[],
  scales: [string]
) => {
  const dateFormat = ['month', 'mtd'].includes(type) ? 'YYYY-MM' : 'YYYY-MM-DD'

  // @ts-ignore
  const grpData = groupBy(
    data,
    //@ts-ignore
    (d: TripSummary) => `${d.zone} ${moment(new Date(d.date)).startOf(type).format(dateFormat)}`
  )
  const barData: any = []
  const totalData: any = []
  let max = 0

  scales.forEach((xString: any) => {
    const subData = grpData[xString]

    const reducedData = reduce(
      subData,
      (a: any, val: any) => {
        a.empty += val.emptyCount
        a.laden += val.ladenCount
        a.total += val.ladenCount + val.emptyCount
        return a
      },
      { empty: 0, laden: 0, total: 0 }
    )

    barData.push({
      value: reducedData.laden,
      type: 'Laden',
      // @ts-ignore
      time: xString
    })
    barData.push({
      value: reducedData.empty,
      type: 'Empty',
      // @ts-ignore
      time: xString
    })
    totalData.push({
      // @ts-ignore
      Total: reducedData.total,
      // @ts-ignore
      time: xString
    })

    max = Math.max(max, reducedData.total)
  })

  return {
    max,
    barData,
    totalData
  }
}
