import type { HTMLAttributes, PropsWithChildren } from 'react'

import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 10px 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
`

export const Title = styled.div`
  margin: 0 0;
  text-align: left;
  font-weight: 500;
  font-size: 0.95rem;
  color: rgb(40, 45, 50);
  padding: 15px 20px 15px;
`

export const Content = styled.div<PropsWithChildren<HTMLAttributes<HTMLDivElement>>>`
  padding: 0px 20px 15px;
`

export const ReferenceLabel = styled.span`
  font-size: 12.5px;
  color: rgb(40, 45, 50);
  text-transform: uppercase;
`
