export const getDataGql = `
  query billingUnitsExport ($limit: Int, $offset: Int, $q: String, $statuses: [BillingUnitStatus]) {
    billingUnits (limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        name
        description
        status
        uuid
      }
    }
  }
`

export const createEntityGql = `
  mutation createBillingUnitImport ($input: CreateBillingUnitInput) {
    createBillingUnit (input: $input) {
      uuid
    }
  }
`

export const updateEntityGql = `
  mutation updateBillingUnitImport ($input: UpdateBillingUnitInput) {
    updateBillingUnit (input: $input) {
      uuid
    }
  }
`
