import type { BookingTableColumnProps } from '@/components/Booking/ListView/ColumnBuilder'
import type { BookingsSearchInput } from '@/types/graphql'

import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
  CopyOutlined,
  EditOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MinusCircleOutlined
} from '@ant-design/icons'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import { Button, Flex, Layout, notification, Popover, Tooltip, Typography } from 'antd-v5'
import { stringify } from 'query-string'

import BookingListView from '@/components/Booking/ListView'
import { getColumns } from '@/components/Booking/ListView/ColumnBuilder'
import ExportBookingList from '@/components/Booking/ListView/Export'
import { BOOKINGS_MAIN_QUERY } from '@/components/Booking/schema'
import SideFilter from '@/components/Booking/SideFilters'
import { SingleLineTableContainer } from '@/components/Booking/Styled'
import UserFilter from '@/components/Booking/UserFilter'
import { getBookingQuery } from '@/components/Layouts/Search/helper'
import { useIsMobile } from '@/hooks/use-mobile'
import { DEPARTMENTS_SETTING, useBaseCompanySetting } from '@/hooks/useBaseCompanySetting'
import { useBookingStore } from '@/store/booking'
import { logger } from '@/utils/logger'
import notify from '@/utils/notify'
import responseHandler from '@/utils/responseHandler'
import { copyToClipboard, getSearchParamValue } from '@/utils/u'

const { Sider, Content, Header } = Layout

const SearchResult = memo(() => {
  const client = useApolloClient()
  const isMobile = useIsMobile()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const selectedBookingsTable = useBookingStore.use.selectedBookingsTable()
  const clearSelectedBookingsTable = useBookingStore.use.clearSelectedBookingsTable()

  const { setting } = useBaseCompanySetting(DEPARTMENTS_SETTING, {
    variables: { key: 'overviewPageSettings' },
    fetchPolicy: 'cache-first'
  })

  const [total, setTotal] = useState(0)
  const [results, setResults] = useState([])
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [loadingCopy, setLoadingCopy] = useState(false)
  const [collapsedDrawer, setCollapsedDrawer] = useState(isMobile)
  const [columns, setColumns] = useState<BookingTableColumnProps[]>(getColumns())
  const [query, setQuery] = useState<BookingsSearchInput>({
    q: '',
    sort: '',
    limit: 20,
    offset: 0,
    filter: {},
    _noSubGraph: true
  })

  const [getBookings, { loading, data, error }] = useLazyQuery(BOOKINGS_MAIN_QUERY, {
    variables: { input: query },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (isMobile) setCollapsedDrawer(true)
    else setCollapsedDrawer(false)
  }, [isMobile])

  useEffect(() => {
    if (setting?.[0]?.value) {
      setColumns(getColumns(JSON.parse(setting?.[0]?.value)))
    }
  }, [setting, selectedBookingsTable])

  useEffect(() => {
    const isAssigned = getSearchParamValue(location, 'isAssigned')
    const offset = Number(getSearchParamValue(location, 'offset')) || 0
    const limit = Number(getSearchParamValue(location, 'limit')) || 20

    const { graphqlFormat } = getBookingQuery(location, {
      __noSubGraph: true,
      isAssigned: isAssigned ? !!Number(getSearchParamValue(location, 'isAssigned')) : null,
      offset,
      limit
    })

    setQuery(graphqlFormat)
    getBookings()
  }, [getBookings, location])

  useEffect(() => {
    if (data?.bookingsSearchJson?.rows) {
      setResults(data.bookingsSearchJson.rows)
      setTotal(data.bookingsSearchJson?.pageInfo?.count ?? 0)
      setCurrentPage(
        (data.bookingsSearchJson?.pageInfo?.offset ?? 0) /
          (data.bookingsSearchJson?.pageInfo?.limit ?? 20)
      )
    }
  }, [data])

  const handlePageChange = useCallback(
    (page: number, pageSize?: number) => {
      const limit = pageSize ?? 20
      const offset = (page - 1) * limit

      if (limit + offset > 10000) {
        notification.error({
          message: 'You have exceeded query limit. Please narrow your search.',
          duration: 5
        })
        return
      }

      const { graphqlFormat, urlFormat } = getBookingQuery(location, {
        offset,
        limit,
        __noSubGraph: true
      })

      navigate(`/?${stringify(urlFormat)}`)

      setQuery({ ...query, ...graphqlFormat })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query, location]
  )

  const handleSizeChange = useCallback(
    (current: number, size: number) => {
      setPageSize(size)
      handlePageChange(current, size)
    },
    [handlePageChange]
  )

  useEffect(() => {
    if (location.search) return

    const { urlFormat } = getBookingQuery(location, query)

    navigate(`/?${stringify(urlFormat)}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, query])

  if (error) {
    responseHandler(error, 'error')
    logger.error('BookingsListView BOOKING_SEARCH_QUERY error', error)
  }

  const toggleSideDrawer = () => setCollapsedDrawer(prev => !prev)

  const handleCopy = async (copyAll: boolean, skipNotify: boolean = false) => {
    setLoadingCopy(true)
    try {
      let bookingNos = ''

      if (copyAll) {
        const { data } = await client.query({
          query: BOOKINGS_MAIN_QUERY,
          variables: { input: { ...query, limit: total } }
        })
        bookingNos =
          data.bookingsSearchJson?.rows?.map(booking => booking.no || booking.uuid).join(' ') || ''
      } else {
        bookingNos = selectedBookingsTable.join(' ')
      }

      await copyToClipboard(bookingNos)
      if (!skipNotify) {
        notify(
          `${copyAll ? total : selectedBookingsTable.length} booking(s) copied to clipboard`,
          'success'
        )
      }
      return bookingNos
    } finally {
      setLoadingCopy(false)
    }
  }

  const navigateBulkFunction = async (copyAll: boolean) => {
    const params = new URLSearchParams({
      function: 'CHANGE_STATUS',
      bookings: copyAll ? await handleCopy(true, true) : await handleCopy(false, true)
    }).toString()

    navigate(`/bulk-functions?${params}`)
  }

  return (
    <SingleLineTableContainer>
      <Layout>
        <Sider
          collapsible
          theme="light"
          trigger={null}
          collapsedWidth={0}
          collapsed={collapsedDrawer}
          onCollapse={toggleSideDrawer}
        >
          {!collapsedDrawer && <SideFilter />}
        </Sider>

        <Layout>
          <Header className="bg-white px-2">
            <div className="flex space-between items-center">
              <div className="space-x-4 flex">
                <span className="cursor-pointer" onClick={toggleSideDrawer}>
                  {collapsedDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </span>
                <div className="flex space-x-2">
                  <Typography.Text type="secondary" strong className="flex items-center">
                    {t('index.filterByUsers')} :
                  </Typography.Text>
                  <UserFilter />
                </div>
              </div>
              <div className="flex items-center space-x-1">
                {selectedBookingsTable.length > 0 && (
                  <>
                    <Popover
                      arrow={false}
                      title="Bulk Edit"
                      placement="bottomRight"
                      content={
                        <Flex vertical gap="small">
                          <Button loading={loadingCopy} onClick={() => navigateBulkFunction(false)}>
                            Edit Selected Bookings ({selectedBookingsTable.length})
                          </Button>
                          <Button loading={loadingCopy} onClick={() => navigateBulkFunction(true)}>
                            Edit All Bookings ({total})
                          </Button>
                        </Flex>
                      }
                    >
                      <Button size="small">
                        <EditOutlined />
                      </Button>
                    </Popover>
                    <Tooltip title="Copy selected bookings">
                      <Button type="dashed" size="small" onClick={() => handleCopy(false, true)}>
                        <CopyOutlined />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Clear selected bookings">
                      <Button type="dashed" size="small" onClick={clearSelectedBookingsTable}>
                        <MinusCircleOutlined />
                      </Button>
                    </Tooltip>
                    <Typography.Text className="text-xs ml-4" type="secondary">
                      {selectedBookingsTable.length} Selected
                    </Typography.Text>
                    <Tooltip title="Select all bookings record">
                      <Button
                        className="border-none box-none"
                        loading={loadingCopy}
                        onClick={() => handleCopy(true)}
                      >
                        {t('instructions.selectAll')} {total}
                      </Button>
                    </Tooltip>
                  </>
                )}
                <ExportBookingList total={total} query={query} columns={columns} />
              </div>
            </div>
          </Header>
          <Content className="bg-off-white p-0">
            <BookingListView
              total={total}
              columns={columns}
              loading={loading}
              results={results}
              pageSize={pageSize}
              currentPage={currentPage}
              handleSizeChange={handleSizeChange}
              handlePageChange={handlePageChange}
            />
          </Content>
        </Layout>
      </Layout>
    </SingleLineTableContainer>
  )
})

export default SearchResult
