import type { Trailer } from 'App/types/graphql'
import { TrailerStatus } from 'App/types/graphql'

import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import debounce from 'lodash/debounce'

import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'
import { TRAILERS_QUERY } from '../Schemas/schema'

const Option = Select.Option

type TrailerSelectorProps = {
  onChange?: any
  value?: string
  style?: React.CSSProperties
  statuses?: Array<TrailerStatus>
}

const TrailerSelector = forwardRef((props: TrailerSelectorProps, ref: any) => {
  const { t } = useTranslation()

  const { value, statuses = [TrailerStatus.Active], onChange, style } = props

  const [trailers, setTrailers] = useState<Trailer[]>([])
  const [searchInput, setSearchInput] = useState<string>('')

  const { data, error, loading, refetch } = useQuery(TRAILERS_QUERY, {
    variables: {
      q: value || searchInput || '',
      statuses,
      limit: 20
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (data?.trailers?.rows?.length) {
      setTrailers(data?.trailers?.rows)
    }
  }, [data])

  if (error) {
    logger.error('TrailerSelector TRAILERS_QUERY error', error)
    responseHandler(error, 'error')
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        refetch()
      } catch (error) {
        // ok to fail when the component has already unmounted
      }
    }, 500),
    [refetch]
  )

  return (
    <Select
      ref={ref}
      allowClear
      showSearch
      style={style}
      loading={loading}
      onChange={onChange}
      filterOption={false}
      onSearch={handleSearch}
      value={value || undefined} // undefined to show placeholder
      placeholder={t('common.selectATrailer')}
      notFoundContent={loading ? t('common.searching') : t('common.notFound')}
    >
      {trailers?.map((trailer: Trailer) => (
        <Option key={trailer.uuid} value={trailer.uuid}>
          {trailer.code} - {trailer.registration}
        </Option>
      ))}
    </Select>
  )
})

export default TrailerSelector
