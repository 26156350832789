import type { BaseCompany } from '@/types/graphql'
import { gql } from '@/types'

import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'

import Fallback from '@/components/Spinner/Fallback'
import ViewerContext from '@/contexts/viewer'
import base from '@/hocs/base'
import useGlobalCompanyStore from '@/store/globalCompany'
import useUserStore from '@/store/user'
import { logger } from '@/utils/logger'
import handleResponse from '@/utils/responseHandler'

const VIEWER_QUERY = gql(`
  query viewerSlim3($auth0UserId: String) {
    viewer(auth0UserId: $auth0UserId) {
      email
      baseCompanies {
        uuid
        name
        types
        status
        country {
          # for shift
          alpha2
          alpha3
        }
        company {
          uuid
          name # for export
          currency {
            uuid
            code
            name
          }
        }
      }
    }
  }
`)

export default WrappedComponent => {
  const WithBootstrap = props => {
    const cachedViewer = useUserStore.use.viewerData()
    const setViewerData = useUserStore.use.setViewerData()

    const setGlobalCompanies = useGlobalCompanyStore.use.setGlobalCompanies()
    const setSelectedGlobalCompany = useGlobalCompanyStore.use.setSelectedGlobalCompany()

    const globalCompanies = useGlobalCompanyStore.use.globalCompanies()
    const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

    const { data, error, loading } = useQuery(VIEWER_QUERY, {
      fetchPolicy: 'cache-first',
      variables: { auth0UserId: props.loggedInUser.sub }
    })

    useEffect(() => {
      if (data?.viewer && !loading) {
        setViewerData(data.viewer)

        if (isEmpty(selectedGlobalCompany)) {
          const autoSelectFirstBc = data.viewer.baseCompanies?.[0]

          if (autoSelectFirstBc) setSelectedGlobalCompany(autoSelectFirstBc)
        } else {
          const company = data.viewer.baseCompanies?.find(
            bc => bc?.uuid === selectedGlobalCompany.uuid
          )
          if (company) setSelectedGlobalCompany(company)
        }
        const companies = (data.viewer.baseCompanies ?? []).filter(
          (company): company is BaseCompany => company !== null
        )
        setGlobalCompanies(companies)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading])

    if (loading) return <Fallback />

    if (error) {
      logger.error('WithBootstrap VIEWER_QUERY Error.', error)
      handleResponse(error, 'error')
    }

    const viewer = data?.viewer || cachedViewer || {}

    return (
      <ViewerContext.Provider value={viewer}>
        <WrappedComponent {...props} globalCompanies={globalCompanies} />
      </ViewerContext.Provider>
    )
  }

  return base(WithBootstrap)
}
