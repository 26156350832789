import { memo } from 'react'
import { Button } from 'antd-v5'
import { compose, withHandlers } from 'recompose'

import createConsolVoucherMutation from '@/containers/consolVoucher/mutation/createConsolVoucher'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'

const handlers = withHandlers({
  handleClick: props => async () => {
    const { createConsolVoucher, vouchers } = props

    const type = vouchers[0].transactionType === 'ACCPAY' ? 'ConsolAPInvoice' : 'ConsolARInvoice'

    const input = {
      type,
      voucherUuids: vouchers.map(v => v.uuid)
    }

    try {
      respHandler('Consolidating vouchers, hang on...', 'load')

      const result = await createConsolVoucher(input)

      const reference = result.data.createConsolVoucher.reference

      respHandler(
        `Vouchers consolidated successfully. Reference number is ${reference}.`,
        'success'
      )
    } catch (error) {
      logger.error('createConsolVoucher Error.', error)
      respHandler(error, 'error')
    }
  }
})

const enhance = compose(createConsolVoucherMutation, handlers)

const ConsolidateInvoiceButton = memo(props => {
  const { handleClick, vouchers = [] } = props

  return (
    <>
      <Button disabled={vouchers.length === 0} onClick={handleClick}>
        Consolidate Invoice
      </Button>
    </>
  )
})

export default enhance(ConsolidateInvoiceButton)
