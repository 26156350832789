import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import capitalize from 'lodash/capitalize'

import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import responseHandler from '@/utils/responseHandler'
import webStorage from '@/utils/webStorage'

const BASECOMPANIES_TYPES_QUERY = gql`
  query getBaseCompanyTypes1 {
    getBaseCompanyTypes {
      key
      value
      meta
    }
  }
`

const useBaseCompanyType = () => {
  const [companyTypes, setCompanyTypes] = useState(
    webStorage.getItem(LOCAL_STORAGE_KEYS.BASE_COMPANY_TYPES) || []
  )

  const [getBcTypes, { loading, data, error }] = useLazyQuery(BASECOMPANIES_TYPES_QUERY)

  if (error) {
    responseHandler(error, 'error')
  }

  useEffect(() => {
    if (!companyTypes.length) {
      getBcTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data?.getBaseCompanyTypes) {
      const bcTypes = data.getBaseCompanyTypes
        .filter((type: any) => !['FINANCE'].includes(type.meta?.[0]))
        .map((type: any) => ({
          key: type.key,
          label: capitalize(type.key),
          meta: type.meta || {},
          category: `${type.meta?.type?.toLowerCase()}Categories`
        }))

      setCompanyTypes(bcTypes)
      webStorage.setItem(LOCAL_STORAGE_KEYS.BASE_COMPANY_TYPES, bcTypes)
    }
  }, [data])

  return {
    loading,
    data: companyTypes
  }
}

export default useBaseCompanyType
