import { useCallback, useEffect, useState } from 'react'
import { DeploymentUnitOutlined } from '@ant-design/icons'
import { gql, useLazyQuery } from '@apollo/client'
import { Tooltip } from 'antd-v5'
import { Base64 } from 'js-base64'

import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

export const PROCESS_FLOW_MAP_QUERY = gql`
  query getProcessFlowMap($type: String!, $process: String!) {
    processFlowMap(type: $type, process: $process)
  }
`
const MermaidLink = props => {
  const [click, setClick] = useState(false)

  const [getProcessFlowMap, { loading, data, error }] = useLazyQuery(PROCESS_FLOW_MAP_QUERY, {
    variables: {
      type: props.type,
      process: props.process
    },
    fetchPolicy: 'cache-first'
  })

  const loadProcessFlowMap = useCallback(() => setClick(true), [])

  useEffect(() => {
    if (click) {
      getProcessFlowMap({
        variables: {
          type: props.type,
          process: props.process
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [click])

  useEffect(() => {
    if (data?.processFlowMap && !loading) {
      const defaultState = {
        code: data.processFlowMap,
        mermaid: { theme: 'default' }
      }
      const base64Str = Base64.encodeURI(JSON.stringify(defaultState))
      const url = `https://mermaidjs.github.io/mermaid-live-editor/#/view/${base64Str}`
      window.open(url, '_blank')
    }
    setClick(false)
  }, [data, loading])

  if (loading) {
    return null
  }

  if (error) {
    responseHandler(error, 'error')
    logger.error(error, 'error')
  }

  return (
    <Tooltip title="View process flow map">
      <DeploymentUnitOutlined
        onClick={() => loadProcessFlowMap()}
        style={{ color: 'gray', cursor: 'pointer' }}
      />
    </Tooltip>
  )
}

export default MermaidLink
