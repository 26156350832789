import { graphql } from '@apollo/client/react/hoc'
import styled from 'styled-components'

import { BookingActionButton } from '@/components/Booking/BookingActionButton'
import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from '@/containers/booking/schema/bookingForWorkflowsSlim'

const withGraphqlBookingQuery = graphql(BOOKING_FOR_WORKFLOWS_SLIM_QUERY, {
  options: props => ({
    variables: {
      uuid: props.booking.uuid
    }
  })
})

const StyledActionContainer = styled.div`
  button {
    margin-right: 5px;
  }
`

const buttons = {
  ACCEPTED: booking => [
    <BookingActionButton
      key="CLOSE_OPERATION"
      booking={booking}
      buttonText="Close Operation"
      newBookingStatus="CLOSE_OPERATION"
    />
  ],
  CLOSE_OPERATION: booking => [
    <BookingActionButton
      key="ACCEPTED"
      booking={booking}
      buttonText="Revert Operation Close"
      newBookingStatus="ACCEPTED"
    />,
    <BookingActionButton
      key="CLOSE_FINANCE"
      booking={booking}
      buttonText="Close Finance"
      newBookingStatus="CLOSE_FINANCE"
    />
  ],
  CLOSE_FINANCE: booking => [
    <BookingActionButton
      key="CLOSE_OPERATION"
      booking={booking}
      buttonText="Revert Finance Close"
      newBookingStatus="CLOSE_OPERATION"
    />
  ]
}

const CompPostExtras = props => {
  const { booking } = props
  const buttonFn = buttons[booking.status]

  if (!buttonFn) {
    return null
  }

  return <StyledActionContainer>{buttonFn(booking)}</StyledActionContainer>
}

export default withGraphqlBookingQuery(CompPostExtras)
