import { gql } from '@apollo/client'

export default gql`
  query viewer2($auth0UserId: String) {
    viewer(auth0UserId: $auth0UserId) {
      baseCompanies {
        uuid
        name
        shortCode
      }
    }
  }
`
