import type { TableRowSelection } from 'antd-v5/es/table/interface'
import { gql } from '@/types'
import { DocumentCreatorTemplateStatus, TransactionType, VoucherStatus } from '@/types/graphql'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DatabaseOutlined,
  DollarOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  RollbackOutlined
} from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Dropdown, InputNumber, Menu, Modal, Popover, Table, Typography } from 'antd-v5'
import startCase from 'lodash/startCase'

import Link from '@/components/Link'
import BookingSelect from '@/components/Select/BookingSelect'
import CostItemSelect from '@/components/Select/BookingSelect/cost-item-select'
import JobSelect from '@/components/Select/job/job-select'
import { getPrintableLink } from '@/components/Shared/documentCreator/openPrintableInNewTab'
import CustomText from '@/components/Voucher/CustomText'
import VoucherPdfAsyncDownloadAction from '@/components/Voucher/Management/VoucherPdfAsyncDownloadAction'
import ChangeTypeAction from '@/components/Voucher/ModalHeader/ChangeTypeAction'
import Rollover from '@/components/Voucher/ModalHeader/Rollover'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'
import { updateSelectedVoucher } from '@/states/reducers/voucher'
import useGlobalCompanyStore from '@/store/globalCompany'
import { useVoucherStore } from '@/store/voucher'
import responseHandler from '@/utils/responseHandler'

const DOCUMENT_CREATOR_TEMPLATES = gql(`
  query documentCreatorTemplates5($q: String, $statuses: [DocumentCreatorTemplateStatus]) {
    documentCreatorTemplates(q: $q, statuses: $statuses) {
      rows {
        uuid
        type
      }
    }
  }
`)

const Selections = props => {
  const { loadRequiredData, bookingQuery, handleCreateCreditNote, fullBookingQuery } = props
  const dispatch = useDispatch()

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const openSelectVoucherItemModal = useVoucherStore.use.openSelectVoucherModal()
  const setOpenSelectVoucherItemModal = useVoucherStore.use.setOpenSelectVoucherModal()

  const selectedVoucher = useSelector(state => state.voucher.selectedVoucher)
  const updateSelectedVoucherAction = obj => dispatch(updateSelectedVoucher(obj))

  const [keySelected, setKeySelected] = useState('')
  const [modifiedValue, setModifiedValue] = useState({})
  const [selectedVoucherItems, setSelectedVoucherItems] = useState<any>([])

  const handleJvValueChange = (record, field, value) => {
    const recordKey = record.uuid

    setModifiedValue(prev => ({
      ...prev,
      [recordKey]: {
        ...prev[recordKey],
        [field]: value
      }
    }))
  }

  const {
    data: dcTemplates,
    loading: dcTemplatesLoading,
    error: dcTemplatesError
  } = useQuery(DOCUMENT_CREATOR_TEMPLATES, {
    variables: { q: 'INVOICE', statuses: [DocumentCreatorTemplateStatus.Activated] }
  })

  const { hasPermission } = usePermissionIsAllowed({
    resource: 'documentCreatorTemplate',
    permission: 'index'
  })

  const renderRollover = useCallback(() => {
    return (
      // @ts-expect-error need to migrate to functional component
      <Rollover
        bookingQuery={bookingQuery}
        selectedVoucher={selectedVoucher}
        loadRequiredData={loadRequiredData}
      />
    )
  }, [bookingQuery, selectedVoucher, loadRequiredData])

  const handleClick = useCallback(
    ({ key }) => {
      if (key === 'creditNote' || key === 'journalVoucher') {
        setKeySelected(key)
        setOpenSelectVoucherItemModal(true)
      }
    },
    [setOpenSelectVoucherItemModal]
  )

  const handleChangedVoucherType = useCallback(
    value => {
      if (!value) return

      if (fullBookingQuery) fullBookingQuery.refetch()

      updateSelectedVoucherAction({
        ...selectedVoucher,
        type: value
      })
    },

    [fullBookingQuery, selectedVoucher, updateSelectedVoucherAction]
  )

  const status = useMemo(() => selectedVoucher?.status, [selectedVoucher])

  const menu = useMemo(
    () => (
      <Menu onClick={handleClick}>
        {status !== 'NEW' && (
          <Menu.Item key="view">
            <Link target="_blank" to={`/vouchers/${selectedVoucher?.uuid}/view`}>
              <EyeOutlined style={{ marginRight: '8px' }} />
              View voucher
            </Link>
          </Menu.Item>
        )}
        {status !== 'NEW' && (
          <Menu.Item key="viewWithCostSheet">
            <Link target="_blank" to={`/vouchers/${selectedVoucher?.uuid}/view?withCostSheet=true`}>
              <EyeOutlined style={{ marginRight: '8px' }} />
              View voucher with cost sheet
            </Link>
          </Menu.Item>
        )}
        {status !== 'NEW' && (
          <Menu.Item key="changeVoucherType">
            <ChangeTypeAction voucher={selectedVoucher} onChanged={handleChangedVoucherType} />
          </Menu.Item>
        )}
        {hasPermission &&
          status === VoucherStatus.Approved &&
          selectedVoucher?.transactionType === TransactionType.Accrec &&
          dcTemplates?.documentCreatorTemplates?.rows
            ?.filter(t =>
              selectedVoucher?.documentCreatorTemplate?.uuid
                ? t?.uuid === selectedVoucher.documentCreatorTemplate.uuid
                : t
            )
            .map(t => (
              <Menu.Item key={t?.type}>
                <VoucherPdfAsyncDownloadAction
                  voucher={selectedVoucher}
                  type={t?.type}
                  text={`${t?.type} as PDF`}
                />
              </Menu.Item>
            ))}
        {hasPermission &&
          status === VoucherStatus.Approved &&
          selectedVoucher?.transactionType === TransactionType.Accrec &&
          dcTemplates?.documentCreatorTemplates?.rows
            ?.filter(t =>
              selectedVoucher?.documentCreatorTemplate?.uuid
                ? t?.uuid === selectedVoucher.documentCreatorTemplate.uuid
                : t
            )
            .map(t => (
              <Menu.Item key={t?.type}>
                <a
                  target="_blank"
                  href={getPrintableLink(
                    selectedVoucher.uuid,
                    selectedGlobalCompany.uuid,
                    t?.type as string
                  )}
                  rel="noreferrer"
                >
                  <EyeOutlined style={{ marginRight: '8px' }} />
                  {`Print as ${t?.type}`}
                </a>
              </Menu.Item>
            ))}
        {hasPermission &&
          status === VoucherStatus.Approved &&
          selectedVoucher?.transactionType === TransactionType.Accrec &&
          !dcTemplates?.documentCreatorTemplates?.rows?.length && (
            <Menu.Item key="downloadAsyncNoTemplates" disabled>
              <Typography.Text strong style={{ color: 'red' }}>
                No Document Creator Templates
              </Typography.Text>
            </Menu.Item>
          )}
        {dcTemplatesLoading && <Menu.Item key="downloadAsyncLoading">Loading...</Menu.Item>}
        {dcTemplatesError && hasPermission && (
          <Menu.Item key="downloadAsyncError">Error loading templates</Menu.Item>
        )}
        {(status === VoucherStatus.Approved || status === VoucherStatus.Paid) &&
          !selectedVoucher?.isCreditNote &&
          !selectedVoucher?.isJournalVoucher && (
            <Menu.Item key="creditNote">
              <RollbackOutlined />
              Credit note
            </Menu.Item>
          )}
        {(status === VoucherStatus.Approved || status === VoucherStatus.Paid) &&
          !selectedVoucher?.isCreditNote &&
          !selectedVoucher?.isJournalVoucher && (
            <Menu.Item key="journalVoucher">
              <DollarOutlined />
              Journal Voucher
            </Menu.Item>
          )}
        {status !== 'NEW' && (
          <Menu.Item key="viewIntegrationLogs">
            <Link
              target="_blank"
              to={`/manage/integration-logs?voucherUuids=${selectedVoucher?.uuid}`}
            >
              <DatabaseOutlined style={{ marginRight: '8px' }} />
              Integration logs
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="logs">
          <Link
            target="_blank"
            to={`/manage/logs/voucher/?models=voucher&voucherUuid=${selectedVoucher?.uuid}`}
          >
            <DatabaseOutlined style={{ marginRight: '8px' }} />
            Voucher logs
          </Link>
        </Menu.Item>
      </Menu>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, selectedVoucher, hasPermission, dcTemplates, dcTemplatesLoading, dcTemplatesError]
  )

  useEffect(() => {
    if (openSelectVoucherItemModal && selectedVoucher?.voucherItems) {
      setSelectedVoucherItems(selectedVoucher.voucherItems)
    }
  }, [openSelectVoucherItemModal, selectedVoucher?.voucherItems])

  const table = () => {
    const columns = [
      {
        title: 'Job',
        dataIndex: 'job',
        key: 'job',
        render: (_, record) => <span>{record?.job?.jobNo}</span>
      },
      {
        title: 'Cost Item',
        key: 'costItem',
        render: (_, record) => {
          const currentRecord = modifiedValue[record.uuid] || {}
          const content = (
            <div className="w-full space-y-4 min-w-96">
              <div className="flex flex-col space-y-2">
                <span>Booking</span>
                <BookingSelect
                  allowClear
                  value={currentRecord?.bookingUuid}
                  onChange={val => handleJvValueChange(record, 'bookingUuid', val)}
                  style={{ width: '100%' }}
                />
              </div>
              {currentRecord?.bookingUuid && (
                <div className="flex flex-col space-y-2">
                  <span>Job</span>
                  <JobSelect
                    value={currentRecord?.jobUuid}
                    onChange={val => handleJvValueChange(record, 'jobUuid', val)}
                    bookingUuid={currentRecord.bookingUuid}
                  />
                </div>
              )}
              {currentRecord?.bookingUuid && currentRecord?.jobUuid && (
                <div className="flex flex-col space-y-2">
                  <span>Cost Item</span>
                  <CostItemSelect
                    className="w-full"
                    bookingUuid={currentRecord.bookingUuid}
                    value={currentRecord?.costItemUuid}
                    onChange={val => handleJvValueChange(record, 'costItemUuid', val)}
                  />
                </div>
              )}
            </div>
          )

          return (
            <Popover content={content} title="Cost Item" placement="bottom" trigger="click">
              <div className="space-x-2 cursor-pointer">
                <span>{record.costItem.chargeItem.code}</span>
                <EditOutlined />
              </div>
            </Popover>
          )
        }
      },
      {
        title: `${startCase(keySelected)} Cost Item`,
        key: 'jvCostItem',
        render: (_, record) => (
          <span>
            {record.costItem.chargeItem.code} - {record.costItem.chargeItem.name}
          </span>
        )
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text, record) => (
          <InputNumber
            defaultValue={text}
            value={modifiedValue[record.uuid]?.quantity || text}
            onChange={value => handleJvValueChange(record, 'quantity', value)}
            min={0}
          />
        )
      },
      {
        title: 'Base Rate',
        dataIndex: 'baseRate',
        key: 'baseRate',
        render: (text, record) => (
          <div className="space-x-2">
            <CustomText muted noPadding text={record.currency.code} />
            <InputNumber
              defaultValue={text}
              value={modifiedValue[record.uuid]?.baseRate || text}
              onChange={value => handleJvValueChange(record, 'baseRate', value)}
            />
          </div>
        )
      }
    ]

    const rowSelection: TableRowSelection<any> = {
      selectedRowKeys: selectedVoucherItems.map(item => item.uuid),
      onChange: (_, selectedRows) => setSelectedVoucherItems(selectedRows),
      getCheckboxProps: record => ({})
    }

    return (
      <Table
        dataSource={selectedVoucher?.voucherItems}
        rowSelection={rowSelection}
        rowKey={record => record.uuid}
        columns={columns}
        pagination={false}
      />
    )
  }

  const handleOnOk = () => {
    let modifiedItems = { ...selectedVoucherItems }

    if (selectedVoucherItems.length === 0)
      return responseHandler('At least one voucher item needs to be selected', 'error')

    if (Object.keys(modifiedValue).length > 0) {
      modifiedItems = selectedVoucherItems.map(item => {
        const modified = modifiedValue[item.uuid]

        if (modified) {
          let job = { ...item.job }
          let costItem = { ...item.costItem }

          if (modified.jobUuid) job = { uuid: modified.jobUuid }
          if (modified.costItemUuid) costItem = { uuid: modified.costItemUuid }

          return {
            ...item,
            ...modified,
            job,
            costItem
          }
        }
        return item
      })
    }

    handleCreateCreditNote(keySelected, modifiedItems)
  }

  return (
    <>
      {renderRollover()}
      <Dropdown overlay={menu} trigger={['click']}>
        <EllipsisOutlined className="cursor-pointer" />
      </Dropdown>
      <Modal
        title={`${startCase(keySelected)} Items to Create`}
        width={'80%'}
        destroyOnClose
        closable={false}
        open={openSelectVoucherItemModal}
        onOk={handleOnOk}
        onCancel={() => setOpenSelectVoucherItemModal(false)}
      >
        {table()}
      </Modal>
    </>
  )
}

export default memo(Selections)
