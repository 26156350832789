import { AddressStatus } from 'App/types/graphql'

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Input, InputNumber, Radio } from 'antd'

import FormItemMapper from 'App/components/Manage/FormItemMapper'
import AddressTagsSelect from 'App/components/Select/AddressTagsSelect'
import AddressTypesSelect from 'App/components/Select/AddressTypesSelect'
import SearchLocationInput from 'App/components/Select/MapSearch'
import TransportZonesSelect from 'App/components/Select/TransportZonesSelect'
import AreaCodeSelect from 'App/components/Select/TypeToFetch/AreaCodeSelect'
import CountrySelect from 'App/components/Select/TypeToFetch/CountrySelect'
import config from 'App/config'
import { COUNTRIES_QUERY } from 'App/graphql/country'
import useGlobalCompanyStore from 'App/store/globalCompany'
import FormActions from './Actions'
import AddressMap from './AddressMap'

const TRANSPORT_AREA_CODES_QUERY = gql`
  query transportAreaCodesForAddress1($limit: Int, $offset: Int, $q: String) {
    transportAreaCodes(limit: $limit, offset: $offset, q: $q) {
      rows {
        code
        zone
      }
    }
  }
`

const RadioButton = Radio.Button
const RadioGroup = Radio.Group

const defaultMessage = 'Field required.'

const frontGoogleMapsUrl = config.google.mapsPlaceUrl

const AddressFormModal = props => {
  const { item, form, onDelete } = props
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const isPoint = item?.location?.type === 'Point' && item?.location?.coordinates?.[0]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [areaCodeStatus, setAreaCodeStatus] = useState('')
  const [isPolygonMode, setIsPolygonMode] = useState(isPoint ? false : true)
  const [newCountryAlpha3, setNewCountryAlpha3] = useState(false)
  const [googleMapsURL, setGoogleMapsURL] = useState(
    `${frontGoogleMapsUrl}${item?.placeId ? item?.placeId : ''}`
  )

  const prevData = useRef({ areaCode: item?.areaCode }).current

  const defaultCountryAlpha3 = useMemo(
    () => selectedGlobalCompany?.country?.alpha3,
    [selectedGlobalCompany]
  )

  const { data: countriesData, refetch: refetchCountries } = useQuery(COUNTRIES_QUERY, {
    variables: { query: defaultCountryAlpha3 },
    fetchPolicy: 'cache-first'
  })

  const { refetch: refetchAreaCode } = useQuery(TRANSPORT_AREA_CODES_QUERY, {
    variables: { limit: 500 },
    skip: true
  })

  const getGoogleMapsData = useCallback(
    async searchRes => {
      // await refetchCountries to use the country name from the last Google Maps search result
      await refetchCountries({
        query: searchRes?.countryName ? searchRes?.countryName : defaultCountryAlpha3
      })
      setNewCountryAlpha3(true)

      await form.setFieldsValue({
        name: searchRes?.name || '',
        address1: searchRes?.address1 || '',
        address2: searchRes?.address2 || '',
        address3: searchRes?.address3 || '',
        city: searchRes?.city || '',
        district: searchRes?.district || '',
        postCode: searchRes?.postCode || '',
        latitude: searchRes?.latitude || '',
        longitude: searchRes?.longitude || '',
        placeId: searchRes?.placeId || '',
        plusCode: searchRes?.plusCode || '',
        geojson: searchRes?.latitude && searchRes?.longitude ? false : true,
        location:
          searchRes?.latitude && searchRes?.longitude
            ? {
                point: {
                  type: 'Point',
                  coordinates: [searchRes.longitude, searchRes.latitude]
                }
              }
            : null
      })

      if (searchRes?.longitude && searchRes?.latitude) {
        setIsPolygonMode(false)
      }

      setGoogleMapsURL(`${frontGoogleMapsUrl}${searchRes?.placeId ? searchRes?.placeId : ''}`)
    },
    [defaultCountryAlpha3, form, refetchCountries]
  )

  // useEffect to assign the correct countryAlpha3 from COUNTRIES_QUERY based on the last Google Maps search result
  useEffect(() => {
    if (countriesData?.countries?.rows?.length > 0 && newCountryAlpha3) {
      form.setFieldsValue({
        countryAlpha3: countriesData?.countries?.rows[0]?.alpha3
          ? countriesData?.countries?.rows[0]?.alpha3
          : ''
      })
      setNewCountryAlpha3(false)
    }
  }, [form, countriesData, newCountryAlpha3])

  useEffect(() => {
    if (form.getFieldValue('areaCode') && prevData.areaCode !== form.getFieldValue('areaCode')) {
      ;(async () => {
        try {
          const results = await refetchAreaCode({
            q: form.getFieldValue('areaCode')
          })
          if (results?.data?.transportAreaCodes?.rows?.length > 0) {
            form.setFieldsValue({
              zone: results.data.transportAreaCodes.rows.find(
                x => x.code === form.getFieldValue('areaCode')
              )?.zone
            })
          }
        } catch (e) {
          console.log('Error', e)
        }
      })()
      prevData.areaCode = form.getFieldValue('areaCode')
    }
  }, [form, prevData, refetchAreaCode])

  const resetAddressForm = useCallback(() => {
    form.setFieldsValue({
      name: '',
      type: '',
      countryAlpha3: '',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      city: '',
      district: '',
      postCode: '',
      areaCode: '',
      zone: '',
      latitude: '',
      longitude: '',
      placeId: '',
      plusCode: '',
      phone: '',
      fax: '',
      tags: ''
    })
  }, [form])

  const fields = useMemo(
    () => [
      {
        full: true,
        cols: [
          {
            label: 'Name',
            value: 'name',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.name || '',
              rules: [
                {
                  required: true,
                  message: defaultMessage
                }
              ]
            }
          },
          {
            label: 'Types',
            value: 'type',
            input: <AddressTypesSelect />,
            attributes: {
              initialValue: item?.type?.length ? item?.type : undefined,
              rules: [
                {
                  required: true,
                  message: defaultMessage
                }
              ]
            }
          },
          {
            label: 'Country',
            value: 'countryAlpha3',
            input: <CountrySelect useAlpha3 />,
            attributes: {
              initialValue: item?.countryAlpha3 || defaultCountryAlpha3,
              rules: [
                {
                  required: true,
                  message: defaultMessage
                }
              ]
            }
          },
          {
            label: 'Address 1',
            value: 'address1',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address1 || '',
              rules: [
                {
                  required: true,
                  message: defaultMessage
                }
              ]
            }
          },
          {
            label: 'Address 2',
            value: 'address2',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address2 || ''
            }
          },
          {
            label: 'Address 3',
            value: 'address3',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address3 || ''
            }
          },
          {
            label: 'Address 4',
            value: 'address4',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address4 || ''
            }
          },
          {
            label: 'City',
            value: 'city',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.city || ''
            }
          },
          {
            label: 'District',
            value: 'district',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.district || ''
            }
          },
          {
            label: 'Post code',
            value: 'postCode',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.postCode || ''
            }
          },
          {
            label: 'Area code',
            value: 'areaCode',
            input: <AreaCodeSelect placeholder={'Select an area code'} />,
            attributes: {
              initialValue: item?.areaCode,
              rules: [
                {
                  required: true,
                  message: defaultMessage
                }
              ]
            }
          },
          {
            label: 'Zone',
            value: 'zone',
            input: <TransportZonesSelect />,
            attributes: {
              initialValue: item?.zone,
              rules: [
                {
                  required: true,
                  message: defaultMessage
                }
              ]
            }
          },
          {
            label: 'GeoJSON',
            value: 'geojson',
            input: (
              <RadioGroup buttonStyle="solid">
                <RadioButton value={true} onChange={() => setIsPolygonMode(true)}>
                  Polygon
                </RadioButton>
                <RadioButton value={false} onChange={() => setIsPolygonMode(false)}>
                  Point
                </RadioButton>
              </RadioGroup>
            ),
            attributes: {
              initialValue: isPolygonMode
            }
          },
          {
            value: 'location',
            input: <Input hidden />,
            attributes: {
              rules: [
                {
                  required: false,
                  message: defaultMessage
                }
              ]
            }
          },
          {
            label: 'Latitude',
            value: 'latitude',
            input: <InputNumber min={-90} max={90} step={0.1} style={{ minWidth: 170 }} />,
            attributes: {
              initialValue: item?.location?.coordinates[0] || ''
            }
          },
          {
            label: 'Longitude',
            value: 'longitude',
            input: <InputNumber min={-180} max={180} step={0.1} style={{ minWidth: 170 }} />,
            attributes: {
              initialValue: item?.location?.coordinates[1] || ''
            }
          },
          {
            label: 'Google Place Id',
            value: 'placeId',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.placeId || ''
            }
          },
          {
            label: 'Plus Code',
            value: 'plusCode',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.plusCode || ''
            }
          },
          {
            label: 'Phone',
            value: 'phone',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.phone || ''
            }
          },
          {
            label: 'Fax',
            value: 'fax',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.fax || ''
            }
          },
          {
            label: 'Tags',
            value: 'tags',
            input: <AddressTagsSelect />,
            attributes: {
              initialValue: item?.tags?.length ? item?.tags : undefined
            }
          },
          {
            label: 'Status',
            value: 'status',
            input: (
              <RadioGroup buttonStyle="solid">
                <RadioButton value={AddressStatus.Activated}>Active</RadioButton>
                <RadioButton value={AddressStatus.Deleted}>Deleted</RadioButton>
              </RadioGroup>
            ),
            attributes: {
              initialValue: item?.status || AddressStatus.Activated
            }
          }
        ]
      }
    ],
    [item, defaultCountryAlpha3, isPolygonMode]
  )

  return (
    <div style={{ display: 'flex', gap: 20 }}>
      <div style={{ flex: 1, minWidth: 400 }}>
        <SearchLocationInput onClickSearchResult={getGoogleMapsData} />

        {areaCodeStatus}

        <FormItemMapper fields={fields} getFieldDecorator={getFieldDecorator} />

        <FormActions
          {...props}
          onDelete={onDelete}
          googleMapsURL={googleMapsURL}
          resetAddressForm={resetAddressForm}
        />
      </div>

      <div style={{ flex: 1 }}>
        <AddressMap
          isPolygonMode={isPolygonMode}
          getFieldValue={getFieldValue}
          setFieldsValue={setFieldsValue}
        />
      </div>
    </div>
  )
}

export default memo(AddressFormModal)
