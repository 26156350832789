import { memo, useMemo } from 'react'
import { NotificationOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd-v5'
import startCase from 'lodash/startCase'
import moment from 'moment'

import ChronologyAddButton from '@/components/Workflow/Chronology/ChronologyAddButton'
import ChronologyUpdateButton from '@/components/Workflow/Chronology/ChronologyUpdateButton'
import { ChronoDate, ChronoName, Wrapper } from '@/components/Workflow/Chronology/Styled'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'
import { chronologyTypes } from '@/utils/labelMap'

const dateFormat = 'DD/MM/YYYY HH:mm'

const getLastUpdated = data => {
  if (!data) return null

  const user = data.user || {}
  const name = user.nickname || user.email || '(unknown)'
  const updatedAt = moment(data.updatedAt).format('LLL')

  return (
    <span>
      Last updated by {name} on {updatedAt}
    </span>
  )
}

const Chronology = ({ chrono, booking }) => {
  const formattedDate = useMemo(
    () => (chrono.data?.date ? moment(chrono.data.date).format(dateFormat) : 'Not set.'),
    [chrono]
  )
  const title = useMemo(() => startCase(chronologyTypes[chrono.title] || chrono.title), [chrono])

  const { hasPermission: canCreate } = usePermissionIsAllowed({
    resource: 'chronology',
    permission: 'create'
  })
  const { hasPermission: canUpdate } = usePermissionIsAllowed({
    resource: 'chronology',
    permission: 'update'
  })

  return (
    <Wrapper>
      <Tooltip title={chrono.title}>
        <ChronoName>{title}</ChronoName>
      </Tooltip>

      <ChronoDate>
        <Tooltip title={getLastUpdated(chrono.data)} placement="bottom">
          {formattedDate}
        </Tooltip>

        {chrono?.data?.remarks && (
          <Tooltip title={chrono.data.remarks} placement="bottom">
            <NotificationOutlined style={{ paddingLeft: 10, cursor: 'pointer' }} />
          </Tooltip>
        )}

        {chrono?.data?.uuid && canUpdate && (
          <ChronologyUpdateButton booking={booking} bookingUuid={booking.uuid} chrono={chrono} />
        )}

        {canCreate && (
          <ChronologyAddButton booking={booking} bookingUuid={booking.uuid} chrono={chrono} />
        )}
      </ChronoDate>
    </Wrapper>
  )
}

export default memo(Chronology)
