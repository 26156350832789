import { Form } from 'antd'
import { compose } from 'recompose'

import BookingSelect from 'App/components/Select/BookingSelect'
import UserQuery from 'App/containers/user/query/user'

const enhance = compose(UserQuery)

const FormItem = Form.Item

const BookingNoFilterFormItem = (props: any) => {
  const {
    form: { getFieldDecorator },
    handleSelectBooking,
    initialBookings
  } = props

  return (
    <FormItem label="Booking No.">
      {getFieldDecorator('bookingUuid', {
        initialValue: initialBookings
      })(<BookingSelect mode="multiple" onChange={handleSelectBooking} />)}
    </FormItem>
  )
}

export default enhance(BookingNoFilterFormItem)
