import type { Booking } from '@/types/graphql'

import { PlusOutlined } from '@ant-design/icons'
import { Flex, Spin, Table, Tooltip, Typography } from 'antd-v5'

import config from '@/config'
import useGlobalCompanyStore from '@/store/globalCompany'
import { getRef } from '@/utils/booking'

type JobTableProps = {
  bookings: Booking[]
  onHandleJobSelected: (elasticBk: Booking) => void
  loading: boolean
}

const JobTable = (props: JobTableProps) => {
  const { bookings, onHandleJobSelected, loading } = props
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const type = selectedGlobalCompany.types && selectedGlobalCompany.types[0]

  if (loading)
    return (
      <Flex justify="center">
        <Spin />
      </Flex>
    )

  if (!bookings) return <Typography.Text type="danger">No Bookings Found.</Typography.Text>

  const columns = [
    {
      title: 'Add',
      key: 'add',
      width: 20,
      render: (j: any) => <PlusOutlined onClick={() => onHandleJobSelected(j)} />
    },
    {
      title: 'No',
      key: 'noshipperRef',
      width: config.env.prod ? 45 : 60,
      ellipsis: true,
      render: (j: any) => {
        return (
          <a target="_bookings" href={`/bookings/${j.uuid}`}>
            {getRef(type, j)}
          </a>
        )
      }
    },
    {
      title: 'Shipper',
      key: 'shipper',
      width: 100,
      ellipsis: true,
      render: (j: any) => (
        <Tooltip placement="topLeft" title={j.shipper?.name} arrow={false}>
          {j.shipper?.name}
        </Tooltip>
      )
    },
    {
      title: 'Consignee',
      key: 'consignee',
      width: 100,
      ellipsis: true,
      render: (j: any) => (
        <Tooltip placement="topLeft" title={j.consignee?.name} arrow={false}>
          {j.consignee?.name}
        </Tooltip>
      )
    }
  ]

  return <Table rowKey={(r: any) => r.uuid} columns={columns} dataSource={bookings} size="small" />
}

export default JobTable
