export const getDataGql = `
  query jobTypesExport1 ($limit: Int, $offset: Int, $q: String, $statuses: [JobTypeStatus]) {
    jobTypes (limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        description
        sorting
        tripDetails {
          type
          fromCompanyTypes
          toCompanyTypes
        }
        status
      }
    }
  }
`

export const createEntityGql = `
  mutation createJobTypeExport1 ($input: UpdateJobTypeInput!) {
    createJobType (input: $input) {
      code
      uuid
      name
      description
      sorting
      status
    }
  }
`

export const updateEntityGql = `
  mutation updateJobTypeExport1 ($input: UpdateJobTypeInput!) {
    updateJobType (input: $input) {
      code
      uuid
      name
      description
      sorting
      status
    }
  }
`
