import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { Mutation } from '@apollo/client/react/components'
import { Button } from 'antd-v5'
import moment from 'moment'
import { bindActionCreators, compose } from 'redux'

import { HiddenActions, HiddenFields } from '@/components/DocumentViewer/Content/Approvals/Styled'
import FieldItem from '@/components/DocumentViewer/Content/FieldItem'
import { APPROVE_BOOKING_DOCUMENT } from '@/components/DocumentViewer/Content/schema'
import { Loader } from '@/components/DocumentViewer/Content/Styled'
import BookingDocumentQuery from '@/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import schema from '@/containers/booking/schema'
import * as bookingDocumentActions from '@/states/reducers/bookingDocument'
import handleResponse from '@/utils/responseHandler'

const HiddenView = props => {
  const {
    showHidden,
    isApproved,
    selectedBookingDocument,
    approvalType,
    updateSelectedBookingDocument,
    toggleHiddenFields,
    approvedApproval
  } = props

  const { uuid } = useParams()

  const handleApprove = approveDocument => {
    approveDocument({
      variables: {
        uuid: selectedBookingDocument.uuid,
        type: approvalType
      }
    })
  }

  const handleApproved = result => {
    const { approveBookingDocument } = result

    updateSelectedBookingDocument({
      ...approveBookingDocument,
      allowedApprovals: selectedBookingDocument.allowedApprovals
    })

    toggleHiddenFields()
  }

  const renderHiddenFields = () => {
    if (!showHidden) {
      return null
    }

    if (!isApproved) {
      return (
        <Mutation
          mutation={APPROVE_BOOKING_DOCUMENT}
          onCompleted={handleApproved}
          onError={error => handleResponse(error, 'error')}
          refetchQueries={[
            {
              query: schema.BOOKING_QUERY,
              variables: { uuid }
            },
            {
              query: BookingDocumentQuery,
              variables: {
                uuid: selectedBookingDocument.uuid
              }
            }
          ]}
        >
          {(approveDocument, { loading }) => {
            if (loading) {
              return (
                <Loader>
                  <LoadingOutlined /> Approving...
                </Loader>
              )
            }

            return (
              <HiddenActions>
                <Button onClick={toggleHiddenFields}>Not now</Button>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => handleApprove(approveDocument)}
                >
                  {loading ? 'Hang on...' : 'Approve'}
                </Button>
              </HiddenActions>
            )
          }}
        </Mutation>
      )
    }

    return (
      <>
        <FieldItem name="Approved by :" value={approvedApproval.approvedBy?.email || '-'} />
        <FieldItem
          name="Approved on :"
          value={
            (approvedApproval.createdAt &&
              moment(approvedApproval.createdAt).format('Do MMM, YYYY - h:mm a')) ||
            '-'
          }
        />
      </>
    )
  }

  if (!showHidden) {
    return null
  }

  return <HiddenFields border={isApproved}>{renderHiddenFields()}</HiddenFields>
}

export default compose(
  connect(
    state => ({
      selectedBookingDocument: state.bookingDocument.selectedBookingDocument
    }),
    dispatch => ({
      dispatch,
      // @ts-expect-error
      ...bindActionCreators({ ...bookingDocumentActions }, dispatch)
    })
  )
)(HiddenView)
