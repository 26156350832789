import find from 'lodash/find'
import sortBy from 'lodash/sortBy'

import { openPrintableInNewTab } from '@/components/Shared/documentCreator/openPrintableInNewTab'
import { NoDocument } from '@/components/Workflow/Styled'
import ViewSelector from '@/components/Workflow/Voucher/ViewSelections/ViewSelector'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'
import useGlobalCompanyStore from '@/store/globalCompany'
import { statuses } from '@/utils/voucher'

function ViewSelections(props) {
  const { section, viewableVouchers, voucherTypes, handleViewClick } = props

  const { isPortalUser } = useProcessPortalUser()
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const getSortedVoucher = (vouchers, section) => {
    const vouchersWithSection = vouchers.map(voucher => {
      const sectionVoucher = section.vouchers.find(v => v.type === voucher.type)
      return { ...voucher, sectionVoucher }
    })

    return sortBy(vouchersWithSection, [
      voucher => statuses.indexOf(voucher.status),
      voucher => voucher.sectionVoucher.type
    ])
  }

  if (!viewableVouchers.length) {
    return <NoDocument>No vouchers found.</NoDocument>
  }

  const sortedVouchers = getSortedVoucher(viewableVouchers, section)

  return (
    <>
      {sortedVouchers.map(voucher => {
        const sectionVoucher = find(section.vouchers, item => item.type === voucher.type)
        return (
          <ViewSelector
            key={voucher.uuid}
            voucher={voucher}
            isPortalUser={isPortalUser}
            voucherTypes={voucherTypes}
            sectionVoucher={sectionVoucher}
            // TODO: this handleClick is to be removed, no longer in use.
            handleClick={() => handleViewClick(voucher, sectionVoucher)}
            openPrintableVoucherInNewTab={() =>
              openPrintableInNewTab(voucher.uuid, selectedGlobalCompany.uuid, 'INVOICE')
            }
          />
        )
      })}
    </>
  )
}

export default ViewSelections
