import { CompanyStatus } from 'App/types/graphql'

import { memo, useState } from 'react'
import { Row, Skeleton } from 'antd'

import NoAccess from 'App/components/Shared/NoAccess'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import PageHeader from '../PageHeader'
import { PageView, ViewContent } from '../Styled'
import TableViewActions from './Actions'
import TableView from './TableView'

const Companies = () => {
  const [filters, updateFilters] = useState({
    types: [],
    statuses: [CompanyStatus.Activated, CompanyStatus.Deleted]
  })
  const [currentSize, updateSize] = useState(0)
  const [searchKeyword, updateSearchKeyword] = useState('')

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'company',
    permission: 'index'
  })

  if (permLoading) {
    return (
      <Row style={{ marginTop: '30px' }}>
        <Skeleton active />
      </Row>
    )
  }

  if (!hasPermission) {
    return <NoAccess />
  }

  return (
    <PageView>
      <PageHeader title="Companies" />
      <ViewContent>
        <TableViewActions
          filters={filters}
          searchKeyword={searchKeyword}
          updatePageSize={updateSize}
          updateSearchKeyword={updateSearchKeyword}
        />
        <TableView
          filters={filters}
          currentSize={currentSize}
          updateFilters={updateFilters}
          searchKeyword={searchKeyword}
          updatePageSize={updateSize}
        />
      </ViewContent>
    </PageView>
  )
}

export default memo(Companies)
