import type { BaseCompany } from '@/types/graphql'
import { TransactionType } from '@/types/graphql'

import { connect } from 'react-redux'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import useGlobalCompanyStore from '@/store/globalCompany'
import EditableFields from './EditableFields'
import { EXCHANGE_RATE } from './schema'

const enhance = connect(state => ({ selectedVoucher: state.voucher.selectedVoucher }))

const getLocalExchangeRate = (
  voucher,
  voucherItem,
  selectedGlobalCompany: BaseCompany,
  exchangeRates
) => {
  const localCurrency = selectedGlobalCompany?.currency

  const costItemCurrency =
    voucher.transactionType === TransactionType.Accpay
      ? voucherItem.costItem.costCurrencyUuid
      : voucherItem.costItem.sellCurrencyUuid

  if (costItemCurrency === voucher.currencyUuid && voucher.currencyUuid !== localCurrency?.uuid) {
    return voucher.transactionType === TransactionType.Accpay
      ? voucherItem.costItem.costExchangeRate
      : voucherItem.costItem.sellExchangeRate
  }

  if (voucher.currencyUuid !== localCurrency?.uuid) {
    const { rows = [] } = exchangeRates

    if (rows.length === 0) return undefined

    return rows[0].rate
  }

  return 1
}

const EditableFieldsWrapper = props => {
  const { selectedVoucher, selectedVoucherItem } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const { data, loading } = useQuery(EXCHANGE_RATE, {
    variables: {
      limit: 1,
      offset: 0,
      fromUuid: selectedVoucher.currency.uuid,
      toUuid: selectedGlobalCompany?.currency?.uuid,
      date: moment().format('YYYY-MM-DD')
    }
  })

  const localExchangeRate = getLocalExchangeRate(
    selectedVoucher,
    selectedVoucherItem,
    selectedGlobalCompany,
    data
  )

  const { rows = [] } = data?.exchangeRates || {}

  if (loading) return <span>Loading...</span>

  const dbExchangeRate = rows?.[0]?.rate

  return (
    <EditableFields
      {...props}
      localExchangeRate={localExchangeRate}
      dbExchangeRate={dbExchangeRate}
    />
  )
}

export default enhance(EditableFieldsWrapper)
