export const getDataGql = `
  query volumeUnitsExport1 ($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    volumeUnits (limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
  }
`

export const createEntityGql = `
  mutation createVolumeUnitExport1 ($input: CreateVolumeUnitInput!) {
    createVolumeUnit (input: $input) {
      code
      uuid
      name
      description
      sorting
      status
    }
  }
`

export const updateEntityGql = `
    mutation updateVolumeUnitExport1 ($input: UpdateVolumeUnitInput!) {
      updateVolumeUnit (input: $input) {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
`
