import { ContainerGradeStatus } from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

const sampleData = [
  {
    name: 'A',
    code: 'A',
    sorting: 1,
    description: 'A',
    status: 'ACTIVE',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: The required fields are: name (e.g. A or B), code (e.g. A), sorting (e.g. 1)'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Sorting*',
    dataIndex: 'sorting',
    key: 'sorting'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (containerGrade: any) => {
  if (!containerGrade.code) return { nullError: 'Code is required, e.g. A.' }
  if (!containerGrade.name) return { nullError: 'Name is required, e.g. A.' }
  if (!containerGrade.sorting) return { nullError: 'Sorting is required, e.g. 1.' }
}

const populateContainerGradeObj = (obj: any, containerGradeId: string) => {
  const containerGradeObj: any = {
    uuid: containerGradeId?.trim(),
    code: obj.code?.toString()?.trim(),
    name: obj.name,
    sorting: parseInt(obj.sorting),
    description: obj.description,
    status: obj.status || ContainerGradeStatus.Active
  }

  return containerGradeObj
}

const containerGradesHelper = {
  queryName: 'containerGrades',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const containerGradesQuery = {
        limit: 10000
      }
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, containerGradesQuery)
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const containerGrades = results?.data?.containerGrades?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row,
        zzz: ''
      }))
      return containerGrades
    } catch (error) {
      logger.error('containerGradesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (containerGradeData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...containerGrade } = containerGradeData

    const nullInputError = findNullInputs(containerGrade)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
        const containerGrades = results?.data?.containerGrades?.rows

        for (let i = 0; i < containerGrades?.length; i++) {
          cache.uuid[containerGrades[i]?.uuid] = containerGrades[i]?.code
          cache.code[containerGrades[i]?.code] = containerGrades[i]?.uuid
        }
      } catch (error) {
        logger.error('containerGradesHelper cache getDataGql error', error)
        return error
      }
    }

    // Check containerGradeId
    let containerGradeId: string = ''
    if (cache.uuid[containerGrade?.uuid]) {
      containerGradeId = containerGrade?.uuid
    } else if (cache.code[containerGrade?.code]) {
      containerGradeId = cache.code[containerGrade?.code]
    }

    let mutationResults
    // Update
    if (containerGradeId?.length > 0) {
      try {
        const containerGradeObj = populateContainerGradeObj(containerGrade, containerGradeId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: containerGradeObj
        })
      } catch (error) {
        logger.error('containerGradesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        containerGradeId = uuidv4()
        const containerGradeObj = populateContainerGradeObj(containerGrade, containerGradeId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: containerGradeObj
        })
      } catch (error) {
        logger.error('containerGradesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default containerGradesHelper
