import { TransportJobTypeStatus } from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

const sampleData = [
  {
    name: 'C',
    code: 'C',
    description: 'GP',
    sorting: 1,
    zone: 'ACTIVE',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks = '*Note: The required fields are: name, code'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Sorting',
    dataIndex: 'sorting',
    key: 'sorting'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (transportZone: any) => {
  if (!transportZone.code) return { nullError: 'Code is required.' }
  if (!transportZone.name) return { nullError: 'Name is required.' }
}

const populateTransportZoneObj = (obj: any, transportZoneId: string) => {
  const transportZoneObj: any = {
    uuid: transportZoneId?.trim(),
    code: obj.code?.toString()?.trim(),
    name: obj.name,
    description: obj.description,
    sorting: parseInt(obj.sorting),
    status: obj.status || TransportJobTypeStatus.Active
  }
  return transportZoneObj
}

const transportZonesHelper = {
  queryName: 'transportZones',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const result = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      const transportZones = result?.data?.transportZones?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row,
        zzz: ''
      }))
      return transportZones
    } catch (error) {
      logger.error('transportZonesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (transportZoneData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...transportZone } = transportZoneData
    const nullInputError = findNullInputs(transportZone)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const transportZonesQuery = {
          limit: 10000
        }
        const result = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          input: transportZonesQuery
        })
        const transportZones = result?.data?.transportZones?.rows

        for (let i = 0; i < transportZones?.length; i++) {
          cache.uuid[transportZones[i]?.uuid] = transportZones[i]
          cache.code[transportZones[i]?.code] = transportZones[i]
        }
      } catch (error) {
        logger.error('transportZonesHelper handleImportData error', error)
        return error
      }
    }

    // Check transportZoneId
    let transportZoneId: string = ''
    if (cache.uuid[transportZone?.uuid]) {
      transportZoneId = transportZone?.uuid
    } else if (cache.code[transportZone?.code]) {
      transportZoneId = cache.code[transportZone?.code]
    }

    let mutationResults
    // Update
    if (transportZoneId?.length > 0) {
      try {
        const transportZoneObj = populateTransportZoneObj(transportZone, transportZoneId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: transportZoneObj
        })
      } catch (error) {
        logger.error('transportZonesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        transportZoneId = uuidv4()
        const transportZoneObj = populateTransportZoneObj(transportZone, transportZoneId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: transportZoneObj
        })
      } catch (error) {
        logger.error('transportZonesHelper handleImportData createEntityGql error', error)
        return error
      }
    }
    return mutationResults
  },
  mapFromCsv,
  sampleData,
  remarks,
  tableColumns
}
export default transportZonesHelper
