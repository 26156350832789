import { Input, Typography } from 'antd-v5'

import EmptyAnchor from '@/components/EmptyAnchor'

const SelectedVouchersView = (props: any) => {
  const { selectedVouchers = [], onClear = () => {} } = props

  const voucherNumbers = selectedVouchers
    .map(v => v?.voucherNumber || v?.uuid.substring(0, 8))
    .join(', ')

  const isSelectedVouchers = selectedVouchers.length

  return (
    <>
      <div className="flex justify-between">
        <Typography.Text>Selected vouchers:</Typography.Text>

        {!!isSelectedVouchers && (
          <Typography.Text onClick={onClear}>
            <EmptyAnchor>Clear</EmptyAnchor>
          </Typography.Text>
        )}
      </div>

      <Input disabled value={voucherNumbers} />
    </>
  )
}

export default SelectedVouchersView
