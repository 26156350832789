import type { LoggedInUserType } from '@/hocs/types/auth0'
import type { Voucher } from '@/types/graphql'
import type { MenuProps, TabsProps } from 'antd-v5'
import { gql } from '@/types'

import { memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { EllipsisOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { Button, Card, Dropdown, Result, Skeleton, Tabs, Typography } from 'antd-v5'
import download from 'downloadjs'
import queryString from 'query-string'

import NoAccess from '@/components/Shared/NoAccess'
import BulkApproveButton from '@/components/Voucher/Management/BulkApproveButton'
import BulkSubmitButton from '@/components/Voucher/Management/BulkSubmitButton'
import ConsolidateInvoiceButton from '@/components/Voucher/Management/ConsolidateInvoiceButton'
import ConsolVoucherManagement from '@/components/Voucher/Management/ConsolVoucherManagement'
import ExportButton from '@/components/Voucher/Management/ExportButton'
import SelectedVouchersView from '@/components/Voucher/Management/SelectedVouchersView'
import { ListWrapper } from '@/components/Voucher/Management/Styled'
import VoucherFilter from '@/components/Voucher/Management/VoucherFilter/VoucherFilter'
import VoucherSelectionTable from '@/components/Voucher/Management/VoucherSelectionTable'
import VoucherUrlLoader from '@/components/Voucher/Management/VoucherUrlLoader'
import VoucherModalView from '@/components/Voucher/ModalView'
import { TableWrapper } from '@/components/Voucher/Styled'
import config from '@/config'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'
import useGlobalCompanyStore from '@/store/globalCompany'
import useUserStore from '@/store/user'
import auth, { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'
import webStorage from '@/utils/webStorage'

const VOUCHERS_MAIN_QUERY = gql(`
  query vouchersMainEs($input: VouchersSearchInput) {
    vouchersSearchJson(input: $input) {
      rows
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

const VoucherManagement = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const user: LoggedInUserType = auth.getProfile() || useUserStore.use.loggedInUser()
  const { loading: portalLoading, isPortalUser, baseCompanyUuids } = useProcessPortalUser(user)

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const visible = useSelector(state => state.voucher.showVoucher)

  const [lastUpdateTime, setLastUpdateTime] = useState()
  const [activeKey, setActiveKey] = useState<string>('vouchers')
  const [selectedVouchers, setSelectedVouchers] = useState<Voucher[]>([])

  const [query, setQuery] = useState({
    q: '',
    limit: 20,
    offset: 0,
    filter: {}
  })

  const [getVouchers, { data, error, loading, refetch }] = useLazyQuery(VOUCHERS_MAIN_QUERY, {
    variables: { input: query },
    fetchPolicy: 'cache-and-network'
  })

  const { loading: permissionLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'voucher',
    permission: 'index'
  })

  const { hasPermission: consolPermission } = usePermissionIsAllowed({
    resource: 'consolVoucher',
    permission: 'index'
  })

  const handleVouchersChange = useCallback(
    (selected: Voucher, changedVouchers: Voucher[]) => {
      if (selected) {
        setSelectedVouchers([...selectedVouchers, ...changedVouchers])
      } else {
        const filterSelectedVouchers = selectedVouchers.filter(v =>
          changedVouchers.every(r => r.uuid !== v.uuid)
        )
        setSelectedVouchers([...filterSelectedVouchers])
      }
    },
    [selectedVouchers]
  )

  const handleGenerateAdvice = useCallback(() => {
    const location = {
      pathname: '/vouchers/advice',
      search: queryString.stringify({
        uuids: selectedVouchers.map(v => v.uuid)
      })
    }
    navigate(location)
  }, [selectedVouchers, navigate])

  const handleGenerateSummary = useCallback(() => {
    const location = {
      pathname: '/vouchers/summary',
      search: queryString.stringify({
        uuids: selectedVouchers.map(v => v.uuid)
      })
    }
    navigate(location)
  }, [selectedVouchers, navigate])

  const queuePdf = useCallback(
    async (uuid: string) => {
      try {
        const documentCreatorUrl = `${config.api.baseUrl}/documentCreator/INVOICE/${uuid}/${selectedGlobalCompany.uuid}`
        const pdfServiceUrl = `${config.pdf.baseUrl}/queue`

        const bodyData = {
          url: documentCreatorUrl,
          header: {
            authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
            'base-company-uuid': selectedGlobalCompany.uuid
          }
        }

        const response = await fetch(pdfServiceUrl, {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        })

        if (!response.ok) {
          throw new Error(await response.text())
        }

        const body = await response.json()
        const pdfUuid = body.uuid

        return pdfUuid
      } catch (error) {
        logger.error('VoucherManagement queuePdf error', error)
        respHandler(error, 'error')
      }
    },
    [selectedGlobalCompany]
  )

  const checkPdfStatus = useCallback(
    (pdfUuid: string) => {
      return new Promise((resolve, reject) => {
        const pdfServiceUrl = `${config.pdf.baseUrl}/status/${pdfUuid}`

        const intervalId = setInterval(async () => {
          try {
            const response = await fetch(pdfServiceUrl)

            if (!response.ok) {
              throw new Error(await response.text())
            }

            const body = await response.json()

            if (body.finishedOn && body.finishedOn > 0) {
              clearInterval(intervalId)
              resolve(body)
            }
          } catch (error) {
            logger.error('VoucherManagement checkPdfStatus error', error)
            respHandler(error, 'error')
            clearInterval(intervalId)
            reject(error)
          }
        }, 3000)
      })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config]
  )

  const downloadPdf = useCallback(async (pdfUuid: string, voucher: Voucher) => {
    try {
      const downloadUrl = `${config.pdf.baseUrl}/pdf/${pdfUuid}`
      const response = await fetch(downloadUrl, { method: 'GET' })
      const blob = await response.blob()
      // @ts-ignore
      download(blob, `${voucher.voucherNumber || voucher.uuid}.pdf`)
    } catch (error) {
      logger.error('VoucherManagement downloadPdf error', error)
      respHandler(error, 'error')
    }
  }, [])

  const handleDownload = useCallback(async () => {
    respHandler('Downloading PDF files, please wait...', 'load')

    try {
      for (let i = 0; i < selectedVouchers.length; i++) {
        const voucher = selectedVouchers[i]

        const pdfUuid = await queuePdf(voucher.uuid)
        await checkPdfStatus(pdfUuid)
        await downloadPdf(pdfUuid, voucher)
      }

      respHandler('All downloaded successfully.', 'success')
    } catch (error) {
      logger.error('VoucherManagement handleDownload error', error)
      respHandler(error, 'error')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVouchers])

  const handleTabChange = useCallback(
    (activeKey: string) => {
      setActiveKey(activeKey)
      setLastUpdateTime(activeKey === 'consolidated' ? new Date().getTime() : lastUpdateTime)
    },
    [lastUpdateTime]
  )

  const handleClearSelectedVouchers = useCallback(() => setSelectedVouchers([]), [])

  const refreshVouchers = useCallback(() => navigate(location), [location, navigate])

  useEffect(() => {
    const interval = setInterval(() => navigate(location), 300000)
    return () => clearInterval(interval)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (query?.limit === data?.vouchersSearchJson?.pageInfo?.count) {
      setSelectedVouchers(data ? [...data.vouchersSearchJson.rows] : [])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (permissionLoading || portalLoading) {
    return <Skeleton active />
  }

  if (!baseCompanyUuids?.length) return <NoAccess />

  if (error) {
    logger.error('VoucherManagement VOUCHER_SEARCH_QUERY error', error)
    respHandler(error, 'error')
  }

  const dropdownMenuItems: MenuProps['items'] = [
    !isPortalUser
      ? {
          key: 'generateAdvice',
          disabled: selectedVouchers.length === 0,
          label: (
            <div onClick={handleGenerateAdvice}>
              <Typography.Text>Generate Advice</Typography.Text>
            </div>
          )
        }
      : null,
    !isPortalUser
      ? {
          key: 'generateSummary',
          disabled: selectedVouchers.length === 0,
          label: (
            <div onClick={handleGenerateSummary}>
              <Typography.Text>Generate Summary</Typography.Text>
            </div>
          )
        }
      : null,
    {
      key: 'download',
      disabled: selectedVouchers.length === 0,
      label: (
        <div onClick={handleDownload}>
          <Typography.Text>Download</Typography.Text>
        </div>
      )
    }
  ]

  const tabItems: TabsProps['items'] = [
    {
      key: 'vouchers',
      label: 'Vouchers',
      children: (
        <>
          <div className="mb-4">
            <VoucherFilter
              query={query}
              setQuery={setQuery}
              refetch={getVouchers}
              loggedInUser={user}
            />
          </div>

          <div className="flex justify-end gap-10 mb-4">
            {!isPortalUser && (
              <div className="flex gap-10">
                <ExportButton vouchers={selectedVouchers} />

                <BulkSubmitButton
                  refetch={refetch}
                  vouchers={selectedVouchers}
                  onVouchersSubmitted={refreshVouchers}
                />

                <BulkApproveButton
                  vouchers={selectedVouchers}
                  onVouchersApproved={refreshVouchers}
                />

                <ConsolidateInvoiceButton vouchers={selectedVouchers} />
              </div>
            )}

            <Dropdown menu={{ items: dropdownMenuItems }}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </div>

          {!hasPermission ? (
            <NoAccess />
          ) : error ? (
            <Result status="warning" title="Failed to load vouchers." />
          ) : (
            <>
              <SelectedVouchersView
                selectedVouchers={selectedVouchers}
                onClear={handleClearSelectedVouchers}
              />

              <Card bordered={false} size="small">
                <TableWrapper>
                  <VoucherUrlLoader />

                  {visible && <VoucherModalView />}

                  <VoucherSelectionTable
                    // @ts-ignore
                    query={query}
                    loading={loading}
                    setQuery={setQuery}
                    selectedVouchers={selectedVouchers}
                    data={data?.vouchersSearchJson || []}
                    onVouchersChange={handleVouchersChange}
                    selectedGlobalCompany={selectedGlobalCompany}
                  />
                </TableWrapper>
              </Card>
            </>
          )}
        </>
      )
    },
    {
      key: 'consolidated',
      label: 'Consolidated',
      children: (
        <>
          {!consolPermission ? (
            <NoAccess />
          ) : (
            <ConsolVoucherManagement lastUpdateTime={lastUpdateTime} />
          )}
        </>
      )
    }
  ]

  return (
    <ListWrapper>
      <Tabs
        type="card"
        items={tabItems}
        activeKey={activeKey}
        onChange={handleTabChange}
        defaultActiveKey="vouchers"
      />
    </ListWrapper>
  )
}

export default memo(VoucherManagement)
