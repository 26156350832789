import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { bindActionCreators } from 'redux'

import { getBkQueryVariables } from '@/components/Booking/bookingHelper'
import EditJobAndTrip from '@/components/DocumentViewer/Content/Activities/EditJobTrip'
import {
  Actions,
  Activities,
  SectionName,
  Wrapper
} from '@/components/DocumentViewer/Content/Activities/Styled'
import DocumentVerifyView from '@/components/DocumentViewer/Content/Activities/Verify'
import ApprovalsView from '@/components/DocumentViewer/Content/Approvals'
import EditableInput from '@/components/DocumentViewer/Content/EditableInput'
import FieldItem from '@/components/DocumentViewer/Content/FieldItem'
import { UPDATE_BOOKING_DOCUMENT } from '@/components/DocumentViewer/Content/schema'
import BookingDocumentQuery from '@/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import schema from '@/containers/booking/schema'
import * as bookingDocumentActions from '@/states/reducers/bookingDocument'

const ActivitiesView = props => {
  const { bookingDocument, toggleDocumentViewer, bookingQuery, refreshBooking } = props
  const params = useParams()

  const onHandleUpdates = (update, key, value) => {
    update({
      variables: {
        uuid: bookingDocument.uuid,
        type: bookingDocument.type,
        [key]: value
      }
    })
  }

  const handleCompleted = result => {
    const { updateBookingDocument } = result
    props.updateSelectedBookingDocument({
      ...updateBookingDocument,
      allowedApprovals: bookingDocument.allowedApprovals
    })
  }

  const renderActions = () => {
    return (
      <Actions>
        <DocumentVerifyView
          bookingQuery={bookingQuery}
          bookingDocument={bookingDocument}
          toggleDocumentViewer={toggleDocumentViewer}
          refreshBooking={refreshBooking}
        />
      </Actions>
    )
  }

  const showJobTripUpdate =
    bookingDocument?.allowedApprovals?.isJobRequired ||
    bookingDocument?.allowedApprovals?.isJobRequired

  const mutationProps = {
    mutation: UPDATE_BOOKING_DOCUMENT,
    refetchQueries: [
      {
        query: schema.BOOKING_QUERY,
        variables: getBkQueryVariables(params)
      },
      {
        query: BookingDocumentQuery,
        variables: {
          uuid: bookingDocument.uuid
        }
      }
    ],
    onCompleted: handleCompleted
  }

  return (
    <Wrapper>
      <Activities>
        <SectionName>Approvals</SectionName>
        <ApprovalsView
          bookingDocument={bookingDocument}
          approvals={bookingDocument.allowedApprovals && bookingDocument.allowedApprovals.approvals}
          approvedApprovals={bookingDocument.approvals}
        />
        <SectionName>Activities</SectionName>
        {showJobTripUpdate && (
          <FieldItem name="Job & Trip :" value={<EditJobAndTrip {...props} />} />
        )}
        <FieldItem
          name="Reference :"
          value={
            <EditableInput
              value={bookingDocument.reference}
              fieldName="reference"
              mutationProps={mutationProps}
              handleUpdates={onHandleUpdates}
            />
          }
        />
        <FieldItem
          name="Remarks :"
          value={
            <EditableInput
              value={bookingDocument.remarks}
              fieldName="remarks"
              mutationProps={mutationProps}
              handleUpdates={onHandleUpdates}
            />
          }
        />
        <FieldItem
          name="Tags :"
          value={
            <EditableInput
              type="tags"
              value={bookingDocument.tags}
              fieldName="tags"
              mutationProps={mutationProps}
              handleUpdates={onHandleUpdates}
            />
          }
        />
        <FieldItem name="Status :" value={bookingDocument.status} type="bold" />
        <FieldItem
          name="Last updated on :"
          value={
            bookingDocument.updatedAt &&
            moment(bookingDocument.updatedAt).format('Do MMM, YYYY - h:mm a')
          }
        />
        <FieldItem
          name="Created on :"
          value={
            bookingDocument.createdAt &&
            moment(bookingDocument.createdAt).format('Do MMM, YYYY - h:mm a')
          }
        />
        <FieldItem
          name="Created by :"
          value={bookingDocument.createdBy && bookingDocument.createdBy.email}
        />
      </Activities>
      {renderActions()}
    </Wrapper>
  )
}

export default connect(
  () => ({}),
  // @ts-expect-error
  dispatch => ({ dispatch, ...bindActionCreators({ ...bookingDocumentActions }, dispatch) })
)(ActivitiesView)
