export const getDataGql = `
  query containerTypesExport1 ($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    containerTypes (limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
  }
`

export const createEntityGql = `
  mutation createContainerTypeExport1 ($input: CreateContainerTypeInput!) {
    createContainerType (input: $input) {
      code
      uuid
      name
      description
      sorting
      status
    }
  }
`

export const updateEntityGql = `
    mutation updateContainerTypeExport1 ($input: UpdateContainerTypeInput!) {
      updateContainerType (input: $input) {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
`
