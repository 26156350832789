import type { AddressType } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { useEffect, useState } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Form } from 'antd'
import { Modal, notification, Select } from 'antd-v5'
import { debounce } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { v4 as uuidv4 } from 'uuid'

import PageHeader from '@/components/Manage/PageHeader'
import { HeaderItem, ModalHeader } from '@/components/Manage/Styled'
import AddressFormModal from '@/components/Shared/AddressFormModal'
import { ADDRESS_CREATE } from '@/graphql/address'
import { formatCompanyAddress } from '@/utils/company'
import handleResponse from '@/utils/responseHandler'
import { ADDRESSES_QUERY } from './schema'
import { BoldSecondaryText, QuickAddDiv, SecondaryText, Separator } from './Styled'

const Option = Select.Option

type AddressSelectProps = SelectProps & {
  form: WrappedFormUtils
  quickAdd?: boolean
  companyUuid?: string
  type?: AddressType[]
}

const AddressSelect = (props: AddressSelectProps) => {
  const { form, companyUuid, quickAdd, type, ...rest } = props
  const { value, onChange, disabled } = props

  const [uuid] = useState(uuidv4())
  const [isAddressFormOpen, setIsAddressFormOpen] = useState(false)

  const { data, refetch } = useQuery(ADDRESSES_QUERY, {
    variables: {
      type: type || [],
      companyUuid
    },
    fetchPolicy: 'cache-first',
    skip: isEmpty(companyUuid)
  })

  const handleSelect = value => onChange?.(value)

  const handleSearch = debounce(value => {
    refetch({
      q: value,
      type,
      companyUuid
    })
  }, 300)

  const handleSubmit = async (e, addAddress, companyId) => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const input = {
            uuid,
            ...formatCompanyAddress(values),
            companyUuid: companyId
          }

          await addAddress({
            variables: {
              input
            }
          })

          notification.success({
            message: 'Submit Result',
            description: 'Address has been created successfully, please refresh'
          })
        } catch (error) {
          handleResponse(error, 'error')
        }
      }
    })
  }

  const handleFormModalVisibility = () => {
    setIsAddressFormOpen(!isAddressFormOpen)
  }

  const handleCompleted = () => {
    refetch()
    handleFormModalVisibility()
    form.resetFields()
    onChange?.(uuid)
  }

  useEffect(() => {
    const addresses = data?.addresses?.rows || []
    if (addresses?.length === 1 && (!value || !addresses.find(a => a?.uuid === value)))
      onChange?.(addresses?.[0]?.uuid)
  }, [data?.addresses?.rows, value, onChange])

  const addresses = data?.addresses?.rows || []
  const selectedAddress = addresses.find(a => a?.uuid === value)
  const bindingValue = selectedAddress?.uuid || undefined

  const renderQuickAdd = () => (
    <QuickAddDiv>
      <span onClick={handleFormModalVisibility}>
        <PlusCircleOutlined />
        Add Address
      </span>
      <Modal
        footer={null}
        width={'80%'}
        style={{ top: 20 }}
        open={isAddressFormOpen}
        onCancel={handleFormModalVisibility}
        title={
          <ModalHeader>
            <HeaderItem span={20}>
              <PageHeader type="small" title="Address Details" />
            </HeaderItem>
          </ModalHeader>
        }
      >
        <Mutation mutation={ADDRESS_CREATE} onCompleted={handleCompleted}>
          {addAddress => (
            <AddressFormModal
              form={form}
              mode="create"
              submitText="Submit"
              companyId={companyUuid}
              onCustomSubmit={e => handleSubmit(e, addAddress, companyUuid)}
            />
          )}
        </Mutation>
      </Modal>
    </QuickAddDiv>
  )

  return (
    <>
      <Select
        {...rest}
        showSearch
        filterOption
        value={bindingValue}
        onChange={handleSelect}
        onSearch={handleSearch}
        optionLabelProp="name"
        optionFilterProp="children"
        notFoundContent="No items found."
        placeholder="Select an address..."
      >
        {addresses.map(address => (
          <Option key={address?.uuid} value={address?.uuid} name={address?.name}>
            {address?.name}
            <Separator />
            <SecondaryText>{address?.address1 || '-'}</SecondaryText>
            {address?.address2 && <SecondaryText>{address?.address2}</SecondaryText>}
            {address?.address3 && <SecondaryText>{address?.address3}</SecondaryText>}
            {address?.address4 && <SecondaryText>{address?.address4}</SecondaryText>}
            <div style={{ display: 'flex' }}>
              <SecondaryText>{`${address?.postCode || ''},`}&nbsp;</SecondaryText>
              {address?.areaCode && (
                <BoldSecondaryText>{address?.areaCode}&nbsp;</BoldSecondaryText>
              )}
              <SecondaryText>{`[${address?.zone}]`}</SecondaryText>
            </div>
          </Option>
        ))}
      </Select>
      {quickAdd && !disabled ? renderQuickAdd() : null}
    </>
  )
}

export default Form.create()(AddressSelect)
