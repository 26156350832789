import { gql, useQuery } from '@apollo/client'
import { Form, Select } from 'antd'
import flow from 'lodash/flow'
import map from 'lodash/fp/map'
import sortBy from 'lodash/fp/sortBy'
import uniq from 'lodash/uniq'

import useBaseCompanyType from 'App/hooks/useBaseCompanyType'
import { voucherTypes } from 'App/utils/labelMap'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'

const processTypes = flow(
  uniq,
  map(t => {
    return {
      label: (voucherTypes[t] && voucherTypes[t].text) || t,
      type: t
    }
  }),
  sortBy(['label'])
)

const FormItem = Form.Item
const Option = Select.Option

const VoucherTypeFilterFormItem = props => {
  const {
    form: { getFieldDecorator },
    value
  } = props
  const { data: currentCompanyData = [] } = useBaseCompanyType()
  const bookingTypes = currentCompanyData?.map(d => d.meta.type)
  let actionTemplatesGql = ''
  bookingTypes.forEach(bt => {
    actionTemplatesGql += `actionTemplates${bt}: actionTemplates(resource: $resource, bookingType:${bt}) {
      ... on VoucherTemplate {
        type
      }
    }`
  })
  const voucherTypeGql = gql`
    query getActionTemplates ($resource: templateResource!) {
      ${actionTemplatesGql}
    }
  `
  const { error: queryError, data: voucherTypesQuery } = useQuery(voucherTypeGql, {
    variables: { resource: 'voucher' }
  })
  if (queryError) {
    logger.error('VoucherTypeFilterFormItem voucherTypeGql error', queryError)
    respHandler(queryError, 'error')
  }

  if (bookingTypes.length < 1) {
    return null
  }

  let voucherTypes = []
  if (voucherTypesQuery) {
    for (const key in voucherTypesQuery) {
      voucherTypes.push(processTypes([...(voucherTypesQuery[key]?.map(t => t.type) || [])]))
    }
  }
  voucherTypes = voucherTypes.flat()

  return (
    <FormItem label="Voucher Type">
      {getFieldDecorator('voucherType', {
        initialValue: value
      })(
        <Select mode="multiple" placeholder="Please select">
          {voucherTypes.length > 0 &&
            voucherTypes.map(t => (
              <Option key={t.type} value={t.type}>
                {t.label}
              </Option>
            ))}
        </Select>
      )}
    </FormItem>
  )
}

export default VoucherTypeFilterFormItem
