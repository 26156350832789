import { Form } from 'antd'
import moment from 'moment'

import DateRangePicker from 'App/components/Form/DateRangePicker'

const FormItem = Form.Item

const IssueDateFilterFormItem = (props: any) => {
  const {
    form: { getFieldDecorator },
    value = {}
  } = props

  const initialValue = {
    from: (value['>='] && moment(value['>='][0])) || null,
    to: (value['<='] && moment(value['<='][0])) || null
  }

  return (
    <FormItem label="Issue Date">
      {getFieldDecorator('issueDate', {
        initialValue
      })(<DateRangePicker />)}
    </FormItem>
  )
}

export default IssueDateFilterFormItem
