import type { GeneratedDocumentQuery } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Button, Dropdown, Menu, Modal, Spin } from 'antd'
import isEmpty from 'lodash/isEmpty'

import { GENERATED_DOCUMENT_QUERY } from '@/components/Booking/General/DocumentGeneratorSection/schema'
import DocumentCreatorModalContent from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalContent'
import ModalFooter from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalFooter'
import config from '@/config'
import { useBookingStore } from '@/store/booking'

interface DocumentCreatorModalProps {
  afterClose?: () => void
  generatedDocumentUuid: string
}

const DocumentCreatorModal = (props: DocumentCreatorModalProps) => {
  const { afterClose = () => {}, generatedDocumentUuid } = props

  const { t } = useTranslation()

  const [visible, setVisible] = useState(true)
  const [generatedDocument, setGeneratedDocument] = useState<
    GeneratedDocumentQuery['generatedDocument'] | undefined
  >(undefined)

  const { data, loading, error, refetch } = useQuery(GENERATED_DOCUMENT_QUERY, {
    variables: {
      uuid: generatedDocumentUuid
    }
  })

  useEffect(() => {
    if (!generatedDocument) setGeneratedDocument(data?.generatedDocument || undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleChange = async () => {
    const { data } = await refetch()
    setGeneratedDocument(data?.generatedDocument || undefined)
  }

  const setDocumentGeneratorModalRefetch = useBookingStore.use.setDocumentGeneratorModalRefetch()
  const resetDocumentGeneratorModalRefetch =
    useBookingStore.use.resetDocumentGeneratorModalRefetch()

  useEffect(() => {
    setDocumentGeneratorModalRefetch(refetch)

    return () => {
      resetDocumentGeneratorModalRefetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const burgerMenu = (
    <Menu>
      <Menu.Item>
        <Button
          type="link"
          icon="export"
          href={`${config.documentGenerator.baseUrl}/preview?document=${generatedDocument?.uuid}`}
          target="_blank"
          size="small"
        >
          Print ROT
        </Button>
      </Menu.Item>
    </Menu>
  )

  const Title = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '97%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'end'
        }}
      >
        <div>{t('booking.documentGenerator.generic')}</div>
        <Dropdown overlay={burgerMenu} placement="bottomRight">
          <MenuOutlined style={{ fontSize: 14, cursor: 'pointer', color: '#858585' }} />
        </Dropdown>
      </div>
    )
  }

  return (
    <Modal
      style={{ top: 20 }}
      destroyOnClose
      title={<Title />}
      width="97%"
      visible={visible}
      footer={
        <Spin spinning={loading}>
          <ModalFooter
            generatedDocument={generatedDocument}
            onChange={handleChange}
            onClose={() => setVisible(false)}
          />
        </Spin>
      }
      onCancel={() => setVisible(false)}
      afterClose={afterClose}
    >
      {error && <span>{t('common.errorOccurred')}</span>}

      {loading && <span>{t('common.loading')}</span>}

      {!error && !isEmpty(generatedDocument) && (
        <DocumentCreatorModalContent generatedDocument={generatedDocument} />
      )}
    </Modal>
  )
}

export default DocumentCreatorModal
