import { forwardRef, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useLazyQuery } from '@apollo/client'
import { Select } from 'antd'

import { logger } from 'App/utils/logger'

export const BASE_COMPANY_SETTING = gql`
  query departmentSelect($key: String) {
    setting(key: $key) {
      key
      setting {
        value
        type
      }
    }
  }
`

const DepartmentSelect = forwardRef((props: any, ref: any) => {
  const { t } = useTranslation()

  const {
    value,
    style,
    onChange,
    disabled = false,
    mode = 'multiple',
    setIsDeptRequired,
    queryOnMount = false
  } = props

  const [departments, setDepartments] = useState([])

  const [getDepartments, { data, error, loading }] = useLazyQuery(BASE_COMPANY_SETTING, {
    variables: { key: 'bookingDepartments' },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (queryOnMount || value?.length !== 0) {
      getDepartments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data?.setting) {
      const bookingDepartments = data?.setting?.setting?.[0]?.value
      if (bookingDepartments) {
        const parsedSettings = JSON.parse(bookingDepartments)
        setIsDeptRequired && setIsDeptRequired(parsedSettings?.isRequired)
        return setDepartments(parsedSettings?.departments)
      }
      return setDepartments([])
    }
  }, [data, setIsDeptRequired])

  if (error) {
    //@ts-ignore
    if (error.graphQLErrors?.[0]?.extensions?.exception?.statusCode !== 404) {
      logger.error('DepartmentSelect BASE_COMPANY_SETTING error', error)
    }
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return

    getDepartments()
  }

  return (
    <Select
      ref={ref}
      allowClear
      showSearch
      value={value}
      mode={mode}
      loading={loading}
      onChange={onChange}
      disabled={disabled}
      style={{ ...style }}
      filterOption={false}
      onFocus={fetchOnFocus}
      placeholder={t('common.selectDepartment')}
      notFoundContent={loading ? t('common.searching') : t('common.notFound')}
    >
      {departments?.map((department: string) => (
        <Select.Option key={department} value={department}>
          {department}
        </Select.Option>
      ))}
    </Select>
  )
})

export default memo(DepartmentSelect)
