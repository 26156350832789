import type { EmptyTrip } from 'App/types/graphql'

export const combineJobs = (
  onlyShowLaden: any,
  onlyShowMT: any,
  query: any,
  jobsData: any,
  emptyTripsData: any
) => {
  if (onlyShowLaden(query)) {
    return jobsData?.transportJobs?.rows?.length ? jobsData.transportJobs.rows : []
  }

  if (onlyShowMT(query)) {
    return emptyTripsData?.emptyTrips?.rows?.length
      ? emptyTripsData.emptyTrips.rows.map((item: EmptyTrip) => ({ ...item, isEmptyTrip: true }))
      : []
  }

  let combinedJobs: any = []
  // append laden job
  if (jobsData?.transportJobs?.rows?.length) {
    combinedJobs = [...combinedJobs, ...jobsData.transportJobs.rows]
  }
  // append MT job
  if (emptyTripsData?.emptyTrips?.rows?.length) {
    combinedJobs = [
      ...combinedJobs,
      ...emptyTripsData.emptyTrips.rows.map((item: EmptyTrip) => ({ ...item, isEmptyTrip: true }))
    ]
  }
  return combinedJobs
}

export const updateLegStatus = (selectedJobs, legUuid, update) => {
  if (!selectedJobs?.length) {
    return
  }
  const curr = [...selectedJobs]
  const updatedLeg = curr.find(job => job.legUuid === legUuid)
  if (!updatedLeg) {
    return
  }
  const index = curr.findIndex(leg => leg.legUuid === legUuid)
  const newUpdatedLeg = {
    ...updatedLeg,
    ...update
  }

  return { newUpdatedLeg, index }
}
