import { connect } from 'react-redux'
import { calculateVoucher } from '@shipx/formula2'
import { Col } from 'antd-v5'
import { add, round, subtract } from 'mathjs'
import numeral from 'numeral'
import { withHandlers } from 'recompose'
import { bindActionCreators, compose } from 'redux'

import FormattedCurrency from '@/components/Formatted/Currency'
import { Summary } from '@/components/Voucher/Styled'
import * as voucherActions from '@/states/reducers/voucher'
import useGlobalCompanyStore from '@/store/globalCompany'

const { LabelItem, ValueItem, SummaryItem, SummaryLabels, SummaryValues, SummaryWrapper } = Summary

// TODO: to remove this function and mathjs package once the @shipx/formula2 is updated
// this function copied over from backend src/utils/vouchers/changeStatus.ts:183
export const computeVoucher = (voucherItems, voucher) => {
  const sums = voucherItems
    .filter(vi => !vi.isDeleted)
    .reduce(
      (rtn, vi) => {
        rtn.taxRounding = add(rtn.taxRounding, round(vi.taxTotal, 2))
        rtn.exchangeRounding = 0 //add(rtn.exchangeRounding, round(vi.localSubTotal || 0, 2))

        return rtn
      },
      { taxRounding: 0, exchangeRounding: 0 }
    )

  const taxRounding = round(subtract(round(parseFloat(voucher.taxTotal), 2), sums.taxRounding), 2)
  const exchangeRounding = round(
    subtract(round(parseFloat(voucher.localSubTotal), 2), sums.exchangeRounding),
    2
  )

  return {
    exchangeRounding,
    taxRounding
  }
}

const enhance = compose(
  connect(
    state => ({ showDeletedVoucherItems: state.voucher.showDeletedVoucherItems }),
    dispatch => ({
      dispatch,
      // @ts-expect-error
      ...bindActionCreators({ ...voucherActions }, dispatch)
    })
  ),
  withHandlers({
    toggleVoucherItemsView: props => async e => {
      if (e.target && e.target.value) {
        const { updateVoucherItemsView } = props

        updateVoucherItemsView(e.target.value)
      }
    },
    toggleVoucherItemsDeletedView: props => () => {
      const { showDeletedVoucherItems, updateVoucherItemsDeletedView } = props

      updateVoucherItemsDeletedView(!showDeletedVoucherItems)
    }
  })
)

const SummaryView = props => {
  const { selectedVoucher } = props
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  let total = numeral(0)
  let subtotal = numeral(0)
  let taxTotal = numeral(0)
  let taxRounding = numeral(0)

  const calculatedVoucher =
    selectedVoucher.status === 'NEW' ? calculateVoucher(selectedVoucher) : selectedVoucher
  const computedVoucher = computeVoucher(selectedVoucher.voucherItems, calculatedVoucher)

  subtotal = numeral(calculatedVoucher.subTotal)
  taxTotal = numeral(calculatedVoucher.taxTotal)
  taxRounding = numeral(computedVoucher.taxRounding)
  total = numeral(calculatedVoucher.total)

  const summary: { value; label; state; currencyCode? }[] = [
    {
      label: 'Sub Total',
      value: subtotal.value(),
      state: 'normal'
    },
    {
      label: 'Tax Total',
      value: taxTotal.value(),
      state: 'normal'
    },
    {
      label: 'Net Total',
      value: total.value(),
      state: 'highlight'
    }
  ]
  const summary2 = [
    {
      label: 'Tax Rounding',
      value: taxRounding,
      state: 'normal'
    }
  ]

  if (selectedVoucher?.currency?.uuid !== selectedGlobalCompany?.company?.currency?.uuid) {
    const localExRate = selectedVoucher?.voucherItems?.find(
      vi => vi.currency?.uuid === selectedVoucher?.currency?.uuid
    )?.localExchangeRate

    summary.push({
      label: 'Local Total',
      currencyCode: selectedGlobalCompany?.company?.currency?.code,
      value: (total.value() ?? 1) * localExRate,
      state: 'highlight'
    })
  }

  const renderSummaries = summaries => {
    return summaries.map(summary => (
      <SummaryItem key={summary.label} state={summary.state}>
        <SummaryLabels span={12}>
          <LabelItem>{summary.label} : </LabelItem>
        </SummaryLabels>
        <SummaryValues span={12}>
          <ValueItem>
            <FormattedCurrency
              value={summary.value}
              currency={summary.currencyCode || selectedVoucher.currency?.code}
            />
          </ValueItem>
        </SummaryValues>
      </SummaryItem>
    ))
  }

  return (
    <SummaryWrapper>
      <Col span={12} />
      <Col span={6}>{renderSummaries(summary2)}</Col>
      <Col span={6}>{renderSummaries(summary)}</Col>
    </SummaryWrapper>
  )
}

export default enhance(SummaryView)
