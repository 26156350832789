import { BillingUnitStatus } from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

const sampleData = [
  {
    no: 1,
    code: 'TRIP',
    name: 'TRIP',
    description: 'TEST',
    status: BillingUnitStatus.Activated,
    uuid: '52e89d86-a781-4ca7-9fdf-32206aa8436d',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks = '*Note: The required fields are: code (e.g. TRIP) and name (e.g. TRIP).'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    key: 'uuid'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (billingUnit: any) => {
  if (!billingUnit.code) {
    return { nullError: 'Code is required, e.g. TRIP.' }
  }
  if (!billingUnit.name) {
    return { nullError: 'Name is required, e.g. TRIP.' }
  }
  if (
    billingUnit.status &&
    billingUnit.status !== BillingUnitStatus.Activated &&
    billingUnit.status !== BillingUnitStatus.Deleted
  ) {
    return {
      nullError: `Status should be ${BillingUnitStatus.Activated} or ${BillingUnitStatus.Deleted}, or please leave this field blank.`
    }
  }
}

const populateBillingUnitObj = (obj: any, billingUnitUuid: string) => {
  const billingUnitObj: any = {
    uuid: billingUnitUuid,
    code: obj.code.toString().trim(),
    name: obj.name.toString().trim(),
    description: obj.description,
    status: obj.status || BillingUnitStatus.Activated
  }
  return billingUnitObj
}

const billingUnitsHelper = {
  queryName: 'billingUnits',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const billingUnitsQuery = {
        limit: 10000
      }
      const res = await getGqlResponse(selectedGlobalCompany, getDataGql, billingUnitsQuery)
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const billingUnits = res?.data?.billingUnits?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row,
        zzz: ''
      }))
      return billingUnits
    } catch (error) {
      logger.error('billingUnitsHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (data: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...billingUnit } = data

    const nullInputError = findNullInputs(billingUnit)
    if (nullInputError) {
      return nullInputError
    }

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const billingUnitsQuery = {
          limit: 10000
        }
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, billingUnitsQuery)
        const billingUnits = results?.data?.billingUnits?.rows

        for (let i = 0; i < billingUnits?.length; i++) {
          cache.uuid[billingUnits[i]?.uuid] = billingUnits[i]
          cache.code[billingUnits[i]?.code] = billingUnits[i]?.uuid
        }
      } catch (error) {
        logger.error('billingUnitsHelper cache getDataGql error', error)
        return error
      }
    }

    // Check if exists
    let billingUnitUuid: string = ''
    if (cache.uuid[billingUnit?.uuid]) {
      billingUnitUuid = billingUnit.uuid
    } else if (cache.code[billingUnit?.code]) {
      billingUnitUuid = cache.code[billingUnit.code]
    }

    let mutationResults
    // Update
    if (billingUnitUuid?.length) {
      try {
        const billingUnitObj = populateBillingUnitObj(billingUnit, billingUnitUuid)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: billingUnitObj
        })
      } catch (error) {
        logger.error('billingUnitsHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        billingUnitUuid = uuidv4()
        const billingUnitObj = populateBillingUnitObj(billingUnit, billingUnitUuid)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: billingUnitObj
        })
      } catch (error) {
        logger.error('billingUnitsHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default billingUnitsHelper
