import type { DatePickerMode } from 'antd/lib/date-picker/interface'

import { useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { DatePicker, Form } from 'antd'
import { Button, Col, Divider, Input, Popconfirm, Popover, Row } from 'antd-v5'
import moment from 'moment'

import JobSelect from '@/components/Select/NoQuery/JobSelect'
import TripSelector from '@/components/Select/NoQuery/TripSelector'
import { getJobsAndTrips } from '@/components/Workflow/Chronology/ChronologyAddButton'
import schema from '@/containers/booking/schema'
import { REMOVE_CHRONOLOGY, UPDATE_CHRONOLOGY } from '@/graphql/booking'
import { chronologyTypes } from '@/utils/labelMap'
import { logger } from '@/utils/logger'
import handleResponse from '@/utils/responseHandler'

const FormItem = Form.Item

const dateFormat = 'DD/MM/YYYY HH:mm'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const ChronologyUpdateButton = props => {
  const { chrono, booking, form, bookingUuid } = props

  const { getFieldDecorator, getFieldsValue } = form

  const [mode, setMode] = useState<DatePickerMode>('date')
  const [isVisible, setIsVisible] = useState(false)

  const [updateChronology] = useMutation(UPDATE_CHRONOLOGY)
  const [removeChronology] = useMutation(REMOVE_CHRONOLOGY)

  const title = chronologyTypes[chrono.title] || chrono.title

  let dateValue = moment().toDate()

  if (chrono?.data?.date) {
    dateValue = moment(chrono.data.date)
  }

  const [only1Job, only1Trip, tripsToSelect] = getJobsAndTrips(booking, getFieldsValue)

  const handleUpdate = async e => {
    e.preventDefault()

    form.validateFields(async (err, values) => {
      if (err) {
        console.log(err)
        return
      }

      try {
        handleResponse(`Updating ${chrono.title}, hang on...`, 'load')

        const updateObj = {
          uuid: chrono.data.uuid,
          jobUuid: values.jobUuid,
          tripUuid: values.tripUuid,
          type: chrono.type,
          date: values.chronoDatetime,
          remarks: values.remarks,
          reference: values.reference
        }

        await updateChronology({
          variables: { input: updateObj },
          refetchQueries: [
            {
              query: schema.BOOKING_QUERY_WORKFLOW,
              variables: { uuid: bookingUuid }
            }
          ]
        })

        setIsVisible(false)

        handleResponse('Updated successfully.', 'success')
      } catch (error) {
        logger.error('ChronologyUpdateButton updateChronology error', error)
        handleResponse(error, 'error')
      }
    })
  }

  const handleRemove = async () => {
    try {
      handleResponse(`Removing ${chrono.title}, hang on...`, 'load')

      await removeChronology({
        variables: { uuid: chrono.data.uuid },
        refetchQueries: [
          {
            query: schema.BOOKING_QUERY_WORKFLOW,
            variables: { uuid: bookingUuid }
          }
        ]
      })

      setIsVisible(false)

      handleResponse('Removed successfully.', 'success')
    } catch (error) {
      console.log(error)
      handleResponse(error, 'error')
    }
  }

  const handleDatepickerChange = () => {
    if (mode === 'date') {
      setMode('time')
    }
  }
  const handleOpenChange = () => setMode('date')

  const handlePanelChange = (value, mode) => setMode(mode)

  const updateContent = (
    <Form onSubmit={handleUpdate} style={{ width: '600px' }}>
      <FormItem label="Date & Time" {...formItemLayout}>
        {getFieldDecorator('chronoDatetime', {
          rules: [{ required: true, message: 'Date time required.' }],
          initialValue: dateValue
        })(
          <DatePicker
            showTime
            mode={mode}
            format={dateFormat}
            style={{ width: 200 }}
            onOpenChange={handleOpenChange}
            onChange={handleDatepickerChange}
            onPanelChange={handlePanelChange}
            placeholder="Select a date and time"
          />
        )}
      </FormItem>

      <FormItem label="Job" {...formItemLayout}>
        {getFieldDecorator('jobUuid', {
          initialValue: chrono?.data?.jobUuid || only1Job || undefined,
          rules: [{ required: !!chrono?.isJobRequired, message: 'Job is required.' }]
        })(<JobSelect jobs={booking?.jobs} />)}
      </FormItem>

      <FormItem label="Trip" {...formItemLayout}>
        {getFieldDecorator('tripUuid', {
          initialValue: chrono?.data?.tripUuid || only1Trip || undefined,
          rules: [{ required: !!chrono?.isTripRequired, message: 'Trip is required.' }]
        })(
          <TripSelector form={form} initialValue={chrono?.data?.tripUuid} trips={tripsToSelect} />
        )}
      </FormItem>

      <FormItem label="Reference" {...formItemLayout}>
        {getFieldDecorator('reference', {
          initialValue: chrono?.data?.reference
        })(<Input />)}
      </FormItem>

      <FormItem label="Remarks" {...formItemLayout}>
        {getFieldDecorator('remarks', {
          initialValue: chrono?.data?.remarks
        })(<Input />)}
      </FormItem>

      <Divider style={{ margin: '12px 0' }} />

      <Row>
        <Col span={6}>
          <Popconfirm
            arrow={false}
            okText="Remove"
            title="Confirm remove?"
            onConfirm={handleRemove}
          >
            <Button color="danger" variant="solid">
              Remove
            </Button>
          </Popconfirm>
        </Col>
        <Col span={18} style={{ textAlign: 'right' }}>
          <Button onClick={() => setIsVisible(false)}>Cancel</Button>
          <Button type="primary" htmlType="submit" style={{ marginLeft: '5px' }}>
            Update
          </Button>
        </Col>
      </Row>
    </Form>
  )

  return (
    <Popover
      arrow={false}
      trigger="click"
      placement="right"
      title={title}
      open={isVisible}
      content={updateContent}
      onOpenChange={visible => setIsVisible(visible)}
    >
      <EditOutlined className="px-2" onClick={() => setIsVisible(true)} />
    </Popover>
  )
}

export default Form.create()(ChronologyUpdateButton)
