import { Form, Radio } from 'antd'

const FormItem = Form.Item
const RadioGroup = Radio.Group

const radioStyle = {
  height: '30px',
  display: 'block',
  lineHeight: '30px'
}

const IssueDateFilterFormItem = (props: any) => {
  const {
    value,
    form: { getFieldDecorator }
  } = props

  return (
    <FormItem label="Credit Note">
      {getFieldDecorator('isCreditNote', {
        initialValue: value
      })(
        <RadioGroup>
          <Radio style={radioStyle}>All</Radio>
          <Radio style={radioStyle} value={true}>
            Credit Note only
          </Radio>
          <Radio style={radioStyle} value={false}>
            Non-credit note only
          </Radio>
        </RadioGroup>
      )}
    </FormItem>
  )
}

export default IssueDateFilterFormItem
