import { graphql } from '@apollo/client/react/hoc'

import schema from '../schema'

export default graphql(schema.CREATE_VOUCHER, {
  props: ({ mutate }) => ({
    createVoucher: params =>
      mutate({
        variables: {
          input: params
        }
      })
  })
})
