import type { Booking, JobType, MakeBookingInput, MutationUploadBookingDocumentArgs } from 'App/types/graphql'

import { format } from 'date-fns'
import { compact, identity, pick, pickBy, uniq } from 'lodash'

import config from '@/config'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import responseHandler from '@/utils/responseHandler'
import webStorage from '@/utils/webStorage'

type JobOrTrip = 'job' | 'trip'
type FileLevel = 'booking' | 'job' | 'trip'

export type CollectedFileMetaData = {
  jobUuid?: string
  tripUuid?: string
  key: string
  level: FileLevel
  files: File[]
  index?: number
}

export const filterDetails = (details: Record<string, any>, pickFields: string[]) =>
  pick(pickBy(details, identity), pickFields) || {}

export const getRequiredFieldKeys = (
  type: JobOrTrip,
  jobType: JobType,
  filterFnc: (field) => boolean = f => !!identity(f)
) => {
  let requiredFields = jobType?.requiredFields || []
  let dynamicFields =
    type === 'job' ? jobType?.dynamicFields || [] : jobType?.tripDynamicFields || []

  requiredFields = requiredFields.filter(filterFnc)
  dynamicFields = dynamicFields.filter(filterFnc)

  return compact(uniq([...requiredFields.map(f => f?.name), ...dynamicFields.map(f => f?.key)]))
}

export const getTrimmedDetails = (
  type: JobOrTrip,
  details: Record<string, any>,
  jobType?: JobType | null
) => {
  const rawDetails = { ...(details || {}) }

  if (!jobType) return rawDetails

  const detailsFields = getRequiredFieldKeys(type, jobType)

  return filterDetails(rawDetails, detailsFields)
}

export const getTrimmedInput = (job: any, jobTypes: JobType[], values?: any, type?: any) => {
  const isValidDate = (dateString: string): boolean => {
    return !isNaN(new Date(dateString).getTime())
  }

  const formattedValues = Object.keys(values).map(key => {
    return {
      [key]: format(values[key], 'DD/MM/YYYY HH:mm')
    }
  })
  const newValues = Object.assign({}, ...formattedValues)
  const rawDetails = { ...(newValues || {}) }
  const foundJobType = jobTypes.find(jt => jt.code === job.job.type)
  if (!foundJobType) return rawDetails

  const detailsFields = [
    ...uniq(foundJobType?.requiredFields?.map(k => k?.name) as string[]),
    ...(type === 'job'
      ? (foundJobType?.dynamicFields?.filter(f => f?.type === 'DATE').map(m => m?.key) as string[])
      : (foundJobType?.tripDynamicFields?.map(k => k?.key) as string[]))
  ].filter(Boolean)

  const trimmedDetails = filterDetails(rawDetails, detailsFields)

  const filteredDetails = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(trimmedDetails).filter(([key, value]) => isValidDate(value))
  )

  return filteredDetails
}

export const collectFiles = (jobData: Booking | MakeBookingInput):CollectedFileMetaData[] => {
  const files = []

  // Helper function to process details object and find files
  const processDetails = (
    details: any,
    level: FileLevel,
    uuids: { jobUuid?: string; tripUuid?: string }
  ) => {
    if (!details) return

    const filesInDetails = Object.entries(details)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([key, value]) => Array.isArray(value) && value.some(item => item instanceof File))
      .map(([key, value]) => {
        const jobs = jobData?.jobs
        return {
          ...uuids,
          key,
          level,
          files: value,
          index:
            level === 'booking' ? undefined : level === 'job'?
            jobs?.findIndex(j => j.uuid === uuids.jobUuid) :
            jobs?.find(j => j?.uuid === uuids.jobUuid)?.trips?.findIndex(t => t.uuid === uuids.tripUuid)
        }
      })

    files.push(...filesInDetails)
  }

  // Process booking level details
  processDetails(jobData.details, 'booking', {})
  // Process each job
  if (Array.isArray(jobData.jobs)) {
    jobData.jobs.forEach(job => {
      // Process job level details
      processDetails(job.details, 'job', { jobUuid: job.uuid })
      // Process each trip in the job
      if (Array.isArray(job.trips)) {
        job.trips.forEach(trip => {
          // Process trip level details
          processDetails(trip.details, 'trip', { jobUuid: job.uuid, tripUuid: trip.uuid })
        })
      }
    })
  }

  return files
}

export const handleBookingDocumentUpload = async (
  baseCompanyUuid: string,
  acceptedFiles: File[],
  input: MutationUploadBookingDocumentArgs
) => {
  const body = new FormData()
  const { files, ...rest } = input

  for (const key in rest) {
    body.append(key, rest[key])
  }

  acceptedFiles.forEach(file => body.append('files', file))

  try {
    responseHandler('Uploading document, hang on...', 'load')

    const resp = await fetch(`${config.api.baseUrl}/booking/upload-document`, {
      method: 'POST',
      headers: {
        'base-company-uuid': baseCompanyUuid,
        authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`
      },
      body,
      // @ts-ignore
      progress: (e: { loaded: number; total: number }) => {
        console.log(`Progress: ${e.loaded / e.total}%`)
      }
    })

    const respData = await resp.json()

    if (respData.message) {
      responseHandler(respData.message, 'error')
    } else {
      responseHandler('Uploaded successfully.', 'success')
      return respData
    }
  } catch (err) {
    console.log(err)
    responseHandler('something went wrong uploading file', 'error')
  }
}
