import type { Job } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { useMutation } from '@apollo/client'
import { Form } from 'antd'
import { Modal } from 'antd-v5'

import RolloverForm from '@/components/Workflow/RolloverAction/RolloverForm'
import { JOBS_FROM_BOOKING_SLIM_QUERY } from '@/containers/booking/schema/basicBookingQuerySlim'
import { ROLLOVER_JOB } from '@/graphql/booking'
import respHandler from '@/utils/responseHandler'

type RolloverModalProps = {
  form: WrappedFormUtils
  job: Job
  onClose: () => void
}
const RolloverModal = ({ job, form, onClose }: RolloverModalProps) => {
  const [rolloverJob, { loading }] = useMutation(ROLLOVER_JOB, {
    refetchQueries: [
      {
        query: JOBS_FROM_BOOKING_SLIM_QUERY,
        variables: {
          uuid: job.bookingUuid
        }
      }
    ]
  })

  const onModalOk = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await rolloverJob({
            variables: {
              jobUuid: values.jobUuid,
              bookingUuid: values.bookingUuid === '' ? undefined : values.bookingUuid
            }
          })
          respHandler('Job rollover successful.', 'success')
          onClose()
        } catch (error) {
          console.log(error)
          respHandler(error, 'error')
        }
      }
    })
  }

  return (
    <Modal
      open
      onOk={onModalOk}
      okText="Rollover"
      onCancel={onClose}
      confirmLoading={loading}
      title={`Rollover Job: ${job.no || ''}`}
    >
      <RolloverForm form={form} job={job} />
    </Modal>
  )
}

export default Form.create()(RolloverModal)
