import { CompanyInviteStatus } from '@/types/graphql'

import { memo, useCallback, useState } from 'react'
import { Row, Skeleton } from 'antd'

import Link from 'App/components/Link'
import NoAccess from 'App/components/Shared/NoAccess'
import TableView from 'App/components/TableView'
import { NormalText, StyledAnchor } from 'App/components/TableView/Styled'
import WithSearchAndPagination from 'App/components/TableView/WithSearchAndPagination'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import useGlobalCompanyStore from 'App/store/globalCompany'
import PageHeader from '../../PageHeader'
import StatusFilter from '../../Shared/CrudType/TableView/StatusFilter'
import { PageView, ViewContent } from '../../Styled'
import TableActions from './Actions'
import RolesFilter from './Filters/Roles'
import { EMPLOYEES_QUERY } from './schema'

const EmployeesTableView = memo(() => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'employee',
    permission: 'index'
  })
  const [filter, setFilter] = useState({
    roles: []
  })

  const renderColumns = useCallback(() => {
    const columns = [
      {
        title: 'Email',
        key: 'email',
        render: (text, record) => (
          <Link to={`/manage/employees/${record.uuid}`}>
            <StyledAnchor>{record.user?.email || '-'}</StyledAnchor>
          </Link>
        )
      },
      {
        title: 'Name',
        key: 'name',
        render: (text, record) => <NormalText>{record.user?.name || '-'}</NormalText>
      },
      {
        title: 'Roles',
        filterDropdown: <RolesFilter filter={filter} setFilter={setFilter} />,
        key: 'roles',
        render: (text, record) => <NormalText>{record.roles?.join(', ') || '-'}</NormalText>
      },
      {
        ...StatusFilter({
          status: CompanyInviteStatus,
          isCaps: false
        })
      }
    ]

    return columns
  }, [filter])

  if (permLoading) {
    return (
      <Row style={{ marginTop: '30px' }}>
        <Skeleton active />
      </Row>
    )
  }

  if (!hasPermission) {
    return <NoAccess />
  }

  return (
    <WithSearchAndPagination>
      {({ currentSize, updateSize, searchKeyword, updateSearchKeyword }) => (
        <PageView>
          <PageHeader title="Employees" />
          <ViewContent fullWidth>
            <TableActions
              updatePageSize={updateSize}
              updateSearchKeyword={updateSearchKeyword}
              filter={filter}
            />
            <TableView
              currentSize={currentSize}
              searchKeyword={searchKeyword}
              updatePageSize={updateSize}
              renderColumns={renderColumns}
              dataPropKey="employees"
              queryProps={{
                query: EMPLOYEES_QUERY,
                fetchPolicy: 'cache-and-network',
                variables: {
                  ...filter,
                  offset: currentSize * 20,
                  limit: 20,
                  query: searchKeyword,
                  companyUuids: [selectedGlobalCompany?.uuid]
                }
              }}
            />
          </ViewContent>
        </PageView>
      )}
    </WithSearchAndPagination>
  )
})

export default EmployeesTableView
