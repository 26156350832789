import { GlCodeStatus } from 'App/types/graphql'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

type GlCodeObjType = {
  code?: string | null | undefined
  description?: string | null | undefined
  uuid?: string | null | undefined
}

const sampleData = [
  {
    no: 1,
    code: '47000000',
    company: {
      code: 'BB1400',
      name: 'Swift Integrated Logistics Sdn Bhd'
    },
    description: 'SILS Revenue Code',
    status: 'activated',
    uuid: 'a1df5f8c-ba81-4ad1-a328-95476c9f1ead',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks = '*Note: The required input field is: code (e.g. 47000000).'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Company Code',
    dataIndex: 'company.code',
    key: 'company.code'
  },
  {
    title: 'Company Name',
    dataIndex: 'company.name',
    key: 'company.name'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    key: 'uuid',
    ellipsis: true
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = async (selectedGlobalCompany: any, glCode: any) => {
  if (!glCode.code) {
    return { nullError: 'Code is required (e.g. 47000000).' }
  }
}

const populateGlCodeObj = (obj: any, glCodeUuid: string, mode: string) => {
  const glCodeObj: GlCodeObjType = {
    code: obj.code?.toString()?.trim(),
    description: obj.description?.toString()
  }

  if (mode === 'update') {
    glCodeObj.uuid = glCodeUuid?.trim()
  }

  return glCodeObj
}

const glCodesHelper = {
  queryName: 'glCodes',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
        limit: 10000,
        statuses: GlCodeStatus.Activated
      })
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const glCodes = results?.data?.glCodes?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row,
        zzz: ''
      }))
      return glCodes
    } catch (error) {
      logger.error('glCodesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (glCodeData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...glCode } = glCodeData

    const nullInputError = await findNullInputs(selectedGlobalCompany, glCode)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          limit: 10000,
          statuses: GlCodeStatus.Activated
        })
        const glCodes = results?.data?.glCodes?.rows

        for (let i = 0; i < glCodes?.length; i++) {
          cache.uuid[glCodes[i]?.uuid] = glCodes[i]?.code?.toString()
          cache.code[glCodes[i]?.code?.toString()] = glCodes[i]?.uuid
        }
      } catch (error) {
        logger.error('glCodesHelper cache getExportData error', error)
        return error
      }
    }

    // Check glCodeUuid
    let glCodeUuid: string = ''
    if (cache.uuid[glCode?.uuid]) {
      glCodeUuid = glCode?.uuid
    } else if (cache.code[glCode?.code?.toString()]) {
      glCodeUuid = cache.code[glCode?.code?.toString()]
    }

    let mutationResults
    // Update
    if (glCodeUuid?.length > 0) {
      try {
        const glCodeObj = populateGlCodeObj(glCode, glCodeUuid, 'update')
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: glCodeObj
        })
      } catch (error) {
        logger.error('glCodesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        const glCodeObj = populateGlCodeObj(glCode, glCodeUuid, 'create')
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: glCodeObj
        })
      } catch (error) {
        logger.error('glCodesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default glCodesHelper
