import type { Booking, Company, CostItem, Voucher } from '@/types/graphql'
import type { TabsProps } from 'antd-v5'
import type { RadioChangeEvent } from 'antd/lib/radio'

import { useEffect, useState } from 'react'
import { EyeOutlined } from '@ant-design/icons'
import { Radio } from 'antd'
import { Card, Col, Row, Tabs } from 'antd-v5'

import BulkInvoiceByCustomer from '@/components/Voucher/Bookings/BulkInvoiceByCustomer'
import BulkInvoiceGenerator from '@/components/Voucher/Bookings/BulkInvoiceGenerator'
import withVoucherBookings from '@/components/Voucher/Bookings/Container'
import BookingSearch from '@/components/Voucher/Bookings/Search'
import TabView from '@/components/Voucher/Bookings/TabView'
import { NoItemsFound } from '@/components/Voucher/Styled'
import VoucherItemJvModal from '@/components/Voucher/voucher-item-jv-modal'
import { useVoucherStore } from '@/store/voucher'
import { getRef } from '@/utils/booking'

const { Group, Button } = Radio

type VoucherBookingsProps = {
  updateSelectedVoucherBooking: (val) => void
  selectedVoucher: Voucher
  selectedGlobalCompany: Company
  voucherBookings: Booking[]
  onRemoveDocument: (val) => void
  costsheetBookings: any[]
  onSelectSingleCostItem: (voucher, costItem) => void
  selectedVoucherBooking: Voucher
  onSelectSingleDocument: (val) => void
  voucherBookingsLoading: boolean
  updateCostsheetBookings: (val) => void
  handleSelect: (val) => void
}

const VoucherBookings = (props: VoucherBookingsProps) => {
  const {
    selectedVoucher,
    selectedGlobalCompany,
    voucherBookings,
    costsheetBookings,
    selectedVoucherBooking,
    voucherBookingsLoading,
    updateSelectedVoucherBooking,
    onRemoveDocument,
    onSelectSingleCostItem,
    onSelectSingleDocument,
    updateCostsheetBookings,
    handleSelect
  } = props
  const [bulkEntryType, setBulkEntry] = useState('single')

  const isJvVoucher = useVoucherStore.use.isJvVoucher()
  const isJvNewItems = useVoucherStore.use.isJvNewItems()
  const voucherStore = useVoucherStore.use.voucher()
  const setIsJvVoucher = useVoucherStore.use.setIsJvVoucher()
  const setIsJvNewItems = useVoucherStore.use.setIsJvNewItems()
  const setOpenJvModal = useVoucherStore.use.setOpenJvModal()
  const setDefaultVoucherBooking = useVoucherStore.use.setDefaultVoucherBooking()
  const setDefaultCostItem = useVoucherStore.use.setDefaultCostItem()

  const changeBulkEntryType = (e: RadioChangeEvent) => {
    setBulkEntry(e.target.value)
  }

  const handleTabClick = activeKey => {
    if (activeKey) {
      updateSelectedVoucherBooking(activeKey)
    }
  }

  useEffect(() => {
    setIsJvVoucher(selectedVoucher?.isJournalVoucher || false)

    return () => {
      setIsJvVoucher(false)
    }
  }, [setIsJvVoucher, selectedVoucher])

  // This is a hacky fix to populate voucherBooking with booking.no.
  // Apparently voucherBooking comes from algolia and it doesn't have booking no.
  const getVoucherBookingWithNo = (voucherBooking: Booking) => {
    const selectedVoucherBooking =
      (selectedVoucher.bookings &&
        selectedVoucher.bookings.find(b => b?.uuid === voucherBooking.uuid)) ||
      voucherBooking

    return {
      no: selectedVoucherBooking.no,
      uuid: selectedVoucherBooking.uuid
    }
  }

  const handleSelectSingleCostItem = (
    voucher: Voucher | undefined,
    costItem: CostItem | undefined,
    saveJv?: boolean
  ) => {
    if (isJvNewItems) {
      if (!voucherStore) {
        console.error('Voucher not found')
        setIsJvNewItems(false)
        return
      }

      voucher = voucherStore
    }

    if (!voucher || !costItem) {
      console.error('Voucher or cost item not found')
      return
    }

    if (saveJv !== undefined || !isJvVoucher || isJvNewItems) {
      onSelectSingleCostItem(voucher, costItem)
    } else {
      setDefaultVoucherBooking(voucher)
      setDefaultCostItem(costItem)

      setOpenJvModal(true)
      return
    }
  }

  const getTab = (voucherBooking: Booking, isSelected: boolean) => {
    const voucherBookingWithNo = getVoucherBookingWithNo(voucherBooking)
    const type = selectedGlobalCompany?.types?.[0]
    const tabTitle = getRef(type, voucherBookingWithNo)

    return (
      <div className="flex items-center gap-8">
        <span>{tabTitle}</span>
        {isSelected && (
          <EyeOutlined
            target="_blank"
            rel="noopener noreferrer"
            title="Open booking in new tab"
            href={`/bookings/${voucherBookingWithNo.uuid}`}
          />
        )}
      </div>
    )
  }

  const renderTabs = () => {
    if (voucherBookingsLoading) {
      return <Card bordered={false} loading={voucherBookingsLoading} />
    }

    if (!voucherBookings?.length)
      return <NoItemsFound borderedTop>No bookings linked.</NoItemsFound>

    const items: TabsProps['items'] = voucherBookings.map(voucherBooking => {
      const tabKey = selectedVoucherBooking || voucherBookings?.[0]?.uuid
      const isSelected = voucherBooking.uuid === tabKey

      return {
        key: voucherBooking.uuid,
        label: getTab(voucherBooking, isSelected),
        children: isSelected && (
          <TabView
            bookingUuid={voucherBooking.uuid}
            costsheetBookings={costsheetBookings}
            onRemoveDocument={onRemoveDocument}
            updateCostsheetBookings={updateCostsheetBookings}
            onSelectSingleCostItem={handleSelectSingleCostItem}
            onSelectSingleDocument={onSelectSingleDocument}
          />
        )
      }
    })

    return <Tabs hideAdd type="card" items={items} onChange={handleTabClick} />
  }

  const renderBulk = () => {
    return (
      <BulkInvoiceGenerator
        // @ts-ignore
        selectedVoucher={selectedVoucher}
        onSelectSingleCostItem={handleSelectSingleCostItem}
        updateCostsheetBookings={updateCostsheetBookings}
      />
    )
  }
  const renderBulkCustomer = () => {
    return (
      <BulkInvoiceByCustomer
        // @ts-ignore
        selectedVoucher={selectedVoucher}
        onSelectSingleCostItem={handleSelectSingleCostItem}
        updateCostsheetBookings={updateCostsheetBookings}
      />
    )
  }

  return (
    <>
      <Row>
        <Col span={12}>
          {bulkEntryType === 'single' && (
            <>
              Associated Bookings
              <BookingSearch
                voucher={selectedVoucher}
                handleSelect={handleSelect}
                voucherBookings={voucherBookings}
              />
            </>
          )}
        </Col>

        <Col span={12} style={{ textAlign: 'right' }}>
          <Group defaultValue={bulkEntryType} buttonStyle="solid" onChange={changeBulkEntryType}>
            <Button value="single">Single</Button>
            <Button value="customer">Customer</Button>
            <Button value="bulk">Bulk</Button>
          </Group>
        </Col>
      </Row>

      <br />

      <Row>
        <Col span={24}>
          {bulkEntryType === 'single' && renderTabs()}
          {bulkEntryType === 'customer' && renderBulkCustomer()}
          {bulkEntryType === 'bulk' && renderBulk()}
        </Col>
      </Row>

      <VoucherItemJvModal
        selectedVoucher={selectedVoucher}
        onSelectSingleCostItem={handleSelectSingleCostItem}
      />
    </>
  )
}

export default withVoucherBookings(VoucherBookings)
