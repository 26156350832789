import type { BookingsSearchInput } from '@/types/graphql'

import { type ApolloClient } from '@apollo/client'
import { cloneDeep, defaultsDeep, isArray, isEmpty, isObject, sampleSize } from 'lodash'
import { utils, writeFile } from 'xlsx'

import { BOOKING_SEARCH_QUERY } from '@/components/Voucher/schema'
import config from '@/config'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

export const regex = {
  number: new RegExp(/\d/, 'g'),
  nonNumber: new RegExp(/\D/, 'g'),
  email: new RegExp(/^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z-]+(?:\.[\dA-Za-z-]+)*$/, 'i'),
  uuid: new RegExp(/^[\da-f]{8}-[\da-f]{4}-[1-5][\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/, 'i')
}

export const hasNumber = (input: string) => {
  if (!input) return false
  return regex.number.test(input)
}

export const hasNonNumber = (input: string) => {
  if (!input) {
    return false
  }
  return regex.nonNumber.test(input)
}

export const isUuid = (input: any) => {
  if (!input || typeof input !== 'string') return false
  return regex.uuid.test(input)
}

export const isEmail = (input: string) => {
  if (!input || typeof input !== 'string') return false
  return regex.email.test(input)
}

export const dotToCamelCase = (str: string) => {
  if (!str) return
  return (
    str[0].toLowerCase() +
    str
      .replace(/\.([a-z])/g, function (a, b) {
        return b.toUpperCase()
      })
      .slice(1)
  )
}

export const camelCaseToSpace = (string: string) => {
  if (!string) return

  const result = string.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

// Lodash 5 will drop support for omit
export const omit = (object: any, unwantedKeys: string[] = []) => {
  if (isEmpty(object)) return null

  const newObj = { ...object }

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  unwantedKeys?.length &&
    unwantedKeys.forEach((key: string) => {
      if (newObj[key]) {
        delete newObj[key]
      }
    })

  return newObj
}

const recursiveOmit = (obj: unknown, unwantedKeys: string[]): unknown => {
  if (Array.isArray(obj)) {
    return obj.map(item => recursiveOmit(item, unwantedKeys))
  }
  if (!isObject(obj)) {
    return obj
  }

  const newObj: any = {}

  Object.keys(obj).forEach(key => {
    if (unwantedKeys.includes(key)) {
      return
    }

    const value: any = obj[key]

    if (value?._isAMomentObject) {
      newObj[key] = value.format()
    } else if (Array.isArray(value)) {
      newObj[key] = value.map(item => recursiveOmit(item, unwantedKeys))
    } else if (isObject(value)) {
      newObj[key] = recursiveOmit(value, unwantedKeys)
    } else {
      newObj[key] = value
    }
  })

  return newObj
}

export const removeNestedObjProps = (object: any, unwantedKeys: string[] = []): any => {
  if (isEmpty(object)) return null

  const clonedObj = cloneDeep(object)
  return recursiveOmit(clonedObj, unwantedKeys)
}

export const getXlsx = (dataArray: any[], fileName: string, sheetName: string) => {
  try {
    if (!dataArray?.length) {
      return responseHandler('There is no data to export.', 'warning')
    }
    const arrayOfObjects: any[] = prepareForExport(dataArray)

    const wb = utils.book_new()
    const ws1 = utils.json_to_sheet(arrayOfObjects)

    utils.book_append_sheet(wb, ws1, sheetName)
    writeFile(wb, `${fileName}.xlsx`)

    return responseHandler('Successfully exported file.', 'success')
  } catch (error: any) {
    logger.error(`getXlsx Error fileName='${fileName}'.`, error)
    return responseHandler(config.anErrorOccurredPleaseTryAgainLater, 'error')
  }
}

const prepareForExport = (array: any[]): any[] => {
  if (!array?.length) return []

  const clonedArray: any[] = cloneDeep(array)
  return recursiveFlatten(clonedArray)
}

export const recursiveFlatten: any = (array: any[]): any[] => {
  if (!array?.length) return []

  const newArray: any[] = array.map((arr: any) => flattenObj(arr))
  return hasNestedObjects(newArray) ? recursiveFlatten(newArray) : newArray
}

export const hasNestedObjects = (array: any[]): boolean => {
  if (!array?.length) return false

  for (let i = 0; i < array.length; i++) {
    const keys: string[] = Object.keys(array[i])

    for (let j = 0; j < keys.length; j++) {
      const value = array[i][keys[j]]

      if (value && (isArray(value) || typeof value === 'object')) {
        return true
      }
    }
  }

  return false
}

export const flattenObj = (object: any) => {
  if (isEmpty(object)) return null

  const newObj = Object.keys(object)?.reduce(
    (res: any, key: string) => {
      const value = res[key]

      if (value && (isArray(value) || typeof value === 'object')) {
        if (typeof value?.[0] === 'string') {
          res[key] = res[key].join(',')
        } else {
          Object.keys(value)?.forEach((k: string) => {
            res[`${key}.${k}`] = value[k]
          })

          delete res[key]
        }
      }

      return res
    },
    { ...object }
  )

  return newObj
}

export const hasPermissionError = (error: any) => {
  if (!error?.graphQLErrors?.length) return false

  const errorMessages = [
    'No User found to check isAuthenticated',
    'Forbidden to access resource',
    "You don't have access",
    'Token has expired',
    'no permission to',
    'Not allowed to',
    'Not Authorised',
    'Unauthorized'
  ]

  for (let i = 0; i < errorMessages.length; i++) {
    const foundErr = error.graphQLErrors.find((err: any) =>
      err.message?.toLowerCase()?.includes(errorMessages[i].toLowerCase())
    )
    if (foundErr) {
      return true
    }
  }

  return false
}

export const convertToShortRateRule = (rateRule: string): string => {
  const lk = {
    EQUAL: 'EQ',
    NOT_EQUAL: 'NE',
    GREATER_THAN_EQUAL: 'GTE',
    GREATER_THAN: 'GT',
    LESS_THAN: 'LT',
    LESS_THAN_EQUAL: 'LTE'
  }

  return lk[rateRule] || ''
}

interface MoveArrayElemType {
  array: any[]
  index: number
  moveUp: boolean
}
export const moveArrayElement = ({ array, index, moveUp }: MoveArrayElemType): any[] => {
  if (!array?.length) return []

  let newArray: any[] = cloneDeep(array)

  if (moveUp) {
    if (index > 1) {
      newArray = newArray
        .slice(0, index - 1)
        .concat(array.slice(index, index + 1))
        .concat(array.slice(index - 1, index))
        .concat(array.slice(index + 1, array.length))
    } else {
      newArray = newArray
        .slice(index, index + 1)
        .concat(array.slice(0, index))
        .concat(array.slice(index + 1, array.length))
    }
  } else {
    newArray = newArray
      .slice(0, index)
      .concat(array.slice(index + 1, index + 2))
      .concat(array.slice(index, index + 1))
      .concat(array.slice(index + 2, array.length))
  }

  return newArray
}

// is month-to-date
export const isMTD = (date1: Date, date2: Date = new Date()) => {
  return new Date(date1).getDate() <= new Date(date2).getDate()
}

export const truncateArrayWithEllipsis = (arr: any[], maxLength: number) => {
  const cond = arr?.length > maxLength
  const diff = arr?.length - maxLength

  return cond ? arr.slice(0, maxLength).concat(`...${diff} more`) : arr
}

type PrintStringOrArrayOpts = {
  delimiter?: string
  defaultValue?: string
}

export const printStringOrArray = (value: string | string[], opts: PrintStringOrArrayOpts = {}) => {
  opts = { defaultValue: '-', delimiter: ', ', ...opts }

  if (!value) return opts.defaultValue
  if (typeof value === 'string') return value
  if (Array.isArray(value)) return value.join(opts.delimiter)

  return value
}

export const getSearchParamValue = (location, key: string) => {
  const fromURL = location.search.slice(1)
  const parsed = new URLSearchParams(fromURL)
  return parsed.get(key)
}

export const stringifyNested = (input: any): string => {
  if (typeof input === 'object') {
    if (Array.isArray(input)) {
      const elements = input
        .map(element => stringifyNested(element))
        .filter(Boolean)
        .join('\n')
      return elements
    } else {
      const properties = Object.entries(input)
        .map(([key, value]) => (value ? `${key}: ${stringifyNested(value)}` : null))
        .filter(Boolean)
        .join('\n')
      return properties
    }
  } else {
    if (typeof input !== 'number' && Date.parse(input) > 0) {
      return new Date(input).toLocaleDateString()
    }
    return String(input)
  }
}

export const isValidStringNumber = (numString: string): boolean => isFinite(+numString)

export const getFileExtension = (fileName: string): string => {
  return fileName.split('.').pop() || ''
}

export function generateSixCharacterString() {
  const chars = '23456789ABCDEFGHJKMNPQRSTUVWXYZ'
  return sampleSize(chars, 6).join('')
}

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)
  } catch (error) {
    console.error('Failed to copy: ', error)
  }
}

export const handleGraphQLError = (
  error: unknown,
  callback: typeof responseHandler = responseHandler
) => {
  const errorMessage =
    error instanceof Error
      ? error.message.replace('GraphQL error: ', '')
      : isObject(error)
        ? JSON.stringify(error)
        : 'unknown error'

  return callback(errorMessage, 'error')
}

export const fetchBooking = async (
  client: ApolloClient<object>,
  bookingInput: BookingsSearchInput
) => {
  try {
    const input = defaultsDeep(bookingInput, {
      sort: '',
      limit: 20,
      offset: 0,
      filter: {},
      _noSubGraph: true
    })

    const { data } = await client.query({
      query: BOOKING_SEARCH_QUERY,
      variables: { input }
    })

    return data
  } catch (error) {
    logger.error('Voucher Modal BOOKING_SEARCH_QUERY error', error)
    handleGraphQLError(error)
  }
}

export const toArray = (text: unknown) => {
  return Array.isArray(text) ? text : [text]
}

export const sanitiseValue = <T>(values: T, fieldsToRemove: string[]) => {
  if (values == null) return values

  if (Array.isArray(values)) {
    return values.map(item => sanitiseValue(item, fieldsToRemove)) as T
  }

  if (typeof values === 'object') {
    const cleaned: Record<string, any> = {}
    for (const [key, value] of Object.entries(values)) {
      if (fieldsToRemove.includes(key)) continue
      cleaned[key] = sanitiseValue(value, fieldsToRemove)
    }
    return cleaned as T
  }

  return values
}
