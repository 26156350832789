import type { DOMAttributes, PropsWithChildren } from 'react'

import { Icon } from 'antd'
import styled from 'styled-components'

export default styled.div<
  DOMAttributes<HTMLElement> &
    PropsWithChildren<{ highlighted?: boolean; verified?: boolean; deleted?: boolean }>
>`
  width: 192px;
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid rgb(230, 230, 230);
  ${props => props.highlighted && 'border-left: 2px solid rgb(24, 144, 255);'}
  ${props =>
    !props.verified && !props.deleted && !props.highlighted
      ? 'border-left: 2px solid #f4c542;'
      : ''}
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: white;

  &:hover {
    transition: 0.3s;
    border-color: rgb(200, 200, 200);
    ${props =>
      !props.verified && !props.deleted && !props.highlighted
        ? 'border-left: 2px solid #f4c542;'
        : ''}
  }
`

export const LeftIcon = styled(Icon)`
  color: black;
  margin-right: 3px;
`

export const ApprovedApprovalIcon = styled(Icon)`
  display: inline-block;
  color: #52c41a;
  font-size: 13px;
  margin-top: 2px;
`

export const PendingApprovalIcon = styled(Icon)`
  display: inline-block;
  color: #f4c542;
  font-size: 13px;
  margin-top: 2px;
`

export const PendingIcon = styled(Icon)`
  display: inline-block;
  color: #f4c542;
  font-size: 13px;
  margin-top: 2px;
`

export const DocHeader = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: rgb(30, 34, 38);
`

export const DocDescription = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const DocFileName = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  width: 128px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
`

export const DocName = styled.span`
  display: inline-block;
  font-size: 12.5px;
  width: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const DocUsage = styled.span`
  float: right;

  i.anticon {
    margin-left: 2px;
  }
`

export const ApproveType = styled.p`
  font-size: 13.222px;
  font-weight: 600;
`
