import { Typography } from 'antd'
import styled from 'styled-components'

export const FieldHeader = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: black;
  letter-spacing: 0.2px;
  display: table-cell;
  padding-right: 10px;
  white-space: nowrap;
  ${props => (props.header ? 'text-decoration: underline;' : '')}
`

export const FieldValueTypography = styled(Typography.Text)`
  font-size: 0.85rem;
  font-weight: 500;
  min-height: 45px;
  color: rgb(140, 140, 140);
  display: table-cell;
  vertical-align: middle;
`
