import { useNavigate } from 'react-router-dom'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Form, notification } from 'antd'
import pick from 'lodash/pick'
import uuidv4 from 'uuid/v4'

import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import PageHeader from '../PageHeader'
import { Inputs, PageView, ViewContent } from '../Styled'
import InputForm from './Form'

const CreateYard = ({ form }) => {
  const navigate = useNavigate()

  const handleSubmit = (e, createYard) => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      try {
        values.address = JSON.parse(values.address)

        const requestValues = {
          ...pick(values, ['code', 'name', 'remarks', 'address', 'status']),
          uuid: uuidv4()
        }

        await createYard({
          variables: {
            input: requestValues
          }
        })
      } catch (error) {
        logger.error('CreateYard error', error)
        respHandler(error, 'error')
      }
    })
  }

  const handleError = error => {
    notification.error({
      message: 'Error',
      description: error.message.replace('GraphQL error: ', '')
    })
  }

  const handleCompleted = () => {
    notification.success({
      message: 'Submit Notice',
      description: 'Yard has been created successfully.'
    })

    navigate('/manage/yards')
  }

  return (
    <PageView>
      <PageHeader title="Create Yard" subtitle="Please fill in all required fields." />
      <ViewContent>
        <Mutation mutation={yardCreate} onError={handleError} onCompleted={handleCompleted}>
          {(createYard, { loading }) => (
            <Inputs onSubmit={e => handleSubmit(e, createYard)}>
              {/* @ts-expect-error */}
              <InputForm item={{}} loading={loading} submitText="Submit" form={form} />
            </Inputs>
          )}
        </Mutation>
      </ViewContent>
    </PageView>
  )
}

export const yardCreate = gql`
  mutation createYard1($input: CreateYardInput!) {
    createYard(input: $input) {
      uuid
    }
  }
`

export default Form.create()(CreateYard)
