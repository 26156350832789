import { gql } from '@apollo/client'

export const NODE_BODY = gql(`
  fragment NodeBody on Node {
    _id
    parentId
    entry {
      isOr
      query {
        comparator
        value
      }
    }
    childSelector {
      key
      splitBy
    }
    calculator {
      aggregator
      operator
      nodeValue
      chargeItemCode
      remarks
      unit
      isQuantity
    }
    status
  }
`)
