import { TransactionType } from '@/types/graphql'

import { useDispatch } from 'react-redux'
import { Col, Row, Tooltip } from 'antd-v5'

import {
  Amount,
  Currency,
  Invoice,
  InvoiceNo,
  Selector,
  Status,
  VoucherType
} from '@/components/Workflow/Voucher/ViewSelections/Styled'
import * as voucherActions from '@/states/reducers/voucher'
import respHandler from '@/utils/responseHandler'

const ViewSelector = props => {
  const {
    voucher,
    isPortalUser,
    voucherTypes,
    sectionVoucher,
    toggleVoucherView,
    openPrintableVoucherInNewTab
  } = props

  const dispatch = useDispatch()

  const updateSelectedVoucher = payload => dispatch(voucherActions.updateSelectedVoucher(payload))
  const updateVoucherBookings = payload => dispatch(voucherActions.updateVoucherBookings(payload))

  const total = (voucher.total || 0)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  const isCreditNote = voucher.isCreditNote
  const voucherType = voucherTypes[voucher.type]
  const isJournalVoucher = voucher.isJournalVoucher
  const isAp = sectionVoucher.transactionType === TransactionType.Accpay

  const handleClick = () => {
    if (isPortalUser) {
      return openPrintableVoucherInNewTab()
    }

    respHandler('Loading voucher...', 'load')

    try {
      updateSelectedVoucher({
        ...voucher,
        transactionCategory: sectionVoucher.transactionCategory
      })
      updateVoucherBookings([])
      toggleVoucherView()
    } catch (error) {
      console.log('ViewSelector error:', error)
    }

    respHandler('', 'close')
  }

  return (
    <>
      <Selector category={sectionVoucher.transactionCategory} onClick={handleClick}>
        <Row>
          <Col span={14}>
            <Tooltip title={voucher.type}>
              <VoucherType>{voucherType?.text || voucher.type}</VoucherType>
            </Tooltip>
          </Col>
          <Col span={10}>
            <InvoiceNo>{voucher.voucherNumber}</InvoiceNo>
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <Status>
              {isCreditNote && <span>(CN)</span>}
              {isJournalVoucher && <span>(JV)</span>}
              {voucher.status}
            </Status>
          </Col>
          <Col span={10}>
            <Invoice>
              <Currency>{voucher.currency?.code}</Currency>
              <Amount isAp={isAp} isCreditNote={isCreditNote} isJournalVoucher={isJournalVoucher}>
                {total}
              </Amount>
            </Invoice>
          </Col>
        </Row>
      </Selector>
    </>
  )
}

export default ViewSelector
