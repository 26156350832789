import { gql } from '@/types'

export enum PluginLocationAssets {
  AssetsBookingNewPost = 'ASSETS_BOOKING_NEW_POST'
}

export const GET_PLUGIN_LIST_TEST = gql(`
  query pluginForComponent($locations: [String], $statuses: [ActiveStatus]) {
    plugins(locations: $locations, statuses: $statuses) {
      rows {
        id
        name
        data
        locations
      }
    }
  }
`)

export const GET_PLUGIN = gql(`
  query Plugin($pluginId: ID) {
    plugin(id: $pluginId) {
      data
      id
      locations
      name
      operationType
      ownerUuid
      sequence
      status
      type
    }
  }
`)

export const GET_PLUGINS = gql(`
  query Plugins($limit: Int, $offset: Int, $q: String, $types: [PluginType], $locations: [String], $statuses: [ActiveStatus]) {
    plugins(limit: $limit, offset: $offset, q: $q, types: $types, locations: $locations, statuses: $statuses) {
      rows {
        data
        id
        locations
        name
        operationType
        ownerUuid
        sequence
        status
        type
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const GET_PLUGINS_MODULES = gql(`
  query PluginModules($q: String, $limit: Int, $offset: Int) {
    pluginModules(q: $q, limit: $limit, offset: $offset) {
      rows {
        name
        validLocations
      }
    }
  }
`)

export const CREATE_PLUGIN = gql(`
  mutation CreatePlugin($input: PluginInput) {
    createPlugin(input: $input) {
      id
    }
  }
`)

export const UPDATE_PLUGIN = gql(`
  mutation UpdatePlugin($input: PluginInput) {
    updatePlugin(input: $input) {
      id
    }
  }
`)

export const DELETE_PLUGIN = gql(`
  mutation DeletePlugin($deletePluginId: ID) {
    deletePlugin(id: $deletePluginId) {
      success
      message
    }
  }
`)

export const UNDELETE_PLUGIN = gql(`
  mutation UndeletePlugin($undeletePluginId: ID) {
    undeletePlugin(id: $undeletePluginId) {
      message
      success
    }
  }
`)
