import { memo } from 'react'
import { Link } from 'react-router-dom'

const HeaderLogo = () => (
  <Link to="/" aria-label="back to home page">
    <picture>
      <source
        width={53}
        height={42}
        media="(max-width:1100px)"
        srcSet="/static/switftlogo_notext.png"
      />
      <img
        width={110}
        height={50}
        decoding="async"
        alt="swift haulage logo"
        src="/static/swiftLogoR.png"
      />
    </picture>
  </Link>
)

export default memo(HeaderLogo)
