import { Component } from 'react'
import { Button, Icon } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CustomButton = styled(Button)`
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
`

class RequestButton extends Component {
  render() {
    const { text, onClick } = this.props

    return (
      <CustomButton onClick={onClick}>
        <Icon type="mail" />
        {text}
      </CustomButton>
    )
  }
}

RequestButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func
}

export default RequestButton
