import { TransportJobTypeStatus } from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

const sampleData = [
  {
    name: 'gp',
    code: 'GP',
    sorting: 1,
    description: 'GP',
    status: 'ACTIVE',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: The required fields are: name (e.g. GP or DG1), code (e.g. GP), sorting (e.g. 1)'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Sorting*',
    dataIndex: 'sorting',
    key: 'sorting'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (transportJobType: any) => {
  if (!transportJobType.code) return { nullError: 'Code is required, e.g. GP.' }
  if (!transportJobType.name) return { nullError: 'Name is required, e.g. GP.' }
  if (!transportJobType.sorting) return { nullError: 'Sorting is required, e.g. 1.' }
}

const populateTransportJobTypeObj = (obj: any, transportJobTypeId: string) => {
  const transportJobTypeObj: any = {
    uuid: transportJobTypeId?.trim(),
    code: obj.code?.toString()?.trim(),
    name: obj.name,
    sorting: parseInt(obj.sorting),
    description: obj.description,
    status: obj.status || TransportJobTypeStatus.Active
  }

  return transportJobTypeObj
}

const transportJobTypesHelper = {
  queryName: 'transportJobTypes',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const transportJobTypes = results?.data?.transportJobTypes?.rows?.map(
        (row: any, i: number) => ({ no: i + 1, ...row, zzz: '' })
      )
      return transportJobTypes
    } catch (error) {
      logger.error('transportJobTypesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (transportJobTypeData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...transportJobType } = transportJobTypeData

    const nullInputError = findNullInputs(transportJobType)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const transportJobTypesQuery = {
          limit: 10000
        }
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          input: transportJobTypesQuery
        })
        const transportJobTypes = results?.data?.transportJobTypes?.rows

        for (let i = 0; i < transportJobTypes?.length; i++) {
          cache.uuid[transportJobTypes[i]?.uuid] = transportJobTypes[i]?.code
          cache.code[transportJobTypes[i]?.code] = transportJobTypes[i]?.uuid
        }
      } catch (error) {
        logger.error('transportJobTypesHelper cache getDataGql error', error)
        return error
      }
    }

    // Check transportJobTypeId
    let transportJobTypeId: string = ''
    if (cache.uuid[transportJobType?.uuid]) {
      transportJobTypeId = transportJobType?.uuid
    } else if (cache.code[transportJobType?.code]) {
      transportJobTypeId = cache.code[transportJobType?.code]
    }

    let mutationResults
    // Update
    if (transportJobTypeId?.length > 0) {
      try {
        const transportJobTypeObj = populateTransportJobTypeObj(
          transportJobType,
          transportJobTypeId
        )
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: transportJobTypeObj
        })
      } catch (error) {
        logger.error('transportJobTypesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        transportJobTypeId = uuidv4()
        const transportJobTypeObj = populateTransportJobTypeObj(
          transportJobType,
          transportJobTypeId
        )
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: transportJobTypeObj
        })
      } catch (error) {
        logger.error('transportJobTypesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default transportJobTypesHelper
