import type { ReactElement } from 'react'

import { graphql } from '@apollo/client/react/hoc'

import addressGql from './addressGql'

interface AddressData {
  address1?: string
  address2?: string
  address3?: string
  address4?: string
  postCode?: string
  areaCode?: string
  zone?: string
}

interface AddressGraphqlResponse {
  loading: boolean
  error?: any
  address?: AddressData
}

interface AddressProps {
  addressUuid?: string
  addressGraphql?: AddressGraphqlResponse
}

const addressGraphql = graphql(addressGql, {
  name: 'addressGraphql',
  skip: (props: AddressProps) => !props.addressUuid,
  options: (props: AddressProps) => ({
    variables: {
      uuid: props.addressUuid
    }
  })
})

const Address = (props: AddressProps): ReactElement => {
  const { addressUuid = '', addressGraphql = {} } = props
  const { loading, error, address }: any = addressGraphql

  if (!addressUuid) {
    return <span>Address is not selected.</span>
  }

  if (loading) {
    return <span>Loading ...</span>
  }

  if (error) {
    return <span>Error while loading address.</span>
  }

  if (!address) {
    return <span>No address.</span>
  }

  const postAreaCode = [address.postCode, address.areaCode]?.filter(Boolean)?.join(', ')

  return (
    <div>
      {address.name && <b>{address.name}</b>}
      {address.address1 && <div>{address.address1}</div>}
      {address.address2 && <div>{address.address2}</div>}
      {address.address3 && <div>{address.address3}</div>}
      {address.address4 && <div>{address.address4}</div>}
      {address.areaCode && <div>{`${postAreaCode} [${address.zone}]`}</div>}
    </div>
  )
}

export default addressGraphql(Address)
