import type { Company } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'

import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'
import styled from 'styled-components'

import QuickCreate from '@/components/Select/TypeToFetch/CompanySelect/QuickCreate'
import COMPANIES_QUERY from '@/components/Select/TypeToFetch/CompanySelect/schema'
import { camelCaseToSpace } from '@/utils/u'

const Option = Select.Option

const SecondaryText = styled.div`
  color: rgb(120, 120, 120);
  font-size: 12.5px;
  margin-top: 1px;
`

type CompanySelectProps = Omit<SelectProps, 'onChange'> & {
  allowSelectNull?: boolean
  portalCompanyQueryType?: string
  quickCreate?: boolean
  countryAlpha3?: string
  accountTypes?: string[]
  statuses?: string[]
  types?: string[]
  formId?: string
  name?: string
  onChange?: (value, company?: Company) => void
}

const CompanySelect = forwardRef<any, CompanySelectProps>((props, ref) => {
  const {
    placeholder = 'Select a company...',
    portalCompanyQueryType = 'all',
    allowSelectNull = false,
    quickCreate = false,
    disabled = false,
    countryAlpha3,
    accountTypes,
    statuses,
    onChange,
    value,
    types,
    style,
    mode,
    formId,
    name
  } = props

  const [keyword, setKeyword] = useState('')
  const [isBilling, setIsBilling] = useState(false)

  const handleSearch = useCallback((value: string) => {
    debounce((searchValue: string) => {
      setKeyword(searchValue)
    }, 500)(value)
  }, [])

  useEffect(() => {
    handleSearch(name || '')
  }, [name, handleSearch])

  const { loading, data, refetch } = useQuery(COMPANIES_QUERY, {
    variables: {
      uuids: (!keyword && mode === 'multiple' && value) || null,
      query: (!mode && value) || keyword,
      countryAlpha3,
      portalCompanyQueryType,
      accountTypes,
      types,
      statuses
    },
    skip: !keyword && mode === 'multiple' && !value,
    fetchPolicy: 'cache-first'
  })

  const companies = data?.companies?.rows || []

  const handleChange = value => {
    const company = companies.find(c => c.uuid === value)
    const billing = company?.types?.includes('billing') || false
    onChange?.(value, company)
    setIsBilling(billing)
  }

  let bindingValue = value

  if (allowSelectNull && value === null) {
    bindingValue = null
  }

  return (
    <>
      <Select
        ref={ref}
        id={formId ?? 'form-company-selector'}
        allowClear
        showSearch
        mode={mode}
        loading={loading}
        disabled={disabled}
        value={bindingValue}
        placeholder={placeholder}
        onChange={handleChange}
        notFoundContent={loading ? 'Searching...' : 'Not found.'}
        onSearch={handleSearch}
        optionLabelProp="label"
        filterOption={false}
        style={style}
      >
        {allowSelectNull && (
          <Option key="null" value={null} company={null}>
            Null
          </Option>
        )}

        {companies.map(company => {
          const label = [company.code, company.name]?.filter(Boolean).join(' - ')
          const secondaryLabel = [company.country?.alpha3]
            .concat(company.types?.map(t => camelCaseToSpace(t)))
            ?.filter(Boolean)
            .join(' - ')

          return (
            <Option key={company.uuid} value={company.uuid} title={company.name} label={label}>
              {label}
              <SecondaryText>{secondaryLabel}</SecondaryText>
            </Option>
          )
        })}
      </Select>

      {quickCreate && !disabled && !isBilling && (
        <QuickCreate types={types ?? []} refetch={refetch} onChange={onChange} />
      )}
    </>
  )
})

export default CompanySelect
