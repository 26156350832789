import type { Booking } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { useState } from 'react'
import { Form } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import JobsForm from './JobsForm'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 }
}

type TransportDetailsSubFormProps = {
  form: WrappedFormUtils
  duplicateBookingObject?: Booking
}

const TransportDetailsSubForm = (props: TransportDetailsSubFormProps) => {
  const { form, duplicateBookingObject = {} } = props
  const { getFieldDecorator } = form

  const [jobs] = useState(duplicateBookingObject?.jobs || [{ details: {}, key: uuidv4() }])

  return (
    <Form.Item {...formItemLayout}>
      {getFieldDecorator('jobs', {
        initialValue: jobs
      })(<JobsForm />)}
    </Form.Item>
  )
}

export default TransportDetailsSubForm
