import { Component } from 'react'
import { Button, Dropdown, Icon } from 'antd'
import includes from 'lodash/includes'
import { compose, withState } from 'recompose'

import { StyledButton } from '../../../ButtonWrapper'
import PermissionCheckBox from './PermissionCheckBox'
import { MiscItem, MiscTitle, PermissionName, StyledMiscPermissions } from './Styled'

class MiscPermissions extends Component {
  render() {
    const {
      selectedRole,
      allowedPermissions,
      miscResource,
      miscPermissions,
      showMisc,
      toggleMisc
    } = this.props

    return (
      <Dropdown
        getPopupContainer={trigger => trigger.parentNode}
        trigger={['click']}
        overlay={
          <StyledMiscPermissions>
            <MiscTitle>
              <PermissionName>{miscResource} permissions</PermissionName>
            </MiscTitle>
            {miscPermissions.map((mp, index) => {
              const isAllowed = includes(allowedPermissions, mp)
              return (
                <MiscItem key={index}>
                  <PermissionCheckBox
                    isAllowed={isAllowed}
                    displayName={mp}
                    resourceType={mp}
                    resourceName={miscResource}
                    selectedRole={selectedRole}
                  />
                </MiscItem>
              )
            })}
            <Button type="primary" onClick={() => toggleMisc(false)}>
              Done
            </Button>
          </StyledMiscPermissions>
        }
        placement="bottomRight"
      >
        <StyledButton onClick={() => toggleMisc(!showMisc)}>
          <Icon type="ellipsis" style={{ color: 'black' }} />
        </StyledButton>
      </Dropdown>
    )
  }
}

export default compose(withState('showMisc', 'toggleMisc', false))(MiscPermissions)
