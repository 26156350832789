import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

import bookingSchema from 'App/containers/booking/schema'

export const UPDATE_VOUCHER_ITEM_SLIM = gql`
  mutation updateVoucherItemSlim1($input: UpdateVoucherItemInput!) {
    updateVoucherItem(input: $input) {
      uuid
      description
      unit
      size
      quantity
      isDeleted
      currency {
        uuid
        code
        name
      }
      baseRate
      exchangeRate
      localExchangeRate
      rate
      tax {
        uuid
        name
        code
        percentage
      }
      job {
        bookingUuid
        uuid
        no
        jobNo
      }
      taxPercentage
      taxTotal
      subTotal
      total
      bookingUuid
      costItem {
        uuid
        bookingUuid
        description
        quantity
        chargeItem {
          uuid
          code
          name
          description
          sellTax {
            percentage
          }
        }
      }
      voucherCn {
        uuid
        voucherNumber
      }
    }
  }
`

export default graphql(UPDATE_VOUCHER_ITEM_SLIM, {
  props: ({ mutate }) => ({
    updateVoucherItem: (params, bookingUuid) =>
      mutate({
        variables: {
          input: params
        },
        refetchQueries: [
          {
            query: bookingSchema.BOOKING_VOUCHER_COST_SHEET,
            variables: {
              uuid: bookingUuid
            }
          }
        ]
      })
  })
})
