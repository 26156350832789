import enums from 'App/types/enums'

import { Col, Form, Radio, Row } from 'antd'

import { logger } from 'App/utils/logger'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
}

export const getUserPreferenceValue = (type: string, key: string, data: any) => {
  try {
    const result = data?.userPreferences?.find(
      (d: any) => d?.type === type && d?.key === key
    )?.value
    return result ? JSON.parse(result) : undefined
  } catch (error) {
    logger.error('UserSettingsForm getUserPreferenceValue error', error)
  }
}

const UserSettingsForm = (props: any) => {
  const { form, data } = props
  const { getFieldDecorator } = form

  const formItems: any = [
    {
      key: enums.Vehicle.Display.key,
      label: 'Vehicle Display',
      ctrlType: (
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={enums.Vehicle.Display.code}>
            {enums.Vehicle.Display.code}
          </Radio.Button>
          <Radio.Button value={enums.Vehicle.Display.registration}>
            {enums.Vehicle.Display.registration}
          </Radio.Button>
          <Radio.Button value={enums.Vehicle.Display.both}>
            {enums.Vehicle.Display.both}
          </Radio.Button>
        </Radio.Group>
      ),
      initialValue: getUserPreferenceValue(
        enums.UserPreference.Type.SETTINGS,
        enums.Vehicle.Display.key,
        data
      )
    }
  ]

  return (
    <Row gutter={24}>
      {formItems?.map((formItem: any) => (
        <Col key={formItem.key} span={12}>
          <Form.Item label={formItem.label} {...formItemLayout}>
            {getFieldDecorator(formItem.key, {
              initialValue: formItem.initialValue
            })(formItem.ctrlType)}
          </Form.Item>
        </Col>
      ))}
    </Row>
  )
}

export default UserSettingsForm
