import type { Driver, Vehicle } from 'App/types/graphql'

import moment from 'moment'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import createSelectors from './createSelectors'

interface TimingData {
  start: string
  startOut: string
  end: string
  endOut: string
}
interface VDJMState {
  selectedDriver: Driver | undefined
  setSelectedDriver: (driver: Driver) => void

  selectedVehicle: Vehicle | undefined
  setSelectedVehicle: (vehicle: Vehicle) => void

  legUuid: string | null
  setLegUuid: (uuid: string) => void

  timingData: TimingData | null
  setTimingData: (data: TimingData) => void

  queryStartDate: Date | null
  setQueryStartDate: (date: Date) => void

  queryEndDate: Date | null
  setQueryEndDate: (date: Date) => void

  queryStartDateUnix: number | null
  setQueryStartDateUnix: (date: Date | number) => void

  queryEndDateUnix: number | null
  setQueryEndDateUnix: (date: Date | number) => void

  addJobTabType: string
  setAddJobTabType: (tabType: string) => void
}

export const useVDJMStore = createSelectors(
  create<VDJMState>()(
    devtools(
      persist(
        set => ({
          // Persistent state
          selectedDriver: undefined,
          setSelectedDriver: (driver: Driver) => set({ selectedDriver: driver }),

          // Non-persistent state
          selectedVehicle: undefined,
          setSelectedVehicle: (vehicle: Vehicle) => set({ selectedVehicle: vehicle }),

          queryStartDate: null,
          setQueryStartDate: (date: Date) =>
            set({
              queryStartDate: date,
              queryStartDateUnix: moment(date).unix()
            }),

          queryEndDate: null,
          setQueryEndDate: (date: Date) =>
            set({
              queryEndDate: date,
              queryEndDateUnix: moment(date).unix()
            }),

          queryStartDateUnix: null,
          setQueryStartDateUnix: (date: any) => set({ queryStartDateUnix: date }),

          queryEndDateUnix: null,
          setQueryEndDateUnix: (date: any) => set({ queryEndDateUnix: date }),

          addJobTabType: 'addJob',
          setAddJobTabType: (tabType: string) => set({ addJobTabType: tabType }),

          legUuid: null,
          setLegUuid: (uuid: string) => set({ legUuid: uuid }),

          timingData: null,
          setTimingData: data => set({ timingData: data })
        }),
        {
          name: 'VDJM Store',
          partialize: state => ({
            addJobTabType: state.addJobTabType
          })
        }
      )
    )
  )
)
