import { gql } from '@apollo/client'

export const gqlRoles = gql`
  query employeeRoles($input: RolesQuery) {
    roles(input: $input) {
      rows {
        uuid
        name
        code
        status
        updatedAt
        createdAt
        editBy {
          name
          email
        }
        editByUuid
      }
    }
  }
`

export const gqlCreateRole = gql`
  mutation createRole0($input: CreateRoleInput!) {
    createRole(input: $input) {
      uuid
      name
      code
      status
    }
  }
`

export const gqlUpdateRole = gql`
  mutation updateRole0($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      uuid
    }
  }
`
