import { gql } from '@/types'

import { useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Icon, Select } from 'antd'
import debounce from 'lodash/debounce'

import TaxDocumentViewer from '@/components/Manage/Companies/ChargeItemMisc/ModalView/TaxDocumentViewer'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const TAXES_SELECTOR_QUERY = gql(`
  query taxesSelector1($showAll: Boolean, $q: String) {
    taxes(showAll: $showAll, q: $q) {
      rows {
        uuid
        name
        code
        exemptionDocumentUuid
      }
    }
  }
`)

const Option = Select.Option

type TaxSelectProps = {
  value?: string
  onChange: (value: string, tax: any) => void
  showAll?: boolean
}

function TaxSelect({ value, onChange, showAll = false }: TaxSelectProps) {
  const [taxes, setTaxes] = useState<any>([])
  const [searchInput, setSearchInput] = useState('')
  const [toggleModal, setToggleModal] = useState(false)

  const { error, loading } = useQuery(TAXES_SELECTOR_QUERY, {
    variables: {
      q: searchInput || '',
      showAll
    },
    onCompleted: data => {
      setTaxes(data?.taxes?.rows)
    },
    fetchPolicy: 'cache-and-network'
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(value => {
      try {
        setSearchInput(value)
      } catch (error) {
        logger.error('TaxSelect handleSearch error', error)
      }
    }, 500),
    []
  )

  if (error) {
    logger.error('TaxSelect error', error)
    responseHandler(error, 'error')
  }

  const handleChange = (value, option) => onChange(value, option.props.tax)

  const currentTax = taxes?.find(tax => tax.uuid === value)
  const taxExempted = !!currentTax?.exemptionDocumentUuid

  return (
    <>
      <Select
        id={'taxCode-selector'}
        showSearch
        filterOption
        optionFilterProp="children"
        placeholder="Select a tax..."
        notFoundContent={loading ? <Icon type="loading" /> : 'Not found'}
        //@ts-ignore
        getPopupContainer={trigger => trigger.parentNode}
        onSearch={handleSearch}
        onChange={handleChange}
        value={value}
      >
        {taxes?.map(tax => {
          return (
            // @ts-ignore
            <Option key={tax.uuid} value={tax.uuid} tax={tax}>
              {tax.code} - {tax.name}
            </Option>
          )
        })}
      </Select>

      {currentTax && (
        <TaxDocumentViewer
          uuid={currentTax.exemptionDocumentUuid}
          setToggleModal={setToggleModal}
          toggleModal={toggleModal}
        />
      )}

      {taxExempted && currentTax && (
        <div onClick={() => setToggleModal(true)}>
          Tax exempted for <a>{currentTax.code}</a>
        </div>
      )}
    </>
  )
}

export default TaxSelect
