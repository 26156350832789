import { memo } from 'react'
import { Tooltip } from 'antd-v5'

import { PendingIcon } from '@/components/Workflow/Document/Container'

const PendingVerificationIcon = () => {
  return (
    <Tooltip title="Pending verification.">
      <PendingIcon type="warning" />
    </Tooltip>
  )
}

export default memo(PendingVerificationIcon)
