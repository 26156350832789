import type { FC } from 'react'

import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd'

import { PLACES_QUERY } from 'App/components/Booking/DynamicField/PlaceSelector/schema'
import useDebounce from 'App/hooks/useDebounce'

interface IPlaceSelect {
  value?: any
  onChange?: any
  disabled?: boolean
}

const PlaceSelect: FC<IPlaceSelect> = ({ disabled, ...otherProps }) => {
  const [searchInput, setSearchInput] = useState(otherProps?.value || '')

  const debouncedSearch = useDebounce(searchInput, 300)

  const [getPlaces, { data, loading }] = useLazyQuery(PLACES_QUERY, {
    variables: {
      q: debouncedSearch,
      limit: 10
    },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    getPlaces()
  }, [debouncedSearch, getPlaces])

  return (
    <Select
      {...otherProps}
      allowClear
      showSearch
      loading={loading}
      disabled={disabled}
      filterOption={false}
      style={{ width: '100%' }}
      placeholder="Select a city..."
      onSearch={(value: string) => setSearchInput(value)}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    >
      {data?.places?.map(place => (
        <Select.Option key={place?.placeId} value={place.placeId || ''}>
          {place?.description}
        </Select.Option>
      ))}
    </Select>
  )
}

export default PlaceSelect
