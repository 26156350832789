import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Row, Typography } from 'antd'
import { isArray } from 'lodash'

import SharedSelector from 'App/components/Select/SharedSelector'
import TransportZonesSelect from 'App/components/Select/TransportZonesSelect'
import { transportSources } from 'App/components/Transport/Planning/TripSummary/helpers'
import { DEPARTMENTS_SETTING, useBaseCompanySetting } from 'App/hooks/useBaseCompanySetting'
import { logger } from 'App/utils/logger'
import webStorage from 'App/utils/webStorage'

export const filterFormLabelStyle = {
  height: '32px',
  margin: 0,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

const PlanningTripsFilter = memo((props: any) => {
  const {
    tableData,
    baseZone,
    setBaseZone,
    setSelectedZones,
    selectedSources,
    setSelectedSources,
    selectedDepts,
    setSelectedDepts,
    selectedTags,
    setSelectedTags,
    parentName
  } = props

  const allTags = useMemo(
    () =>
      tableData
        ?.reduce((tags: Array<string>, leg: any) => {
          if (!isArray(leg.bookingDetails?.tags)) {
            return tags
          }
          leg.bookingDetails?.tags?.forEach((t: string) => {
            if (!tags.includes(t)) {
              tags.push(t)
            }
          })
          return tags
        }, [])
        ?.filter(Boolean)
        ?.sort((a: any, b: any) => a?.localeCompare(b)),
    [tableData]
  )

  const [depts, setDepts] = useState([])

  const { setting, loading } = useBaseCompanySetting(DEPARTMENTS_SETTING, {
    variables: { key: 'bookingDepartments' },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    try {
      const bookingDepartments = setting?.[0]?.value
      if (bookingDepartments) {
        const parsedSettings = JSON.parse(bookingDepartments)
        return setDepts(parsedSettings?.departments.concat(['UNKNOWN']))
      }
    } catch (error) {
      logger.error('DepartmentSelect BASE_COMPANY_SETTING error', error)
    }
  }, [setting])

  const onSetBaseZone = useCallback((zone: string) => {
    setBaseZone(zone)
    setSelectedZones({
      from: zone,
      to: zone,
      fromToMatch: false
    })
  }, [])

  const onTransportSourcesChange = useCallback((sources: Array<string>) => {
    let newSources: Array<string> = []
    if (sources?.length) {
      newSources = sources
    } else {
      newSources = transportSources
    }

    setSelectedSources(newSources)
    webStorage.setItem(`${parentName}_TRANSPORT_SOURCES`, newSources)
  }, [])

  const onDeptChange = useCallback((department: any) => {
    let newArray: Array<string> = []
    if (department?.length) {
      newArray = department
    }
    setSelectedDepts(newArray)
    webStorage.setItem('SELECTED_DEPARTMENTS_PLANNING_TABLE', newArray)
  }, [])

  const onTagChange = useCallback((item: any) => {
    let newArray: Array<string> = []
    if (item?.length) {
      newArray = item
    }
    setSelectedTags(newArray)
  }, [])

  return (
    <>
      <Row gutter={24} style={{ marginBottom: '10px' }}>
        <Col span={12}>
          <Row gutter={24} style={{ marginBottom: '10px' }}>
            <Col span={4}>
              <Typography.Paragraph style={filterFormLabelStyle}>Base Zone:</Typography.Paragraph>
            </Col>
            <Col span={20}>
              <TransportZonesSelect
                placeholder="Select a zone..."
                value={baseZone}
                onChange={onSetBaseZone}
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={24} style={{ marginBottom: '10px' }}>
            <Col span={4}>
              <Typography.Paragraph style={filterFormLabelStyle}>Source(s):</Typography.Paragraph>
            </Col>
            <Col span={20}>
              <SharedSelector
                mode="multiple"
                style={{ width: '100%' }}
                value={selectedSources}
                onChange={onTransportSourcesChange}
                dataArray={transportSources}
                allowClear={true}
                showSearch={true}
                placeholder="Select transport source(s)..."
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={24} style={{ marginBottom: '10px' }}>
        <Col span={12}>
          <Row gutter={24} style={{ marginBottom: '10px' }}>
            <Col span={4}>
              <Typography.Paragraph style={filterFormLabelStyle}>
                Department(s):
              </Typography.Paragraph>
            </Col>
            <Col span={20}>
              <SharedSelector
                mode="multiple"
                style={{ width: '100%' }}
                loading={loading}
                value={selectedDepts}
                onChange={onDeptChange}
                dataArray={depts}
                allowClear={true}
                showSearch={true}
                filterOption={true}
                placeholder="Select department(s)..."
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={24} style={{ marginBottom: '10px' }}>
            <Col span={4}>
              <Typography.Paragraph style={filterFormLabelStyle}>Tag(s):</Typography.Paragraph>
            </Col>
            <Col span={20}>
              <SharedSelector
                mode="multiple"
                style={{ width: '100%' }}
                value={selectedTags}
                onChange={onTagChange}
                dataArray={allTags}
                allowClear={true}
                placeholder="Select tag(s)..."
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
})

export default PlanningTripsFilter
