import type { Company } from 'App/types/graphql'
import type { Json2CsvOptions } from 'json-2-csv'
import { CompanyStatus } from 'App/types/graphql'

import { compact, isEmpty, pick } from 'lodash'
import { groupBy } from 'lodash/fp'
import { v4 as uuidv4 } from 'uuid'

import { sanitizeToArray } from 'App/components/Transport/Utils/jobHelper'
import { currenciesGql } from 'App/utils/importExport/chargeItems/schema'
import { logger } from 'App/utils/logger'
import { hasNonNumber, isUuid } from 'App/utils/u'
import { getGqlResponse } from '../helpers'
import {
  createAddressGql,
  createContactGql,
  createEntityGql,
  getAllAreaCodesGql,
  getDataGql,
  updateAddressGql,
  updateCompanyCodeGql,
  updateContactGql,
  updateEntityGql
} from './schema'

type MutationResultsType = {
  company?: any
  companyCode?: any
  createAddress?: any
  updateAddress?: any
  createContact?: any
  updateContact?: any
}

const companyKeys = [
  'code',
  'billTo',
  'currency',
  'creditorCode',
  'debtorCode',
  'description',
  'name',
  'registration',
  'status',
  'taxNumber',
  'overrideDuplicateCode'
]

const addressKeys = [
  'name',
  'address1',
  'address2',
  'address3',
  'address4',
  'areaCode',
  'city',
  'countryAlpha3',
  'district',
  'fax',
  'location',
  'phone',
  'postCode',
  'zone'
]

const contactKeys = ['name', 'email', 'phone', 'title', 'designation', 'notes']

const sampleData = [
  {
    no: 1,
    code: 'BB0001',
    country: {
      name: 'Malaysia',
      alpha3: 'MYS'
    },
    billTo: {
      code: 'BB0000',
      uuid: 'fce9d4fa-2ecf-4a3b-bc50-6085247fa90b'
    },
    creditorCode: 'CC001',
    creditorTerm: '30',
    currency: {
      code: 'MYR',
      uuid: 'edac2598-fb86-4857-8e83-c26b32984db1'
    },
    debtorCode: 'DC001',
    debtorTerm: '30',
    description: 'For testing purposes',
    name: 'Test Company 567 Sdn Bhd',
    registration: 'RG404',
    status: CompanyStatus.Activated,
    tags: '["DEPT:NORTH"]',
    taxNumber: 'TX123456',
    overrideDuplicateCode: 'true',
    types: '["billing","shipperConsignee"]',
    uuid: '3af62b7a-c131-4321-90ca-4d928c6d521d',
    address: {
      name: 'Swift Integrated Logistics Sdn. Bhd.',
      type: '["BILLING","DELIVERY"]',
      countryAlpha3: 'MYS',
      address1: 'Suite 8.02, Level 8, Intan Millennium Square 2 (IMS2)',
      address2: 'No 88, Jalan Batai Laut 4',
      address3: 'Taman Intan, 41300, Klang',
      address4: 'Selangor Darul Ehsan',
      city: 'Klang',
      district: 'Taman Intan',
      postCode: '41300',
      areaCode: 'C',
      zone: 'C',
      location: {
        type: 'Point',
        coordinates: [100.0, 0.0]
      },
      phone: '03-3169 6700',
      fax: '03-3169 6700',
      tags: '["isDefault"]',
      status: CompanyStatus.Activated,
      uuid: '42320437-cc8c-4d62-a24c-9ec11121a8c1',
      // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
      zzz: ''
    },
    contact: {
      name: 'Batman bin Superman',
      email: 'b.superman@swiftlogistics.com.my',
      phone: '012-3456789',
      title: 'Mr.',
      designation: 'Director',
      notes: 'superhero',
      status: CompanyStatus.Activated,
      uuid: '50786f9a-ecf7-4dda-8e67-0ef955de7ce1',
      // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
      zzz: ''
    }
  }
]

const remarks =
  '*Note: The required company fields are: code (e.g. BB0001), country.alpha3 (e.g. MYS), creditorCode/debtorCode (e.g. BB0001), creditorTerm/debtorTerm (e.g. 30), name (e.g. Swift Integrated Logistics Sdn. Bhd.), status (e.g. activated), and types (e.g. ["billing","shipperConsignee"]). ' +
  'If you wish to import address(es), the required address fields are: address.name (e.g. Swift Integrated Logistics Sdn. Bhd.), address.type (e.g. ["BILLING","DELIVERY"]), address.countryAlpha3 (e.g. MYS), address.address1 (e.g. No 88, Jalan Batai Laut 4), address.areaCode (e.g. C), address.zone (e.g. C), and address.status (e.g. activated). ' +
  'If you wish to import contact(s), the required contacts fields are: contact.name (e.g. Batman bin Superman), contact.phone (e.g. 012-3456789), and contact.status (e.g. activated).'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Country*',
    dataIndex: 'country.alpha3',
    key: 'country.alpha3'
  },
  {
    title: 'Bill To',
    dataIndex: 'billTo.code',
    key: 'billTo.code'
  },
  {
    title: 'Creditor',
    dataIndex: 'creditorCode',
    key: 'creditorCode'
  },
  {
    title: 'Debtor',
    dataIndex: 'debtorCode',
    key: 'debtorCode'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Types*',
    dataIndex: 'types',
    key: 'types',
    render: (text: any, record: any) => {
      const t = Array.isArray(record?.types) ? record?.types : [record.types]
      return t?.join(', ')
    }
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    key: 'uuid',
    ellipsis: true
  },
  {
    title: 'address.areaCode*',
    dataIndex: 'addresses',
    key: 'address.areaCode',
    render: (addresses: { areaCode: string }[]) =>
      addresses.map(address => address.areaCode || 'NA').join(', ')
  },
  {
    title: 'address.zone*',
    dataIndex: 'addresses',
    key: 'address.zone',
    render: (addresses: { zone: string }[]) =>
      addresses.map(address => address.zone || 'NA').join(', ')
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  company: {
    uuid: {},
    code: {}
  },
  currency: {
    uuid: {},
    code: {}
  },
  areaCode: {}
}

const findBadCompanyInput = async (company: any, selectedGlobalCompany: any) => {
  if (!company?.name) {
    return { nullError: 'Company name is required, Swift Haulage Berhad.' }
  }
  if (!company?.code) return { nullError: 'Company code is required, SHB001.' }
  if (!company?.types) {
    return {
      nullError: 'Company type(s) is required, e.g. ["billing","shipperConsignee"].'
    }
  }
  if (company?.country?.alpha3?.length !== 3) {
    return {
      nullError: 'Company country.alpha3 is required and should only have 3 letters, e.g. MYS.'
    }
  }
  if (company?.creditorTerm && hasNonNumber(company?.creditorTerm)) {
    return {
      nullError: `Company creditorTerm="${company?.creditorTerm}" should be a number, e.g. 30.`
    }
  }
  if (company?.debtorTerm && hasNonNumber(company?.debtorTerm)) {
    return {
      nullError: `Company debtorTerm="${company?.debtorTerm}" should be a number, e.g. 30.`
    }
  }
  if (
    company?.overrideDuplicateCode &&
    company?.overrideDuplicateCode !== 'true' &&
    company?.overrideDuplicateCode !== 'false'
  ) {
    return {
      nullError: `overrideDuplicateCode="${company?.overrideDuplicateCode}" should be TRUE or FALSE. If you are not sure, then please remove this column.`
    }
  }
  if (
    company?.status &&
    company?.status !== CompanyStatus.Activated &&
    company?.status !== CompanyStatus.Deleted
  ) {
    return {
      nullError: `status="${company?.status}" should be ${CompanyStatus.Activated} or ${CompanyStatus.Deleted}.`
    }
  }
}

const findNullAddressInputs = async (address: any, selectedGlobalCompany: any) => {
  if (!address?.name) {
    return {
      nullError: 'address.name is required, e.g. Swift Integrated Logistics Sdn. Bhd.'
    }
  }
  if (!address?.type) {
    return {
      nullError: 'address.type is required, e.g. ["BILLING","DELIVERY"].'
    }
  }
  if (!address?.countryAlpha3) {
    return { nullError: 'address.countryAlpha3 is required, e.g. MYS.' }
  }
  if (address?.countryAlpha3?.length !== 3) {
    return {
      inputError: 'address.countryAlpha3 is required and should only have 3 letters, e.g. MYS.'
    }
  }
  if (!address?.address1) {
    return {
      nullError:
        'address.address1 is required, e.g. Suite 8.02, Level 8, Intan Millennium Square 2 (IMS2).'
    }
  }
  if (!address?.areaCode) {
    return { nullError: 'address.areaCode is required, e.g. C.' }
  }
  if (!address?.zone) return { nullError: 'address.zone is required, e.g. C.' }
  if (
    address?.status &&
    address?.status !== CompanyStatus.Activated &&
    address?.status !== CompanyStatus.Deleted
  ) {
    return {
      nullError: `address.status="${address.status}" should be ${CompanyStatus.Activated} or ${CompanyStatus.Deleted}.`
    }
  }

  if (Object.keys(cache.areaCode)?.length < 1) {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getAllAreaCodesGql, {
        limit: 10000
      })
      // if (results?.data?.transportAreaCodes?.rows?.length < 1) { return { nullError: 'Error in Querying Area Codes.' } }

      for (let i = 0; i < results?.data?.transportAreaCodes?.rows?.length; i++) {
        cache.areaCode[results?.data?.transportAreaCodes?.rows[i]?.code?.toString()] =
          results?.data?.transportAreaCodes?.rows[i]?.uuid
      }
    } catch (error) {
      logger.error('findNullAddressInputs getAllAreaCodesGql error', error)
      return error
    }
  }

  if (!cache.areaCode[address?.areaCode?.toString()]) {
    return {
      nullError: `The provided areaCode="${address?.areaCode?.toString()}" is not found, please create it in the Transport Area Codes page first.`
    }
  }
}

const findNullContactInputs = (contact: any) => {
  if (!contact.name) return { nullError: 'contact.name is required.' }
  if (!contact.phone) return { nullError: 'contact.phone is required.' }
  contact.phone = String(contact.phone)
  if (contact.phone?.indexOf('E+') >= 0) {
    return {
      nullError: `contacts.phone=${contact.phone} should be a valid number, e.g. 012-34567890.`
    }
  }
  if (
    contact?.status &&
    contact?.status !== CompanyStatus.Activated &&
    contact?.status !== CompanyStatus.Deleted
  ) {
    return {
      nullError: `contact.status="${contact?.status}" should be ${CompanyStatus.Activated} or ${CompanyStatus.Deleted}.`
    }
  }
}

const populateCompanyObj = (
  obj: any,
  companyUuid: string,
  billToUuid: string,
  currencyUuid: string,
  mode: string
) => {
  const companyObj: any = {
    uuid: companyUuid?.trim(),
    name: obj?.name,
    types: obj?.types,
    countryAlpha3: obj?.country?.alpha3,
    description: obj?.description,
    registration: obj?.registration,
    tags: sanitizeToArray(obj?.tags),
    taxNumber: obj?.taxNumber
  }

  if (billToUuid?.length > 0) {
    companyObj.billToUuid = billToUuid
  }

  if (currencyUuid?.length > 0) {
    companyObj.preferredCurrencyUuid = currencyUuid
  }

  if (mode === 'update') {
    companyObj.status = obj?.status || CompanyStatus.Activated
  } else if (mode === 'create') {
    companyObj.code = obj?.code?.toString()
    companyObj.status = CompanyStatus.Activated
  }

  return companyObj
}

const populateCodesObj = (obj: any, companyUuid: string) => {
  const codesObj: any = {
    code: obj?.code?.toString()?.trim(),
    creditorCode: obj?.creditorCode?.toString()?.trim(),
    creditorTerm: obj?.creditorTerm ? Number(obj?.creditorTerm) : 0,
    debtorCode: obj?.debtorCode?.toString()?.trim(),
    debtorTerm: obj?.debtorTerm ? Number(obj?.debtorTerm) : 0,
    overrideDuplicateCode: false,
    registration: obj?.registration,
    taxNumber: obj?.taxNumber,
    uuid: companyUuid?.trim()
  }
  if (obj?.overrideDuplicateCode === 'true') {
    codesObj.overrideDuplicateCode = true
  }

  return codesObj
}

const populateAddressObj = (obj: any, companyUuid: string, mode: string, addressUuid: string) => {
  const addressObj: any = {
    name: obj?.name,
    type: obj?.type,
    countryAlpha3: obj?.countryAlpha3,
    address1: obj?.address1,
    address2: obj?.address2,
    address3: obj?.address3,
    address4: obj?.address4,
    city: obj?.city,
    district: obj?.district,
    postCode: String(obj?.postCode),
    areaCode: obj?.areaCode,
    zone: obj?.zone,
    phone: String(obj?.phone),
    fax: obj?.fax
  }

  if (obj?.tags) {
    addressObj.tags = sanitizeToArray(obj?.tags)
  }

  if (obj?.location?.coordinates) {
    if (obj?.location?.type === 'Point') {
      addressObj.location = {
        point: { ...obj?.location }
      }
    } else if (obj?.location?.type === 'Polygon') {
      addressObj.location = {
        polygon: { ...obj?.location }
      }
    }
  }

  if (mode === 'update') {
    addressObj.uuid = addressUuid?.trim()
    addressObj.status = obj?.status || CompanyStatus.Activated
  } else if (mode === 'create') {
    addressObj.uuid = uuidv4()
    addressObj.status = CompanyStatus.Activated
    addressObj.companyUuid = companyUuid
  }

  return addressObj
}

const populateContactObj = (obj: any, entityUuid: string, mode: string, contactUuid: string) => {
  const contactObj: any = {
    name: obj?.name,
    email: obj?.email,
    phone: String(obj?.phone),
    title: obj?.title,
    designation: obj?.designation,
    notes: obj?.notes
  }

  if (mode === 'update') {
    contactObj.uuid = contactUuid?.trim()
    contactObj.status = obj?.status || CompanyStatus.Activated
  } else if (mode === 'create') {
    contactObj.uuid = uuidv4()
    contactObj.status = CompanyStatus.Activated
    contactObj.companyUuid = entityUuid
  }

  return contactObj
}

const findGqlError = (gqlMutationResult: any) => {
  for (const key in gqlMutationResult) {
    if (gqlMutationResult[key]?.message) {
      return gqlMutationResult[key]
    }
  }
}

const companyHelper = {
  queryName: 'companies',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
        limit: 10000,
        statuses: [CompanyStatus.Activated, CompanyStatus.Deleted]
      })

      const companies = results?.data?.companies?.rows?.flatMap((row: Company, n: number) => {
        const addresses = [...(row?.addresses || [])]
        const contacts = [...(row?.contacts || [])]
        const rowLength = Math.max(addresses?.length || 0, contacts?.length || 0) || 1

        delete row?.addresses
        delete row?.contacts

        const cc: any = []

        n += 1

        for (let i = 0; i < rowLength; i++) {
          const address = addresses?.[i] || {}
          const contact = contacts?.[i] || {}
          const company = i === 0 ? row : pick(row, 'code')

          const c = {
            no: n,
            ...company,
            address,
            contact
          }

          cc.push(c)
        }

        return cc
      })

      return companies
    } catch (error) {
      logger.error('companyHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (companyData: any, selectedGlobalCompany: any) => {
    const mutationResults: MutationResultsType = {}
    const { addresses = [], contacts = [], importStatus, key, ...company } = companyData

    const nullInputError = await findBadCompanyInput(company, selectedGlobalCompany)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.company.uuid)?.length < 1) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          limit: 10000,
          statuses: [CompanyStatus.Activated, CompanyStatus.Deleted]
        })
        const companies = results?.data?.companies?.rows

        for (let i = 0; i < companies?.length; i++) {
          cache.company.uuid[companies[i]?.uuid] = companies[i]
          cache.company.code[companies[i]?.code] = companies[i]?.uuid
        }
      } catch (error) {
        logger.error('companyHelper cache getExportData error', error)
        return error
      }
    }

    // Check companyUuid
    let companyUuid: string = ''
    if (company?.uuid && isUuid(company?.uuid) && cache.company.uuid[company?.uuid]) {
      companyUuid = company?.uuid
    } else if (cache.company.code[company?.code]) {
      companyUuid = cache.company.code[company?.code]
    }

    // Check billToUuid
    let billToUuid: string = ''
    if (company?.billTo?.uuid && isUuid(company?.billTo?.uuid)) {
      if (cache.company.uuid[company?.billTo?.uuid]) {
        billToUuid = company?.billTo?.uuid
      } else {
        return {
          nullError: `billTo.uuid="${company?.billTo?.uuid}" not found. Please create this company or leave the billTo.uuid field blank.`
        }
      }
    } else if (company?.billTo?.code) {
      if (cache.company.code[company?.billTo?.code]) {
        billToUuid = cache.company.code[company?.billTo?.code]
      } else {
        return {
          nullError: `billTo.code="${company?.billTo?.code}" not found. Please create this company or leave the billTo fields blank.`
        }
      }
    }

    if (Object.keys(cache.currency.uuid)?.length < 1) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, currenciesGql, { limit: 10000 })
        const currencies = results?.data?.currencies?.rows

        for (let i = 0; i < currencies?.length; i++) {
          cache.currency.uuid[currencies[i]?.uuid] = currencies[i]?.code
          cache.currency.code[currencies[i]?.code] = currencies[i]?.uuid
        }
      } catch (error) {
        logger.error('companyHelper cache currenciesGql error', error)
        return error
      }
    }

    // Check currencyUuid
    let currencyUuid: string = ''
    if (company?.currency?.uuid && isUuid(company?.currency?.uuid)) {
      if (cache.currency.uuid[company?.currency?.uuid]) {
        currencyUuid = company?.currency?.uuid
      } else {
        return {
          nullError: `currency.uuid="${company?.currency?.uuid}" not found. Please enable this currency or leave the currency.uuid field blank.`
        }
      }
    } else if (company?.currency?.code) {
      if (cache.currency.code[company?.currency?.code]) {
        currencyUuid = cache.currency.code[company?.currency?.code]
      } else {
        return {
          nullError: `currency.code="${company?.currency?.code}" not found. Please enable this currency or leave the currency fields blank.`
        }
      }
    }

    // Update company
    if (companyUuid?.length > 0) {
      try {
        const companyObj = populateCompanyObj(
          company,
          companyUuid,
          billToUuid,
          currencyUuid,
          'update'
        )
        mutationResults.company = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: companyObj
        })
      } catch (error) {
        logger.error('companyHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create new company
      try {
        companyUuid = uuidv4()
        const companyObj = populateCompanyObj(
          company,
          companyUuid,
          billToUuid,
          currencyUuid,
          'create'
        )
        mutationResults.company = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: companyObj
        })
      } catch (error) {
        logger.error('companyHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    // Update company code
    try {
      const updateCodesObj = populateCodesObj(company, companyUuid)
      mutationResults.companyCode = await getGqlResponse(
        selectedGlobalCompany,
        updateCompanyCodeGql,
        { input: updateCodesObj }
      )
    } catch (error) {
      logger.error('companyHelper handleImportData updateCompanyCodeGql error', error)
      return error
    }

    if (addresses?.length > 0) {
      for (let i = 0; i < addresses?.length; i++) {
        try {
          let addMode: string = 'create'
          let addressUuid: string = ''

          const currentAddress = cache.company.uuid[companyUuid]?.addresses?.find(
            (add: any) =>
              add?.uuid === addresses[i]?.uuid || add.address1 === addresses[i]?.address1
          )
          addressUuid = currentAddress?.uuid || ''
          if (addressUuid && isUuid(addressUuid)) {
            addMode = 'update'
          }

          if (addMode === 'update') {
            const nullAddressInputError = await findNullAddressInputs(
              addresses[i],
              selectedGlobalCompany
            )
            if (nullAddressInputError) return nullAddressInputError

            const addressObj = populateAddressObj(addresses[i], companyUuid, addMode, addressUuid)
            mutationResults.updateAddress = await getGqlResponse(
              selectedGlobalCompany,
              updateAddressGql,
              { input: addressObj }
            )
          } else {
            if (
              !addresses[i].name ||
              !addresses[i].type ||
              !addresses[i].countryAlpha3 ||
              !addresses[i].address1
            ) {
              continue
            }

            const nullAddressInputError = await findNullAddressInputs(
              addresses[i],
              selectedGlobalCompany
            )
            if (nullAddressInputError) return nullAddressInputError

            const addressObj = populateAddressObj(addresses[i], companyUuid, addMode, addressUuid)
            mutationResults.createAddress = await getGqlResponse(
              selectedGlobalCompany,
              createAddressGql,
              { input: addressObj }
            )
          }
        } catch (error) {
          logger.error('companyHelper handleImportData update/create address error', error)
          return error
        }
      }
    }

    if (contacts?.length > 0) {
      for (let i = 0; i < contacts?.length; i++) {
        try {
          let contactMode: string = 'create'
          let contactUuid: string = ''

          const currentContact = cache.company.uuid[companyUuid]?.contacts?.find(
            (con: any) => con?.uuid === contacts[i]?.uuid || con.phone === contacts[i]?.phone
          )
          contactUuid = currentContact?.uuid || ''
          if (contactUuid && isUuid(contactUuid)) {
            contactMode = 'update'
          }

          if (contactMode === 'update') {
            const nullContactError = findNullContactInputs(contacts[i])
            if (nullContactError) return nullContactError

            const contactObj = populateContactObj(
              contacts[i],
              companyUuid,
              contactMode,
              contactUuid
            )
            mutationResults.updateContact = await getGqlResponse(
              selectedGlobalCompany,
              updateContactGql,
              { input: contactObj }
            )
          } else {
            if (!contacts[i].name || !contacts[i].phone) {
              continue
            }

            const nullContactError = findNullContactInputs(contacts[i])
            if (nullContactError) return nullContactError

            const contactObj = populateContactObj(
              contacts[i],
              companyUuid,
              contactMode,
              contactUuid
            )
            mutationResults.createContact = await getGqlResponse(
              selectedGlobalCompany,
              createContactGql,
              { input: contactObj }
            )
          }
        } catch (error) {
          logger.error('companyHelper handleImportData update/create contact error', error)
          return error
        }
      }
    }

    const errorObj = findGqlError(mutationResults)
    if (errorObj) {
      return errorObj
    }
    return mutationResults
  },

  mapFromCsv: (csvData: any) => {
    if (!csvData || !csvData?.length) {
      return []
    }

    const csvDataByCompanyCode = groupBy('code', csvData)
    const csvGroupedData = Object.values(csvDataByCompanyCode)

    const getCompanyIndexData = (csvGroupedData: any[]) => {
      let maxInfoCount = 0
      let maxInfoIndex = -1

      for (let i = 0; i < csvGroupedData.length; i++) {
        const infoCount = compact(Object.values(csvGroupedData[i])).length

        if (infoCount > maxInfoCount) {
          maxInfoCount = infoCount
          maxInfoIndex = i
        }
      }

      return maxInfoIndex > 0 ? csvGroupedData[maxInfoIndex] : csvGroupedData[0]
    }

    const cleanedData: any[] = []

    for (let i = 0; i < csvGroupedData?.length; i++) {
      // get the row with the complete company data
      const companyIndexDataObj = getCompanyIndexData(csvGroupedData[i])

      companyKeys.forEach(key => {
        if (key === 'billTo' || key === 'currency') {
          companyIndexDataObj[key] = {
            code: companyIndexDataObj[key]?.code?.toString()?.trim()
          }
        } else if (key === 'overrideDuplicateCode') {
          companyIndexDataObj[key] = companyIndexDataObj[key]?.toString()?.toLowerCase()?.trim()
        } else {
          companyIndexDataObj[key] = companyIndexDataObj[key]?.toString()?.trim()
        }
      })

      const currentCompany = csvDataByCompanyCode[companyIndexDataObj.code] || []

      companyIndexDataObj.addresses = []

      // TO BE REMOVED IN FUTURE: SUPPORT OLD FORMAT
      if (companyIndexDataObj.address_1) {
        let addressCount = 0

        Object.keys(companyIndexDataObj).forEach((k: any, index: number) => {
          if (k.indexOf('address_') > -1) {
            addressCount += 1
          }
        })

        for (let j = 0; j < addressCount; j++) {
          if (!companyIndexDataObj[`address_${j + 1}`]) {
            break
          } else {
            for (const k of addressKeys) {
              if (k === 'location') {
                if (companyIndexDataObj[`address_${j + 1}`].location) {
                  const o = {
                    type: companyIndexDataObj[`address_${j + 1}`].location?.type,
                    coordinates: companyIndexDataObj[`address_${j + 1}`].location?.coordinates
                  }
                  companyIndexDataObj[`address_${j + 1}`].location = isEmpty(o) ? null : o
                }
                continue
              }

              companyIndexDataObj[`address_${j + 1}`][k] = companyIndexDataObj[`address_${j + 1}`][
                k
              ]
                ?.toString()
                ?.trim()
            }

            const item = companyIndexDataObj[`address_${j + 1}`]

            if (item && item.name) {
              companyIndexDataObj.addresses.push(item)
            }

            delete companyIndexDataObj[`address_${j + 1}`]
          }
        }
      }
      const rowAddresses = currentCompany.map(c => c.address).filter(c => !isEmpty(c) || c?.name)

      companyIndexDataObj.addresses.push(...rowAddresses)

      companyIndexDataObj.contacts = []

      // TO BE REMOVED IN FUTURE: SUPPORT OLD FORMAT
      if (companyIndexDataObj.contact_1) {
        let contactCount = 0

        Object.keys(companyIndexDataObj).forEach((k: any, index: number) => {
          if (k.indexOf('contact_') > -1) {
            contactCount += 1
          }
        })

        for (let j = 0; j < contactCount; j++) {
          if (!companyIndexDataObj[`contact_${j + 1}`]) {
            break
          } else {
            for (const k of contactKeys) {
              companyIndexDataObj[`contact_${j + 1}`][k] = companyIndexDataObj[`contact_${j + 1}`][
                k
              ]
                ?.toString()
                ?.trim()
            }

            const item = companyIndexDataObj[`contact_${j + 1}`]

            if (item && item.name) {
              companyIndexDataObj.contacts.push(item)
            }

            delete companyIndexDataObj[`contact_${j + 1}`]
          }
        }
      }

      const rowContacts = currentCompany.map(c => c.contact).filter(c => !isEmpty(c) || c?.name)

      companyIndexDataObj.contacts.push(...rowContacts)

      companyIndexDataObj.importStatus = 'pending'
      companyIndexDataObj.key = i + 1

      cleanedData.push(companyIndexDataObj)
    }

    return cleanedData
  },

  sampleData,

  remarks,

  tableColumns,

  options: {
    emptyFieldValue: '',
    expandArrayObjects: true,
    excludeKeys: ['address', 'contact', 'currency', 'billTo'] //need to manually add objects key to exclude since they are already expanded
  } as Json2CsvOptions
}

export default companyHelper
