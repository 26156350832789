import type { RefetchQueriesFunction } from '@apollo/client'
import type { TransportJob } from 'App/types/graphql'

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useExpanded, useTable } from 'react-table'
import { Button, Divider, Row, Tooltip, Typography } from 'antd'
import { cloneDeep } from 'lodash'

import { isActiveLeg } from 'App/components/Transport/Utils/jobHelper'
import AddLegForm from './AddLegForm'
import { getColumns } from './columns'
import { formatLegTableData } from './formatLegTableData'
import LegActivities from './LegActivities'
import { LegTableStyles } from './LegTableStyles'
import UpdateLegForm from './UpdateLegForm'

interface LegTableProps {
  refetchLegs?: RefetchQueriesFunction
  legs: TransportJob[]
  legsLoading?: boolean
  vehicleCodeOrReg?: string
}

const LegTable = memo((props: LegTableProps) => {
  const { legs, legsLoading, refetchLegs, vehicleCodeOrReg } = props

  const [showDeleted, setShowDeleted] = useState<boolean>(true)
  const [legFormVisible, setLegFormVisible] = useState<boolean>(false)
  const [legActivitiesVisible, setLegActivitiesVisible] = useState<boolean>(false)
  const [selectedLeg, setSelectedLeg] = useState<TransportJob | undefined>(undefined)

  const clonedLegs: TransportJob[] = useMemo(() => cloneDeep(legs), [legs])
  const deletedLeg: TransportJob | undefined = useMemo(
    () => clonedLegs?.find((leg: TransportJob) => !isActiveLeg(leg)),
    [clonedLegs]
  )
  const memoData: TransportJob[] = useMemo(
    () => (showDeleted ? clonedLegs : clonedLegs?.filter((leg: TransportJob) => isActiveLeg(leg))),
    [clonedLegs, showDeleted]
  )

  const setFormVisibility = useCallback(
    (legUuid: string) => {
      if (selectedLeg?.legUuid !== legUuid) {
        const currentLeg = memoData?.find((leg: TransportJob) => leg.legUuid === legUuid)
        setSelectedLeg(currentLeg)
      }
      setLegFormVisible(true)
    },
    [memoData, selectedLeg]
  )

  const onCloseForm = useCallback(() => {
    setSelectedLeg(undefined)
    setLegFormVisible(false)
  }, [])

  const onSetShowDeleted = useCallback(() => {
    setShowDeleted((state: boolean) => !state)
  }, [])

  const setActivitiesVisibility = useCallback(
    (legUuid: string) => {
      if (selectedLeg?.legUuid !== legUuid) {
        const currentLeg = memoData?.find((leg: TransportJob) => leg.legUuid === legUuid)
        setSelectedLeg(currentLeg)
      }
      setLegActivitiesVisible(true)
    },
    [memoData, selectedLeg?.legUuid]
  )

  useEffect(() => {
    const noEndOut: TransportJob | undefined = memoData?.find((data: TransportJob) => !data?.endOut)
    if (!noEndOut) return

    const isDeleted: boolean = !isActiveLeg(noEndOut)
    if (!selectedLeg?.legUuid && noEndOut && !isDeleted) {
      setFormVisibility(noEndOut.legUuid)
    }
  }, [memoData, selectedLeg, setFormVisibility])

  const columns = useMemo(
    () => getColumns(setFormVisibility, refetchLegs, setActivitiesVisibility),
    [refetchLegs, setFormVisibility, setActivitiesVisibility]
  )

  const tableData = useMemo(
    /* @ts-ignore */
    () => formatLegTableData(memoData, vehicleCodeOrReg) || [],
    [memoData, vehicleCodeOrReg]
  )

  const newSelectedLeg = useMemo(
    () => memoData?.find((l: TransportJob) => l?.legUuid === selectedLeg?.legUuid),
    [memoData, selectedLeg]
  )
  const activeLegs = useMemo(
    () => clonedLegs?.filter((l: TransportJob) => isActiveLeg(l)),
    [clonedLegs]
  )

  const { rows, prepareRow, headerGroups, getTableProps, visibleColumns, getTableBodyProps } =
    useTable(
      {
        /* @ts-ignore */
        columns,
        data: tableData
      },
      useExpanded
    )

  return (
    <>
      <Divider style={{ marginTop: 15, marginBottom: deletedLeg ? 0 : 15 }}>
        <Typography.Text>{'Jobs > Trips > Legs'}</Typography.Text>
        <span style={{ display: 'inline', paddingLeft: '20px' }}>
          <Tooltip title="Refresh legs">
            <Button icon="sync" loading={legsLoading} onClick={refetchLegs} />
          </Tooltip>
        </span>
      </Divider>

      {deletedLeg && (
        <Row type="flex" justify="end">
          <Button onClick={onSetShowDeleted} style={{ color: 'rgba(0, 0, 0, 0.45)' }} type="link">
            {showDeleted ? 'Hide' : 'Show'} Deleted
          </Button>
        </Row>
      )}

      <Row>
        <LegTableStyles>
          <table {...getTableProps()}>
            <thead>
              {headerGroups?.map((headerGroup: any) => (
                <tr
                  key={headerGroup.getHeaderGroupProps().key}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers?.map((column: any) => (
                    <th key={column.Header} {...column.getHeaderProps()} style={column.style}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row: any) => {
                prepareRow(row)
                const rowProps = row.getRowProps()

                return (
                  <React.Fragment key={rowProps.key}>
                    <tr {...rowProps}>
                      {row.cells.map((cell: any) => {
                        const isDeleted: boolean = !isActiveLeg(row.original)
                        const renderByCell = cell.column.id === 'legCrudButtons'
                        const isSelected = row.original.legUuid === selectedLeg?.legUuid
                        const bgColor = isSelected
                          ? '#e6f7ff'
                          : isDeleted
                            ? 'rgba(0,0,0,0.06)'
                            : undefined

                        if (renderByCell) {
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={cell.getCellProps().key}
                              style={{ backgroundColor: bgColor, ...cell.column.style }}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        }

                        return (
                          <td
                            {...cell.getCellProps()}
                            key={cell.getCellProps().key}
                            style={{ backgroundColor: bgColor, ...cell.column.style }}
                          >
                            <Button
                              disabled={!!isDeleted}
                              type="link"
                              style={{
                                padding: 0,
                                width: '100%',
                                display: 'block',
                                fontSize: '10px',
                                textAlign: 'left',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                color: 'rgba(0, 0, 0, 0.65)'
                              }}
                              onClick={() => setFormVisibility(row.original.legUuid)}
                            >
                              {cell.render('Cell')}
                            </Button>
                          </td>
                        )
                      })}
                    </tr>
                    {row.isExpanded && (
                      <AddLegForm
                        // @ts-ignore
                        row={row}
                        rowProps={rowProps}
                        refetchLegs={refetchLegs}
                        visibleColumns={visibleColumns}
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </LegTableStyles>
      </Row>

      {legFormVisible && (
        <UpdateLegForm
          // @ts-ignore
          legs={activeLegs}
          leg={newSelectedLeg}
          onCloseForm={onCloseForm}
          legsLoading={legsLoading}
          refetchLegs={refetchLegs}
        />
      )}

      {legActivitiesVisible && (
        <LegActivities leg={newSelectedLeg} setLegActivitiesVisible={setLegActivitiesVisible} />
      )}
    </>
  )
})

export default LegTable
