import type { Job } from '@/types/graphql'

import convert from 'convert-units'
import countBy from 'lodash/fp/countBy'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import fpGroupBy from 'lodash/fp/groupBy'
import map from 'lodash/fp/map'
import toPairs from 'lodash/fp/toPairs'
import includes from 'lodash/includes'
import sumBy from 'lodash/sumBy'
import { multiply } from 'mathjs'

import labelMap from '@/utils/labelMap'

export const tagTypes = [
  'HOUSE_BL',
  'HOUSE_BOOKING_CONFIRMATION',
  'AUTO_APPROVE_EXPORT_CUSTOMS',
  'AUTO_APPROVE_IMPORT_CUSTOMS',
  'BILL_OF_LADING_HOLD',
  'DOOR_TO_DOOR',
  'DOOR_TO_PORT_ORI',
  'DOOR_TO_PORT_DEST',
  'PORT_TO_PORT',
  'PORT_TO_DOOR'
]

export const transportDocumentTypes = ['SEA_WAYBILL', 'ORDER_BL', 'NOMINATE_BL', 'BEARER_BL']

export const incoTerms = [
  {
    code: 'EXW',
    label: 'Ex Works'
  },
  {
    code: 'FCA',
    label: 'Free Carrier'
  },
  {
    code: 'CPT',
    label: 'Carriage Paid To'
  },
  {
    code: 'CIP',
    label: 'Carriage and Insurance Paid To'
  },
  {
    code: 'DAT',
    label: 'Delivered at Terminal'
  },
  {
    code: 'DAP',
    label: 'Delivered at Place'
  },
  {
    code: 'DDP',
    label: 'Delivered Duty Paid'
  },
  {
    code: 'FAS',
    label: 'Free Alongside Ship'
  },
  {
    code: 'FOB',
    label: 'Free on Board'
  },
  {
    code: 'CFR',
    label: 'Cost and Freight'
  },
  {
    code: 'CIF',
    label: 'Cost, Insurance, and Freight'
  }
]

export const getBl = booking => {
  if (booking.type === 'IMPORT') {
    return booking.details.bl
  }
  return (
    booking.details.shippingRef ||
    booking.details.shipperRef ||
    (booking.details.reference || []).join(',')
  )
}

export const getshipperOrConsignee = booking => {
  if (booking.type === 'IMPORT') {
    return (booking.consignee && booking.consignee.name) || booking.consigneeName
  }
  return (booking.shipper && booking.shipper.name) || booking.shipperName
}

export const getRef = (companyType, booking) => {
  if (!booking) {
    return '-'
  }

  if (booking.no) {
    return booking.no
  }

  if (booking.referenceNo) {
    return booking.referenceNo
  }

  let ref = ''

  if (!booking.details) {
    booking.details = {}
  }

  switch (companyType) {
    case 'shipperConsignee':
      ref = [
        booking.type === 'IMPORT' ? booking.details.consigneeRef : '',
        booking.type === 'EXPORT' ? booking.details.shipperRef : ''
      ]
        .filter(s => s)
        .join(' & ')
      break
    case 'forwarder':
    case 'haulier':
    case 'liner':
      ref = [
        booking.type === 'IMPORT' ? booking.details[`${companyType}DestRef`] : '',
        booking.type === 'EXPORT' ? booking.details[`${companyType}OriRef`] : ''
      ]
        .filter(s => s)
        .join(' & ')
      break
    default:
  }

  if (!ref) {
    ref =
      booking.details.bl ||
      booking.details.shippingRef ||
      (booking.details.reference || []).join(',') ||
      ''
  }

  return ref
}

export const getContainerStats = jobs =>
  flow(
    countBy((job: Job) => (job.details?.containerType || '') + '/' + (job.details?.size || '')),
    toPairs,
    map(([key, count]) => {
      const splitted = key.split('/')
      return {
        type: splitted[0],
        size: splitted[1],
        count
      }
    })
  )(jobs)

export const getWeightStats = jobs =>
  flow(
    countBy((job: Job) => job.details.weight + '/' + job.details.weightUnit),
    toPairs,
    map(([key, count]) => {
      const splitted = key.split('/')
      return {
        weight: splitted[0],
        unit: splitted[1],
        count
      }
    })
  )(jobs)

export const getJobsVolume = jobs => {
  return getContainerStats(jobs)
    .map(s => `${s.count}x${s.size} ${s.type}`)
    .join(', ')
}

export const getJobsWeightSum = jobs => {
  const jobWeightStats = getWeightStats(jobs)

  return flow(
    filter(
      (j: { weight; unit; count }) =>
        !(j.weight === 'undefined' || j.weight === 'null') &&
        !(j.unit === 'undefined' || j.unit === 'null')
    ),
    fpGroupBy(j => j.unit),
    map(jobs => {
      return `${sumBy(jobs, j => Number(multiply(j.weight || 1, j.count || 1)))} ${jobs[0].unit}`
    })
  )(jobWeightStats).join(', ')
}

export const getJobsVolumeSum = jobs => {
  const sum = jobs
    .map(j => {
      if (!j.details || !j.details.volume || !j.details.volumeUnit) {
        return 0
      }

      return convert(j.details.volume).from(j.details.volumeUnit).to('m3')
    })
    .reduce((acc, cur) => {
      return acc + cur
    }, 0)

  return sum
}

export const getCustomerRef = booking => {
  if (booking.type === 'EXPORT') {
    return booking.details.shipperRef
  }

  return booking.details.consigneeRef
}

export const handleResponse = error => {
  if (error.graphQLErrors && error.graphQLErrors.length) {
    const graphQLError = error.graphQLErrors[0]

    return {
      success: false,
      message: graphQLError.message
    }
  }
}

export const filterBookingState = bookingState => {
  let iconName = icons.PENDING

  if (includes(bookingState, 'Comp')) {
    iconName = icons.DONE
  } else if (includes(bookingState, 'Less')) {
    iconName = icons.WARNING
  }

  if (bookingState) {
    return {
      stateName: bookingState,
      stateDisplayName: labelMap[bookingState] || bookingState,
      iconName
    }
  } else {
    return {
      stateDisplayName: 'Pending',
      iconName: 'access_time'
    }
  }
}

const icons = {
  PENDING: 'access_time',
  DONE: 'done',
  WARNING: 'warning'
}

const stateDisplayInfo = {
  documentation: { defaultDisplayName: 'Documentation', iconName: 'file-alt', fa: true },
  liner: { defaultDisplayName: 'Liner', iconName: 'anchor', fa: true },
  freight: { defaultDisplayName: 'Freight', iconName: 'ship', fa: true },
  customs: { defaultDisplayName: 'Customs', iconName: 'chalkboard-teacher', fa: true },
  haulage: { defaultDisplayName: 'Hlg/Trans', iconName: 'truck', fa: true },
  compAp: { defaultDisplayName: 'Payment', iconName: 'money-bill', fa: true },
  compAr: { defaultDisplayName: 'Invoice', iconName: 'file-invoice-dollar', fa: true },
  complete: { defaultDisplayName: 'Completion', iconName: 'flag-checkered', fa: true }
}

export const displayState = {
  expDoc: stateDisplayInfo.documentation,
  expHlg: stateDisplayInfo.haulage,
  expLiner: stateDisplayInfo.liner,
  expFreight: stateDisplayInfo.freight,
  expCust: stateDisplayInfo.customs,
  expCompAp: stateDisplayInfo.compAp,
  expCompAr: stateDisplayInfo.compAr,
  expComp: stateDisplayInfo.complete,
  impDoc: stateDisplayInfo.documentation,
  impLiner: stateDisplayInfo.liner,
  impFreight: stateDisplayInfo.freight,
  impCust: stateDisplayInfo.customs,
  impHlg: stateDisplayInfo.haulage,
  impCompAp: stateDisplayInfo.compAp,
  impCompAr: stateDisplayInfo.compAr,
  impComp: stateDisplayInfo.complete,
  transDoc: stateDisplayInfo.documentation,
  transHlg: stateDisplayInfo.haulage,
  transCompAr: stateDisplayInfo.compAr,
  transComp: stateDisplayInfo.complete
}

export const getDisplayState = key => {
  const dispState = displayState[key]

  return dispState || stateDisplayInfo.complete
}
