import type { MenuProps } from 'antd-v5'

import { memo, useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd-v5'
import { parse, stringify } from 'query-string'

import CustomBadge from '@/components/Layouts/LeftFilter/CustomBadge'
import { getBookingQuery } from '@/components/Layouts/Search/helper'
import labelMap from '@/utils/labelMap'
import styles from './Filter.module.css'

interface FacetProps {
  key: string
  doc_count: number
}

type FilterProps = {
  type: string
  titleAttribute: string
  facet: FacetProps[]
}

type MenuItem = Required<MenuProps>['items'][number]

const Filter = ({ titleAttribute, facet, type }: FilterProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [selectSubMenu, setSelectSubMenu] = useState<string>('')

  const count = useMemo(
    () => (facet ? facet.reduce((sum: number, item: FacetProps) => sum + item.doc_count, 0) : 0),
    [facet]
  )
  const title = useMemo(() => labelMap[titleAttribute], [titleAttribute])

  const buildQuery = useCallback(
    (facet: string | undefined, key: string) => {
      const fromURL = location.search.slice(1)
      const urlData: any = parse(fromURL)
      const query = {
        q: urlData?.q || '',
        sort: urlData?.sort || '',
        limit: urlData?.limit || 20,
        offset: urlData?.offset || 0,
        filter: facet ? { facetFilter: { [key]: facet } } : {}
      }

      const { urlFormat } = getBookingQuery(location, query)

      const queryParams = stringify(urlFormat)
      navigate(`/?${queryParams}`)
    },
    [location, navigate]
  )

  const handleSubMenuClick: MenuProps['onClick'] = e => {
    if (selectSubMenu === e.key) {
      setSelectSubMenu('')
      navigate('/')
    } else {
      setSelectSubMenu(e.key)
      buildQuery(e.key, titleAttribute)
    }
  }

  const menuItems = facet?.map((item: FacetProps) => {
    const key =
      typeof item.key === 'number'
        ? item.key === 1
          ? 'TRUE'
          : item.key === 0
            ? 'FALSE'
            : item.key
        : item.key

    const display = labelMap[key] || key

    return {
      key: `${type}-${display}-${item.doc_count}`,
      label: (
        <div className={styles.menuItem}>
          <span className={styles.itemText}>{display}</span>
          <CustomBadge count={item.doc_count} />
        </div>
      )
    }
  })

  const items: MenuItem[] = [
    {
      key: `${type}-${title || ''}`,
      label: <CustomBadge count={count} text={title} />,
      children: menuItems
    }
  ]

  return (
    <Menu mode="inline" items={items} onClick={handleSubMenuClick} selectedKeys={[selectSubMenu]} />
  )
}

export default memo(Filter)
