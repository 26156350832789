import { memo } from 'react'
import { useTable } from 'react-table'

interface ReactTableProps {
  tableColumns: any
  tableData: any
  styleTableCells: (r: any) => void
  styleTableHeaders?: (r: any) => void
}

const BasicReactTable = memo((props: ReactTableProps) => {
  const { tableColumns, tableData, styleTableCells, styleTableHeaders = () => {} } = props

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: tableColumns,
    data: tableData
  })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any, index: any) => {
          const styleOptions = { headerGroup, index }
          return (
            <tr
              key={headerGroup.headers[index].Header}
              {...headerGroup.getHeaderGroupProps()}
              style={styleTableHeaders(styleOptions)}
            >
              {headerGroup.headers.map((column: any) => (
                <th key={column.Header} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          )
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, rowIndex: number) => {
          prepareRow(row)
          return (
            <tr key={row.id} {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                const styleOptions = { cell, row, rowIndex }
                return (
                  <td
                    key={cell.column.Header}
                    {...cell.getCellProps()}
                    style={styleTableCells(styleOptions)}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
})

export default BasicReactTable
