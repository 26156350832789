import styled from 'styled-components'

export const ActionWrapper = styled.div`
  padding: 0px 0 10px;

  .ant-select {
    width: 100%;
    color: #151b26;
    font-size: 13.222px;
    font-weight: 500;
  }

  .ant-select-selection--single {
    height: 36px;
  }

  .ant-select-selection__rendered {
    line-height: 34px;
  }
`

export const FilterName = styled.div`
  font-size: 13.222px;
  color: #151b26;
  padding-right: 10px;
`
