import { ContainerTypeStatus } from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

const sampleData = [
  {
    name: 'gp',
    code: 'GP',
    sorting: 1,
    description: 'GP',
    status: 'ACTIVE',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: The required fields are: name (e.g. GP or DG1), code (e.g. GP), sorting (e.g. 1)'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Sorting*',
    dataIndex: 'sorting',
    key: 'sorting'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (containerType: any) => {
  if (!containerType.code) return { nullError: 'Code is required, e.g. GP.' }
  if (!containerType.name) return { nullError: 'Name is required, e.g. GP.' }
  if (!containerType.sorting) return { nullError: 'Sorting is required, e.g. 1.' }
}

const populateContainerTypeObj = (obj: any, containerTypeId: string) => {
  const containerTypeObj: any = {
    uuid: containerTypeId?.trim(),
    code: obj.code?.toString()?.trim(),
    name: obj.name,
    sorting: parseInt(obj.sorting),
    description: obj.description,
    status: obj.status || ContainerTypeStatus.Active
  }

  return containerTypeObj
}

const containerTypesHelper = {
  queryName: 'containerTypes',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const containerTypesQuery = {
        limit: 10000
      }
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, containerTypesQuery)
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const containerTypes = results?.data?.containerTypes?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row,
        zzz: ''
      }))
      return containerTypes
    } catch (error) {
      logger.error('containerTypesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (containerTypeData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...containerType } = containerTypeData

    const nullInputError = findNullInputs(containerType)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
        const containerTypes = results?.data?.containerTypes?.rows

        for (let i = 0; i < containerTypes?.length; i++) {
          cache.uuid[containerTypes[i]?.uuid] = containerTypes[i]?.code
          cache.code[containerTypes[i]?.code] = containerTypes[i]?.uuid
        }
      } catch (error) {
        logger.error('containerTypesHelper cache getDataGql error', error)
        return error
      }
    }

    // Check containerTypeId
    let containerTypeId: string = ''
    if (cache.uuid[containerType?.uuid]) {
      containerTypeId = containerType?.uuid
    } else if (cache.code[containerType?.code]) {
      containerTypeId = cache.code[containerType?.code]
    }

    let mutationResults
    // Update
    if (containerTypeId?.length > 0) {
      try {
        const containerTypeObj = populateContainerTypeObj(containerType, containerTypeId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: containerTypeObj
        })
      } catch (error) {
        logger.error('containerTypesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        containerTypeId = uuidv4()
        const containerTypeObj = populateContainerTypeObj(containerType, containerTypeId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: containerTypeObj
        })
      } catch (error) {
        logger.error('containerTypesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default containerTypesHelper
