import { Link, useLocation } from 'react-router-dom'
import includes from 'lodash/includes'

import { withViewer } from 'App/contexts/viewer'
import { GroupLabel, MenuBody, MenuSelector, StyledMenu } from './Styled'

const SuperAdminMenu = () => {
  const location = useLocation()

  return (
    <StyledMenu>
      <MenuBody>
        <GroupLabel>Super Admin</GroupLabel>
        <Link to="/superadmin/base-companies">
          <MenuSelector selected={includes(location.pathname, '/superadmin/base-companies')}>
            Base Companies
          </MenuSelector>
        </Link>
        <Link to="/superadmin/superadmins">
          <MenuSelector selected={includes(location.pathname, '/superadmin/superadmins')}>
            Super Admins
          </MenuSelector>
        </Link>
      </MenuBody>
    </StyledMenu>
  )
}

export default withViewer(SuperAdminMenu)
