import { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { Button, Col, DatePicker, Form, Input, Popover, Row } from 'antd'
import moment from 'moment'
import { compose } from 'recompose'
import styled from 'styled-components'

import RelativeDateTime from 'App/components/RelativeDateTime'
import { UPDATE_LEG } from 'App/graphql/booking'
import respHandler from 'App/utils/responseHandler'

const withGraphqlUpdateLeg = graphql(UPDATE_LEG, {
  props: ({ mutate }) => ({
    updateLeg: input =>
      mutate({
        variables: {
          input
        }
      })
  })
})

const enhance = compose(withGraphqlUpdateLeg, Form.create())

const StyledDateSpan = styled.span`
  cursor: pointer;

  :hover {
    color: #40a9ff;
  }
`

const LegDateAction = class extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      visible: false
    }
  }

  handleVisibleChange = visible => {
    this.setState({
      ...this.state,
      visible
    })
  }

  handleUpdate = () => {
    const { form, updateLeg, onChange = () => {} } = this.props

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.setState({
            ...this.state,
            loading: true
          })

          await updateLeg(values)
          await onChange()

          this.setState({
            ...this.state,
            loading: false
          })
        } catch (error) {
          console.log(error)
          respHandler(error, 'error')
          this.setState({
            ...this.state,
            loading: false
          })
        }
      }
    })
  }

  renderPopoverContent = () => {
    const {
      form: { getFieldDecorator },
      leg
    } = this.props
    const { loading } = this.state
    const { start } = leg

    return (
      <Row type="flex" justify="space-between" gutter={8}>
        <Col>
          {getFieldDecorator('uuid', {
            initialValue: leg.uuid
          })(<Input type="hidden" />)}
          {getFieldDecorator('start', {
            initialValue: start && moment(start)
          })(
            <DatePicker
              style={{ width: '280px' }}
              showTime={{ format: 'h:mm:ss a', use12Hours: true }}
              format="YYYY-MM-DD h:mm:ss a"
              getCalendarContainer={trigger => trigger.parentNode}
            />
          )}
        </Col>
        <Col>
          <Button type="primary" loading={loading} onClick={this.handleUpdate}>
            Update
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    const { leg } = this.props
    const { visible } = this.state
    const start = leg && leg.start

    return (
      <Popover
        content={this.renderPopoverContent()}
        placement="bottom"
        trigger="click"
        getPopupContainer={trigger => trigger.parentNode}
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
      >
        <StyledDateSpan>
          <RelativeDateTime value={start} />
        </StyledDateSpan>
      </Popover>
    )
  }
}

export default enhance(LegDateAction)
