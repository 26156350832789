import type { MutationButton } from 'App/components/Transport/Components/Shared/BulkUpdateLegsTable'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Button, notification } from 'antd'
import { omit } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import { v4 } from 'uuid'

import BulkUpdateLegsTable from 'App/components/Transport/Components/Shared/BulkUpdateLegsTable'
import { updateLegStatus } from 'App/components/Transport/Planning/Vehicle/helpers'
import {
  UPDATE_LEG_MUTATION,
  UPDATE_LEG_TIMING_MUTATION
} from 'App/components/Transport/Schemas/schema'
import { useUnplannedStore } from 'App/store/unplanned'

export const TitleSpan = styled.span`
  margin: 0px;
  padding: 0px;
  color: #222222;
  cursor: pointer;
`

const SelectedAddPlanVehicleJobsTable = props => {
  const { t } = useTranslation()
  const { setSelectedUnplannedLegs } = useUnplannedStore()

  const {
    form,

    getJobs,
    activityObj,
    selectedRow,
    selectedJobs,
    setSelectedRow,
    setSelectedJobs,
    setFormSuccessMsg
  } = props

  const [refetch, setRefetch] = useState(false)
  const [currentUpdate, setCurrentUpdate] = useState<any>({})
  const [debouncedLoading, setDebouncedLoading] = useState<boolean | null>(null)

  const [pageObj, setPageObj] = useState<any>({
    pageSize: 8,
    current: 1,
    onChange: (current: number) => {
      setPageObj({
        ...pageObj,
        current,
        total: selectedJobs?.length
      })
    }
  })

  const [updateLeg, { loading: updateLoading }] = useMutation(UPDATE_LEG_MUTATION, {
    onCompleted: data => {
      if (data?.updateLeg?.success) {
        setFormSuccessMsg('Successfully updated legs')
      }
      const legUuid = data?.updateLeg?.message || currentUpdate.legUuid
      const { newUpdatedLeg, index } =
        updateLegStatus(selectedJobs, legUuid, {
          isUpdated: true
        }) || {}

      if (newUpdatedLeg) {
        setSelectedJobs(prev => [
          ...prev.slice(0, index || 0),
          newUpdatedLeg,
          ...prev.slice((index || 0) + 1)
        ])
      }
      setRefetch(true)
    },
    onError: error => {
      return notification.error({
        message: 'Failed to update leg',
        description: error.message,
        key: error.message
      })
    }
  })

  const [updateLegTiming, { loading: timingLoading }] = useMutation(UPDATE_LEG_TIMING_MUTATION, {
    onCompleted: async data => {
      if (data?.updateLegTiming?.success) {
        setFormSuccessMsg('Successfully updated legs timing')
      }

      const legUuid = data?.updateLegTiming?.message || currentUpdate.legUuid
      const { newUpdatedLeg, index } =
        updateLegStatus(selectedJobs, legUuid, {
          isUpdated: true
        }) || {}

      if (newUpdatedLeg) {
        setSelectedJobs(prev => [
          ...prev.slice(0, index || 0),
          newUpdatedLeg,
          ...prev.slice((index || 0) + 1)
        ])
      }
      setRefetch(true)
    },
    onError: error => {
      if (currentUpdate) {
        const { newUpdatedLeg, index } =
          updateLegStatus(selectedJobs, currentUpdate.legUuid, {
            updateStatus: 'Failed'
          }) || {}
        if (newUpdatedLeg) {
          setSelectedJobs(prev => [
            ...prev.slice(0, index || 0),
            newUpdatedLeg,
            ...prev.slice((index || 0) + 1)
          ])
        }
      }

      return notification.error({
        message: 'Failed to update leg timing',
        description: error.message,
        key: error.message
      })
    }
  })

  useEffect(() => {
    if (!refetch) {
      return
    }

    const timeout = setTimeout(() => {
      setDebouncedLoading(timingLoading || updateLoading)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [refetch, timingLoading, updateLoading])

  useEffect(() => {
    if (refetch && !debouncedLoading) {
      getJobs()
      setRefetch(false)
      setDebouncedLoading(null)
    }
  }, [debouncedLoading, getJobs, refetch])

  useEffect(() => {
    setPageObj({
      ...pageObj,
      total: selectedJobs?.length,
      showTotal: (total: number, range: any) => `${range[0]}-${range[1]} of ${total} items`
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobs])

  const updateLegMutation = async row => {
    if (!row.legUuid || !row.tripUuid) {
      return notification.error({
        message: 'Cannot update leg',
        description: 'Leg or trip UUID is missing'
      })
    }

    setCurrentUpdate(row.legUuid)

    const { newUpdatedLeg, index } =
      updateLegStatus(selectedJobs, row.legUuid, { updateStatus: 'Updating details' }) || {}

    if (newUpdatedLeg) {
      setSelectedJobs(prev => [
        ...prev.slice(0, index || 0),
        newUpdatedLeg,
        ...prev.slice((index || 0) + 1)
      ])
    }

    const values = form.getFieldsValue()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { transportSource, type, ...updateLegInput } = values

    const updateLegRes = await updateLeg({
      variables: {
        input: {
          ...omit(updateLegInput, 'planStart'),
          _id: v4(),
          legUuid: row.legUuid,
          tripUuid: row.tripUuid,
          isSynchronous: true
        }
      }
    })

    if (updateLegRes?.data?.updateLeg?.success) {
      const isFirstTripAndFirstLeg = row?.sequence === 1 && row?.tripSequence === 1

      const updateLegTimingObj: any = {
        _id: v4(),
        tripUuid: row?.tripUuid,
        legUuid: row?.legUuid,
        planStart: moment(
          isFirstTripAndFirstLeg ? row.shipperRequiredDate : updateLegInput.planStart
        ).toDate()
      }

      await updateLegTiming({ variables: { input: updateLegTimingObj } })
    }
  }

  const mutationButtons: MutationButton[] = [
    {
      key: 'updateLeg',
      title: t('transport.bulkUpdateLegsModal.updateLeg'),
      onClick: async (row: any) => await updateLegMutation(row)
    }
  ]

  const columns = [
    {
      title: (
        <Button type="danger" onClick={() => setSelectedJobs([])}>
          {t('transport.bulkUpdateLegsModal.removeAll')}
        </Button>
      ),
      dataIndex: 'jobNo',
      key: 'removeJob',
      render: (jNo: string) => (
        <Button
          icon="minus"
          style={{ width: '100%', maxWidth: 95 }}
          onClick={() => {
            const curr = [...selectedJobs]
            const index = curr.findIndex(job => job.jobNo === jNo)
            curr.splice(index, 1)
            setSelectedJobs(curr)
            setSelectedUnplannedLegs(curr)
          }}
        />
      )
    },
    {
      title: t('common.jobNo'),
      dataIndex: 'jobNo',
      key: 'jobNo',
      render: (text: string) => <TitleSpan>{text}</TitleSpan>
    },
    {
      title: t('common.from'),
      dataIndex: 'from.name',
      key: 'from',
      render: (text: string) => <TitleSpan>{text || 'From?'}</TitleSpan>
    },
    {
      title: t('common.to'),
      dataIndex: 'to.name',
      key: 'to',
      render: (text: string) => <TitleSpan>{text || '-'}</TitleSpan>
    },
    {
      title: t('transport.bulkUpdateLegsModal.transportSource'),
      dataIndex: 'transportSource',
      key: 'transportSource',
      render: (text: string) => <TitleSpan>{text || '-'}</TitleSpan>
    },
    {
      title: t('transport.bulkUpdateLegsModal.updateStatus'),
      dataIndex: 'updateStatus',
      key: 'updateStatus',
      render: (text: string) => (
        <TitleSpan>{text || t('transport.bulkUpdateLegsModal.pendingUpdate')}</TitleSpan>
      )
    }
  ]

  return (
    <BulkUpdateLegsTable
      columns={columns}
      data={selectedJobs}
      paginationObj={pageObj}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      mutationButtons={mutationButtons}
      activityObj={activityObj}
    />
  )
}

export default SelectedAddPlanVehicleJobsTable
