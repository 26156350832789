import type { Voucher } from '@/types/graphql'
import { TransactionType, VoucherStatus, VoucherTypeType } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Table, Typography } from 'antd-v5'

import { getLocalDateString } from '@/components/RelativeDateTime'
import { PrintDocumentCreator } from '@/components/Shared/documentCreator/openPrintableInNewTab'
import CustomText from '@/components/Voucher/CustomText'
import VoucherModalInvoiceNumber from '@/components/Voucher/Management/VoucherModalInvoiceNumber'
import VoucherPdfAsyncDownloadAction from '@/components/Voucher/Management/VoucherPdfAsyncDownloadAction'
import { isApproved } from '@/components/Voucher/Utils'
import { getRef } from '@/utils/booking'
import { voucherTypes } from '@/utils/labelMap'

const getColumns = (props, navigate) => {
  const { selectedGlobalCompany } = props
  const type = selectedGlobalCompany?.types?.[0]

  return [
    {
      title: 'No.',
      dataIndex: 'voucherNumber',
      key: 'voucherNumber',
      render: (text: string, record: any) => (
        <VoucherModalInvoiceNumber voucher={record} bcUuid={selectedGlobalCompany?.uuid} />
      )
    },
    {
      title: 'Booking No.',
      key: 'bookingNumber',
      ellipsis: true,
      render: (text: string, record: any) => {
        const html = record.bookings?.reduce((acc: any[], booking: any, index: number) => {
          const ref = getRef(type, booking)
          if (!ref) return acc
          acc.push(
            <a
              key={index || ref}
              onClick={() => navigate(`/bookings/${ref}`)}
              style={{ fontSize: 12, fontWeight: 600, paddingRight: 5 }}
            >
              {ref}
            </a>
          )
          return acc
        }, [])

        return html
      }
    },
    {
      title: 'Date',
      dataIndex: 'issueDate',
      key: 'issueDate',
      width: 80,
      render: (text: string) => <CustomText text={getLocalDateString(text)} />
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 85,
      render: (text: string) => {
        let cssClass = ''

        if (text === VoucherStatus.Approved) {
          cssClass = 'table-data-text-color-green'
        } else if (text === VoucherStatus.Deleted) {
          cssClass = 'table-data-text-color-red'
        } else if (text === VoucherStatus.Draft) {
          cssClass = 'table-data-text-color-chocolate'
        } else if (text === VoucherStatus.Submitted) {
          cssClass = 'table-data-text-color-grey'
        }

        return <div className={cssClass}>{text}</div>
      }
    },
    {
      title: 'Tran. Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      width: 90,
      render: (text: string, record: any) => {
        let cssClass = ''

        if (record.transactionType === VoucherTypeType.Accpay) {
          cssClass = 'table-data-text-color-blue'
        } else if (record.transactionType === VoucherTypeType.Accrec) {
          cssClass = 'table-data-text-color-green'
        }
        const cn = record.isCreditNote ? '(CN)' : record.isJournalVoucher ? '(JV)' : ''

        return <div className={cssClass}>{record.transactionType + cn}</div>
      }
    },
    {
      title: 'Code - Company',
      key: 'company',
      width: 150,
      ellipsis: true,
      render: (text: string, record: any) => {
        const code =
          record.transactionType === TransactionType.Accrec
            ? record.customer?.debtorCode
            : record.vendor?.creditorCode

        const companyName =
          record.transactionType === TransactionType.Accrec
            ? record.customer?.name
            : record.vendor?.name

        return (
          <>
            <CustomText text={code} />
            {' - '}
            <CustomText text={companyName} />
          </>
        )
      }
    },
    {
      title: 'Type',
      key: 'type',
      ellipsis: true,
      render: (text: string, record: any) => {
        const label = voucherTypes[record.type]?.text || record.type
        return <CustomText text={label} />
      }
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      render: (text: string) => <CustomText text={text || '-'} />
    },
    {
      title: 'Total',
      key: 'total',
      align: 'right',
      render: (text: string, record: any) => (
        <>
          <span style={{ marginRight: 2 }}>
            <CustomText text={record.currency.code} />
          </span>
          <CustomText text={record.total} isNumeric />
        </>
      )
    },
    {
      title: 'Aging Days',
      dataIndex: 'dueDate',
      key: 'dueDate',
      align: 'right',
      width: 50,
      render: (text: string) => {
        const currentDateUnix = new Date().getTime()
        const dueDateUnix = new Date(text).getTime()
        const days = Math.floor((currentDateUnix - dueDateUnix) / 86400 / 1000)
        return <CustomText text={days} />
      }
    },
    {
      title: 'View',
      key: 'view',
      align: 'center',
      width: 60,
      render: (text: string, record: any) => {
        if (isApproved(record)) {
          return (
            <div className="flex justify-end">
              <PrintDocumentCreator
                modelUUID={record.uuid}
                baseCompanyUuid={selectedGlobalCompany.uuid}
                type={'INVOICE'}
              />
              {record.transactionType === TransactionType.Accrec && (
                <span style={{ marginLeft: 5 }}>
                  <VoucherPdfAsyncDownloadAction voucher={record} type="INVOICE" />
                </span>
              )}
            </div>
          )
        } else return null
      }
    }
  ]
}

const getRowSelection = (props: any) => {
  const { selectedVouchers, onVouchersChange } = props

  const selectedRowKeys = selectedVouchers.map((v: Voucher) => v?.uuid)

  return {
    selectedRowKeys,
    onSelect: (record: any, selected: any) => {
      if (onVouchersChange) {
        onVouchersChange(selected, [record])
      }
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      if (onVouchersChange) {
        onVouchersChange(selected, changeRows)
      }
    }
  }
}

const VoucherSelectionTable = (props: any) => {
  const navigate = useNavigate()

  const [pageSize, setPageSize] = useState(20)
  const [total, setTotal] = useState(props.data.pageInfo?.count || 0)

  const { data, query, loading, setQuery, selectedVouchers } = props

  useEffect(() => {
    setTotal(data.pageInfo?.count)
  }, [data])

  const rowKey = (record: Voucher) => record.uuid

  const getFooter = () => (
    <Typography.Text className="w-full flex justify-end">Total records: {total}</Typography.Text>
  )

  const handleQueryAll = () => {
    setQuery({ ...query, limit: data.pageInfo.count, offset: 0 })
  }

  const handlePageChange = (page: any, pageSize: any) => {
    setQuery({
      ...query,
      limit: pageSize,
      offset: (page - 1) * pageSize
    })
  }

  const handleSizeChange = (current: number, size: number) => {
    setPageSize(size)
    handlePageChange(current, size)
  }

  const pagination = {
    pageSize,
    showSizeChanger: true,
    onChange: handlePageChange,
    total: data.pageInfo?.count,
    onShowSizeChange: handleSizeChange,
    pageSizeOptions: ['10', '20', '50', '100'],
    current: Math.max(1, (data.pageInfo?.offset || 0) / (data.pageInfo?.limit || 1) + 1)
  }

  return (
    <>
      {!!selectedVouchers.length && (
        <div className="flex flex-col text-center justify-center self-center gap-8 mb-4">
          <Typography.Text type="secondary">
            {`${selectedVouchers.length} vouchers are selected`}
          </Typography.Text>
          <Button loading={loading} onClick={handleQueryAll}>
            <Typography.Text>
              {loading ? 'loading...' : `Select all ${total} vouchers`}
            </Typography.Text>
          </Button>
        </div>
      )}

      <Table
        rowKey={rowKey}
        loading={loading}
        dataSource={data?.rows}
        rowSelection={getRowSelection(props)}
        // @ts-ignore
        columns={getColumns(props, navigate)}
        pagination={pagination}
        footer={getFooter}
      />
    </>
  )
}

export default VoucherSelectionTable
