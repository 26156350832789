import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import startCase from 'lodash/startCase'

import Booking from '@/components/Booking'

export default function BookingPage() {
  const location = useLocation()
  const params = useParams()

  useEffect(() => {
    let title = ''
    const pathname = location.pathname.split('/')

    if (pathname.length === 3 && pathname?.[1] === 'bookings') {
      title = pathname?.[1]?.replace('-', ' ')
    }

    document.title = `ShipX ${title ? '|' : ''} ${startCase(title)}`
  }, [location])

  return <Booking params={params} />
}
