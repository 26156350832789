import type { Role } from 'App/types/graphql'
import { RoleStatus } from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { isUuid } from 'App/utils/u'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

const sampleData = [
  {
    no: 1,
    code: 'TRIP',
    name: 'TRIP',
    tags: null,
    status: RoleStatus.Active,
    uuid: '52e89d86-a781-4ca7-9fdf-32206aa8436d',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks = '*Note: The required fields are: code (e.g. OPERATIONS) and name (e.g. OPERATIONS).'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    key: 'uuid'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (obj: Role) => {
  if (!obj.code) {
    return { nullError: 'Code is required, e.g. OPERATIONS.' }
  }
  if (!obj.name) {
    return { nullError: 'Name is required, e.g. OPERATIONS.' }
  }
  if (obj.status && obj.status !== RoleStatus.Active && obj.status !== RoleStatus.Cancelled) {
    return {
      nullError: `Status should be ${RoleStatus.Active} or ${RoleStatus.Cancelled}, or please remove this column.`
    }
  }
}

const populateCache = async (selectedGlobalCompany: any) => {
  try {
    const rolesQuery = {
      limit: 10000
    }
    const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { input: rolesQuery })
    const roles = results?.data?.roles?.rows

    for (let i = 0; i < roles?.length; i++) {
      cache.uuid[roles[i]?.uuid] = roles[i]
      cache.code[roles[i]?.code] = roles[i]?.uuid
    }
  } catch (error) {
    logger.error('rolesHelper populateCache error', error)
    return error
  }
}

const populateRole = (obj: any, roleUuid: string, isCreate: boolean) => {
  const roleObj: any = {
    uuid: roleUuid,
    name: obj.name?.toString().trim(),
    tags: obj.tags?.length ? obj.tags?.split(',') : null // avoid having [""]
  }

  if (isCreate) {
    roleObj.code = obj.code?.toString().trim()
  } else if (obj.status) {
    // For update; create shouldn't have status
    roleObj.status = obj.status
  }

  return roleObj
}

const rolesHelper = {
  queryName: 'roles',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const rolesQuery = {
        limit: 10000
      }
      const res = await getGqlResponse(selectedGlobalCompany, getDataGql, { input: rolesQuery })
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      return res?.data?.roles?.rows?.map((row: any, i: number) => ({ no: i + 1, ...row, zzz: '' }))
    } catch (error) {
      logger.error('rolesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (data: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...role } = data

    const nullInputError = findNullInputs(role)
    if (nullInputError) {
      return nullInputError
    }

    if (Object.keys(cache.uuid)?.length < 1) {
      const cacheErr = await populateCache(selectedGlobalCompany)
      if (cacheErr) {
        return cacheErr
      }
    }

    // Check if exists
    let roleUuid: string = ''
    if (cache.uuid[role?.uuid]) {
      roleUuid = role.uuid
    } else if (cache.code[role?.code]) {
      roleUuid = cache.code[role.code]
    }

    let mutationResults
    // Update
    if (isUuid(roleUuid)) {
      try {
        const roleObj = populateRole(role, roleUuid, false)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: roleObj
        })
      } catch (error) {
        logger.error('rolesHelper updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        roleUuid = uuidv4()
        const roleObj = populateRole(role, roleUuid, true)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: roleObj
        })
      } catch (error) {
        logger.error('rolesHelper createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default rolesHelper
