import { forwardRef, useEffect, useRef } from 'react'
import { Input } from 'antd'

import { hasNonNumber, regex } from 'App/utils/u'

type CustomTimeInputProps = {
  allowClear?: boolean
  autoFocus?: boolean
  maxLength?: number
  value?: string
  highlightText?: boolean
  onChange?: () => void
  onSubmitTime?: () => void
  placeholder?: string
  timeInput?: any
  setTimeInput?: any
  style?: any
}

export const padTimeTo4Digits = (time: string) => {
  let newTimeString: string = time

  if (time && hasNonNumber(time)) {
    // @ts-ignore
    newTimeString = time?.replace(regex.nonNumber, '')
  }

  if (newTimeString && Number(newTimeString) > 2359) {
    newTimeString = '2359'
  }

  if (newTimeString?.length === 1) {
    newTimeString = `00:0${newTimeString}`
  }

  if (newTimeString?.length === 2) {
    newTimeString = `00:${newTimeString}`
  }

  if (newTimeString?.length === 3) {
    newTimeString = `0${newTimeString?.slice(0, 1)}:${newTimeString?.slice(1, 3)}`
  }

  if (newTimeString?.length === 4) {
    if (Number(newTimeString.slice(-2)) >= 60) {
      const thirdLastDigit = newTimeString.slice(newTimeString.length - 3, newTimeString.length - 2)
      const newValue = `${newTimeString?.slice(0, newTimeString.length - 3)}${
        thirdLastDigit && Number(thirdLastDigit) + 1
      }${'00'}`
      newTimeString = newValue
    }

    newTimeString = `${newTimeString?.slice(0, 2)}:${newTimeString?.slice(2, 4)}`
  }

  return newTimeString
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomTimeInput = forwardRef((props: CustomTimeInputProps, ref: any) => {
  const {
    allowClear = false,
    autoFocus = false,
    maxLength = 4,
    value,
    highlightText = false,
    onChange,
    onSubmitTime,
    placeholder = '0000',
    timeInput,
    setTimeInput,
    style = {}
  } = props

  const InputElement = useRef(null)

  useEffect(() => {
    if (InputElement.current && highlightText) {
      const selectInputText = setTimeout(() => {
        // @ts-ignore
        InputElement.current?.select()
      }, 0)

      return () => {
        clearTimeout(selectInputText)
      }
    }
  }, [highlightText])

  useEffect(() => {
    setTimeInput(value)

    if (value && typeof value === 'string' && hasNonNumber(value)) {
      // @ts-ignore
      setTimeInput(value?.replace(regex.nonNumber, ''))
    }

    if (value && Number(value) > 2359) {
      setTimeInput('2359')
    }

    // @ts-ignore
    if (value?.length === 4 && Number(value.slice(-2)) >= 60) {
      // @ts-ignore
      const thirdLastDigit = value.slice(value.length - 3, value.length - 2)
      const newValue = `${value?.slice(0, value.length - 3)}${
        thirdLastDigit && Number(thirdLastDigit) + 1
      }${'00'}`
      setTimeInput(newValue)
    }
    return () => {}
  }, [setTimeInput, value])

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.key === 'Enter' && onSubmitTime) {
        onSubmitTime()
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onSubmitTime])

  return (
    <Input
      allowClear={allowClear}
      autoFocus={autoFocus}
      maxLength={maxLength}
      onChange={onChange}
      placeholder={placeholder}
      ref={InputElement}
      style={style}
      value={timeInput}
    />
  )
})

export default CustomTimeInput
