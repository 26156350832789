import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import delay from 'lodash/delay'
import queryString from 'query-string'

import SignOff from 'App/components/Auth/SignOff'
import redux from 'App/hocs/redux'
import { logOut } from 'App/states/auth/actions'

const messages = {
  440: 'Token expired, please login again.'
}

function SignOffPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    const hashedProps = queryString.parse(location.search)
    const errorMessage =
      typeof hashedProps.statusCode === 'string' && messages[hashedProps.statusCode]
        ? messages[hashedProps.statusCode]
        : ''

    setErrorMessage(errorMessage)

    delay(() => {
      dispatch(logOut())
      navigate('/auth/sign-in')
    }, 1500)
  }, [dispatch, navigate, location])

  return <SignOff message={errorMessage} />
}

export default redux(SignOffPage)
