import { gql } from '@/types'

export const BASE_COMPANY_CURRENCY = gql(`
  query baseCompanyCurrencies($uuid: UUID!) {
    baseCompany(uuid: $uuid) {
      uuid
      currencies {
        uuid
        code
        name
      }
    }
  }
`)

export const GET_CURRENCIES = gql(`
  query currencies($limit: Int, $offset: Int) {
    currencies(limit: $limit, offset: $offset) {
      rows {
        uuid
        code
        name
      }
    }
  }
`)
