export const getDataGql = `
  query weightUnitsExport1 ($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    weightUnits (limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
  }
`

export const createEntityGql = `
  mutation createWeightUnitExport1 ($input: CreateWeightUnitInput!) {
    createWeightUnit (input: $input) {
      code
      uuid
      name
      description
      sorting
      status
    }
  }
`

export const updateEntityGql = `
    mutation updateWeightUnitExport1 ($input: UpdateWeightUnitInput!) {
      updateWeightUnit (input: $input) {
        code
        uuid
        name
        description
        sorting
        status
      }
    }
`
