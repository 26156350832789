import 'mapbox-gl/dist/mapbox-gl.css'

import type { MarkerDragEvent } from 'react-map-gl'

import { useCallback, useEffect, useState } from 'react'
import Map, { Marker } from 'react-map-gl'

import DrawControl from '@/components/Shared/AddressFormModal/AddressMap/DrawControl'
import Pin from '@/components/Shared/AddressFormModal/AddressMap/Pin'
import config from '@/config'

type AddressMapProps = {
  isPolygonMode: boolean
  setFieldsValue: any
  getFieldValue: any
}

function AddressMap({ isPolygonMode, setFieldsValue, getFieldValue }: AddressMapProps) {
  const [marker, setMarker] = useState<any>({
    latitude: getFieldValue('latitude'),
    longitude: getFieldValue('longitude')
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [features, setFeatures] = useState({})

  const [userLocation, setUserLocation] = useState<any>(null)
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        },
        () => {
          setUserLocation({
            longitude: 101.473547,
            latitude: 3.057504
          })
        }
      )
    }
  }

  useEffect(() => {
    getLocation()
  }, [])

  const onUpdate = useCallback(
    e => {
      setFeatures(currFeatures => {
        const newFeatures = { ...currFeatures }
        for (const f of e.features) {
          newFeatures[f.id] = f
        }
        // @ts-expect-error
        const geoJson = Object.values(newFeatures)?.[0]?.geometry
        if (geoJson) {
          setFieldsValue({
            geoJson: true,
            latitude: null,
            longitude: null,
            location: { polygon: { ...geoJson, coordinates: geoJson.coordinates[0] } }
          })
        }
        return newFeatures
      })
    },
    [setFieldsValue]
  )

  const onDelete = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = { ...currFeatures }
      for (const f of e.features) {
        delete newFeatures[f.id]
      }

      return newFeatures
    })
  }, [])

  const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat
    })
  }, [])

  const onSetMarker = ({ lngLat }: any) => {
    setMarker({
      longitude: lngLat.lng,
      latitude: lngLat.lat
    })
    setFieldsValue({
      geojson: false,
      location: {
        point: {
          type: 'Point',
          coordinates: [marker.longitude, marker.latitude]
        }
      },
      latitude: marker.latitude,
      longitude: marker.longitude
    })
  }

  const isMarker = typeof marker?.latitude === 'number' && typeof marker?.longitude === 'number'

  return (
    <>
      {(userLocation || isMarker) && (
        <Map
          attributionControl={false}
          style={{ width: '98%', height: '90%' }}
          mapboxAccessToken={config.mapbox.token}
          mapStyle="mapbox://styles/mapbox/satellite-v9"
          onClick={!isPolygonMode ? onSetMarker : undefined}
          initialViewState={isMarker ? { ...marker, zoom: 16 } : { ...userLocation, zoom: 16 }}
        >
          {isPolygonMode ? (
            <>
              <DrawControl
                position="top-left"
                onCreate={onUpdate}
                onUpdate={onUpdate}
                onDelete={onDelete}
                defaultMode="draw_polygon"
                displayControlsDefault={false}
                controls={{ polygon: true, trash: true }}
              />
            </>
          ) : (
            marker && (
              <Marker
                draggable
                anchor="bottom"
                onDrag={onMarkerDrag}
                style={{ zIndex: 1000 }}
                latitude={marker.latitude}
                longitude={marker.longitude}
              >
                <Pin size={40} />
              </Marker>
            )
          )}
        </Map>
      )}
    </>
  )
}

export default AddressMap
