import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default WrappedComponent => {
  const WithSecure = ({ isAuthenticated, ...props }) => {
    const navigate = useNavigate()

    useEffect(() => {
      if (!isAuthenticated) navigate('/auth/sign-off')
    }, [isAuthenticated, navigate])

    return isAuthenticated ? <WrappedComponent {...props} /> : null
  }

  return WithSecure
}
