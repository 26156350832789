import { graphql } from '@apollo/client/react/hoc'

import schema from '../schema'

export default graphql(schema.BOOKING_QUERY, {
  name: 'bookingQuery',
  options: props => ({
    variables: {
      uuid: props.requisitionTemplate.uuid
    }
  })
})
