import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { MoreOutlined, SyncOutlined } from '@ant-design/icons'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import { Alert, Col, Popover, Row, Typography } from 'antd'
import { Button } from 'antd-v5'

import { ActionItem, Actions } from '@/components/Manage/Styled'
import {
  GET_TIMING_ESTIMATE,
  TRIGGER_ESTIMATE_TIMING_SINGLE
} from '@/components/Transport/Schemas/schema'
import { isActiveLeg } from '@/components/Transport/Utils/jobHelper'
import { usePreviousProps } from '@/hooks/usePreviousProps'
import { PluginComponentLocation } from '@/plugins/options'
import PluginLoader from '@/plugins/PluginLoader'
import { useVDJMStore } from '@/store/vdjm'
import { transLegTimings } from '@/utils/labelMap'
import responseHandler from '@/utils/responseHandler'
import TimeInputWrapper from './TimeInputWrapper'

const LegTimingsWrapper = memo((props: any) => {
  const {
    form,
    leg,
    legs,
    legsLoading,
    refetchLegs,
    setFormError,
    isControlMode,
    setActivityObj,
    setFormSuccessMsg
  } = props

  const legUuid = useVDJMStore.use.legUuid()
  const setLegUuid = useVDJMStore.use.setLegUuid()
  const setTimingData = useVDJMStore.use.setTimingData()

  const client = useApolloClient()

  const [triggerEstimate] = useLazyQuery(TRIGGER_ESTIMATE_TIMING_SINGLE, {
    client,
    onCompleted: res => {
      if (res?.triggerEstimateTimingSingle?.success) {
        if (legUuid) {
          getTimingEstimate({ variables: { legUuid } })
        }
      } else {
        responseHandler('Failed to trigger timing estimate.', 'error')
      }
    },
    onError: err => {
      responseHandler(err, 'error')
    }
  })

  const [getTimingEstimate] = useLazyQuery(GET_TIMING_ESTIMATE, {
    client,
    onCompleted: res => {
      const { start, startOut, end, endOut } = res?.getTimingEstimate || {}
      setTimingData({
        start: start ?? '',
        startOut: startOut ?? '',
        end: end ?? '',
        endOut: endOut ?? ''
      })
    },
    onError: err => {
      responseHandler(err, 'error')
    }
  })

  // useMemo so that this won't overwrite the legActivities state
  const defaultTimings = useMemo(
    () =>
      transLegTimings.map((timing: any) => {
        return {
          ...timing,
          isEditable: false,
          timing: leg?.[timing.label]
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [legActivities, setLegActivities] = useState(defaultTimings)
  const [timingUpdateMsg, setTimingUpdateMsg] = useState('')
  const [dateSuccessMsg, setDateSuccessMsg] = useState('')
  const [dateError, setDateError] = useState('')
  const [, setRenderCount] = useState(0)

  const prevLeg: any = usePreviousProps(leg)

  const refreshLegs = useCallback(() => {
    if (refetchLegs) {
      refetchLegs()
    }
    setRenderCount((count: number) => count + 1)
  }, [refetchLegs])

  // Loads leg timings to a state when the user selects a leg
  useEffect(() => {
    if (prevLeg?.legUuid === leg?.legUuid && prevLeg?.planStart === leg?.planStart) return

    setFormSuccessMsg('')
    setFormError('')
    setDateSuccessMsg('')
    setDateError('')

    setLegActivities((activities: any) => {
      const newActs = activities.map((act: any) => {
        act.timing = leg?.[act.label] && new Date(leg[act.label])
        act.isEditable = false
        act.legStatus = leg?.legStatus

        return act
      })

      const planStartActivity = newActs.find((act: any) => act.label === 'planStart')

      if (planStartActivity?.timing && leg?.legUuid) {
        setLegUuid(leg.legUuid)
        triggerEstimate({ variables: { legUuid: leg.legUuid } })
      }

      const noTiming = newActs?.find((act: any) => !act.timing && isActiveLeg(act))
      if (noTiming) {
        noTiming.isEditable = true
      }

      const isPlanEnd = noTiming?.label.includes('planEnd')
      const hasEndTime = newActs?.find((act: any) => act.label.indexOf('end') >= 0 && act.timing)
      if (isPlanEnd && hasEndTime) {
        noTiming.isEditable = false
        const endOut = newActs?.find((act: any) => act.label.includes('endOut') && isActiveLeg(act))
        if (!endOut?.timing) {
          endOut.isEditable = true
        }
      }

      return newActs
    })

    form.resetFields()
  }, [form, leg, prevLeg, setFormError, setFormSuccessMsg, setLegUuid, triggerEstimate])

  useEffect(() => {
    if (legsLoading) {
      setTimingUpdateMsg(timingUpdateMsg)
    } else {
      setTimingUpdateMsg('')
    }
  }, [legsLoading])

  return (
    <Row>
      {legActivities?.map((activity: any, index: number, array: any) => {
        return (
          <TimeInputWrapper
            key={activity.label}
            // @ts-ignore
            currentActivity={activity}
            leg={leg}
            legs={legs}
            legActivities={array}
            legsLoading={legsLoading}
            refetchLegs={refetchLegs}
            setLegActivities={setLegActivities}
            setDateSuccessMsg={setDateSuccessMsg}
            setDateError={setDateError}
            setRenderCount={setRenderCount}
            setTimingUpdateMsg={setTimingUpdateMsg}
            isControlMode={!!isControlMode}
            setActivityObj={setActivityObj}
          />
        )
      })}

      <Row gutter={24}>
        <Col span={4} />
        <Col span={20}>
          {dateSuccessMsg && (
            <Alert message={dateSuccessMsg} type="success" style={{ fontSize: '12px' }} />
          )}
          {dateError && <Alert message={dateError} type="error" style={{ fontSize: '12px' }} />}
        </Col>
      </Row>
      <div className="flex space-between gap-5 pt-4">
        <Typography.Text className="text-xs" style={{ color: 'green' }}>
          {timingUpdateMsg}
        </Typography.Text>
        <Typography.Text type="secondary" className="text-xs">
          *Press Enter to submit the time.
        </Typography.Text>
      </div>

      <Actions>
        <ActionItem span={24}>
          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Popover
              content={
                <div className="flex flex-col space-y-2">
                  <Button
                    icon={<SyncOutlined />}
                    onClick={refreshLegs}
                    loading={legsLoading}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start'
                    }}
                  >
                    Refresh
                  </Button>
                  <PluginLoader
                    moduleLocations={[PluginComponentLocation.AssetsTimelineVdmUpdateTimingPost]}
                    componentProps={{ leg }}
                  />
                </div>
              }
            >
              <Button>
                <MoreOutlined />
              </Button>
            </Popover>
          </Row>
        </ActionItem>
      </Actions>
    </Row>
  )
})

export default LegTimingsWrapper
