import type { BookingTableColumnProps } from '@/components/Booking/ListView/ColumnBuilder'
import type { Booking } from '@/types/graphql'
import type { PaginationProps } from 'antd/lib/pagination'

import { memo } from 'react'
import { Table, Typography } from 'antd'

import { useBookingStore } from '@/store/booking'

const { Text } = Typography

type BookingsListViewProps = {
  total: number
  columns: BookingTableColumnProps[]
  loading: boolean
  results: Booking[]
  pageSize: number
  currentPage: number
  handlePageChange: (page: number, pageSize?: number) => void
  handleSizeChange: (current: number, size: number) => void
}

const BookingsListView = (props: BookingsListViewProps) => {
  const {
    total,
    columns,
    loading,
    results,
    pageSize,
    currentPage,
    handlePageChange,
    handleSizeChange
  } = props

  const selectedBookingsTable = useBookingStore.use.selectedBookingsTable()
  const setSelectedBookingsTable = useBookingStore.use.setSelectedBookingsTable()

  const pagination: PaginationProps = {
    total,
    showTotal: (total, range) => (
      <Text strong type="secondary">{`${range[0]}-${range[1]} of ${total} bookings`}</Text>
    ),
    showSizeChanger: true,
    pageSize: pageSize || 10,
    current: currentPage + 1,
    onChange: handlePageChange,
    onShowSizeChange: handleSizeChange,
    pageSizeOptions: ['10', '20', '50', '100', '500', '1000']
  }

  return (
    <Table
      className="overflow-x-scroll m-0"
      size="small"
      loading={loading}
      columns={columns}
      dataSource={results}
      pagination={pagination}
      rowKey={(record: Booking) => record.no || record.uuid}
      onRow={(_, index) => ({ id: `booking-row-${index}` })}
      rowSelection={{
        selectedRowKeys: selectedBookingsTable,
        onChange: selectedRowKeys => {
          setSelectedBookingsTable(selectedRowKeys as string[])
        }
      }}
    />
  )
}

export default memo(BookingsListView)
