import { gql } from '@/types'

import { forwardRef, useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Form } from 'antd'
import { compose } from 'recompose'

import { ToggleActions } from '@/components/DocumentViewer/Content/EditableInput/Styled'
import { UPDATE_BOOKING_DOCUMENT } from '@/components/DocumentViewer/Content/schema'
import JobSelect from '@/components/Select/NoQuery/JobSelect'
import TripSelector from '@/components/Select/NoQuery/TripSelector'
import BookingDocumentQuery from '@/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import schema from '@/containers/booking/schema'
import { logger } from '@/utils/logger'
import handleResponse from '@/utils/responseHandler'

const enhance = compose(Form.create())

export const TRIPS_FROM_BOOKING_JOB = gql(`
  query getTripsFromBooking1($uuid: UUID) {
    booking(uuid: $uuid) {
      jobs {
        uuid
        no
        jobNo
        status
        details
        trips {
          uuid
          type
          status
          num
          from {
            name
            areaCode
            zone
          }
          to {
            name
            areaCode
            zone
          }
        }
      }
    }
  }
`)

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}

const getJobsAndTrips = (getFieldsValue: any, bookingDocument: any, bookingTrips: any) => {
  const jobsToSelect = bookingTrips?.booking?.jobs
  const only1Job = jobsToSelect?.length === 1 && jobsToSelect?.[0]?.uuid
  const selectedValues = getFieldsValue()
  const selectedJobUuid =
    selectedValues?.jobUuid || bookingDocument?.jobUuid || only1Job || undefined

  const tripsToSelect = bookingTrips?.booking?.jobs?.find(
    (job: any) => job?.uuid === selectedJobUuid
  )?.trips
  const only1Trip = tripsToSelect?.length === 1 && tripsToSelect?.[0]?.uuid
  const selectedTripUuid =
    selectedValues?.tripUuid || bookingDocument?.tripUuid || only1Trip || undefined

  return [jobsToSelect, selectedJobUuid, tripsToSelect, selectedTripUuid]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditJobAndTrip = forwardRef((props: any, ref: any) => {
  const { bookingDocument, bookingQuery, form } = props
  const { getFieldDecorator, getFieldsValue } = form
  const bookingUuid = bookingQuery?.booking?.uuid

  const [mode, setMode] = useState('view')

  const {
    loading: tripsLoading,
    error: tripsErr,
    data: bookingTrips
  } = useQuery(TRIPS_FROM_BOOKING_JOB, {
    variables: {
      uuid: bookingUuid
    },
    fetchPolicy: 'cache-and-network'
  })
  const [updateBookingDoc, { loading }] = useMutation(UPDATE_BOOKING_DOCUMENT)

  if (tripsLoading) {
    return <span>Loading trips...</span>
  }

  if (tripsErr) {
    logger.error(`Error loading trips from this booking: ${bookingUuid}`, tripsErr)
    handleResponse(tripsErr, 'error')
    return <span>Error loading trips from this booking: {bookingUuid}</span>
  }

  const [jobsToSelect, selectedJobUuid, tripsToSelect, selectedTripUuid] = getJobsAndTrips(
    getFieldsValue,
    bookingDocument,
    bookingTrips
  )

  const onSubmit = () => {
    form.validateFields(async (err: any, values: any) => {
      if (err) return

      try {
        const updateObj = {
          uuid: bookingDocument?.uuid,
          type: bookingDocument?.type,
          jobUuid: values?.jobUuid,
          tripUuid: values?.tripUuid
        }
        await updateBookingDoc({
          variables: updateObj,
          refetchQueries: [
            {
              query: schema.BOOKING_QUERY_WORKFLOW,
              variables: {
                uuid: bookingUuid
              }
            },
            {
              query: BookingDocumentQuery,
              variables: {
                uuid: bookingDocument?.uuid
              }
            }
          ]
        })

        setMode('view')
        handleResponse('Updated job and trip successfully.', 'success')
      } catch (error) {
        logger.error('EditJobAndTrip updateBookingDoc job & trip error', error)
        handleResponse(error, 'error')
      }
    })
  }

  if (
    mode === 'edit' ||
    (!bookingDocument?.jobUuid && bookingDocument?.allowedApprovals?.isJobRequired)
  ) {
    return (
      <>
        <Form>
          <Form.Item label="Job" {...formItemLayout}>
            {getFieldDecorator('jobUuid', {
              initialValue: selectedJobUuid,
              rules: [
                {
                  required: !!bookingDocument?.allowedApprovals?.isJobRequired,
                  message: 'Job is required.'
                }
              ]
            })(<JobSelect jobs={jobsToSelect} />)}
          </Form.Item>
          <Form.Item label="Trip" {...formItemLayout}>
            {getFieldDecorator('tripUuid', {
              initialValue: selectedTripUuid,
              rules: [
                {
                  required: !!bookingDocument?.allowedApprovals?.isTripRequired,
                  message: 'Trip is required.'
                }
              ]
            })(
              <TripSelector
                form={form}
                initialValue={bookingDocument?.tripUuid}
                trips={tripsToSelect}
              />
            )}
          </Form.Item>
        </Form>

        <ToggleActions
          // @ts-ignore
          top="8px"
        >
          <Button type="primary" onClick={onSubmit} loading={loading}>
            Update Job & Trip
          </Button>
        </ToggleActions>
      </>
    )
  }

  return (
    <>
      <ToggleActions>
        <EditOutlined onClick={() => setMode('edit')} />
      </ToggleActions>

      <Form>
        <Form.Item label="Job" {...formItemLayout}>
          {getFieldDecorator('jobUuid', {
            initialValue: bookingDocument?.jobUuid
          })(<JobSelect jobs={jobsToSelect} disabled />)}
        </Form.Item>
        <Form.Item label="Trip" {...formItemLayout}>
          {getFieldDecorator('tripUuid', {
            initialValue: bookingDocument?.tripUuid
          })(
            <TripSelector initialValue={bookingDocument?.tripUuid} trips={tripsToSelect} disabled />
          )}
        </Form.Item>
      </Form>
    </>
  )
})

export default enhance(EditJobAndTrip)
