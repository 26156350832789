import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox } from 'antd-v5'
import filter from 'lodash/filter'

import Wrapper from '@/components/Voucher/Overrides/Styled'
import * as voucherActions from '@/states/reducers/voucher'

const options = [
  { label: 'Override Incomplete Documents', value: 'overrideIncompleteDocs' },
  { label: 'Override Double Voucher', value: 'overrideDoubleVoucher' },
  { label: 'Override Double Reference Number', value: 'overrideDoubleReferenceNumber' }
]

const VoucherOverrides = () => {
  const dispatch = useDispatch()
  const selectedVoucher = useSelector(state => state.voucher.selectedVoucher)

  const handleChange = useCallback(
    checkedValues => {
      const updatedSelectedVoucher = { ...selectedVoucher }

      options.forEach(option => {
        const isSelected = checkedValues.includes(option.value)
        updatedSelectedVoucher[option.value] = isSelected
      })

      dispatch(voucherActions.updateSelectedVoucher(updatedSelectedVoucher))
    },
    [selectedVoucher, dispatch]
  )

  const checkedOptions = filter(options, option => selectedVoucher[option.value])

  return (
    <Wrapper>
      <Checkbox.Group
        options={options}
        onChange={handleChange}
        value={checkedOptions.map(option => option.value)}
      />
    </Wrapper>
  )
}

export default VoucherOverrides
