import { Component } from 'react'
import { Col, Row } from 'antd-v5'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'

import DocumentSelector from '../../DocumentSelector'
import { NoItemsFound, SectionTitle, TableWrapper } from '../Styled'
import withAttachments from './Container'
import Selectables from './Selectables'

class VoucherAttachments extends Component {
  renderDocuments = selectableDocuments => {
    const { selectedVoucher, onRemoveDocument } = this.props
    const removable = includes(['DRAFT', 'NEW'], selectedVoucher.status)

    return (
      <Row>
        {selectableDocuments.map(doc => (
          <Col key={doc.uuid} span={12}>
            <DocumentSelector
              key={doc.uuid}
              uploadedDoc={doc}
              removable={removable}
              hrefUrl={`/booking/document/${doc.uuid}`}
              onRemove={() => onRemoveDocument(doc)}
            />
          </Col>
        ))}
      </Row>
    )
  }

  renderSelectableControl = (selectable, selectableDocuments, selectedDocuments) => {
    const { voucherBooking, onSelectSingleDocument } = this.props

    if (!voucherBooking) {
      return null
    }

    return (
      <Selectables
        selectable={selectable}
        selectedDocuments={selectedDocuments}
        selectableDocuments={selectableDocuments}
        onSelectSingleDocument={onSelectSingleDocument}
      />
    )
  }

  render() {
    const { voucherBooking = { bookingDocuments: [] }, selectedVoucher } = this.props

    const selectableDocuments = filter(
      voucherBooking.bookingDocuments,
      bDoc => bDoc.status !== 'UPLOADED' && bDoc.status !== 'DELETED'
    )

    const selectedDocuments = filter(
      selectableDocuments,
      sDoc => findIndex(selectedVoucher.bookingDocuments, vDoc => vDoc.uuid === sDoc.uuid) !== -1
    )

    const creatable = includes(['DRAFT', 'NEW'], selectedVoucher.status)

    return (
      <>
        <SectionTitle bottom="0" borderless>
          Attachments
          {creatable &&
            this.renderSelectableControl(creatable, selectableDocuments, selectedDocuments)}
        </SectionTitle>
        <TableWrapper>
          {!selectedDocuments.length ? (
            <NoItemsFound align="left">No documents selected.</NoItemsFound>
          ) : (
            this.renderDocuments(selectedDocuments)
          )}
        </TableWrapper>
      </>
    )
  }
}

export default withAttachments(VoucherAttachments)
