import type { Approval } from '@/types/graphql'
import { DocumentStatus } from '@/types/graphql'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import ApprovalSelector from '@/components/DocumentViewer/Content/Approvals/Selector'
import { Wrapper } from '@/components/DocumentViewer/Content/Approvals/Styled'
import { NotAvailable } from '@/components/DocumentViewer/Content/Styled'

const ApprovalsView = ({ bookingDocument, approvedApprovals = [], approvals = [] }) => {
  if (bookingDocument.status === DocumentStatus.Deleted) {
    return <NotAvailable>Document has been deleted.</NotAvailable>
  } else if (bookingDocument.status === DocumentStatus.Uploaded) {
    return <NotAvailable>Document is not verified.</NotAvailable>
  } else if (!approvals || !approvals.length) {
    return <NotAvailable>No approvals required.</NotAvailable>
  }

  return (
    <Wrapper>
      {approvals?.map((approval, index) => {
        const approvedApproval = find(approvedApprovals, (aa: Approval) => aa.type === approval)
        return (
          <ApprovalSelector
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            approvalType={approval}
            approvedApproval={approvedApproval}
            isApproved={!isEmpty(approvedApproval)}
          />
        )
      })}
    </Wrapper>
  )
}

export default ApprovalsView
