import { gql } from '@apollo/client'

export const ROLES_QUERY = gql`
  query rolesMain($input: RolesQuery) {
    roles(input: $input) {
      rows {
        uuid
        name
        code
        tags
        status
        updatedAt
        createdAt
        editBy {
          name
          email
        }
        editByUuid
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

export const CREATE_ROLE = gql`
  mutation createRole1($input: CreateRoleInput!) {
    createRole(input: $input) {
      uuid
    }
  }
`

export const UPDATE_ROLE = gql`
  mutation updateRole1($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      uuid
    }
  }
`
