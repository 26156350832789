import { gql } from 'App/types'

export const GET_FEATURE_FLAGS = gql(`
  query GetFeatureFlags($input: QueryFeatureFlagsInput) {
    featureFlags(input: $input) {
      rows {
        uuid
        baseCompanyUuids
        name
        description
        isEnabled
        status
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const VIEW_FEATURE_FLAG = gql(`
  query ViewFeatureFlag($uuid: UUID!) {
    featureFlag(uuid: $uuid) {
      uuid
      baseCompanyUuids
      name
      description
      isEnabled
      status
    }
  }
  `)

export const CREATE_FEATURE_FLAG = gql(`
  mutation CreateFeatureFlag($input: CreateFeatureFlagInput) {
    createFeatureFlag(input: $input) {
      uuid
      baseCompanyUuids
      name
      description
      isEnabled
      status
    }
  }
`)

export const UPDATE_FEATURE_FLAG = gql(`
  mutation UpdateFeatureFlag($input: UpdateFeatureFlagInput) {
    updateFeatureFlag(input: $input) {
      uuid
      baseCompanyUuids
      name
      description
      isEnabled
      status
    }
  }
`)

export const DELETE_FEATURE_FLAG = gql(`
  mutation DeleteFeatureFlag($uuid: UUID!) {
    deleteFeatureFlag(uuid: $uuid) {
      success
      message
    }
  }
`)

export const UNDELETE_FEATURE_FLAG = gql(`
  mutation UndeleteFeatureFlag($uuid: UUID!) {
    undeleteFeatureFlag(uuid: $uuid) {
      success
      message
    }
  }
`)
