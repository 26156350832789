import type { DynamicField } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'
import {
  CompanyType,
  DocumentGeneratorTemplateFieldQuery,
  DocumentGeneratorTemplateFieldType,
  DynamicFieldControl,
  DynamicFieldQuery,
  DynamicFieldType
} from 'App/types/graphql'

import { Form } from 'antd'
import { startCase } from 'lodash'

import DynamicFieldWrapper from './DynamicFieldWrapper'

const FormItem = Form.Item

interface DataInputProps {
  form: WrappedFormUtils
  entry: [string, any]
  type: DocumentGeneratorTemplateFieldType | undefined
  query: DocumentGeneratorTemplateFieldQuery | undefined | null
  enumName: string | undefined | null
  disabled: boolean
  control: boolean | undefined
  customValues: string | undefined | null
  companyTypes: CompanyType[]
  isAutoPopulate: boolean | undefined
  isMultiple: boolean | undefined
}

const DataInput = (props: DataInputProps) => {
  const {
    form,
    entry,
    enumName,
    disabled,
    type,
    query,
    control,
    customValues,
    companyTypes,
    isAutoPopulate = false,
    isMultiple = false
  } = props

  const { getFieldDecorator } = form

  const [key, value] = entry

  const parseType = (): DynamicFieldType | undefined => {
    switch (type) {
      case DocumentGeneratorTemplateFieldType.Text:
        return DynamicFieldType.Text

      case DocumentGeneratorTemplateFieldType.Date:
        return DynamicFieldType.Date

      default:
        return DynamicFieldType.Selector
    }
  }

  const parseQuery = (): DynamicFieldQuery | undefined => {
    switch (query) {
      case DocumentGeneratorTemplateFieldQuery.Enums:
        return DynamicFieldQuery.Enums

      case DocumentGeneratorTemplateFieldQuery.Place:
        return DynamicFieldQuery.Place

      case DocumentGeneratorTemplateFieldQuery.Port:
        return DynamicFieldQuery.Port

      case DocumentGeneratorTemplateFieldQuery.CustomValues:
        return DynamicFieldQuery.CustomValues

      case DocumentGeneratorTemplateFieldQuery.Company:
        return DynamicFieldQuery.Company

      case DocumentGeneratorTemplateFieldQuery.Address:
        return DynamicFieldQuery.Address

      case DocumentGeneratorTemplateFieldQuery.Department:
        return DynamicFieldQuery.Department

      case DocumentGeneratorTemplateFieldQuery.Quotation:
        return DynamicFieldQuery.Quotation

      default:
        return undefined
    }
  }

  const field: DynamicField = {
    type: parseType(),
    query: parseQuery(),
    control: control ? DynamicFieldControl.Required : DynamicFieldControl.Optional,
    multiple: isMultiple,
    key,
    customValues,
    enumName,
    companyTypes
  }

  return (
    <FormItem key={field.key} label={startCase(field.key ?? '')}>
      {getFieldDecorator(`data.${field.key}`, {
        initialValue: value
      })(<DynamicFieldWrapper disabled={disabled || isAutoPopulate} field={field} />)}
    </FormItem>
  )
}

export default DataInput
