import type { CollectedFileMetaData } from '@/components/Booking/NewBookingForm/SubmitAction/helper'
import type { MakeBookingInput } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Button, message } from 'antd'
import { uniq } from 'lodash'

import convertValuesToInput from '@/components/Booking/NewBookingForm/SubmitAction/convertValuesToInput'
import { collectFiles } from '@/components/Booking/NewBookingForm/SubmitAction/helper'
import makeBookingGql from '@/components/Booking/NewBookingForm/SubmitAction/schema'
import useJobTypes from '@/hooks/useJobTypes'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'
import responseHandler from '@/utils/responseHandler'

type SubmitActionProps = {
  form: WrappedFormUtils
  postSubmitAction: (data: any, files: CollectedFileMetaData[], input: MakeBookingInput) => void
  isOverrideBlockDuplicate: boolean
}

const SubmitAction = memo(
  ({ form, postSubmitAction, isOverrideBlockDuplicate }: SubmitActionProps) => {
    const { t } = useTranslation()

    const { requestJobTypes, jobTypes } = useJobTypes()

    const [isUploadingDoc, setIsUploadingDoc] = useState(false)

    const [makeBooking, { loading }] = useMutation(makeBookingGql)

    useEffect(() => {
      const jobTypesKeys = uniq(form.getFieldValue('jobs')?.map(job => job.type)).filter(
        Boolean
      ) as string[]
      requestJobTypes({ codes: jobTypesKeys })

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.getFieldValue('type'), form.getFieldValue('jobs')])

    const handleClick = useCallback(() => {
      form.validateFields(async (err: any, values: any) => {
        if (!values.details) values.details = {}

        if (err) return responseHandler(t('error.missingInput'), 'error')

        setIsUploadingDoc(true)
        values.isOverrideBlockDuplicate = isOverrideBlockDuplicate
        try {
          const input = convertValuesToInput(values, jobTypes)
          if (!input) return

          const files = collectFiles(input)

          // Clean up the input data by removing file uploads
          files.forEach(({ level, key }) => {
            if (level === 'booking' && input.details) {
              delete input.details[key]
            } else if (level === 'job' && input.jobs) {
              input.jobs.forEach(job => job?.details && delete job.details[key])
            } else if (level === 'trip' && input.jobs) {
              input.jobs.forEach(job =>
                job?.trips?.forEach(trip => trip?.details && delete trip.details[key])
              )
            }
          })

          // @ts-ignore
          const { data } = await makeBooking({ variables: { input } })

          await postSubmitAction(data, files, input as MakeBookingInput)
        } catch (error: any) {
          logger.error('SubmitAction makeBookingGql error', error)
          if (error?.message?.includes('Not allowed to create booking as with this Company')) {
            message.error(error.message)
          } else {
            respHandler(error, 'error')
          }
        } finally {
          setIsUploadingDoc(false)
        }
      })
    }, [t, form, jobTypes, makeBooking, postSubmitAction, isOverrideBlockDuplicate])

    return (
      <Button
        id="submit-new-booking-button"
        type="primary"
        onClick={handleClick}
        loading={loading || isUploadingDoc}
      >
        Submit
      </Button>
    )
  }
)

export default SubmitAction
