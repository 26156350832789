import { memo } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Avatar, Button, Tooltip } from 'antd-v5'

const UserTags = ({ focusToField, selectedUserUuids }: any) => {
  return (
    <>
      {selectedUserUuids?.map((user: any) => (
        <Tooltip key={user.uuid} title={user.email}>
          <Avatar src={user.picture} />
        </Tooltip>
      ))}
      <Button icon={<PlusOutlined />} shape="circle" onClick={focusToField} />
    </>
  )
}
export default memo(UserTags)
