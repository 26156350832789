import Companies from '@/components/Layouts/Companies'
import Logo from '@/components/Layouts/Logo'
import Options from '@/components/Layouts/Options'
import SearchBox from '@/components/Layouts/Search'
import styles from './header.module.css'

const Header = () => (
  <div className={styles.header}>
    <div className={styles.logo}>
      <Logo />
    </div>
    <div className={styles.companies} id="basecompany">
      <Companies />
    </div>
    <div className={styles.searchBox}>
      <SearchBox />
    </div>
    <div className={styles.options}>
      <Options />
    </div>
  </div>
)

export default Header
