import type { Booking } from '@/types/graphql'
import type { ComponentType, CSSProperties } from 'react'
import { BookingStatus } from '@/types/graphql'

import { memo } from 'react'
import { Tooltip } from 'antd-v5'

import bookingStatus from '@/utils/bookingStatus'

type BookingStatusIconProps = {
  booking: Booking
}

const BookingStatusIcon = ({ booking }: BookingStatusIconProps) => {
  const currentStatus = bookingStatus?.[booking.status as BookingStatus] || bookingStatus.default

  const style: CSSProperties = {
    color: currentStatus.color,
    backgroundColor: currentStatus.backgroundColor
  }

  const Icon = currentStatus.icon as ComponentType<{ style: CSSProperties }>

  return (
    <Tooltip title={currentStatus.tips}>
      <Icon style={style} />
    </Tooltip>
  )
}

export default memo(BookingStatusIcon)
