import { memo, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Empty, Result, Row, Skeleton, Table, Typography } from 'antd'
import { find } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import { BASECOMPANIES_QUERY, TRIP_SUMMARIES_QUERY } from '../../Schemas/schema'

export const InputWrapper = styled.div`
  .table-row-highlighted {
    background-color: #e8e8e8;
  }
`

const InlandDistributionTable = memo((props: any) => {
  const { query } = props

  const finalQuery = useMemo(
    () => ({
      startDate: query.startDate,
      endDate: query.endDate,
      baseCompanyUuids: query.baseCompanyUuids,
      transportSources: query.transportSources,
      limit: query.limit
      // startDate: query.startDate && moment(query.startDate).subtract(1, 'days').toDate()
    }),
    [query]
  )

  const { loading, error, data } = useQuery(TRIP_SUMMARIES_QUERY, {
    variables: { input: finalQuery },
    fetchPolicy: 'cache-and-network'
  })

  const {
    loading: bcLoading,
    error: bcError,
    data: bcData
  } = useQuery(BASECOMPANIES_QUERY, { fetchPolicy: 'cache-first' })

  if (bcLoading || loading) {
    return (
      <Row style={{ margin: '16px' }}>
        <Skeleton active />
      </Row>
    )
  }

  if (bcError || error) {
    logger.error('InlandDistributionTable TRIP_SUMMARIES_QUERY error', bcError || error)
    respHandler(bcError || error, 'error')
    return <Result status="warning" title="Failed to load trip summaries." />
  }

  if (!bcData?.baseCompanies?.rows.length || !data?.tripSummaries?.rows?.length) {
    return <Empty description="No data available." />
  }

  // unique zone holder (should add zone as query parameter if changing api)
  const zones = new Set<string>()

  // list of dates from query range, convert to ts as alternative of cloning moment date
  const dateList = Array.from(
    { length: moment(query.endDate).diff(moment(query.startDate), 'days') + 1 },
    (x, i) => moment(query.startDate).startOf('day').add(i, 'days').unix() * 1000
  )

  // base company info for data mapping
  const bcRefList = bcData.baseCompanies.rows

  const dataSource: any[] = []

  const groupedMap: Map<string, any> = data.tripSummaries.rows.reduce(
    (entryMap: Map<any, any>, t: any) => {
      if (t.ladenCount) {
        const key = `${new Date(t.date).getTime()}:${t.baseCompanyUuid}:outbound`
        const value = entryMap.get(key) || {
          [t.to]: 0
        }
        value[t.to] = t.ladenCount + value[t.to] || 0
        zones.add(t.to)
        entryMap.set(key, value)
      }

      if (t.emptyCount) {
        const key = `${new Date(t.date).getTime()}:${t.baseCompanyUuid}:return`
        const value = entryMap.get(key) || {
          [t.from]: 0
        }
        value[t.from] = t.emptyCount + (value[t.from] || 0)
        zones.add(t.from)
        entryMap.set(key, value)
      }

      return entryMap
    },
    new Map()
  )

  const calculateTotal = (array: any[]) => {
    return array.reduce((p: any, c) => {
      if (!c) return p // handle possible c = undefined
      for (const [key, value] of Object.entries(c)) {
        p[key] = value + (p[key] || 0)
      }
      return p
    }, {})
  }

  // generate data based on rows: date -> region -> zone as cols
  dateList.forEach(date => {
    const temp: any[] = []
    query.baseCompanyUuids.forEach((baseCompany: string) => {
      const outbound = groupedMap.get(`${date}:${baseCompany}:outbound`)
      const ret = groupedMap.get(`${date}:${baseCompany}:return`)
      temp.push({
        date,
        baseCompany: bcRefList.find((bc: any) => bc.uuid === baseCompany),
        outbound,
        ret,
        totalO: outbound ? Object.values(outbound).reduce((p: any, c: any) => p + c, 0) : 0,
        totalR: ret ? Object.values(ret).reduce((p: any, c: any) => p + c, 0) : 0,
        key: `${date}:${baseCompany}`
      })
    })

    dataSource.push(...temp)
    dataSource.push({
      date,
      baseCompany: 'TOTAL',
      outbound: calculateTotal(temp.map((tmp: any) => tmp.outbound)),
      ret: calculateTotal(temp.map((tmp: any) => tmp.ret)),
      totalO: temp.reduce((p, c) => p + c.totalO, 0),
      totalR: temp.reduce((p, c) => p + c.totalR, 0),
      key: `${date}:TOTAL`
    })
  })

  const columns = [
    {
      title: '',
      children: [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          render: (text: string, row: any) =>
            typeof row.baseCompany !== 'string' && moment(text).format('ddd, DD MMM,YY')
        },
        {
          title: 'Region',
          dataIndex: 'baseCompany',
          key: 'baseCompany',
          render: (text: any) => {
            if (typeof text === 'string')
              return (
                <Typography.Text strong mark>
                  {text}
                </Typography.Text>
              )
            return text?.shortCode || text?.name || text
          }
        }
      ]
    },
    {
      title: 'OUTBOUND',
      children: [
        ...Array.from(zones).map(zone => {
          return {
            title: zone,
            dataIndex: `outbound.${zone}`,
            key: `outbound.${zone}`,
            render: (text: string) => text || 0
          }
        }),
        {
          title: 'Total',
          dataIndex: 'totalO',
          key: 'totalO'
        }
      ]
    },
    {
      title: 'RETURN',
      children: [
        ...Array.from(zones).map(zone => {
          return {
            title: zone,
            dataIndex: `ret.${zone}`,
            key: `ret.${zone}`,
            render: (text: string) => text || 0
          }
        }),
        {
          title: 'Total',
          dataIndex: 'totalR',
          key: 'totalR'
        }
      ]
    },
    {
      title: '%',
      dataIndex: 'percentage',
      key: 'return_over_outbound',
      render: (text: string, row: any, index: number) => {
        if (!index || !row?.totalR || row.baseCompany === 'TOTAL') return
        const o = find(dataSource, d => {
          if (
            d.baseCompany === row.baseCompany &&
            d.date === row.date - 24 * 60 * 60 * 1000 &&
            d.totalO
          ) {
            return true
          }
        })

        if (o?.totalO) return `${((row.totalR / o.totalO) * 100).toFixed(2)}%`
      }
    }
  ]

  return (
    <InputWrapper>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowClassName={record => (record.baseCompany === 'TOTAL' ? 'table-row-highlighted' : '')}
        bordered
      />
    </InputWrapper>
  )
})

export default InlandDistributionTable
