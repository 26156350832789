import { Component } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Col, Form } from 'antd'
import join from 'lodash/join'
import { compose, withState } from 'recompose'

import MutationView from '@/components/DocumentViewer/Content/EditableInput/MutationView'
import {
  DisplayOnly,
  EditableView,
  ToggleActions
} from '@/components/DocumentViewer/Content/EditableInput/Styled'

class EditableInput extends Component {
  handleToggleMode = () => this.props.toggleEditMode(!this.props.editMode)

  handleCompleted = result => {
    this.handleToggleMode()
    const { mutationProps } = this.props

    if (mutationProps.onCompleted) {
      mutationProps.onCompleted(result)
    }
  }

  handleMutation = (e, onMutate) => {
    e.preventDefault()

    this.props.form.validateFields((err, values) => {
      if (err) return

      const { fieldName, handleUpdates } = this.props

      handleUpdates(onMutate, fieldName, values[fieldName])
    })
  }

  renderModeViews = () => {
    const { editMode, value, fieldName, type } = this.props

    if (!editMode) {
      const displayValue = type === 'tags' ? value && join(value, ', ') : value

      return (
        <DisplayOnly type="flex" align="middle">
          <Col span={22}>{displayValue || '-'}</Col>
          <Col span={2}>
            <ToggleActions>
              <EditOutlined onClick={this.handleToggleMode} />
            </ToggleActions>
          </Col>
        </DisplayOnly>
      )
    }

    const { mutationProps, form } = this.props

    return (
      <MutationView
        form={form}
        type={type}
        value={value}
        fieldName={fieldName}
        mutationProps={mutationProps}
        handleToggleMode={this.handleToggleMode}
        handleMutation={this.handleMutation}
        handleCompleted={this.handleCompleted}
      />
    )
  }

  render() {
    return <EditableView>{this.renderModeViews()}</EditableView>
  }
}

export default compose(Form.create(), withState('editMode', 'toggleEditMode', false))(EditableInput)
