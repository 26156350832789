import { WeightUnitStatus } from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

const sampleData = [
  {
    name: 't',
    code: 't',
    sorting: 1,
    description: 'tons',
    status: 'ACTIVE',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: The required fields are: name (e.g. EXPENSE or INCENTIVE), code (e.g. A003), sorting (e.g. 1)'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Sorting*',
    dataIndex: 'sorting',
    key: 'sorting'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {}
}

const findNullInputs = (weightUnit: any) => {
  if (!weightUnit.code) return { nullError: 'Code is required, e.g. A003.' }
  if (!weightUnit.name) return { nullError: 'Name is required, e.g. ADVANCE.' }
  if (!weightUnit.sorting) return { nullError: 'Sorting is required, e.g. 1.' }
}

const populateWeightUnitObj = (obj: any, weightUnitId: string) => {
  const weightUnitObj: any = {
    uuid: weightUnitId?.trim(),
    code: obj.code?.toString()?.trim(),
    name: obj.name,
    sorting: parseInt(obj.sorting),
    description: obj.description,
    status: obj.status || WeightUnitStatus.Active
  }
  return weightUnitObj
}

const weightUnitsHelper = {
  queryName: 'weightUnits',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
      const weightUnits = results?.data?.weightUnits?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row,
        zzz: ''
      }))
      return weightUnits
    } catch (error) {
      logger.error('weightUnitsHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (weightUnitData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...weightUnit } = weightUnitData

    const nullInputError = findNullInputs(weightUnit)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const weightUnitsQuery = {
          limit: 10000
        }
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          input: weightUnitsQuery
        })
        const weightUnits = results?.data?.weightUnits?.rows

        for (let i = 0; i < weightUnits?.length; i++) {
          cache.uuid[weightUnits[i]?.uuid] = weightUnits[i]?.code
          cache.code[weightUnits[i]?.code] = weightUnits[i]?.uuid
        }
      } catch (error) {
        logger.error('weightUnitsHelper cache getDataGql error', error)
        return error
      }
    }

    // Check weightUnitId
    let weightUnitId: string = ''
    if (cache.uuid[weightUnit?.uuid]) {
      weightUnitId = weightUnit?.uuid
    } else if (cache.code[weightUnit?.code]) {
      weightUnitId = cache.code[weightUnit?.code]
    }

    let mutationResults
    // Update
    if (weightUnitId?.length > 0) {
      try {
        const weightUnitObj = populateWeightUnitObj(weightUnit, weightUnitId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: weightUnitObj
        })
      } catch (error) {
        logger.error('weightUnitsHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create
      try {
        weightUnitId = uuidv4()
        const weightUnitObj = populateWeightUnitObj(weightUnit, weightUnitId)
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: weightUnitObj
        })
      } catch (error) {
        logger.error('weightUnitsHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default weightUnitsHelper
