import type {
  Address as AddressType,
  Booking,
  DynamicField,
  Job,
  Maybe,
  Trip
} from '@/types/graphql'
import { DynamicFieldQuery, DynamicFieldType } from '@/types/graphql'

import { Row, Typography } from 'antd-v5'
import { get, startCase } from 'lodash'
import moment from 'moment'

import { FieldHeader, FieldValueTypography } from '@/components/Booking/General/Labels'
import Address from '@/components/Booking/NewBookingForm/ConfirmationSubForm/CompanyInfo/Address'
import Company from '@/components/Booking/NewBookingForm/ConfirmationSubForm/CompanyInfo/Company'
import { isUuid } from '@/utils/u'

const getData = (booking: Booking | Job | Trip, key: string, dynamicField: DynamicField) => {
  if (dynamicField.type === DynamicFieldType.Date) {
    const splitKey = key.split('.')
    const dateKey = splitKey.length > 1 ? splitKey[1] : key
    const chronologies = get(booking, 'chronologies') || []
    const chron = chronologies.find(c => c?.type === dateKey)
    return moment(chron?.date).format('LLL')
  }

  if (dynamicField.query === DynamicFieldQuery.Company) {
    const value = get(booking.details, key) || get(booking, key)
    const valueUuid = get(booking.details, `${key}Uuid`) || get(booking, `${key}Uuid`)

    if (isUuid(value) || isUuid(valueUuid)) {
      return <Company companyUuid={value || valueUuid} />
    }

    if (value) {
      return `[${value.code || ''}] ${value.name || ''}`
    }
  }

  if (
    [DynamicFieldQuery.Place, DynamicFieldQuery.Port].includes(
      dynamicField.query as DynamicFieldQuery
    )
  ) {
    const place = get(booking?.details, key) || get(booking, key)
    return `${place?.name || ''}`
  }

  if (dynamicField.type === DynamicFieldType.Document) {
    const value: File[] = get(booking.details, key) || get(booking, key) || []
    return value.map((file: File) => file.name).join(', ')
  }

  if (Array.isArray(booking[key])) {
    return (booking[key] || []).join(', ')
  }

  return booking[key] || booking.details?.[key]
}

export const RenderFields = (
  booking: Booking | Job | Trip,
  dynamicField: DynamicField,
  keyPrefix: string = ''
) => {
  const data = getData(booking, dynamicField.key || '', dynamicField)
  return (
    <Row key={`${keyPrefix}-${booking.uuid}-${dynamicField.key}-${data}`}>
      <FieldHeader>{startCase(dynamicField.key || '')} :</FieldHeader>
      <FieldValueTypography id={dynamicField.key as string} copyable={!!data}>
        {data}
      </FieldValueTypography>
    </Row>
  )
}

export const DisplayAddress = (address: Maybe<AddressType | string> | undefined) => {
  if (!address) {
    return <></>
  }

  if (isUuid(address)) {
    return <Address addressUuid={address as string} />
  }

  if (typeof address === 'string') {
    return <>{address}</>
  }

  const {
    address1,
    address2,
    address3,
    address4,
    district,
    postCode,
    name,
    areaCode,
    zone,
    phone
  }: any = address || {}

  const addressStr = [address1, address2, address3, address4, district, postCode]
    .filter(Boolean)
    .join(', ')

  return (
    <>
      <Typography.Text strong>{name}</Typography.Text>
      <br />
      <Typography.Text>
        {addressStr}({areaCode}) [{zone}]
        <br />
        {phone && `Contact: ${phone}`}
      </Typography.Text>
    </>
  )
}
