import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Button, Popconfirm } from 'antd-v5'

import { SUBMIT_GENERATED_DOCUMENT } from '@/components/Booking/General/DocumentGeneratorSection/schema'
import { DocumentGeneratorFormHandler } from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalFooter/utils'
import { useBookingStore } from '@/store/booking'
import respHandler from '@/utils/responseHandler'

interface GenericButtonProps {
  generatedDocument: any
  onChange?: () => void
}

const SubmitButton = (props: GenericButtonProps) => {
  const { generatedDocument, onChange = () => {} } = props

  const { t } = useTranslation()

  const documentGeneratorDataForm = useBookingStore.use.documentGeneratorDataForm()

  const [submitGeneratedDocument] = useMutation(SUBMIT_GENERATED_DOCUMENT)

  const [loading, setLoading] = useState(false)
  const handleConfirm = async () => {
    setLoading(true)

    const formHandler = new DocumentGeneratorFormHandler(
      generatedDocument,
      null,
      documentGeneratorDataForm
    )

    const [success, result] = await formHandler.submitGeneratedDocument(submitGeneratedDocument)

    setLoading(false)

    if (success) {
      onChange()
      respHandler(t('booking.documentGenerator.documentSubmitted'), 'success')
    } else {
      onChange()
      respHandler(result, 'error')
    }
  }

  return (
    <Popconfirm
      arrow={false}
      okType="primary"
      okText={t('common.yes')}
      onConfirm={handleConfirm}
      cancelText={t('common.no')}
      title={t('booking.documentGenerator.documentSubmissionConfirmation')}
    >
      <Button type="primary" loading={loading}>
        {t('common.submit')}
      </Button>
    </Popconfirm>
  )
}

export default SubmitButton
