import { useNavigate } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd-v5'

const BackButton = () => {
  const navigate = useNavigate()

  const handleClick = () => navigate('/')

  return <Button icon={<ArrowLeftOutlined />} style={{ border: 'none' }} onClick={handleClick} />
}

export default BackButton
