import type { ButtonProps } from 'antd/lib/button'
import type { DOMAttributes } from 'react'

import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'

type WithRouterLinkProps = DOMAttributes<HTMLElement> &
  ButtonProps & {
    label: string
    href?: string
  }

const WithRouterLink = (props: WithRouterLinkProps) => {
  const { onClick, href } = props
  const navigate = useNavigate()

  if (href) {
    return (
      <Button
        {...props}
        onClick={(...params) => {
          onClick?.(...params)
          navigate(href)
        }}
      >
        {props.label}
      </Button>
    )
  }

  return <Button {...props}>{props.label}</Button>
}

export default WithRouterLink
