import { Button } from 'antd-v5'
import { compose, withHandlers, withState } from 'recompose'

import BulkSubmitModal from '@/components/Voucher/Management/BulkSubmitModal'

const visibleState = withState('visible', 'setVisible', false)

const handlers = withHandlers({
  handleClick: props => () => {
    const { setVisible } = props
    setVisible(true)
  },
  handleClose: props => status => {
    const { setVisible, onVouchersSubmitted = () => {}, refetch } = props
    setVisible(false)

    if (status === 'done') {
      refetch()
      onVouchersSubmitted()
    }
  }
})

const enhance = compose(visibleState, handlers)

const BulkSubmitButton = props => {
  const { visible, handleClick, handleClose, vouchers = [] } = props

  return (
    <>
      <Button disabled={vouchers.length === 0} onClick={handleClick}>
        Submit for Approval
      </Button>
      {visible && <BulkSubmitModal visible={visible} vouchers={vouchers} onClose={handleClose} />}
    </>
  )
}

export default enhance(BulkSubmitButton)
