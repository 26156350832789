import type {
  DashboardTableRows,
  FormatToDashboardNumberProps,
  Utilization
} from 'App/components/Transport/Dashboard/helpers/types'
import type { ReportJobSummary } from 'App/types/graphql'
import type { Moment } from 'moment'

import { groupBy } from 'lodash'
import moment from 'moment'

import { dailyTimeFormat } from 'App/components/Transport/Dashboard/helpers/daily'
import { monthlyTimeFormat } from 'App/components/Transport/Dashboard/helpers/mtd'
import { ViewOptions } from 'App/components/Transport/Planning/TripSummary/TripSummaryTableWrapper'
import { useOverviewDashboardStore } from 'App/store/overviewDashboard'
import { isMTD } from 'App/utils/u'
import { formatCurrencyAmount, round2Digits } from 'App/utils/voucher'

export const confidentialMetrics: Array<keyof DashboardTableRows> = [
  'mtdDel',
  'mtdCost',
  'mtdRevenue',
  'mtdSell',
  'teu',
  'totalDel',
  'totalCost',
  'totalRevenue',
  'totalSell'
]

export const isCurrency = (key: string): boolean => {
  if (!key) return false

  const toCheck: string = key.toLowerCase()

  return toCheck.includes('revenue') || toCheck.includes('sell') //|| toCheck.includes('cost')
}

const formatMetric = (own: any, subCon: any): string | null => {
  return !own && !subCon ? '-' : `${subCon ? `(${subCon})` : ''} ${own || '-'}`
}

export const getPodSummary = (currSummary: ReportJobSummary) => {
  const webPods = formatMetric(
    currSummary.metrics?.pods?.web?.own,
    currSummary.metrics?.pods?.web?.subCon
  )
  const driverAppPods = formatMetric(
    currSummary.metrics?.pods?.driverApp?.own,
    currSummary.metrics?.pods?.driverApp?.subCon
  )

  return !webPods && !driverAppPods
    ? null
    : `${webPods ? `${webPods}` : '-'},${driverAppPods ? `${driverAppPods}` : '-'}`
}

export const sumByZones = (
  currrentZones: any[],
  field: string,
  aggregator: any,
  dateKey: string
): number => {
  return (
    currrentZones?.reduce((res: number, zone: any) => {
      const subFields = field.split('.')

      if (subFields.length > 1) {
        if (zone[subFields[0]]?.[subFields[1]]) {
          res += Number(zone[subFields[0]][subFields[1]] || 0)
        }
      } else if (zone[field]) {
        res += isCurrency(field) ? round2Digits(zone[field]) : Number(zone[field])
      }

      return res
    }, aggregator[dateKey] || 0) || 0
  )
}

export const formatToDashboardNumber = ({
  tableRowHeaders,
  tableData,
  groupedReportJobSum,
  dateStr,
  selectedGlobalCompany
}: FormatToDashboardNumberProps): any[] => {
  const countryAlpha2: string = selectedGlobalCompany?.country?.alpha2
  const currency: string = selectedGlobalCompany?.company?.currency?.code

  Object.keys(tableRowHeaders).forEach((key: string) => {
    const value = groupedReportJobSum?.[key]?.[dateStr]
    tableData.forEach((d: any) => {
      if (d.key === key) {
        if (value) {
          d[dateStr] = isCurrency(key)
            ? formatCurrencyAmount(value, countryAlpha2, currency).split('.')[0]
            : value
        } else {
          d[dateStr] = '-'
        }
      }
    })
  })

  return tableData
}

export const sumByDateStr = (
  reportJobSummaries: ReportJobSummary[],
  startDate: Moment,
  endDate: Moment,
  sumByStr: string = ViewOptions.MONTHLY
): DashboardTableRows => {
  const groupedSummaries = groupBy(
    reportJobSummaries,
    j => `${moment(j.date).format(dailyTimeFormat)}_${j.bcUuid}`
  )
  const bcUuids: string[] = reportJobSummaries.reduce((res: string[], rjs: any) => {
    if (!res.includes(rjs.bcUuid)) {
      res.push(rjs.bcUuid)
    }
    return res
  }, [])

  const calculateUtilization = (utilizations: Utilization[] = []) => {
    if (!utilizations?.length) return { total: 0, count: 0 }

    return utilizations.reduce(
      ({ total, count }, { tot = 0, c = 0 }) => {
        return { total: total + tot, count: count + c }
      },
      { total: 0, count: 0 }
    )
  }

  // @ts-ignore
  const res: DashboardTableRows = {
    mtdDel: {},
    totalDel: {},
    teu: {},
    mtdCost: {},
    totalCost: {},
    mtdSell: {},
    totalSell: {},
    totalRevenue: {},
    mtdRevenue: {},
    mixedUpdateTimeMins: {},
    mixedJobUpdates: {},
    jobsCreatedAfterShipperDate: {},
    pmUtil: {},
    drUtil: {},
    totalJobs: {},
    mixedPlannedJobs: {},
    mixedDeliveries: {},
    invoiced: {},
    mixedPods: {},
    mixedCompletedJobs: {},
    mixedJobs: {},
    costSheetEdited: {},
    totalDriversUseApp: {}
  }
  const isShowDifference = useOverviewDashboardStore.use.isShowDifference()

  bcUuids.forEach((bcUuid: string) => {
    for (let d = startDate; d <= endDate; d = moment(d).add(1, 'days')) {
      const dateKey: string = d.format(
        sumByStr === ViewOptions.MONTHLY ? monthlyTimeFormat : dailyTimeFormat
      )
      const currSummaries: ReportJobSummary[] =
        groupedSummaries[`${d.format(dailyTimeFormat)}_${bcUuid}`]
      const currSummary: ReportJobSummary = currSummaries?.[0] || {}
      const currZones = currSummary?.zones || []

      if (sumByStr === ViewOptions.MONTHLY) {
        // Compute Month-to-date (MTD) numbers
        if (isMTD(new Date(d.format(dailyTimeFormat)), new Date(endDate.format(dailyTimeFormat)))) {
          res.mtdDel[dateKey] = sumByZones(currZones, 'trips.DEL', res.mtdDel, dateKey)
          res.mtdCost[dateKey] = sumByZones(currZones, 'cost', res.mtdCost, dateKey)
          res.mtdSell[dateKey] = sumByZones(currZones, 'sell', res.mtdSell, dateKey)
          res.mtdRevenue[dateKey] = sumByZones(currZones, 'revenue', res.mtdRevenue, dateKey)
        }

        res.teu[dateKey] = sumByZones(currZones, 'teu', res.teu, dateKey)
      } else if (sumByStr === ViewOptions.DAILY) {
        res.mixedUpdateTimeMins[dateKey] = formatMetric(
          currSummary.metrics?.updateTimeMins?.own?.avg,
          currSummary.metrics?.updateTimeMins?.subCon?.avg
        )
        res.totalDriversUseApp[dateKey] = formatMetric(
          currSummary.metrics?.fractionJobsUpdate?.own?.totalDrCount,
          currSummary.metrics?.fractionJobsUpdate?.subCon?.totalDrCount
        )
        // @ts-ignore
        res.mixedJobUpdates[dateKey] = currSummary.metrics?.fractionJobsUpdate?.own?.total
          ? `${(
              (currSummary.metrics.fractionJobsUpdate.own.dr /
                currSummary.metrics.fractionJobsUpdate.own.total) *
              100
            ).toFixed(2)}`
          : '-'
        //@ts-ignore
        const pmUtil = calculateUtilization(currSummary.pmUtil)
        //@ts-ignore
        const drUtil = calculateUtilization(currSummary.drUtil)
        res.pmUtil[dateKey] = pmUtil.total ? `${pmUtil.count || '-'} / ${pmUtil.total}` : 0
        res.drUtil[dateKey] = drUtil.total ? `${drUtil.count || '-'} / ${drUtil.total}` : 0
      }

      const { jobs, deliveries, invoices } = currSummary.metrics || {
        jobs: {},
        deliveries: {},
        invoices: {}
      }
      const jobCompleted: any = formatMetric(jobs?.completed?.own, jobs?.completed?.subCon)
      const jobPlanned: any = formatMetric(jobs?.own || jobs.total, jobs?.subCon)
      const balancedInvoice = invoices?.completed - invoices?.totalBookings || '-'
      const balancedJobCompleted =
        jobs?.completed?.own + jobs?.completed?.subCon - jobs.total || '-'
      const balancedJobPlanned = jobs?.own + jobs?.subCon - jobs.total || '-'
      const balancedPODs =
        currSummary.metrics?.pods?.web?.own +
          currSummary.metrics?.pods?.web?.subCon +
          currSummary.metrics?.pods?.driverApp?.own +
          currSummary.metrics?.pods?.driverApp?.subCon -
          jobs.total || '-'
      const balancedJobCreatedAfterShipperDate =
        jobs?.createdAfterShipperRequiredDate?.own +
          (jobs?.createdAfterShipperRequiredDate?.subCon || 0) -
          jobs.total || '-'

      res.mixedJobs[dateKey] = jobs.total || '-'
      res.mixedCompletedJobs[dateKey] = jobCompleted
        ? isShowDifference
          ? `${balancedJobCompleted}`
          : jobCompleted
        : '-'
      res.mixedPlannedJobs[dateKey] = jobPlanned
        ? isShowDifference
          ? `${balancedJobPlanned}`
          : jobPlanned
        : '-'
      res.mixedDeliveries[dateKey] = deliveries?.total || '-'
      res.invoiced[dateKey] = isShowDifference
        ? `${balancedInvoice}`
        : invoices?.totalBookings
          ? `${formatMetric(invoices?.own, invoices?.subCon)} / ${invoices?.totalBookings || 0}`
          : '-'

      res.jobsCreatedAfterShipperDate[dateKey] = isShowDifference
        ? `${balancedJobCreatedAfterShipperDate}`
        : formatMetric(
            jobs?.createdAfterShipperRequiredDate?.own,
            jobs?.createdAfterShipperRequiredDate?.subCon
          )
      res.costSheetEdited[dateKey] = currSummary.metrics?.costSheetEdited?.totalBookings
        ? `${((currSummary.metrics.costSheetEdited?.edited / currSummary.metrics.costSheetEdited.totalBookings) * 100).toFixed(2)}`
        : '-'

      res.mixedPods[dateKey] = isShowDifference ? `${balancedPODs}` : getPodSummary(currSummary)
      res.totalDel[dateKey] = sumByZones(currZones, 'trips.DEL', res.totalDel, dateKey)
      res.totalCost[dateKey] = sumByZones(currZones, 'cost', res.totalCost, dateKey)
      res.totalSell[dateKey] = sumByZones(currZones, 'sell', res.totalSell, dateKey)
      res.totalRevenue[dateKey] = sumByZones(currZones, 'revenue', res.totalRevenue, dateKey)
    }
  })

  return res
}
