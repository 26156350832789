import { useMemo } from 'react'
import { Steps } from 'antd-v5'

type FormStepsProps = {
  currentStep: number
  extraItems?: { title: string }[]
}

const FormSteps = ({ currentStep, extraItems = [] }: FormStepsProps) => {
  const items = useMemo(
    () => [
      { title: 'Shipment Information' },
      { title: 'Details' },
      { title: 'Confirmation' },
      ...extraItems
    ],
    [extraItems]
  )

  return <Steps size="small" current={currentStep} items={items} />
}

export default FormSteps
