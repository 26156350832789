import { Col, Form, InputNumber, Row } from 'antd'

const FormItem = Form.Item

const DayAgingFilterFormItem = (props: any) => {
  const {
    form: { getFieldDecorator },
    value
  } = props

  return (
    <FormItem label="Aging (days)">
      <Row gutter={8} type="flex" justify="start">
        <Col>
          <FormItem>
            {getFieldDecorator('dueDate.from', {
              initialValue: value?.from
            })(<InputNumber placeholder="Minimum" />)}
          </FormItem>
        </Col>
        <Col>
          <span>-</span>
        </Col>
        <Col>
          <FormItem>
            {getFieldDecorator('dueDate.to', {
              initialValue: value?.to
            })(<InputNumber placeholder="Maximum" />)}
          </FormItem>
        </Col>
        <Col>
          <span>days</span>
        </Col>
      </Row>
    </FormItem>
  )
}

export default DayAgingFilterFormItem
