import { LogoutOutlined } from '@ant-design/icons'
import { Menu } from 'antd-v5'
import { cloneDeep, pick } from 'lodash'

import Filter from '@/components/Layouts/LeftFilter/Filter'
import { MenuTitle, Sidebar } from '@/components/Layouts/LeftFilter/Styled'
import useBaseCompanyType from '@/hooks/useBaseCompanyType'
import labelMap from '@/utils/labelMap'

const MenuItemGroup = Menu.ItemGroup

const LeftFilter = ({ customOverflow, facets }) => {
  const { data = [] } = useBaseCompanyType()

  const title = (text: string) => (
    <MenuTitle>
      <LogoutOutlined />
      <span>{text}</span>
    </MenuTitle>
  )

  if (!facets) return null

  return (
    <Sidebar customOverflow={customOverflow}>
      <Menu mode="inline" multiple={false}>
        <MenuItemGroup key="general" title={title('General')}>
          {labelMap.miscCategories.map(category => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [key, subKey, sortFnc] = category
            // @ts-expect-error
            let facet = pick(facets, category)[key]

            if (!facet) return null

            if (sortFnc) {
              facet = cloneDeep(facet).sort(sortFnc)
            }

            return <Filter type="general" key={key} titleAttribute={key} facet={facet} />
          })}
        </MenuItemGroup>

        {data?.map(type => (
          <MenuItemGroup key={type?.key} title={title(type.label) as unknown as string}>
            {type.meta?.facets?.map((facetKey: string) => {
              const facet = pick(facets, facetKey)[facetKey]
              return (
                <Filter key={facetKey} type={type.label} titleAttribute={facetKey} facet={facet} />
              )
            })}
          </MenuItemGroup>
        ))}
      </Menu>
    </Sidebar>
  )
}

export default LeftFilter
