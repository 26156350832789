import styled from 'styled-components'

const stdHeight = '35px'

export const Wrapper = styled.div`
  font-size: 13px;
  font-weight: 500;
  text-align: right;

  .ant-select-selection--multiple {
    padding-bottom: 0px;
    min-height: ${stdHeight};
  }

  .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-bottom: 0px;
    line-height: normal;
  }

  .ant-select-selection {
    border: 1px solid white;
    border-radius: 0;
    transition: 0s;
    padding: 4px 0;
    padding-right: 50px;
    min-width: 250px;
  }

  .ant-select-selection:hover {
    border-color: rgb(235, 235, 235);
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-open .ant-select-selection,
  .ant-select-selection:active {
    box-shadow: none;
    border-color: rgb(215, 215, 215);
  }

  .ant-select-selection--multiple .ant-select-selection__choice {
    color: black;
  }

  .ant-select-selection--multiple .ant-select-selection__choice__remove {
    top: 3px;
  }

  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 30px;
    line-height: 25px;
    margin-top: 0px;
  }

  .ant-avatar {
    height: 28px;
    width: 28px;
    border: none;
    margin-right: 3px;
    vertical-align: middle;
  }
`

export const OptionWrapper = styled.div`
  .option-avatar {
    display: inline-block;
    vertical-align: top;
    padding-right: 3px;

    .ant-avatar {
      height: 24px;
      width: 24px;
      border: none;
      margin: unset;
    }
  }

  .option-email {
    display: inline-block;
    vertical-align: middle;
    line-height: 28px;
  }
`

export const OptionLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  color: black;
`

export const OptionSubLabel = styled.div`
  font-size: 12.5px;
  color: rgb(140, 142, 144);
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const AvatarSelector = styled.span`
  .ant-avatar {
    border: dashed 1px rgb(220, 220, 220);
    cursor: pointer;
  }
`
