import { useApolloClient } from '@apollo/client'

import Document from '@/components/Workflow/Document'
import BookingDocumentQuery from '@/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import respHandler from '@/utils/responseHandler'

const UploadedItem = props => {
  const {
    uploadedDoc,
    isHighlighted,
    vouchers = [],
    requests = [],
    handleOpenViewer,
    documentApproval
  } = props

  const client = useApolloClient()

  const handleClick = async () => {
    respHandler('Loading document...', 'load')

    const { data } = await client.query({
      query: BookingDocumentQuery,
      variables: {
        uuid: uploadedDoc.uuid
      }
    })

    if (!data.bookingDocument) {
      respHandler('Unable to load voucher. Please try again later.', 'error')
      return
    }

    handleOpenViewer({
      ...data.bookingDocument,
      allowedApprovals: documentApproval
    })

    respHandler('', 'close')
  }

  return (
    <Document
      uploadedDoc={uploadedDoc}
      vouchers={vouchers}
      requests={requests}
      documentApproval={documentApproval}
      isHighlighted={isHighlighted}
      onClick={handleClick}
    />
  )
}

export default UploadedItem
