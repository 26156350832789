import type { BookingTableColumnProps } from '@/components/Booking/ListView/ColumnBuilder'
import type { BookingsSearchInput } from '@/types/graphql'

import { lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportOutlined } from '@ant-design/icons'
import { Button, Popover, Typography } from 'antd-v5'

const DropDownContent = lazy(() => import('./DropDownContent'))

type ExportBookingListProps = {
  query: BookingsSearchInput
  total: number
  columns: BookingTableColumnProps[]
}

const ExportBookingList = ({ total, query, columns }: ExportBookingListProps) => {
  const { t } = useTranslation()

  return (
    <Popover
      arrow={false}
      placement="bottomLeft"
      styles={{ body: { padding: 0 } }}
      content={<DropDownContent total={total} query={query} columns={columns} />}
    >
      <Button className="border-none box-none">
        <ExportOutlined />
        <Typography.Text strong type="secondary">
          {t('index.export')}
        </Typography.Text>
      </Button>
    </Popover>
  )
}

export default ExportBookingList
