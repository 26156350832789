import { Form, Radio } from 'antd'

const FormItem = Form.Item
const RadioGroup = Radio.Group

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
}

const TransactionTypeFilterFormItem = (props: any) => {
  const {
    form: { getFieldDecorator },
    value
  } = props

  return (
    <FormItem label="Transaction Type">
      {getFieldDecorator('transactionType', {
        initialValue: value
      })(
        <RadioGroup>
          <Radio style={radioStyle}>Both AR and AP</Radio>
          <Radio style={radioStyle} value="ACCREC">
            AR only
          </Radio>
          <Radio style={radioStyle} value="ACCPAY">
            AP only
          </Radio>
        </RadioGroup>
      )}
    </FormItem>
  )
}

export default TransactionTypeFilterFormItem
