import { memo, useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { Button } from 'antd-v5'

import { ColumnFilter } from '@/components/Transport/Planning/Jobs/JobsTable/ColumnFilter'
import { Styles } from '@/components/Transport/Planning/Jobs/JobsTable/styles'
import { getColumns } from '@/components/Workflow/Section/Extras/BookingJobsTable/columns'
import SetColumns from '@/components/Workflow/Section/Extras/BookingJobsTable/SetColumns'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'

type TableProps = {
  data: any
  columns: any
  readOnly: boolean
  defaultColumn: any
  openLegsModal: any
}

type JobsTablePropsType = {
  data: Array<any>
  readOnly: boolean
  openLegsModal: any
}

const Table = memo(({ columns, data, openLegsModal, readOnly }: TableProps) => {
  const { isPortalUser } = useProcessPortalUser()
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any, i: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { key, ...rest } = headerGroup.getHeaderGroupProps()
          return (
            <tr key={headerGroup.headers[i].Header} {...rest}>
              {headerGroup.headers.map((column: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { key, ...rest } = column.getHeaderProps()
                return (
                  <th key={column.Header} {...rest}>
                    {column.render('Header')}
                  </th>
                )
              })}
            </tr>
          )
        })}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, index: number) => {
          prepareRow(row)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { key, ...rest } = row.getRowProps()
          return (
            <tr id={`job-trip-legs-row-${index}`} key={row.id} {...rest}>
              {row.cells.map((cell: any) => {
                const trip = cell.row.original
                const renderByCell = isPortalUser
                  ? cell.column.id === 'PM' || cell.column.id === 'DR'
                  : ''

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { key, ...rest } = cell.getCellProps()
                if (renderByCell) {
                  return (
                    <td key={cell.column.Header} {...rest}>
                      {cell.render('Cell')}
                    </td>
                  )
                }
                const onOpenLegsModal = () => openLegsModal(trip)

                return (
                  <td key={cell.column.Header} {...rest} style={{ ...cell.column.style }}>
                    <Button
                      type="link"
                      style={{
                        padding: 0,
                        width: '100%',
                        display: 'block',
                        fontSize: '10px',
                        textAlign: 'left',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: 'rgba(0, 0, 0, 0.65)',
                        cursor: readOnly ? 'default' : 'pointer'
                      }}
                      onClick={onOpenLegsModal}
                    >
                      {cell.render('Cell')}
                    </Button>
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
})

const JobsTable = memo(({ data, openLegsModal, readOnly }: JobsTablePropsType) => {
  const { isPortalUser } = useProcessPortalUser()
  const [columns, setColumns] = useState(getColumns(isPortalUser))

  const memoData = useMemo(() => data || [], [data])
  const defaultColumn = useMemo(() => ({ Filter: ColumnFilter }), [])

  return (
    <>
      <div className="flex justify-end">
        <SetColumns columns={columns} setColumns={setColumns} />
      </div>

      <Styles>
        <Table
          data={memoData}
          columns={columns}
          readOnly={readOnly}
          defaultColumn={defaultColumn}
          openLegsModal={openLegsModal}
        />
      </Styles>
    </>
  )
})

export default JobsTable
