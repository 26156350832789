import type { CSSProperties, MouseEvent } from 'react'

import { memo, useCallback } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd-v5'
import { t } from 'i18next'

type HelpIconProps = {
  articleUrl: string
  style?: CSSProperties
}

const HelpIcon = ({ articleUrl, style }: HelpIconProps) => {
  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      window.open(articleUrl, '_blank', 'noopener,noreferrer')
    },
    [articleUrl]
  )

  return (
    <Tooltip title={t('common.clickForDetails')}>
      <QuestionCircleOutlined onClick={onClick} style={{ color: 'rgba(0,0,0,0.45)', ...style }} />
    </Tooltip>
  )
}

export default memo(HelpIcon)
