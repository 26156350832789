import type { PortalCompany } from 'App/types/graphql'

import { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Button, Col, Input, Row } from 'antd'

import config from 'App/config'
import useProcessPortalUser from 'App/hooks/useProcessPortalUser'
import { PORTAL_COMPANIES_QUERY } from 'App/utils/importExport/portalCompanies/schema'
import { logger } from 'App/utils/logger'
import handleResponse from 'App/utils/responseHandler'
import { getXlsx, hasPermissionError, removeNestedObjProps } from 'App/utils/u'
import { ActionWrapper } from '../../Companies/Actions/Styled'
import { SearchBox } from '../../Styled'

type TableActionsProps = {
  isLoading: boolean
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TableActions = ({ handleSearch, isLoading }: TableActionsProps) => {
  const { isPortalUser } = useProcessPortalUser()

  const [getPortalCompanies, { loading, data, error }] = useLazyQuery(PORTAL_COMPANIES_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  const queryData = useCallback(() => {
    getPortalCompanies({ variables: { limit: 10000 } })
  }, [getPortalCompanies])

  const getExcelData = useCallback(
    (data: PortalCompany[]) => {
      if (!data?.length) return

      const formattedData: any[] = data?.map((d: PortalCompany, i: number) =>
        removeNestedObjProps({ no: i + 1, ...d }, ['__typename'])
      )
      getXlsx(
        formattedData,
        `Portal_Companies_${new Date().toLocaleDateString()}`,
        'Portal_Companies'
      )
    },
    [data]
  )

  if (error) {
    logger.error('PortalCompany Export Error.', error)
    if (hasPermissionError(error)) {
      handleResponse(config.youDoNotHavePermissionToPerformThisAction, 'warning')
    } else {
      handleResponse(config.anErrorOccurredPleaseTryAgainLater, 'error')
    }
  }

  useEffect(() => {
    getExcelData(data?.portalCompanies?.rows)
  }, [data, getExcelData])

  return (
    <ActionWrapper>
      <Row type="flex" justify="space-between" align="middle">
        <SearchBox span={15}>
          <Input.Search
            allowClear
            loading={isLoading}
            onChange={handleSearch}
            placeholder="Search a portal company here..."
          />
        </SearchBox>

        <Col span={3}>
          <Button loading={loading} icon="download" onClick={queryData}>
            Export
          </Button>
        </Col>

        <Col span={3}>
          {!isPortalUser && (
            <Link to="/manage/portal-company/create">
              <Button icon="plus">Create</Button>
            </Link>
          )}
        </Col>
      </Row>
    </ActionWrapper>
  )
}

export default TableActions
