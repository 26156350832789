import type { Booking, Job, Maybe, User } from '@/types/graphql'
import type { ColumnProps } from 'antd/lib/table'
import type { MouseEvent } from 'react'
import { JobStatus } from '@/types/graphql'

import { Tooltip } from 'antd'
import { get } from 'lodash'
import moment from 'moment'

import BookingStatusIcon from '@/components/Booking/ListView/BookingStatusIcon'
import UserIcon from '@/components/Booking/ListView/UserIcon'
import { getActTime } from '@/components/RelativeDateTime'
import config from '@/config'
import { getContainerStats } from '@/utils/booking'

export type BookingTableColumnProps = ColumnProps<Booking> & {
  fnc?: string
  convert?: Record<string, string>
  onCell?: (booking: Booking) => object
}

const renderWithTooltip = (text?: string) => {
  return (
    <Tooltip title={text} mouseEnterDelay={0.1}>
      {text}
    </Tooltip>
  )
}

export const getShortCntr = (record: Booking) => {
  const activeJobs = record?.jobs?.filter((j: Maybe<Job>) => j?.status !== JobStatus.Cancelled)
  let containerStats = getContainerStats(activeJobs)
    .map(s => `${s.count}x${s.size} ${s.type ? s.type : ''}`)
    .join(', ')
  containerStats = (record.type || record.details.shipmentType || '') + ' ' + containerStats
  return containerStats
}

export const getFormattedDateBookingList = (record: Record<string, unknown>, key: string) => {
  const data = get(record, key)
  return data ? moment(data).format('DD/MM/YY') : '-'
}

export const getNumJobTrip = (record: Record<string, unknown>) => {
  return Array.isArray(record.jobs) ? record.jobs.length : 0
}

const defaultCols: Array<BookingTableColumnProps> = [
  {
    title: 'No.',
    key: 'no',
    width: config.env.production ? '120px' : '150px',
    fnc: 'shortLink',
    dataIndex: 'no'
  },
  {
    title: 'Bill To',
    dataIndex: 'billTo.name',
    key: 'billTo'
  },
  {
    title: 'Shipper',
    dataIndex: 'shipper.name',
    key: 'shipperName'
  },
  {
    title: 'Consignee',
    dataIndex: 'consignee.name',
    key: 'consigneeName'
  },
  {
    title: 'ShortCntr',
    dataIndex: 'shortCntr',
    key: 'shortCntr',
    width: '140px',
    fnc: 'shortCntr'
  },
  {
    title: 'ESTPick/ESTDrop',
    width: '160px',
    key: 'etaEtd',
    fnc: 'etaEtd'
  },
  {
    title: 'Created',
    key: 'createdAt',
    width: '90px',
    fnc: 'date'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '50px',
    fnc: 'bookingStatus'
  }
]

const functions = {
  shortLink: (_, record: Booking) => renderWithTooltip(record.no as string),
  shortCntr: (_, record: Booking) => {
    const containerStats = getShortCntr(record)
    return renderWithTooltip(containerStats)
  },
  jobRequestType: (text: string, record: Booking) => {
    return [record.no, record.details.requestNo, record.type].filter(Boolean).join(' ')
  },
  containerDetails: (_, record: Booking) => {
    const jd = record.jobs?.[0]?.details
    const trailerDetails = jd.trailerType === 'NORMAL' ? '' : ` [${jd.trailerType}]`
    return `${jd.containerNo} (${jd.containerSize}${jd.containerType})${trailerDetails}`
  },
  etaEtd: (_, record: Booking) => {
    const eta = getFormattedDateBookingList(record, 'estPick')
    const etd = getFormattedDateBookingList(record, 'estDrop')
    return renderWithTooltip(`${eta} / ${etd}`)
  },
  date: (_, record: Booking, key: string) => {
    const dateCreated = getFormattedDateBookingList(record, key)
    return renderWithTooltip(dateCreated)
  },
  bookingStatus: (_, record: Booking) => {
    return (
      <>
        <BookingStatusIcon booking={record} />
        {record.assignees?.map(u => <UserIcon key={u?.user?.uuid} user={u?.user as User} />)}
      </>
    )
  },
  bookingStatusHlg: (text: string, record: Booking) => {
    const s = record.lStat
    return (
      <>
        <BookingStatusIcon booking={record} />
        <span style={{ marginLeft: '5px' }}>{s ? `${s.t} ${getActTime(s.ts)}` : ''}</span>
      </>
    )
  },
  stringArray: (text: string[]) => (Array.isArray(text) ? text.join(', ') : '')
}

export const getColumns = (columns?: BookingTableColumnProps[]) => {
  const cols = Array.isArray(columns) ? columns : [...defaultCols]
  const mappedCols = cols.map(s => {
    s.onCell = (record: Booking) => ({
      style: {
        color: 'black',
        cursor: 'pointer',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      },
      // onMouseEnter: () => {},
      onClick: (e: MouseEvent) => {
        const url = `/bookings/${record.no}`
        if (e.ctrlKey || e.metaKey || e.button === 1) {
          window.open(url, '_blank')
        } else {
          window.location.href = url
        }
      }
    })

    if (s.fnc && functions[s.fnc]) {
      s.render = (value: unknown, record) => functions[s.fnc ?? ''](value, record, s.key)
      return s
    }

    if (s.convert) {
      s.render = (text: string) => s.convert?.[text] ?? text
      return s
    }

    if (s.fnc && !functions[s.fnc]) {
      console.error(`GetColumns: fnc ${s.fnc} not found. Please check your baseCompanySettings.`)
      s.render = () => `Fnc '${s.fnc}' not found. Contact IT.`
      return s
    }

    s.render = (text: string) => renderWithTooltip(text)

    return s
  })

  return mappedCols
}
