import { Component } from 'react'
import { Table } from 'antd'

import { NoItemsFound } from '@/components/TableView/Styled'

class TableColumns extends Component {
  render() {
    const {
      items = [],
      pagination,
      renderColumns,
      usePagination,
      selfGenerateKey = true
    } = this.props
    const { onSizeChange } = pagination

    if (items.length === 0) {
      return <NoItemsFound>No items available.</NoItemsFound>
    }

    let dataSource = []

    if (selfGenerateKey) {
      dataSource = items?.map((item, index) => ({
        ...item,
        key: item.uuid || index
      }))
    } else {
      dataSource =
        items &&
        items.map(item => ({
          ...item
        }))
    }

    return (
      <Table
        columns={renderColumns()}
        dataSource={dataSource}
        pagination={
          usePagination
            ? {
                total: pagination?.total,
                simple: true,
                current: pagination?.offset / pagination?.limit + 1,
                pageSize: pagination?.limit,
                onChange: page => onSizeChange(page - 1)
              }
            : false
        }
      />
    )
  }
}

export default TableColumns
