import { Col, Form, InputNumber, Row } from 'antd'

const FormItem = Form.Item
const TotalValueFilterFormItem = (props: any) => {
  const {
    form: { getFieldDecorator },
    value = {}
  } = props

  const initialValueFrom = (value['>='] && value['>='][0]) || null
  const initialValueTo = (value['<='] && value['<='][0]) || null

  return (
    <FormItem label="Total Value">
      <Row gutter={8} type="flex" justify="start">
        <Col>
          <FormItem>
            {getFieldDecorator('total.from', {
              initialValue: initialValueFrom
            })(<InputNumber placeholder="Minimum" />)}
          </FormItem>
        </Col>
        <Col>
          <span>-</span>
        </Col>
        <Col>
          <FormItem>
            {getFieldDecorator('total.to', {
              initialValue: initialValueTo
            })(<InputNumber placeholder="Maximum" />)}
          </FormItem>
        </Col>
      </Row>
    </FormItem>
  )
}

export default TotalValueFilterFormItem
