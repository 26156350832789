import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default WrappedComponent => {
  const WithLogin = props => {
    const { isAuthenticated = false } = props
    const navigate = useNavigate()

    useEffect(() => {
      if (isAuthenticated) navigate('/')
    }, [isAuthenticated, navigate])

    if (isAuthenticated) return <div />

    return <WrappedComponent {...props} />
  }

  return WithLogin
}
