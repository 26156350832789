import styled from 'styled-components'

export const ListWrapper = styled.div`
  min-width: 800px;
  max-width: 1400px;
  margin: auto;
  padding: 16px;
`

export const Wrapper = styled.div`
  width: 800px;
  margin: auto;
`

export const Header = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  color: black;
  letter-spacing: 0.3px;
  padding: 6px 0;
  border-bottom: 1px solid rgb(235, 235, 235);
  margin-bottom: 16px;
`

export const ViewAnchor = styled.a`
  font-size: 12px;
  font-weight: 600;
`

export const Actions = styled.div`
  text-align: left;
  padding: 15px 0 0px;

  .ant-btn-circle {
    margin-right: 5px;

    .anticon {
      font-size: 0.8rem;
    }
  }
`
