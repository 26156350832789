import { WarningOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd-v5'

const WarningTips = ({ message }) => (
  <Tooltip title={<strong>{message}</strong>}>
    <WarningOutlined style={{ color: '#faad14', paddingLeft: 5, cursor: 'pointer' }} />
  </Tooltip>
)

export default WarningTips
