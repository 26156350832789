export const getDataGql = `
  query chargeItemsExport1 (
    $query: String,
    $limit: Int,
    $offset: Int
  ) {
    chargeItems (
      query: $query,
      limit: $limit,
      offset: $offset
    ) {
      rows {
        code
        costCurrency {
          code
          name
          uuid
        }
        costRate
        costTax {
          code
          name
          percentage
          uuid
        }
        description
        expenseCode {
          code
          description
          status
          uuid
        }
        name
        rateType
        revenueCode {
          code
          description
          status
          uuid
        }
        sellCurrency {
          code
          name
          uuid
        }
        sellRate
        sellTax {
          code
          name
          percentage
          uuid
        }
        sequence
        status
        tags
        term
        unit
        codes {
          code
          type
          uuid
        }
        categories {
          code
          uuid
        }
        uuid
      }
    }
  }
`

export const createEntityGql = `
  mutation addChargeItemImport1 ($input: AddChargeItemInput!){
    addChargeItem (input: $input){
      code
      name
      uuid
    }
  }
`

export const updateEntityGql = `
  mutation updateChargeItemImport1 ($input: UpdateChargeItemInput!){
    updateChargeItem (input: $input){
      code
      name
      uuid
    }
  }
`

export const currenciesGql = `
  query currenciesForLookup1 ($query: String, $limit: Int, $offset: Int) {
    currencies (query: $query, limit: $limit, offset: $offset) {
      rows {
        code
        name
        uuid
      }
    }
  }
`

export const taxesGql = `
  query taxesForLookup1 ($limit: Int, $offset: Int) {
    taxes (limit: $limit, offset: $offset) {
      rows {
        code
        name
        percentage
        uuid
      }
    }
  }
`

export const glCodesGql = `
  query glCodesForLookup3 ($q: String, $limit: Int, $offset: Int, $status: GlCodeStatus) {
    glCodes (q: $q, limit: $limit, offset: $offset, status: $status) {
      rows {
        code
        status
        uuid
      }
    }
  }
`

export const chargeCategoriesGql = `
  query chargeCategoriesLookup2 ($query: String, $limit: Int, $offset: Int) {
    chargeCategories(query: $query, limit: $limit, offset: $offset) {
      rows {
        uuid
        code
        type
        description
        sequence
      }
    }
  }
`

export const addChargeItemCodeGql = `
  mutation addChargeItemCodeImport ($input: AddChargeItemCodeInput!) {
    addChargeItemCode (input: $input) {
      uuid
    }
  }
`

export const updateChargeItemCodeGql = `
  mutation updateChargeItemCodeImport ($input: UpdateChargeItemCodeInput!) {
    updateChargeItemCode(input: $input) {
      uuid
    }
  }
`

export const addChargeItemCategoryGql = `
  mutation addChargeItemCategoryImport1 ($chargeItemUuid: UUID!, $chargeCategoryUuid: UUID!) {
    addChargeItemCategory(chargeItemUuid: $chargeItemUuid, chargeCategoryUuid: $chargeCategoryUuid) {
      uuid
    }
  }
`
