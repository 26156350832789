import { Input } from 'antd'
import uuidv4 from 'uuid/v4'

import useGlobalCompanyStore from '@/store/globalCompany'

const HiddenFieldsSubForm = ({ form }) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const { getFieldDecorator } = form

  return (
    <>
      {getFieldDecorator('uuid', { initialValue: uuidv4() })(<Input type="hidden" />)}
      {getFieldDecorator('companyUuid', { initialValue: selectedGlobalCompany?.uuid })(
        <Input type="hidden" />
      )}
    </>
  )
}

export default HiddenFieldsSubForm
