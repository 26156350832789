import { memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Row } from 'antd'
import moment from 'moment'
import queryString from 'query-string'
import styled from 'styled-components'

import { sanitizeToArray } from 'App/components/Transport/Utils/jobHelper'
import Auth0 from 'App/hocs/auth0'
import DashboardWrapper from './DashboardWrapper'
import SearchFiltersForm from './SearchFiltersForm'

interface TransportDashboardProps {
  loggedInUser?: any
}

export const ListWrapper = styled.div`
  padding: 16px;
  scroll-x: scroll;
  overflow-x: auto;
`

const querySize = 10000

const TransportDashboard = memo((props: TransportDashboardProps) => {
  const { loggedInUser } = props

  const location = useLocation()

  const query = useMemo(
    () => queryString.parse(location.search, { arrayFormat: 'comma' }),
    [location.search]
  )
  const selectedGlobalCompany = useSelector(
    (state: any) => state.globalCompany?.selectedGlobalCompany
  )
  const defaultQuery: any = useMemo(
    () => ({
      startDate: query.startDate || moment().subtract(5, 'months').startOf('month').toDate(),
      endDate: query.endDate || moment(),
      baseCompanyUuids: sanitizeToArray(query.baseCompanyUuids) || selectedGlobalCompany?.uuid,
      transportSources: sanitizeToArray(query.transportSources),
      limit: querySize
    }),
    [loggedInUser]
  )

  const [parsedQuery, setParsedQuery] = useState<any>()
  const [refreshCount, setRefreshCount] = useState(0)

  useEffect(() => {
    if (query && Object.keys(query)?.length) {
      setParsedQuery({
        startDate: query.startDate,
        endDate: query.endDate,
        baseCompanyUuids: sanitizeToArray(query.baseCompanyUuids) || loggedInUser.baseCompanyUuids,
        transportSources: sanitizeToArray(query?.transportSources),
        limit: querySize
      })
    }
  }, [refreshCount, query])

  return (
    <Row style={{ minHeight: '90vh', paddingRight: 16 }}>
      <SearchFiltersForm query={parsedQuery || defaultQuery} setRefreshCount={setRefreshCount} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DashboardWrapper query={parsedQuery || defaultQuery} />
      </div>
    </Row>
  )
})

export default Auth0(TransportDashboard)
