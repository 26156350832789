import { gql } from '@apollo/client'

import { companyDetails } from 'App/containers/company/fragment/company'

export default gql`
  query company2($uuid: UUID!) {
    company(uuid: $uuid) {
      ...CompanyDetails
    }
  }

  ${companyDetails}
`
