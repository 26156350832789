import { graphql } from '@apollo/client/react/hoc'

import schema from '../schema'

export default graphql(schema.UNLINK_DOCUMENT_FROM_VOUCHER, {
  props: ({ mutate }) => ({
    unlinkDocumentFromVoucher: params =>
      mutate({
        variables: params
      })
  })
})
