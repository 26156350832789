import { graphql } from '@apollo/client/react/hoc'

import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from 'App/containers/booking/schema/bookingForWorkflowsSlim'
import UPDATE_DOCUMENT_CREATOR from '../schema/updateDocumentCreator'

export default graphql(UPDATE_DOCUMENT_CREATOR, {
  props: ({ mutate, ownProps }) => ({
    updateDocumentCreator: params =>
      mutate({
        variables: {
          input: params
        },
        refetchQueries: [
          {
            query: BOOKING_FOR_WORKFLOWS_SLIM_QUERY,
            variables: {
              uuid:
                (ownProps.booking && ownProps.booking.uuid) ||
                (ownProps.documentCreator && ownProps.documentCreator.bookingUuid)
            }
          }
        ]
      })
  })
})
