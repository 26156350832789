import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  DollarOutlined,
  ExceptionOutlined,
  FileTextOutlined,
  RetweetOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Button, Col, Popconfirm, Popover, Tooltip } from 'antd-v5'
import queryString from 'query-string'

import responseHandler from '@/utils/responseHandler'
import { BookingDetails } from './Styled'

const { Header, Description, Section } = BookingDetails

const CREATE_TRANSPORT_RATE_FROM_BOOKING = gql`
  mutation createTransportRateFromBooking($uuid: UUID) {
    createTransportRateFromBooking(uuid: $uuid) {
      success
    }
  }
`

const RECOMPUTE_COST_SHEET = gql`
  mutation recomputeCostFromVoucherCostSheet($uuid: UUID) {
    recomputeCost(uuid: $uuid) {
      success
    }
  }
`

const COMPUTED_COST_LAST_NODE = gql`
  query computedCostLastNode($uuid: UUID, $rateType: String) {
    computedCostLastNode(uuid: $uuid, rateType: $rateType)
  }
`

const VoucherBookingDetails = props => {
  const { voucherBooking, refetch } = props
  const { quotation, costItems } = voucherBooking
  const [recomputeTooltip, setRecomputeTooltip] = useState(false)
  const [transportRateTooltip, setTransportRateTooltip] = useState(false)

  const costItemUuids = useMemo(() => costItems.map(item => item.uuid), [costItems])
  const q = useMemo(
    () => queryString.stringify({ costItemUuids }, { arrayFormat: 'comma' }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryString, costItemUuids]
  )
  const [createTransportRate, { loading }] = useMutation(CREATE_TRANSPORT_RATE_FROM_BOOKING)
  const [recomputeCostSheet, { loading: recomputeCostSheetLoading }] =
    useMutation(RECOMPUTE_COST_SHEET)

  const [
    getComputedCostLastNode,
    { loading: computedCostLastNodeLoading, data: computedCostLastNodeData }
  ] = useLazyQuery(COMPUTED_COST_LAST_NODE)

  const handleCreateTransportRate = useCallback(async () => {
    try {
      const res = await createTransportRate({ variables: { uuid: voucherBooking.uuid } })
      if (res?.data?.createTransportRateFromBooking?.success) {
        responseHandler('Successfully created transport rate.', 'success')
      }
    } catch (error) {
      responseHandler(error, 'error')
    }
  }, [createTransportRate, voucherBooking.uuid])

  const handleRecomputeCostSheet = useCallback(async () => {
    try {
      const res = await recomputeCostSheet({ variables: { uuid: voucherBooking.uuid } })
      if (res?.data?.recomputeCost?.success) {
        responseHandler('Successfully created transport rate.', 'success')
      }
      refetch()
    } catch (error) {
      responseHandler(error, 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTransportRate, voucherBooking.uuid])

  const handleTraverseNode = useCallback(
    async rateType => {
      try {
        await getComputedCostLastNode({ variables: { uuid: voucherBooking.uuid, rateType } })
      } catch (error) {
        responseHandler(error, 'error')
      }
    },
    [voucherBooking.uuid]
  )

  useEffect(() => {
    if (computedCostLastNodeLoading || !computedCostLastNodeData) return

    const nodes = computedCostLastNodeData.computedCostLastNode

    if (!nodes.length) {
      return responseHandler('No Last Nodes Found', 'error')
    }

    const node = nodes[0]

    window.open(`/manage/rates/${node.rootId}?searchNodeId=${node.nodeId}`, '_blank')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedCostLastNodeData])

  return (
    <>
      <div className="flex justify-between items-center">
        <Col>
          <Section>
            <Header>Quotation Description : </Header>
            <Description>
              {quotation?.description || '-'}
              {quotation && (
                <a
                  href={`/manage/quotations/${quotation.uuid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >{` (${quotation.quotationNo})`}</a>
              )}
            </Description>
          </Section>
        </Col>

        <Col style={{ textAlign: 'right' }}>
          <Popover
            arrow={false}
            title="View Rates for"
            content={
              <div className="flex gap-8">
                <Popconfirm
                  okText="Yes"
                  arrow={false}
                  title="View Rates?"
                  disabled={computedCostLastNodeLoading}
                  onConfirm={() => handleTraverseNode('cost')}
                >
                  <Button>Cost</Button>
                </Popconfirm>

                <Popconfirm
                  okText="Yes"
                  arrow={false}
                  title="View Rates?"
                  disabled={computedCostLastNodeLoading}
                  onConfirm={() => handleTraverseNode('sell')}
                >
                  <Button>Sell</Button>
                </Popconfirm>
              </div>
            }
          >
            <SearchOutlined
              style={{ cursor: 'pointer', color: 'rgb(150,150,150)', marginRight: '0.5rem' }}
            />
          </Popover>

          <Tooltip
            title="Recompute Cost Sheet."
            open={recomputeTooltip}
            onOpenChange={visible => setRecomputeTooltip(visible)}
          >
            <Popconfirm
              okText="Yes"
              arrow={false}
              title="Recompute Cost Sheet?"
              disabled={recomputeCostSheetLoading}
              onConfirm={handleRecomputeCostSheet}
              // @ts-expect-error antd v5 not synced with types
              onClick={() => setRecomputeTooltip(false)}
            >
              <RetweetOutlined style={{ color: 'rgb(150,150,150)', marginRight: '0.5rem' }} />
            </Popconfirm>
          </Tooltip>

          <Tooltip
            title="Create a transport rate from this booking"
            open={transportRateTooltip}
            onOpenChange={visible => setTransportRateTooltip(visible)}
          >
            <Popconfirm
              okText="Yes"
              arrow={false}
              disabled={loading}
              title="Create transport rate?"
              onConfirm={handleCreateTransportRate}
              // @ts-expect-error antd v5 not synced with types
              onClick={() => setTransportRateTooltip(false)}
            >
              <DollarOutlined style={{ color: 'rgb(150,150,150)', marginRight: '0.5rem' }} />
            </Popconfirm>
          </Tooltip>

          <Link
            target="_blank"
            title="Integration Logs"
            to={`/manage/integration-logs?${q}`}
            style={{ color: 'rgb(150,150,150)', marginRight: '0.5rem' }}
          >
            <ExceptionOutlined />
          </Link>

          <Link
            target="_blank"
            title="Cost Sheet"
            style={{ color: 'rgb(150,150,150)' }}
            to={`/bookings/${voucherBooking.uuid}/cost-sheet`}
          >
            <FileTextOutlined />
          </Link>
        </Col>
      </div>
    </>
  )
}

export default memo(VoucherBookingDetails)
