import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { findGqlError, getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

type MutationResultsType = {
  updateTax?: any
  createTax?: any
}

const sampleData = [
  {
    no: 1,
    code: 'SST',
    name: 'test',
    percentage: 10.0,
    isActive: true,
    uuid: '',
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks = '*Note: The required fields are: code and percentage. '

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Percentage*',
    dataIndex: 'percentage',
    key: 'percentage'
  },
  {
    title: 'isActive',
    dataIndex: 'isActive',
    key: 'isActive'
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    ellipsis: true,
    key: 'uuid'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

// cache to hold queried data, to check against data for import
const cache: any = {
  uuid: {},
  code: {}
}

const findBadInputs = (tax: any) => {
  if (!tax.code) {
    return { nullError: 'Code is required, e.g. SST.' }
  }
  if (!tax.percentage) {
    return { nullError: 'Percentage is required, e.g. 10.00.' }
  }
  if (
    tax?.isActive &&
    tax?.isActive?.toString()?.toLowerCase() !== 'true' &&
    tax?.isActive?.toString()?.toLowerCase() !== 'false'
  ) {
    return { nullError: 'isActive should be TRUE or FALSE.' }
  }
}

const populateFields = (obj: any, uuid: string, isUpdate: boolean) => {
  const fields: any = {
    uuid,
    name: obj?.name?.toString(),
    code: obj?.code?.toString()?.trim(),
    percentage: Number(obj?.percentage)
  }

  if (isUpdate) {
    fields.isActive = !!obj?.isActive?.toString()?.toLowerCase()?.includes('true')
  }

  return fields
}

const taxesHelper = {
  queryName: 'taxes',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const response = await getGqlResponse(selectedGlobalCompany, getDataGql, {
        limit: 10000,
        showAll: true
      })
      // add zzz, otherwise there will be a "uuid " key instead of uuid
      const readyForImport = response?.data?.taxes?.rows?.map((d: any, index: number) => ({
        no: index + 1,
        ...d,
        zzz: ''
      }))
      return readyForImport
    } catch (error) {
      logger.error('taxesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (inputData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...tax } = inputData

    const nullInputError = findBadInputs(tax)
    if (nullInputError) {
      return nullInputError
    }

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const res = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          limit: 10000,
          showAll: true
        })
        const queriedData = res?.data?.taxes?.rows

        for (let i = 0; i < queriedData?.length; i++) {
          cache.uuid[queriedData[i]?.uuid] = queriedData[i]
          cache.code[queriedData[i]?.code] = queriedData[i]?.uuid
        }
      } catch (error) {
        logger.error('taxesHelper cache getDataGql error', error)
        return error
      }
    }

    // Check if already exists by uuid or code
    let existingUuid: string = ''
    if (cache.uuid[tax.uuid]) {
      existingUuid = tax.uuid
    } else if (cache.code[tax?.code]) {
      existingUuid = cache.code[tax.code]
    }

    const mutationResults: MutationResultsType = {}
    // If exists, then update
    if (existingUuid?.length > 0) {
      try {
        const toUpdate = populateFields(tax, existingUuid, true)
        mutationResults.updateTax = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: toUpdate
        })
      } catch (error) {
        logger.error('taxesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create new
      try {
        existingUuid = uuidv4()
        const toCreate = populateFields(tax, existingUuid, false)
        mutationResults.createTax = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: toCreate
        })
      } catch (error) {
        logger.error('taxesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    const errorObj = findGqlError(mutationResults)
    if (errorObj) {
      return errorObj
    }

    return mutationResults
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default taxesHelper
