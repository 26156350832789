import { gql } from '@apollo/client'

export const PERMISSION_TYPES_QUERY = gql`
  query permissionTypes {
    permissionTypes {
      resources
      permissions
    }
  }
`

export const PERMISSION_QUERY = gql`
  query permission($role: String) {
    permission(role: $role) {
      resources
      permissions
    }
  }
`

export const CREATE_PERMISSION = gql`
  mutation createPermission($role: String!, $resource: String!, $permission: String!) {
    createPermission(role: $role, resource: $resource, permission: $permission) {
      resources
    }
  }
`

export const DELETE_PERMISSION = gql`
  mutation deletePermission($role: String!, $resource: String!, $permission: String!) {
    deletePermission(role: $role, resource: $resource, permission: $permission) {
      resources
    }
  }
`
