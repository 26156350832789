import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useBookingStore } from '@/store/booking'
import Section from '../../Section'
import AddAction from './AddAction'
import TableView from './TableView'

interface DocumentGeneratorSectionProps {
  bookingUuid: string
}

const DocumentGeneratorSection = ({ bookingUuid }: DocumentGeneratorSectionProps) => {
  const { t } = useTranslation()

  const setBookingUuidForDocumentGenerator =
    useBookingStore.use.setBookingUuidForDocumentGenerator()
  const resetBookingUuidForDocumentGenerator =
    useBookingStore.use.resetBookingUuidForDocumentGenerator()

  useEffect(() => {
    setBookingUuidForDocumentGenerator(bookingUuid)

    return () => {
      resetBookingUuidForDocumentGenerator()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Section
      title={
        <span>
          {t('booking.documentGenerator.sectionTitle')} <AddAction />
        </span>
      }
    >
      <TableView bookingUuid={bookingUuid} />
    </Section>
  )
}

export default DocumentGeneratorSection
