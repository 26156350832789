import { BookingTypeStatus } from '@/types/graphql'

import { forwardRef, useEffect } from 'react'
import { Select } from 'antd'

const Option = Select.Option

const TripSelector = forwardRef((props: any, ref: any) => {
  const { form, initialValue, trips, onChange, value, disabled = false } = props

  useEffect(() => {
    if (!trips?.find((trip: any) => trip?.uuid === initialValue)) {
      form &&
        form.setFieldsValue({
          tripUuid: undefined
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trips])

  const nonDeletedTrips = trips?.filter(cl => cl.status !== BookingTypeStatus.Deleted)

  return (
    <Select
      allowClear
      disabled={disabled}
      filterOption
      onChange={onChange}
      placeholder="Select a trip"
      ref={ref}
      showSearch
      value={value}
      optionFilterProp="children"
      getPopupContainer={(trigger: any) => trigger.parentNode}
    >
      {nonDeletedTrips?.map((trip: any, index: number) => (
        <Option key={trip?.uuid || index} value={trip?.uuid}>
          {`${trip?.num || index + 1}: ${trip?.from?.name} - ${trip?.to?.name}`}
        </Option>
      ))}
    </Select>
  )
})

export default TripSelector
