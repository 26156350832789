import { Row } from 'antd'
import styled from 'styled-components'

export const SearchControl = styled.div`
  width: 320px;
  padding: 6px 0;

  .ant-select {
    width: 100%;

    input {
      font-size: 13px;
      font-weight: 500;
      color: black;
    }

    .anticon {
      margin-right: 3px;
    }
  }

  .ant-select-selection {
    border-radius: 0;
  }

  .ant-select-selection--single {
    height: 32px;
  }

  .ant-select-selection__rendered {
    line-height: 31px;
    margin: 0 9px;
  }
`

export const BookingItem = styled(Row)`
  font-size: 13px;
  font-weight: 500;
  color: black;
  letter-spacing: 0.2px;
  padding: 8px 10px;
  border-bottom: 1px solid rgb(235, 235, 235);
`

export const ListDescription = styled.div`
  font-size: 11.5px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(140, 140, 140);
  padding-bottom: 10px;
  letter-spacing: 0.3px;
`

export const BookingDetails = {
  Section: styled.div`
    padding-bottom: 10px;
  `,
  Header: styled.span`
    font-size: 11.5px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: rgb(150, 150, 150);
    text-transform: uppercase;
  `,
  Description: styled.span`
    font-size: 13px;
    font-weight: 500;
    color: black;
  `
}
