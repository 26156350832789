import ApprovalIcon from '@/components/Workflow/Document/StatusIcons/ApprovalIcon'
import PendingVerificationIcon from '@/components/Workflow/Document/StatusIcons/PendingVerificationIcon'

const StatusIcons = props => {
  const {
    uploadedDoc,
    documentApproval: { approvals = [] }
  } = props

  return (
    <div style={{ float: 'right', marginTop: '2px' }}>
      {uploadedDoc.status === 'UPLOADED' && <PendingVerificationIcon />}

      {approvals?.map(workflowApproval => {
        const approvedApproval = uploadedDoc?.approvals?.find(doc => doc.type === workflowApproval)
        return (
          <ApprovalIcon
            key={workflowApproval}
            workflowApproval={workflowApproval}
            approvedApproval={approvedApproval}
          />
        )
      })}
    </div>
  )
}

export default StatusIcons
