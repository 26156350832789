import styled from 'styled-components'

export const ActionWrapper = styled.div`
  padding: 0px 0 10px;
  text-align: right;

  .ant-btn {
    font-size: 13px;
    height: 30px;
    line-height: 28px;

    .anticon {
      font-size: 14px;
      vertical-align: -0.175em;
    }
  }
`
